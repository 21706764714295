/**
 * @fileoverview LogosCarrusel, this component show a list of the company's customers.
 *
 * @version 1.0
 *
 * @author Kevin Álvarez Barrantes <kevin.alvarez@ucrso.info>
 * History
 * v1.0 - Initial Release
 * ----
 * The first version of LogosCarrusel was writen by Kevin Álvarez B.
 */

import React, { useState } from "react";
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
} from "reactstrap";
import logos1 from "../images/logosTerceros/1.PNG";
import logos2 from "../images/logosTerceros/2.PNG";
import logos3 from "../images/logosTerceros/3.PNG";
import logos4 from "../images/logosTerceros/4.PNG";

import { MDBRow, MDBCol } from "mdbreact";
const items = [
  {
    src: logos1,
  },
  {
    src: logos2,
  },
  {
    src: logos3,
  },
  {
    src: logos4,
  },
];

const LogosCarrusel = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = items.map((item) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.src}
      >
        <img src={item.src} alt={item.altText} />
        <CarouselCaption
          captionText={item.caption}
          captionHeader={item.caption}
        />
      </CarouselItem>
    );
  });

  return (
    <>
      <br />

      <MDBRow>
        <MDBCol sm="12" md="12" lg="12" className="LogosFooter">
          <strong style={{ textAlign: "center", color: "black" }}>
            Agradecemos su confianza
          </strong>
          <hr
            style={{
              backgroundColor: "gray",
              height: 0.5,
            }}
          />
        </MDBCol>
      </MDBRow>
      <Carousel activeIndex={activeIndex} next={next} previous={previous}>
        <CarouselIndicators
          items={items}
          activeIndex={activeIndex}
          onClickHandler={goToIndex}
        />
        {slides}
        <CarouselControl
          direction="prev"
          directionText="Previous"
          onClickHandler={previous}
        />
        <CarouselControl
          direction="next"
          directionText="Next"
          onClickHandler={next}
        />
      </Carousel>
    </>
  );
};
export default LogosCarrusel;
