/**
 * @fileoverview IntroCourses, this component configure the introduction for courses view
 *
 * @version 1.0
 *
 * @author Ester Molina R <maria.molina@ucrso.info>
 * History
 * v1.0 - Initial Realease
 * ----
 * The first version of IntroCourses was written by Ester Molina R.
 */

import React, { Component } from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardTitle,
  MDBBtn,
  MDBCardFooter,
} from "mdbreact";
import { FormGroup } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { baseUrl } from "./baseUrl";
import validations from "./Validations";
import { Link } from "react-router-dom";

class IntroCourses extends Component {
  constructor(props) {
    super(props);
    /**
     * validations:
     * @type {Object}
     * Property that contains methods for validate with regular expressions
     *
     * textID:
     * @type {integer}
     * Property that contains the text identification
     *
     * text:
     * @type {String}
     * Property that contains the text
     */
    this.state = {
      validations: new validations(),
      textID: 0,
      text: "",
    };

    this.getText = this.getText.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.validateData = this.validateData.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    if (sessionStorage.getItem("userType") == 2) {
      this.getText();
    } else if (sessionStorage.getItem("userType") == 3) {
      this.props.history.push("/admin");
    } else {
      this.props.history.push("/");
    }
  }

  /**
   * Method that change the state
   * @param {event} event
   */
  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  /**
   * Method that charge the data from the database
   */
  getText() {
    axios
      .get(baseUrl + "course/getIntro")
      .then((response) => {
        var txt = response.data[0];
        this.setState({
          textID: txt.textID,
          text: txt.textValue,
        });
      })
      .catch((err) => console.log("Ha ocurrido un error inesperado"));
  }

  /**
   * Method that perform the validate data action when an ENTER id clicked
   */
  onKeyEvent = (e) => {
    if (e.key == "Enter") {
      this.validateData(e);
    }
  };

  /**
   * Method that validate the data format
   * @param {event} event
   */
  validateData(event) {
    if (this.state.text.length === 0) {
      this.notify(event, "ERROR", "El texto introductorio no debe estar vacío");
    } else if (
      !this.state.validations.validateTextWithNumberField(this.state.text)
    ) {
      this.notify(
        event,
        "ERROR",
        "El texto introductorio no debe contener caracteres especiales"
      );
    } else {
      this.handleSubmit(event);
    }
  }

  /**
   * Method that notificate the user
   * @param {event} evt
   * @param {String} value
   * @param {String} msj
   */
  notify = (evt, value, msj) => {
    switch (value) {
      case "SUCCESS":
        toast.success(msj);
        break;
      case "ERROR":
        toast.error(msj);
        break;
      case "WARN":
        toast.warn(msj);
        break;
      case "INFO":
        toast.info(msj);
        break;
      default:
        toast.info(msj);
    }
  };

  /**
   * Method that submit the data
   * @param {event} event
   */
  handleSubmit(event) {
    fetch(baseUrl + "course/editText", {
      method: "post",
      body: JSON.stringify({
        textID: this.state.textID,
        text: this.state.text,
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then(this.props.history.push("/admin"));

    event.preventDefault();
  }

  /**
   * Method that render the HTML
   */
  render() {
    return (
      <MDBContainer>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
        <MDBRow>
          <MDBCol className="col-12 col-lg-9 offset-lg-3 mt-3">
            <h3 className="adminTitlesFont">Introducción a los Cursos</h3>
          </MDBCol>
          <MDBCol className="col-12 col-lg-9 offset-lg-3 mb-3">
            <MDBCard style={{ padding: "20px 20px 20px 20px" }}>
              <MDBCardTitle className="cardHeader">
                Información empresarial
              </MDBCardTitle>
              <MDBRow>
                <MDBCol className="col-12">
                  <FormGroup>
                    <textarea
                      rows="5"
                      placeholder="Texto introductorio a los cursos"
                      id="text"
                      name="text"
                      value={this.state.text}
                      onChange={this.handleChange}
                    />
                  </FormGroup>
                </MDBCol>
              </MDBRow>
              <MDBCardFooter color="white">
                <Link to={"/admin"}>
                  <MDBBtn
                    color="purpleButton"
                    className="generalButton purpleButton"
                  >
                    <span className="modalButtonText">Cancelar</span>
                  </MDBBtn>
                </Link>
                <MDBBtn
                  color="greenButton"
                  className="generalButton greenButton"
                >
                  <span className="modalButtonText" onClick={this.validateData}>
                    Guardar
                  </span>
                </MDBBtn>
              </MDBCardFooter>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
  }
}
export default IntroCourses;
