/**
 * @fileoverview HomeConfiguration, this component is configure the home data
 * 
 * @version 1.0
 * 
 * @author Ester Molina R <maria.molina@ucrso.info>
 * History
 * v1.0 - Initial Realease
 * ----
 * The first version of HomeConfiguration was written by Ester Molina R. 
 */

import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardTitle, MDBBtn, MDBCardFooter } from 'mdbreact';
import { FormGroup } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { baseUrl } from './baseUrl';
import validations from './Validations';
import imagen from '../images/imagen.jpeg';
import { Link } from 'react-router-dom';

class HomeConfiguration extends Component {
	constructor(props) {
		super(props);
		/**
		 * validations:
		 * @type {Object}
		 * Property that contains methods for validate with regular expressions
		 * 
		 * image:
		 * @type {object}
		 * Property that contains the front page image
		 * 
		 * intro: 
		 * @type {String}
		 * Property that contains the text for the introduction
		 * 
		 * secondText:
		 * @type {String}
		 * Property that contains the second text of the page
		 * 
		 * introID:
		 * @type {integer}
		 * Property that contains the identification of the introduction
		 * 
		 * secondID:
		 * @type {integer}
		 * Property that contains the identification of the second text
		 * 
		 * imageID:
		 * @type {integer}
		 * Property that contains the identification of the image
		 * 
		 * imageLink:
		 * @type {integer}
		 * Property that contains the image link
		 */
		this.state = {
			validations: new validations(),
			image: null,
			intro: '',
			secondText: '',
			introID: 0,
			secondID: 0,
			imageID: 0,
			imageLink: '',
			url: ''
		};
		this.handleImageChange = this.handleImageChange.bind(this);
		this.handleInput = this.handleInput.bind(this);
		this.getData = this.getData.bind(this);
		this.validateData = this.validateData.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	componentDidMount() {
		if (sessionStorage.getItem('userType') == 2) {
			if (this.state.imageLink.length === 0) {
				document.getElementById('homeImg').style.display = 'none';
			}
			this.getData();
		} else if (sessionStorage.getItem('userType') == 3) {
			this.props.history.push('/admin');
		} else {
			this.props.history.push('/');
		}
	}

	/**
	 * Methos that charge the data from the database
	 */
	getData() {
		axios.get(baseUrl + 'home/getHomeText').then((response) => {
			var textBD = response.data;
			textBD.map((item) => {
				if (item.description === 'Principal') {
					this.setState({
						intro: item.textValue,
						introID: item.textID
					});
				} else {
					this.setState({
						secondText: item.textValue,
						secondID: item.textID
					});
				}
			});
		});

		axios.get(baseUrl + 'home/getHomeMultimedia').then((response) => {
			console.log(response.data.length);
			if (response.data.length !== 0) {
				console.log(response.data[0]);
				var multimedia = response.data[0];
				this.setState({
					imageID: multimedia.multimediaID,
					imageLink: multimedia.link,
					url: '/root/servidor/public/' + multimedia.link
				});
				document.getElementById('homeLabel').textContent =
					multimedia.link.substring(20, 30) +
					multimedia.link.substring(multimedia.link.length - 4, multimedia.link.length);
				document.getElementById('homeImg').src = baseUrl + multimedia.link;
				document.getElementById('homeImg').style.display = 'block';
			}
		});
	}

	/**
	 * Method that change the state
	 * @param {event} e 
	 */
	handleInput(e) {
		const { value, name } = e.target;
		this.setState({
			[name]: value
		});
	}

	/**
	 * Method that change the image state
	 * @param {event} event 
	 */
	handleImageChange(event) {
		if (event.target.files && event.target.files.length === 1) {
			const file = event.target.files[0];
			var name = file.name;
			if (name.length >= 30) {
				name = name.substring(0, 20) + name.substring(name.length - 4, name.length);
			}
			document.getElementById('homeLabel').textContent = name;
			this.setState({
				image: file,
				imageLink: name
			});
		}

		this.readFile(event.target);
	}

	/**
	 * Method that read the upload image
	 * @param {object} input 
	 */
	readFile = (input) => {
		if (input.files && input.files[0]) {
			var reader = new FileReader();
			reader.onload = function(e) {
				var filePreview = document.getElementById('homeImg');
				filePreview.src = e.target.result;
				filePreview.style.display = 'block';
			};
			reader.readAsDataURL(input.files[0]);
		}
	};

	/**
	 * Method that notificate the user
	 * @param {event} evt 
	 * @param {String} value 
	 * @param {String} msj 
	 */
	notify = (evt, value, msj) => {
		switch (value) {
			case 'SUCCESS':
				toast.success(msj);
				break;
			case 'ERROR':
				toast.error(msj);
				break;
			case 'WARN':
				toast.warn(msj);
				break;
			case 'INFO':
				toast.info(msj);
				break;
			default:
				toast.info(msj);
		}
    };
	
	/**
	 * Method that perform the validate data action when an ENTER is clicked
	 * @param {event} e 
	 */
    onKeyEvent =(e)=> {
        if (e.key === "Enter") {
            this.validateData(e);
        }
    }

	/**
	 * Method that validate the data format
	 * @param {event} event 
	 */
	validateData(event) {
		if (this.state.intro.length === 0) {
			this.notify(event, 'ERROR', 'Debe agregar el texto introductorio');
		} else if (!this.state.validations.validateTextWithNumberField(this.state.intro)) {
			this.notify(event, 'ERROR', 'El texto introductorio no debe contener caracteres especiales');
		} else if (this.state.secondText.length === 0) {
			this.notify(event, 'ERROR', 'Debe agregar el resumen de eventos');
		} else if (!this.state.validations.validateTextWithNumberField(this.state.secondText)) {
			this.notify(event, 'ERROR', 'El resumen no debe contener caracteres especiales');
		} else {
			this.handleSubmit(event);
		}
	}

	/**
	 * Method that submit the data
	 * @param {event} event 
	 */
	handleSubmit(event) {
		fetch(baseUrl + 'home/editText', {
			method: 'post',
			body: JSON.stringify({
				textID: this.state.introID,
				description: this.state.intro
			}),
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			}
		});
		fetch(baseUrl + 'home/editText', {
			method: 'post',
			body: JSON.stringify({
				textID: this.state.secondID,
				description: this.state.secondText
			}),
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			}
		});

		var formData = '';
		if (this.state.imageID === 0 && this.state.image !== null) {
			formData = new FormData();
			formData.append('image', this.state.image);
			formData.append('description', 'Portada');
			axios.post(`${baseUrl}home/addImage`, formData);
		} else if (this.state.imageID !== 0 && this.state.image !== null) {
			formData = new FormData();
			formData.append('image', this.state.image);
			formData.append('multimediaImageID', this.state.imageID);
			formData.append('url',this.state.url);
			axios.post(`${baseUrl}home/editImage`, formData);
		}
		this.props.history.push('/admin');
	}

	/**
	 * Method that render the HTML
	 */
	render() {
		return (
			<MDBContainer>
				<ToastContainer
					position="top-right"
					autoClose={5000}
					hideProgressBar
					newestOnTop
					closeOnClick
					rtl={false}
					pauseOnVisibilityChange
					draggable
					pauseOnHover
				/>
				<MDBRow>
					<MDBCol className="col-12 col-lg-9 offset-lg-3 mt-3">
						<h3 className="adminTitlesFont">Configuración página principal</h3>
					</MDBCol>
					<MDBCol className="col-12 col-lg-9 offset-lg-3 mb-3">
						<MDBCard style={{ padding: '20px 20px 20px 20px' }}>
							<MDBCardTitle className="cardHeader">Información empresarial</MDBCardTitle>
							<MDBRow>
								<MDBCol className="col-12">
									<FormGroup>
										<div className="mb-2" style={{ textAlign: 'center' }}>
											<img src={imagen} id="homeImg" alt="logo" width="100%" height="30%" />
										</div>
										<div className="custom-file">
											<input
												type="file"
												className="custom-file-input"
												accept=".jpg,.svg,.jpeg,.png,.gif "
												lang="es"
												id="homeImage"
												name="homeImg"
												onChange={this.handleImageChange}
											/>
											<label className="custom-file-label" id="homeLabel" for="homeImage">
												Imagen principal 500px alto
											</label>
										</div>
									</FormGroup>
								</MDBCol>
							</MDBRow>
							<MDBRow>
								<MDBCol className="col-12">
									<FormGroup>
										<label htmlFor="intro">
											Introducción de la página <font color="red">*</font>
										</label>
										<textarea
											rows="5"
											placeholder="Texto introductorio a la página"
											id="intro"
											name="intro"
											value={this.state.intro}
											onChange={this.handleInput}
										/>
									</FormGroup>
								</MDBCol>
							</MDBRow>
							<MDBRow>
								<MDBCol className="col-12">
									<FormGroup>
										<label htmlFor="secondText">
											Resumen de eventos y cursos <font color="red">*</font>
										</label>
										<textarea
											rows="5"
											placeholder="Resumen de eventos y cursos"
											id="secondText"
											name="secondText"
											value={this.state.secondText}
											onChange={this.handleInput}
										/>
									</FormGroup>
								</MDBCol>
							</MDBRow>
							<MDBCardFooter color="white">
								<Link to={'/admin'}>
									<MDBBtn color="purpleButton" className="generalButton purpleButton">
										<span className="modalButtonText">Cancelar</span>
									</MDBBtn>
								</Link>
								<MDBBtn
									color="greenButton"
									className="generalButton greenButton"
									onClick={this.validateData}
								>
									<span className="modalButtonText">Guardar</span>
								</MDBBtn>
							</MDBCardFooter>
						</MDBCard>
					</MDBCol>
				</MDBRow>
			</MDBContainer>
		);
	}
}
export default HomeConfiguration;
