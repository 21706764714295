/**
 * @fileoverview  ArticleUser it consists of the categories of articles.
 *
 * @version 1.0
 * @author Estrella López López <estrella.lopez@ucrso.info>
 *
 * History - Initial Release
 * ----
 * The first version of ArticleUser was written by Estrella López L.
 */

import React, { Component } from "react";
import { Container, FormGroup } from "reactstrap";
import { Row, Col, MDBCardFooter } from "mdbreact";
import "../../node_modules/swiper/swiper-bundle.min.css";
import { baseUrl } from "./baseUrl";
import axios from "axios";
import { MDBCard, MDBCardImage, MDBCardTitle, MDBCol } from "mdbreact";

class ArticleUser extends Component {
  constructor(props) {
    super(props);
    /**
     * category:
     * @type {String}
     * Property that stores the id of the category.
     *
     * categories:
     * @type {Array}
     * Property that stores the category list.
     *
     * name:
     * @type {String}
     * Property that stores the category name.
     *
     * link:
     * @type {String}
     * Property that stores the link of the document brought from the database.
     */
    this.state = {
      category: 0,
      categories: [],
      name: "",
      link: "",
    };

    this.getCategories = this.getCategories.bind(this);
    this.loadCategoriesArticles = this.loadCategoriesArticles.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  /**
   * Method that loads the elements to be initially displayed on the website.
   */
  componentDidMount() {
    if (
      sessionStorage.getItem("userType") != 2 &&
      sessionStorage.getItem("userType") != 3
    ) {
      this.getCategories();
      this.loadCategoriesArticles();
    } else {
      this.props.history.push("/admin");
    }
  }

  /**
   * Method that loads all the categories with the respective information found in the database.
   */
  getCategories() {
    axios
      .get(baseUrl + "articleUser/getAllCategoriesWithMultimedia")
      .then((response) => {
        this.state.categories = response.data;
        this.setState({ categories: response.data });
      });
  }

  /**
   * Method that redirects to the category articles page if you enter this method.
   * @param {Integer} id
   * @param {String} name
   */
  toggle = (id, name) => () => {
    this.setState({
      category: id,
      name: name,
    });
    this.props.history.push({
      pathname: "/ArticleCategoryUser/" + id,
    });
  };

  /**
   * Method that shows how the categories will be seen.
   */
  loadCategoriesArticles() {
    var tempArray = [];

    this.state.categories.map((elements, key) => {
      tempArray.push(
        <MDBCol style={{ maxWidth: "22rem" }}>
          <MDBCard style={{ heigh: 40, width: 312 }}>
            <a
              class="card-block stretched-link text-decoration-none"
              onClick={this.toggle(elements.categoryID, elements.categoryName)}
            >
              <MDBCardImage
                className="img-fluid"
                src={baseUrl + elements.link}
                waves
              />

              <MDBCardFooter color="sunny-morning-gradient">
                <MDBCardTitle style={{ color: "white" }}>
                  {elements.categoryName}
                </MDBCardTitle>
              </MDBCardFooter>
            </a>
          </MDBCard>
          <br />
        </MDBCol>
      );
    });
    return tempArray;
  }

  /**
   * Method that shows the elements found or added to the web page.
   */
  render() {
    return (
      <Container className="mt-5 ">
        <Row className="mt-12">
          <Col className="col-md-2 col-md-offset-7" className="mx-auto" />
          <Col md="12" className="mx-auto">
            <Row className="mt-0">
              <MDBCol className=" col-12 col-lg-9 offset-lg-0 mt-3">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb white">
                    <li class="breadcrumb-item">
                      <a class="black-text" href="/">
                        Inicio
                      </a>
                    </li>
                    <li class="breadcrumb-item active ">Artículos</li>
                  </ol>
                </nav>
              </MDBCol>
            </Row>

            <Row className="mt-3 col-sm-12">
              <MDBCol className=" col-12 col-lg-9 offset-lg-1 mt-2 ">
                <h3 className="userTitlesFont">Artículos</h3>
              </MDBCol>
            </Row>
            <Row
              className="mt-3 offset-lg-1 col-sm-12 userTextFont"
              style={{ textAlign: "justify" }}
            >
              <Col xs={2} md={6} lg={10}>
                <FormGroup>
                  <label>
                    En esta sección podrás encontrar artículos gratuitos de
                    diversos temas, orientados a enriquecer el conocimiento de
                    nuestros usuarios.
                  </label>
                </FormGroup>
              </Col>
            </Row>

            <Row className="mt-3 col-sm-12">
              <MDBCol className=" col-12 col-lg-9 offset-lg-1 mt-2 ">
                <label
                  className="userSubtitlesFont"
                  style={{ textAlign: "center" }}
                >
                  Categorías
                </label>
              </MDBCol>
            </Row>

            <Row className="mt-3 col-sm-12">
              <MDBCol className=" col-12 offset-lg-1 mt-2 ">
                <div class="d-flex  flex-wrap justify-content-center">
                  {this.loadCategoriesArticles()}
                </div>
              </MDBCol>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }
}
export default ArticleUser;
