/**
 * @fileoverview Category this component consists of the category of a course, in it there is a table that allows you to delete, add and edit.
 *
 * @version 1.0
 * 
 * @author Antony Giovanni Vargas Méndez  <antony.vargas@ucrso.info>
 * @author Estrella López López <estrella.lopez@ucrso.info>
 * 
 * History - Initial Release
 * ----
 * The first version of Category was written by Antony Giovanni Vargas Méndez  and Estrella López López
 */
import React, { Component, Fragment } from 'react';
import { MDBRow, MDBCol, MDBIcon, MDBBtn, MDBTooltip } from 'mdbreact';
import { MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBDataTable, MDBCard, MDBContainer } from 'mdbreact';
import { FormGroup, Container } from 'reactstrap';
import axios from 'axios';
import validations from './Validations';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { baseUrl } from './baseUrl';
import imagen from '../images/imagen.jpeg';

class Category extends Component {
	constructor(props) {
		super(props);
		/**
		 * validations: 
		 * @type {InstanceType}
		 * Property that allows us to instantiate to use the validations in this class.
		 * 
		 * addModal: 
		 * @type {boolean}
		 * Property that changes state to know if the modal will be added.
		 * 
		 * deleteModal:
		 * @type {boolean}
		 * Property that changes state to know if the modal will be removed.
		 * 
		 * categoryName: 
		 * @type {String}
		 * Property that stores the category name.
		 * 
		 * categoryID: 
		 * @type {Integer}
		 * Property that stores the id of the category.
		 *
		 * categories:
		 * @type {Table}
		 * Property that stores the list of categories in table form, consists of name and actions.
		 * 
		 * image:
		 * @type {String}
		 * Property that stores the category image.
		 * 
		 * imageID:
		 * @type {Integer}
		 * Property that stores the id of the image.
		 * 
		 * imageLink:
		 * @type {String}
		 * Property that stores the link of the image brought from the database.
		 */
		this.state = {
			validations: new validations(),
			addModal: false,
			deleteModal: false,
			categoryName: '',
			categoryID: 0,
			categories: {
				columns: [
					{
						label: 'Nombre',
						field: 'categoryName',
						width: 150
					},
					{
						label: 'Acciones',
						field: 'actions',
						width: 150
					}
				],
				rows: []
			},
			image: null,
			imageID: 0,
			imageLink: '',
			imageURL: ''
		};

		this.addModal = this.addModal.bind(this);
		this.deleteModal = this.deleteModal.bind(this);
		this.handleInput = this.handleInput.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.delete = this.delete.bind(this);
		this.validateData = this.validateData.bind(this);
		this.handleImageChange = this.handleImageChange.bind(this);
	}

	/**
	 * Method that validates the name of the category, which comes without symbols or numbers, if okay,
	 * redirects to the method that adds the category to the database.
	 * @param {event} event 
	 */
	validateData(event) {
		if (this.empty(event)) {
			if (!this.state.validations.validateTextField(this.state.categoryName)) {
				this.notify(event, 'ERROR', 'El nombre debe contener solo letras');
			} else {
				this.handleSubmit(event);
			}
		}
	}

	/**
	 * Method that validates that the name of the category and the image are not entered empty in the database, 
	 * it returns false or true if it is correct.
	 * @param {event} event 
	 */
	empty(event) {
		if (this.state.categoryID === 0) {
			if (this.state.categoryName.length === 0) {
				this.notify(event, 'ERROR', 'Debe escribir un nombre para la categoría');
				return false;
			} else if (this.state.image === null || this.state.image === undefined) {
				this.notify(event, 'ERROR', 'Debe seleccionar la imagen de la categoría');
				return false;
			} else {
				return true;
			}
		} else {
			if (this.state.categoryName.length === 0) {
				this.notify(event, 'ERROR', 'Debe escribir un nombre para la categoría');
				return false;
			} else {
				return true;
			}
		}
	}

	/**
	 * Method that receives several parameters and according to them it validates if you enter one validation or another,
	 * depending on this it will close the modal, display the modal, and go to the add, edit or delete method.
	 * @param {String} text 
	 * @param {Integer} id 
	 * @param {String} name 
	 */
	toggle(text, id, name, url) {
		let modalAction = text + 'Modal';
		if (id != null) {
			this.setState({
				[modalAction]: !this.state[modalAction],
				categoryID: id,
				categoryName: name,
				imageURL: url
			});

			this.getData(id, text);
		} else {
			if (this.state[modalAction] === true) {
				this.setState({
					[modalAction]: !this.state[modalAction],
					categoryID: 0,
					categoryName: ''
				});
			} else {
				this.setState({
					imageLink: '',
					[modalAction]: !this.state[modalAction]
				});
			}
		}
	}

	/**
	 * 
	 * Method that, depending on the value of the variable, displays the type of message it is, if it is correctly entered, there was an 
	 * error, an informational message, or a warning message.
	 * Also, a method has been added that reloads categories when editing or adding a category.
	 * @param {String} evt
	 * @param {String} value 
	 * @param {String} msj 
	 */
	notify = (evt, value, msj) => {
		switch (value) {
			case 'SUCCESS':
				if (msj !== 'Se ha agregado correctamente' && msj !== 'Se ha editado correctamente') {
					toast.success(msj);
				} else {
					this.getCategories();
					toast.success(msj);
				}
				break;
			case 'ERROR':
				toast.error(msj);
				break;
			case 'WARN':
				toast.warn(msj);
				break;
			case 'INFO':
				toast.info(msj);
				break;
			default:
				toast.info(msj);
		}
	};

	/**
	 * Method that assigns the value to the category name.
	 * @param {String} e 
	 */
	handleInput(e) {
		const { value, name } = e.target;
		this.setState({
			[name]: value
		});
	}

	/**
	 * Method that, depending on a variable, adds or edits the category data and sends it to the database.
	 * @param {event} event 
	 */
	handleSubmit(event) {
		var formData = '';

		if (this.state.categoryID === 0) {
			formData = new FormData();
			formData.append('categoryName', this.state.categoryName);
			formData.append('image', this.state.image);

			axios
				.post(`${baseUrl}category/addCategory`, formData)
				.then((response) => {
					console.log(response.data);
					this.setState({
						addModal: false,
						categoryName: ''
					});
					this.notify(event, 'SUCCESS', 'Se ha agregado correctamente');
				})
				.catch((err) => console.log(err));
		} else {
			axios
				.post(`${baseUrl}category/editCategory`, {
					categoryID: this.state.categoryID,
					categoryName: this.state.categoryName
				})
				.then((response) => {
					if (this.state.image !== null && this.state.image !== undefined) {
						formData = new FormData();
						formData.append('image', this.state.image);
						formData.append('multimediaImageID', this.state.imageID);
						formData.append('url', this.state.imageURL);
						axios.post(`${baseUrl}category/updateCategoryImage`, formData);
					}
				})
				.then((response) => {
					this.setState({
						categoryID: 0,
						categoryName: '',
						addModal: false
					});
					this.notify(event, 'SUCCESS', 'Se ha editado correctamente');
				})
				.catch((err) => console.log(err));
		}
	}

	/**
	 * Method that receives an id and brings the link of the image from the database that corresponds to the id of the category entered, 
	 * in addition to loading it.
	 * @param {Integer} newCategoryID 
	 */
	getData(newCategoryID, text) {
		if (newCategoryID !== 0) {
			axios
				.get(baseUrl + 'category/getCategoryMultimedia', {
					params: { newCategoryID: newCategoryID }
				})
				.then((response) => {
					var multimedia = response.data[0];

					if (multimedia !== undefined) {
						var name = multimedia.link;
						if (window.innerWidth < 500) {
							name = name.substring(8, 20) + name.substring(name.length - 4, name.length);
						} else {
							name = name.substring(8, 35) + name.substring(name.length - 4, name.length);
						}

						this.setState({
							imageLink: name,
							imageID: multimedia.multimediaID,
							imageURL: '/root/servidor/public/' + multimedia.link
						});

						if (text !== 'delete') {
							document.getElementById('categoryImg').src = baseUrl + multimedia.link;
						}
					}
				});
		}
	}

	/**
	* Method that loads the categories found in the database and places them in a table with the actions, which are delete and edit,
	* in case of pressing each of them a call is made to the toogle method
	*/
	getCategories() {
		axios
			.get(baseUrl + 'category/getAllCategories')
			.then((response) => {
				var dbCategories = response.data;
				this.setState({
					...this.state,
					categories: {
						...this.state.categories,
						rows: dbCategories.map((dbCategory) => {
							return {
								...dbCategory,
								actions: (
									<Fragment>
										<MDBTooltip>
											<MDBBtn
												color="white"
												className="tableControlButton"
												onClick={() =>
													this.toggle('add', dbCategory.categoryID, dbCategory.categoryName)}
											>
												<MDBIcon icon="pen" size="1x" />
											</MDBBtn>

											<div>Editar</div>
										</MDBTooltip>
										<MDBTooltip>
											<MDBBtn
												color="white"
												className="tableControlButton"
												onClick={() =>
													this.toggle(
														'delete',
														dbCategory.categoryID,
														dbCategory.categoryName
													)}
											>
												<MDBIcon icon="trash" size="1x" className="red-text" id="delete" />
											</MDBBtn>
											<div>Eliminar</div>
										</MDBTooltip>
									</Fragment>
								)
							};
						})
					}
				});
			})
			.catch((err) => console.error(err));
	}

	/**
	 * Method that loads the elements to be initially displayed on the website.
	 */
	componentDidMount() {
		if (sessionStorage.getItem('userType') == 2) {
			this.getCategories();
		} else if (sessionStorage.getItem('userType') == 3) {
			this.props.history.push('/admin');
		} else {
			this.props.history.push('/');
		}
	}

	/**
	 * Method that loads the image link to the tag and calls another method to load the image to the src.
	 * @param {event} event 
	 */
	handleImageChange(event) {
		event.preventDefault();

		if (event.target.files && event.target.files.length === 1) {
			const file = event.target.files[0];
			this.setState({
				image: file
			});
			var name = file.name;
			if (window.innerWidth < 500) {
				if (name.length >= 30) {
					name = name.substring(0, 20) + name.substring(name.length - 4, name.length);
				}
			} else {
				if (name.length >= 60) {
					name = name.substring(0, 35) + name.substring(name.length - 4, name.length);
				}
			}
			document.getElementById('labelImage').textContent = name;
		}
		this.readFile1(event.target);
	}

	/**
	 * Method that loads and reads the image in the src structure.
	 * @param {event} input 
	 */
	readFile1 = (input) => {
		if (input.files && input.files[0]) {
			var reader = new FileReader();
			reader.onload = function(e) {
				var filePreview = document.getElementById('categoryImg');
				filePreview.src = e.target.result;
				filePreview.style.display = 'block';
			};
			reader.readAsDataURL(input.files[0]);
		}
	};

	/**
	 * Method that perform the validate data action when an ENTER is clicked
	 * @param {event} e 
	 */
	onKeyEvent = (e) => {
		if (e.key === 'Enter') {
			this.validateData(e);
		}
	};

	/**
	 * Method that removes the selected category from the database, before doing so it checks if the category
	 *  can be removed.
	 * @param {event} event 
	 */
	delete(event) {
		axios(baseUrl + 'category/verifyCategoryForDelete', {
			params: { categoryID: this.state.categoryID }
		})
			.then((results) => {
				var verification = results.data;
				var valid = verification['fun_verifyCategoryForDelete(' + this.state.categoryID + ')'].data[0];

				if (valid === 1) {
					fetch(baseUrl + 'category/deleteCategory', {
						method: 'post',
						body: JSON.stringify({
							categoryID: this.state.categoryID,
							url: this.state.imageURL
						}),
						headers: {
							Accept: 'application/json',
							'Content-Type': 'application/json'
						}
					}).then(() => {
						const newCategoryRows = this.state.categories.rows.filter(
							(row) => row.categoryID !== this.state.categoryID
						);
						this.setState({
							categoryID: 0,
							categoryName: '',
							categories: {
								columns: this.state.categories.columns,
								rows: newCategoryRows
							},
							deleteModal: false
						});
						this.notify(event, 'SUCCESS', 'Se ha eliminado correctamente');
					});
				} else {
					this.setState({
						categoryID: 0,
						categoryName: '',
						deleteModal: false
					});
					this.notify(event, 'WARN', 'No se ha podido eliminar');
				}
			})
			.catch((err) => console.error(err));
		event.preventDefault();
	}

	/**
	 * Method that represents the add modal, it contains the information that is displayed to the user when adding category.
	 */
	addModal() {
		var title = '';
		if (this.state.categoryID === 0) {
			title = 'Agregar Categoría';
		} else {
			title = 'Editar Categoría';
		}
		return (
			<MDBContainer>
				<MDBModal isOpen={this.state.addModal} toggle={() => this.toggle('add')}>
					<MDBModalHeader className="modalHeader modalTitlesFont" toggle={() => this.toggle('add')}>
						{title}
					</MDBModalHeader>
					<MDBModalBody>
						<FormGroup>
							<label className="modalTextFont">
								Nombre de Categoría <font color="red">*</font>
							</label>
							<input
								width="10px"
								className="form-control modalTextFont"
								type="text"
								name="categoryName"
								value={this.state.categoryName}
								onChange={this.handleInput}
								onKeyPress={this.onKeyEvent}
								required
							/>
						</FormGroup>

						<FormGroup>
							<div class="form-group">
								<img
									src={imagen}
									alt="logo"
									id="categoryImg"
									width="100%"
									height="30%"
									style={{ textAlign: 'center' }}
								/>
								<div className="imageSize" id="size">
									Tamaño recomendado es 1920 x 1280
								</div>
								<label>
									Seleccione una imagen <font color="red">*</font>
								</label>
								<div className="custom-file">
									<input
										type="file"
										className="custom-file-input"
										lang="es"
										id="courseImage"
										name="image"
										accept=".jpg,.svg,.jpeg,.png"
										onChange={this.handleImageChange}
										onKeyPress={this.onKeyEvent}
										required
									/>
									<label className="custom-file-label" id="labelImage" htmlFor="customFile">
										{this.state.imageLink === '' && <p>Elegir archivo </p>}
										{this.state.imageLink != null && this.state.imageLink}
									</label>
								</div>
							</div>
						</FormGroup>
					</MDBModalBody>
					<MDBModalFooter>
						<MDBBtn
							color="purpleButton"
							className="modalButton purpleButton"
							onClick={() => this.toggle('add')}
						>
							<span className="modalButtonText">Cancelar</span>
						</MDBBtn>
						<MDBBtn
							color="greenButton"
							className="modalButton greenButton"
							type="submit"
							onClick={this.validateData}
						>
							<span className="modalButtonText">Guardar</span>
						</MDBBtn>
					</MDBModalFooter>
				</MDBModal>
			</MDBContainer>
		);
	}

	/**
	 * Category elimination method, consists of showing the category elimination to the user.
	 */
	deleteModal() {
		return (
			<MDBContainer>
				<MDBModal isOpen={this.state.deleteModal} toggle={() => this.toggle('delete')}>
					<MDBModalHeader className="modalHeader modalTitlesFont" toggle={() => this.toggle('delete')}>
						Eliminar Categoría
					</MDBModalHeader>
					<MDBModalBody>
						<FormGroup className="text-center">
							<label className="modalTextFont">
								¿Desea eliminar esta categoría?<br />Esta acción es irreversible
							</label>
						</FormGroup>
					</MDBModalBody>
					<MDBModalFooter>
						<MDBBtn
							color="purpleButton"
							className="modalButton purpleButton"
							onClick={() => this.toggle('delete')}
						>
							<span className="modalButtonText">Cancelar</span>
						</MDBBtn>
						<MDBBtn color="redButton" className="modalButton redButton" onClick={this.delete}>
							<span className="modalButtonText">Eliminar</span>
						</MDBBtn>
					</MDBModalFooter>
				</MDBModal>
			</MDBContainer>
		);
	}

	/**
	 * Method that shows the elements found or added to the web page.
	 */
	render() {
		return (
			<div>
				<Container>
					<this.addModal />
					<this.deleteModal />
					<ToastContainer
						position="top-right"
						autoClose={5000}
						hideProgressBar
						newestOnTop
						closeOnClick
						rtl={false}
						pauseOnVisibilityChange
						draggable
						pauseOnHover
					/>
					<MDBRow>
						<MDBCol className="col-12 col-lg-9 offset-lg-3 mt-3">
							<h3 className="adminTitlesFont">Administración de Categorías</h3>
						</MDBCol>
						<MDBCol className="col-12 col-lg-9 offset-lg-3">
							<MDBBtn className="addButtom" color="orange" onClick={() => this.toggle('add')}>
								<MDBIcon icon="plus" color="plusIcon" className="plusIcon" size="lg" />
							</MDBBtn>
							<MDBCard style={{ padding: '20px 20px 20px 20px' }}>
								<MDBDataTable
									data={this.state.categories}
									hover
									striped
									bordered
									entriesLabel={'Mostrar'}
									searchLabel={'Buscar'}
									paginationLabel={[ 'Anterior', 'Siguiente' ]}
									infoLabel={[ 'Mostrando', 'de', 'de un total de', 'registros' ]}
									entries={5}
									entriesOptions={[ 5, 10, 15 ]}
									small
									responsive
									noBottomColumns={true}
									noRecordsFoundLabel="No se han encontrado datos"
									theadTextWhite={true}
									pagesAmount={4}
								/>
							</MDBCard>
						</MDBCol>
					</MDBRow>
				</Container>
			</div>
		);
	}
}

export default Category;
