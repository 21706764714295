/**
 * @fileoverview Article, this component allow to show the complete list of the articles in the database.
 * 
 * @version 1.0
 * 
 * @author Anthony Vargas Méndez <anthony.vargas@ucrso.info>
 * History
 * v1.0 - Initial Release
 * ----
 * The first version of Article was writen by Anthony Vargas Méndez
 */
import React, { Component, Fragment } from 'react';
import { MDBRow, MDBCol, MDBIcon, MDBBtn, MDBTooltip } from 'mdbreact';
import { MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBDataTable, MDBCard, MDBContainer } from 'mdbreact';
import { FormGroup, Container } from 'reactstrap';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

import { baseUrl } from './baseUrl';

class Article extends Component {
	state = {
		/**
		 * deleteModal:
		 * @type {boolean}
		 * Property that indicates the value of deleteModal to show the modal
		 * 
		 * articleName:
		 * @type {string}
		 * Property that indicates the name of the article
		 * 
		 * articleID:
		 * @type {Integer}
		 * Property that indicates the article ID
		 * 
		 * description:
		 * @type {string}
		 * Property that indicates the description of the article
		 * 
		 * author:
		 * @type {string}
		 * Property that indicates the author of the article
		 * 
		 * document:
		 * @type {file}
		 * Property that indicates the file of the document
		 * 
		 * articles:
		 * @type {object}
		 * Property indicates the object that contain information of articles to charge the table
		 * 
		 */
		deleteModal: false,
		articleName: '',
		articleID: 0,
		description: '',
		author: '',
		document: null,
		documentURL: '',
		imageURL: '',
		articles: {
			columns: [
				{
					label: 'Nombre del artículo',
					field: 'articleName',
					width: 150
				},
				{
					label: 'Autor',
					field: 'author',
					width: 150
				},
				{
					label: 'Acciones',
					field: 'actions',
					width: 150
				}
			],
			rows: []
		}
	};

	deleteModal = this.deleteModal.bind(this);
	delete = this.delete.bind(this);

	/**
	 * Method that specify the different alerts in the component
	 */
	notify = (evt, value, msj) => {
		switch (value) {
			case 'SUCCESS':
				toast.success(msj);
				break;
			case 'ERROR':
				toast.error(msj);
				break;
			case 'WARN':
				toast.warn(msj);
				break;
			case 'INFO':
				toast.info(msj);
				break;
			default:
				toast.info(msj);
		}
	};

	/**
	 * Method that show or not the modals with the articleID
	 */
	toggle(text, id) {
		let modalAction = text + 'Modal';
		if (id != null) {
			this.setState({
				[modalAction]: !this.state[modalAction],
				articleID: id
			});
		} else {
			if (this.state[modalAction] === true) {
				this.setState({
					[modalAction]: !this.state[modalAction],
					articleID: 0
				});
			} else {
				this.setState({
					[modalAction]: !this.state[modalAction]
				});
			}
		}
	}

	/**
	 * Method that delete a specific article of the data base, and call the api that delete it, and update a new article list
	 */
	delete(event) {
		var newArticleRows = [];
		var documentURL = '';
		var imageURL = '';
		axios
			.get(baseUrl + 'article/getMultimediaArticle', {
				params: { articleID: this.state.articleID }
			})
			.then((response) => {
				var multimedia = response.data;
				multimedia.map((data) => {
					if (data.multimediaTypeID === 4) {
						documentURL = '/root/servidor/public/' + data.link;
					} else {
						imageURL = '/root/servidor/public/' + data.link;
					}
				});

				axios
					.post(baseUrl + 'article/deleteArticle', {
						articleID: this.state.articleID,
						documentURL: documentURL,
						imageURL: imageURL
					})
					.then(
						(newArticleRows = this.state.articles.rows.filter(
							(row) => row.articleID !== this.state.articleID
						)),
						this.setState({
							articleID: 0,
							deleteModal: false,
							articles: {
								columns: this.state.articles.columns,
								rows: newArticleRows
							}
						}),
						this.notify(event, 'SUCCESS', 'Se ha eliminado correctamente')
					)
					.catch((err) => console.error(err));
			});
		event.preventDefault();
	}

	/**
	 * Method that get all articles from de database and set in the articles and assign the buttons of edit an delete
	 */
	getArticles() {
		axios
			.get(baseUrl + 'article/getAllArticles')
			.then((response) => {
				var dbArticles = response.data;
				this.setState({
					...this.state,
					articles: {
						...this.state.articles,
						rows: dbArticles.map((dbArticle) => {
							return {
								...dbArticle,
								actions: (
									<Fragment>
										<MDBTooltip>
											<Link to={`/Articles/${dbArticle.articleID}`}>
												<MDBBtn color="white" className="tableControlButton">
													<MDBIcon icon="pen" size="1x" />
												</MDBBtn>
											</Link>
											<div>Editar</div>
										</MDBTooltip>
										<MDBTooltip>
											<MDBBtn
												color="white"
												className="tableControlButton"
												onClick={() => this.toggle('delete', dbArticle.articleID)}
											>
												<MDBIcon icon="trash" size="1x" className="red-text" id="delete" />
											</MDBBtn>
											<div>Eliminar</div>
										</MDBTooltip>
									</Fragment>
								)
							};
						})
					}
				});
			})
			.catch((err) => console.error(err));
	}

	/**
	 * Method that charge and indicates the session storage
	 */
	componentDidMount() {
		if (sessionStorage.getItem('userType') == 2) {
			this.getArticles();
		} else if (sessionStorage.getItem('userType') == 3) {
			this.props.history.push('/admin');
		} else {
			this.props.history.push('/');
		}
	}

	/**
	 * Method that view a delete modal for delete the article
	 */
	deleteModal() {
		return (
			<MDBContainer>
				<MDBModal isOpen={this.state.deleteModal} toggle={() => this.toggle('delete')}>
					<MDBModalHeader className="modalHeader modalTitlesFont" toggle={() => this.toggle('delete')}>
						Eliminar Artículo
					</MDBModalHeader>
					<MDBModalBody>
						<FormGroup className="text-center">
							<label className="modalTextFont">
								¿Desea eliminar este artículo?<br />Esta acción es irreversible
							</label>
						</FormGroup>
					</MDBModalBody>
					<MDBModalFooter>
						<MDBBtn
							color="purpleButton"
							className="modalButton purpleButton"
							onClick={() => this.toggle('delete')}
						>
							<span className="modalButtonText">Cancelar</span>
						</MDBBtn>
						<MDBBtn color="redButton" className="modalButton redButton" onClick={this.delete}>
							<span className="modalButtonText">Eliminar</span>
						</MDBBtn>
					</MDBModalFooter>
				</MDBModal>
			</MDBContainer>
		);
	}

	/**
	 * Method that render the view with the all articles and their actions
	 */
	render() {
		//----------------------ESTO PARA LA TABLA--------------------------------------------------------/
		return (
			<div>
				{/*-------------------------AQUI ESTA LA TABLA----------------------------------------  */}
				<Container>
					<this.deleteModal />
					<ToastContainer
						position="top-right"
						autoClose={5000}
						hideProgressBar
						newestOnTop
						closeOnClick
						rtl={false}
						pauseOnVisibilityChange
						draggable
						pauseOnHover
					/>
					<MDBRow>
						<MDBCol className="col-12 col-lg-9 offset-lg-3 mt-3">
							<h3 className="adminTitlesFont">Administración de Artículos</h3>
						</MDBCol>
						<MDBCol className="col-12 col-lg-9 offset-lg-3">
							<Link to={'/Articles/Add'}>
								<MDBBtn className="addButtom" color="orange">
									<MDBIcon icon="plus" color="plusIcon" className="plusIcon" size="lg" />
								</MDBBtn>
							</Link>
							<MDBCard style={{ padding: '20px 20px 20px 20px' }}>
								<MDBDataTable
									data={this.state.articles}
									hover
									striped
									bordered
									entriesLabel={'Mostrar'}
									searchLabel={'Buscar'}
									paginationLabel={[ 'Anterior', 'Siguiente' ]}
									infoLabel={[ 'Mostrando', 'de', 'de un total de', 'registros' ]}
									entries={5}
									entriesOptions={[ 5, 10, 15 ]}
									small
									responsive
									noBottomColumns={true}
									noRecordsFoundLabel="No se han encontrado datos"
									theadTextWhite={true}
									pagesAmount={4}
								/>
							</MDBCard>
						</MDBCol>
					</MDBRow>

					{/*-------------------------AQUI TERMINA LO DE LA TABLA----------------------------------------  */}
				</Container>
			</div>
		);
	}
}

export default Article;
