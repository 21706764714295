import React, { Component } from "react";
import { MDBRow, MDBCol } from "mdbreact";
import bid from "../images/logosTerceros/BID.png";
import ucr from "../images/logosTerceros/ucr.png";
import holcim from "../images/logosTerceros/holcim.png";
import tec from "../images/logosTerceros/tec.png";
import amazon from "../images/logosTerceros/amazon.png";
import ibm from "../images/logosTerceros/ibm.png";
import hp from "../images/logosTerceros/hp.png";
import aya from "../images/logosTerceros/aya.png";
import cgr from "../images/logosTerceros/cgr.svg";

export default class LogosCompany extends Component {
  render() {
    return (
      <div>
        <br />

        <MDBRow>
          <MDBCol sm="12" md="12" lg="12" className="LogosFooter">
            <h5 style={{ textAlign: "center" }}>Agradecemos su confianza.</h5>
            <hr
              style={{
                backgroundColor: "gray",
                height: 0.5,
              }}
            />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol sm="12" md="12" lg="12" className="LogosFooter">
            <img
              src={bid}
              alt="logo"
              style={{
                height: 50,
                width: 80,
                marginBottom: 20,
                marginLeft: 30,
              }}
              className="colorlogosFooter"
            />

            <img
              src={ucr}
              alt="logo"
              style={{
                height: 55,
                width: 100,
                marginBottom: 20,
                marginLeft: 30,
              }}
              className="colorlogosFooter"
            />

            <img
              src={holcim}
              alt="logo"
              style={{
                height: 35,
                width: 100,
                marginBottom: 20,
                marginLeft: 30,
              }}
              className="colorlogosFooter"
            />

            <img
              src={tec}
              alt="logo"
              style={{
                height: 100,
                width: 140,
                marginBottom: 20,
                marginLeft: 30,
              }}
              className="colorlogosFooter"
            />

            <img
              src={amazon}
              alt="logo"
              style={{
                height: 70,
                width: 90,
                marginBottom: 20,
                marginLeft: 30,
              }}
              className="colorlogosFooter"
            />

            <img
              src={aya}
              alt="logo"
              style={{
                height: 70,
                marginBottom: 20,
                marginLeft: 30,
              }}
              className="colorlogosFooter"
            />

            <img
              src={ibm}
              alt="logo"
              style={{
                height: 45,
                width: 80,
                marginBottom: 20,
                marginLeft: 30,
              }}
              className="colorlogosFooter"
            />
            <img
              src={cgr}
              alt="logo"
              style={{
                height: 90,
                width: 110,
                marginBottom: 20,
                marginLeft: 30,
              }}
              className="colorlogosFooter"
            />

            <img
              src={hp}
              alt="logo"
              style={{
                height: 45,
                width: 45,
                marginBottom: 20,
                marginLeft: 30,
              }}
              className="colorlogosFooter"
            />
          </MDBCol>
        </MDBRow>
      </div>
    );
  }
}
