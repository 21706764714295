import React from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import { TranslatorProvider } from 'react-translate';

//Rutas
import CourseList from './components/CourseList';
import CourseUp_In from './components/CourseUp_In';
import CourseType from './components/CourseType';
import Category from './components/CategoryComponent';
import Quotation from './components/Quotations';
import QuotationDetail from './components/QuotationDetail';
import Footer from './components/Footer';
import Header from './components/Header';
import AnswerQuotation from './components/QuotationAnswer';
import Article from './components/ArticleComponent';
import AddArticle from './components/AddArticleComponent';
import EditArticle from './components/EditArticleComponent';
import AdminHeader from './components/AdminHeader';
import PrincipalEvent from './components/PrincipalEvent';
import EventUp_In from './components/EventUp_In';
import Employee from './components/EmployeeComponent';
import AddEmployee from './components/AddEmployeeComponent';
import EditEmployee from './components/EditEmployeeComponent';
import ParticipantsList from './components/ParticipantsList';
import Provider from './components/ProviderComponent';
import AddProvider from './components/AddProviderComponent';
import EditProvider from './components/EditProviderComponent';
import IntroCourses from './components/IntroCourses';
import AboutUsConfiguration from './components/AboutUsConfiguration';
import HomeConfiguration from './components/HomeConfiguration';
import Advertisement from './components/AdvertisementComponent';
import AdminHome from './components/AdminHomeComponent';
import EnrollmentReport from './components/EnrollmentReport';
import UsersReport from './components/UsersReport';
import AdminPerfilConfiguration from './components/AdminPerfilConfiguration';
import UserRegister from './components/UserRegister';
import Help from './components/Help';

import CourseDetail from './components/CourseDetail';
import CourseUser from './components/CourseUser';
import QuotationUser from './components/QuotationUser';
import Adv_Event from './components/Adv_Event';
import Adv_EventUp_In from './components/Adv_EventUp_In';
import Adv_Job from './components/Adv_Job';
import Adv_Job_Up from './components/Adv_Job_Up';
import EventUser from './components/EventUser';
import ArticleUser from './components/ArticleUser';
import ArticleCategoryUser from './components/ArticleCategoryUser';
import AboutUsUser from './components/AboutUsUser';

import UserHome from './components/UserHome';
import UserCommunity from './components/UserCommunity';
import PasswordRecovery from './components/PasswordRecovery';
import UserPerfilConfiguration from './components/UserPerfilConfiguration';
import UserCertificates from './components/UserCertificates';

function App() {
	let url = window.location.href;
	var lang = 'es';
	if (url.search('/en') !== -1) {
		lang = 'en';
	} else {
		lang = 'es';
	}
	return (
		<div>
			<TranslatorProvider translations={require('./assets/json/' + lang + '.json')}>
				<BrowserRouter>
					<Switch>
						<HeaderManagement />
					</Switch>

					<main style={{ paddingBottom: '20px' }}>
						<Switch>
							<Route exact path="/CourseList" component={CourseList} />
							<Route exact path="/CourseUp_In" component={CourseUp_In} />
							<Route exact path="/CourseUp_In/:ID" component={CourseUp_In} />
							<Route exact path="/CourseType" component={CourseType} />
							<Route exact path="/Categories" component={Category} />
							<Route exact path="/Quotations" component={Quotation} />
							<Route exact path="/Employees" component={Employee} />
							<Route exact path="/Employees/Add" component={AddEmployee} />
							<Route exact path="/Employees/:userID" component={EditEmployee} />
							<Route exact path="/QuotationDetail/:ID" component={QuotationDetail} />
							<Route exact path="/AnswerQuotation/:ID" component={AnswerQuotation} />
							<Route exact path="/PrincipalEvent/:ID" component={PrincipalEvent} />
							<Route exact path="/Articles" component={Article} />
							<Route exact path="/Articles/Add" component={AddArticle} />
							<Route exact path="/Articles/:articleId" component={EditArticle} />
							<Route exact path="/EventUp_In/:ID/:DATE" component={EventUp_In} />
							<Route exact path="/ParticipantsList/:ID" component={ParticipantsList} />
							<Route exact path="/Providers" component={Provider} />
							<Route exact path="/Providers/Add" component={AddProvider} />
							<Route exact path="/Providers/:providerID" component={EditProvider} />
							<Route exact path="/IntroCourses" component={IntroCourses} />
							<Route exact path="/AboutUsConfiguration" component={AboutUsConfiguration} />
							<Route exact path="/HomeConfiguration" component={HomeConfiguration} />
							<Route exact path="/Adv_Event" component={Adv_Event} />
							<Route exact path="/Adv_EventUp_In/:ID" component={Adv_EventUp_In} />
							<Route exact path="/Adv_Job" component={Adv_Job} />
							<Route exact path="/Adv_Job_Up/:ID" component={Adv_Job_Up} />
							
							<Route exact path="/admin" component={AdminHome} />

							<Route exact path="/EnrollmentReports" component={EnrollmentReport} />
							<Route exact path="/UsersReport" component={UsersReport} />
							<Route exact path="/AdminPerfilConfiguration" component={AdminPerfilConfiguration} />
							<Route exact path="/UserRegister" component={UserRegister} />
							<Route exact path="/Help" component={Help} />

							{/**Links de los usuarios  */}
							<Route exact path="/CourseUser" component={CourseUser} />
							<Route exact path="/CourseDetail/:ID" component={CourseDetail} />
							<Route exact path="/QuotationUser/:ID/:LANGUAGEID/:AMOUNT/:QUOTATIONID" component={QuotationUser} />
							<Route exact path="/EventUser" component={EventUser} />
							<Route exact path="/ArticleUser" component={ArticleUser} />
							<Route exact path="/ArticleCategoryUser/:ID" component={ArticleCategoryUser} />
							<Route exact path="/UserPerfilConfiguration" component={UserPerfilConfiguration} />

							<Route exact path="/" component={UserHome} />
							<Route exact path="/UserCommunity" component={UserCommunity} />

							<Route exact path="/Advertisements" component={Advertisement} />
							<Route exact path="/AboutUs" component={AboutUsUser} />
							<Route exact path="/PasswordRecovery" component={PasswordRecovery} />
							<Route exact path="/PasswordRecovery/:email" component={PasswordRecovery} />
							<Route exact path="/Certifications" component={UserCertificates} />
						</Switch>
					</main>
					<Switch>
						<FooterManagement />
					</Switch>
				</BrowserRouter>
			</TranslatorProvider>
		</div>
	);
}

function HeaderManagement() {
	if (sessionStorage.getItem('userType') != 2 && sessionStorage.getItem('userType') != 3) {
		return <Header />;
	} else {
		return <AdminHeader />;
	}
}

function FooterManagement() {
	if (sessionStorage.getItem('userType') != 2 && sessionStorage.getItem('userType') != 3) {
		return <Footer />;
	} else {
		return <div />;
	}
}

export default App;
