/**
 * @fileoverview ApexChart, this component allow to view the information about the graphics in the admin home.
 * 
 * @version 1.0
 * 
 * @author Anthony Vargas Méndez <anthony.vargas@ucrso.info>
 * History
 * v1.0 - Initial Release
 * ----
 * The first version of ApexChart was writen by Anthony Vargas Méndez
 */

import React from 'react';
import ReactApexChart from 'react-apexcharts';
import axios from 'axios';

class ApexChart extends React.Component {
	state = {
		series: [],
		options: {
			chart: {
				height: 350,
				type: 'line',
				zoom: {
					enabled: true
				}
			},
			dataLabels: {
				enabled: false
			},
			stroke: {
				curve: 'smooth',
				width: 7
			},
			title: {
				text: this.props.title,
				align: 'left'
			},
			grid: {
				row: {
					colors: [ '#f3f3f3', 'transparent' ], // takes an array which will be repeated on columns
					opacity: 0.5
				}
			},
			xaxis: {
				categories: [ 'Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dec' ],
				title: {
					text: `Año ${new Date().getFullYear()}`
				}
			},
			fill: {
				type: 'gradient',
				gradient: {
					shade: 'dark',
					colorStops: [
						{
							offset: 0,
							color: '#FAD375',
							opacity: 1
						},
						{
							offset: 20,
							color: '#FAD375',
							opacity: 1
						},
						{
							offset: 60,
							color: '#f2831c',
							opacity: 1
						},
						{
							offset: 100,
							color: '#f2831c',
							opacity: 1
						}
					],
					shadeIntensity: 1,
					type: 'horizontal',
					opacityFrom: 1,
					opacityTo: 1
				}
			},
			markers: {
				size: 4,
				colors: [ '#f2831c' ],
				strokeColors: '#fff',
				strokeWidth: 2,
				hover: {
					size: 7
				}
			},
			yaxis: {
				title: {
					text: 'Ingresos'
				}
			}
		}
	};

	/**
	 * Method that get all data of the from the database and set the series
	 */
	getChartData() {
		axios
			.get(this.props.apiUrl)
			.then((response) => {
				var series = response.data;
				this.setState({
					series: [ series ]
				});
			})
			.catch((err) => console.error(err));
	}

	/**
	 * Method that charge a getCharData method
	 */
	componentDidMount() {
		this.getChartData();
	}

	/**
	 * Method that render the view with the graphics information in the admin home
	 */
	render() {
		return (
			<div id="chart">
				<ReactApexChart options={this.state.options} series={this.state.series} type="line" height={350} />
			</div>
		);
	}
}

export default ApexChart;
