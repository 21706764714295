/**
 * @fileoverview UserRegister, this component allow to register an user.
 * 
 * @version 1.0
 * 
 * @author Anthony Vargas Méndez <anthony.vargas@ucrso.info>
 * History
 * v1.0 - Initial Release
 * ----
 * The first version of UserRegister was writen by Anthony Vargas Méndez
 */
import React, { Component, Fragment } from 'react';
import { MDBRow, MDBCol, MDBBtn, MDBIcon } from 'mdbreact';
import { MDBCard, MDBContainer, MDBCardBody, MDBCardFooter, MDBCardImage, MDBTooltip } from 'mdbreact';
import { FormGroup } from 'reactstrap';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import validations from './Validations';
import md5 from 'md5';

import Select from 'react-select';
import makeAnimated from 'react-select/animated';

import { baseUrl } from './baseUrl';

const animatedComponents = makeAnimated();

class UserRegister extends Component {
	state = {
		/**
		 * validations:
		 * @type {object}
		 * Property that validate the inputs in this component
		 * 
		 * firstname:
		 * @type {string}
		 * Propety that indicates the name of the user
		 * 
		 * secondName:
		 * @type {string}
		 * Propety that indicates the second name of the user
		 * 
		 * lastname:
		 * @type {string}
		 * Propety that indicates the last name of the user
		 * 
		 * secondLastname:
		 * @type {string}
		 * Propety that indicates the second last name of the user
		 * 
		 * identificationID:
		 * @type {string}
		 * Propety that indicates the identification of the user
		 * 
		 * password:
		 * @type {string}
		 * Propety that indicates the password of the user account
		 * 
		 * confirmPassword:
		 * @type {string}
		 * Propety that indicates the confirmation password of the user account
		 * 
		 * birthdate:
		 * @type {string}
		 * Propety that indicates the birth date of the cuser
		 * 
		 * phone:
		 * @type {string}
		 * Propety that indicates the first phone of the user
		 * 
		 * phone2:
		 * @type {string}
		 * Propety that indicates the second phone of the user
		 * 
		 * email:
		 * @type {string}
		 * Propety that indicates the email address of the user
		 * 
		 * countryID:
		 * @type {Integer}
		 * Propety that indicates the country ID
		 * 
		 * country:
		 * @type {array}
		 * Propety that indicates and contain all countries in the database
		 * 
		 * emails:
		 * @type {array}
		 * Propety that indicates and contain all emails in the database
		 */
		validations: new validations(),
		firstname: '',
		secondName: '',
		lastname: '',
		secondLastname: '',
		identificationID: '',
		password: '',
		confirmPassword: '',
		birthdate: '',
		initDate: '',
		finishDate: '',
		phone: '',
		phone2: '',
		email: '',
		countryID: 0,
		country: [],
		emails: []
	};

	handleInput = this.handleInput.bind(this);
	valueCountries = this.valueCountries.bind(this);
	handleSubmit = this.handleSubmit.bind(this);
	validateData = this.validateData.bind(this);
	empty = this.empty.bind(this);
	validateEmail = this.validateEmail.bind(this);

	/**
	 * Method that specify the different alerts in the component
	 * @param {event} e
	 * @param {value} valuevt
	 * @param {string} msj
	 */
	notify = (evt, value, msj) => {
		switch (value) {
			case 'SUCCESS':
				toast.success(msj);
				break;
			case 'ERROR':
				toast.error(msj);
				break;
			case 'WARN':
				toast.warn(msj);
				break;
			case 'INFO':
				toast.info(msj);
				break;
			default:
				toast.info(msj);
		}
	};

	/**
	 * Method that allow use the enter button
	 * @param {event} e
	 */
	onKeyEvent = (e) => {
		if (e.key === 'Enter') {
			this.validateData(e);
		}
	};

	/**
	 * Method that validate the value to the variables in the inpust of the user register
	 * @param {event} event
	 */
	validateData(event) {
		if (this.empty(event)) {
			if (!this.state.validations.validateTextField(this.state.firstname)) {
				this.notify(event, 'ERROR', 'El nombre del usuario no debe contener caracteres especiales ni números');
			} else if (
				this.state.secondName !== '' &&
				!this.state.validations.validateTextField(this.state.secondName)
			) {
				this.notify(
					event,
					'ERROR',
					'El segundo nombre del usuario no debe contener caracteres especiales ni números'
				);
			} else if (!this.state.validations.validateTextField(this.state.lastname)) {
				this.notify(
					event,
					'ERROR',
					'El apellido del usuario no debe contener caracteres especiales ni números'
				);
			} else if (!this.state.validations.validateTextField(this.state.secondLastname)) {
				this.notify(
					event,
					'ERROR',
					'El segundo apellido del usuario no debe contener caracteres especiales ni números'
				);
			} else if (!this.state.validations.validatePasswordField(this.state.password)) {
				this.notify(event, 'ERROR', 'La contraseña debe tener mayúsculas, minúsculas, números y caracteres');
			} else if (this.state.password !== this.state.confirmPassword) {
				this.notify(
					event,
					'ERROR',
					'La contraseña y la confirmación de la contraseña son diferentes, por favor verifique su contraseña'
				);
			} else if (!this.state.validations.validateNumericField(this.state.identificationID)) {
				this.notify(event, 'ERROR', 'La identificaión solo debe contener números');
			} else if (this.validateEmail(this.state.email) === true) {
				this.notify(event, 'ERROR', 'El correo ingresado ya existe en el sistema');
			} else if (!this.state.validations.validateEmailField(this.state.email)) {
				this.notify(event, 'ERROR', 'El correo ingresado posee un formato incorrecto');
			} else if (!this.state.validations.validateNumericField(this.state.phone)) {
				this.notify(event, 'ERROR', 'El teléfono solo debe contener números');
			} else if (!this.state.validations.validateNumericField(this.state.phone2)) {
				this.notify(event, 'ERROR', 'El segundo teléfono solo debe contener números');
			} else if (!this.state.validations.validateDateField(this.state.birthdate)) {
				this.notify(event, 'ERROR', 'Formato de fecha de nacimiento incorrecto');
			} else {
				this.handleSubmit(event);
			}
		}
	}

	/**
	 * Method that determine if the value to the variables are empty
	 * @param {event} event
	 */
	empty(event) {
		if (this.state.firstname.length === 0) {
			this.notify(event, 'ERROR', 'El nombre del usuario no debe estar vacío');
			return false;
		} else if (this.state.lastname.length === 0) {
			this.notify(event, 'ERROR', 'El apellido no debe estar vacío');
			return false;
		} else if (this.state.identificationID.length === 0) {
			this.notify(event, 'ERROR', 'La identificación no debe estar vacía');
			return false;
		} else if (this.state.birthdate.length === 0) {
			this.notify(event, 'ERROR', 'Debe seleccionar una fecha de nacimiento');
			return false;
		} else if (this.state.password.length === 0) {
			this.notify(event, 'ERROR', 'Debe ingresar una contraseña');
			return false;
		} else if (this.state.confirmPassword.length === 0) {
			this.notify(event, 'ERROR', 'Debe confirmar su contraseña');
			return false;
		} else if (this.state.phone.length === 0) {
			this.notify(event, 'ERROR', 'El teléfono no debe estar vacío');
			return false;
		} else if (this.state.email.length === 0) {
			this.notify(event, 'ERROR', 'Debe ingresar un correo electrónico');
			return false;
		} else if (this.state.countryID === 0) {
			this.notify(event, 'ERROR', 'Debe seleccionar un país');
			return false;
		}
		{
			return true;
		}
	}

	/**
	 * Method that validate if email exist in a database or not
	 * @param {string} auxEmail
	 */
	validateEmail(auxEmail) {
		var validateE = false;
		const emailsList = this.state.emails;
		emailsList.map(function(email) {
			if (email.value === auxEmail) {
				validateE = true;
			}
		});

		if (validateE === true) {
			return true;
		} else {
			return false;
		}
	}

	/**
	 * Method that get all countries from de database and set in the country
	 */
	getCountry() {
		axios
			.get(baseUrl + 'perfilConfiguration/getAllCountries')
			.then((response) => {
				var country = response.data;
				this.setState({
					country
				});
			})
			.catch((err) => console.error(err));
	}

	/**
	 * Method that get all emails from de database and set in the emails
	 */
	getEmails() {
		axios
			.get(baseUrl + 'perfilConfiguration/getEmails')
			.then((response) => {
				var emails = response.data;
				this.setState({
					emails
				});
			})
			.catch((err) => console.error(err));
	}

	/**
	 * Method that charge the getCountry method
	 */
	componentDidMount() {
		if(sessionStorage.getItem('userType') == null || sessionStorage.getItem('userType') != undefined ){
		this.getCountry();
		this.getEmails();
		}else if(sessionStorage.getItem('userType') == 2 || sessionStorage.getItem('userType') == 3){
			this.props.history.push('/admin');
		}else{
			this.props.history.push('/');
		}
	}

	/**
	 * Method that assigns the value to the variables of the user register
	 * @param {event} e
	 */
	handleInput(e) {
		const { value, name } = e.target;
		this.setState({
			[name]: value
		});
	}

	/**
	 * Method that set a country ID in the countryID
	 * @param {event} event
	 */
	valueCountries(event) {
		this.setState({
			countryID: event.value
		});
	}

	/**
	 * Method that charge all values of the user register and call the api to add the user information and their contacts
	 * @param {event} event
	 */
	handleSubmit(event) {
		event.preventDefault();
		fetch(`${baseUrl}perfilConfiguration/addUser`, {
			method: 'post',
			body: JSON.stringify({
				firstname: this.state.firstname,
				secondName: this.state.secondName,
				lastname: this.state.lastname,
				secondLastname: this.state.secondLastname,
				identificationID: this.state.identificationID,
				password: md5(this.state.password),
				birthdate: this.state.birthdate,
				initDate: this.state.initDate,
				finishDate: this.state.finishDate,
				userTypeID: this.state.userTypeID,
				countryID: this.state.countryID,
				phone: this.state.phone,
				phone2: this.state.phone2,
				email: this.state.email
			}),
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			}
		})
			.then(() => {
				axios
					.get(baseUrl + 'perfilConfiguration/getUserIDRegister', {
						params: { email: this.state.email }
					})
					.then((response) => {
						var auxUser = response.data[0];
						console.log('variable: ' + auxUser.userID);
						sessionStorage.setItem('user', auxUser.userID);
						sessionStorage.setItem('name', this.state.firstname + ' ' + this.state.lastname);
						sessionStorage.setItem('email', this.state.email);
						sessionStorage.setItem('userType', 1);
						window.location.href = '/';
					})
					.catch((err) => console.error(err));
			})
			.catch((err) => console.log(err));
	}

	/**
	 * Method that render the view with the all inputs and selects to register a new user
	 * @param {array} auxCountries
	 */
	render() {
		const auxCountries = this.state.country.map(function(auxCountry) {
			return {
				label: auxCountry.name,
				value: auxCountry.countryID
			};
		});

		return (
			<Fragment>
				<MDBContainer>
					<ToastContainer
						position="top-right"
						autoClose={5000}
						hideProgressBar
						newestOnTop
						closeOnClick
						rtl={false}
						pauseOnVisibilityChange
						draggable
						pauseOnHover
					/>
					<MDBRow>
						<MDBCard style={{ padding: '10px 10px 10px 10px' }} className="userRegisterCard">
							<MDBCardImage
								className="peach-gradient d-flex justify-content-center flex-column p-4 rounded"
								tag="div"
							>
								<h2>Registro de Usuario</h2>
							</MDBCardImage>

							<MDBCardBody className="adminFont">
								<MDBRow>
									<MDBCol className="col-12 col-md-4">
										<FormGroup>
											<label>
												Nombre <font color="red">*</font>
											</label>
											<input
												className="form-control "
												value={this.state.firstname}
												type="text"
												name="firstname"
												onChange={this.handleInput}
											/>
										</FormGroup>
									</MDBCol>
									<MDBCol className="col-12 col-md-4">
										<FormGroup>
											<label>Segundo Nombre</label>
											<input
												className="form-control "
												value={this.state.secondName}
												type="text"
												name="secondName"
												onChange={this.handleInput}
											/>
										</FormGroup>
									</MDBCol>
									<MDBCol className="col-12 col-md-4">
										<FormGroup>
											<label>
												Identificación <font color="red">*</font>
											</label>
											<input
												className="form-control "
												value={this.state.identificationID}
												type="text"
												name="identificationID"
												onChange={this.handleInput}
											/>
										</FormGroup>
									</MDBCol>
								</MDBRow>
								<MDBRow>
									<MDBCol className="col-12 col-md-4">
										<FormGroup>
											<label>
												Apellido <font color="red">*</font>
											</label>
											<input
												className="form-control "
												value={this.state.lastname}
												type="text"
												name="lastname"
												onChange={this.handleInput}
											/>
										</FormGroup>
									</MDBCol>
									<MDBCol className="col-12 col-md-4">
										<FormGroup>
											<label>
												Segundo Apellido <font color="red">*</font>
											</label>
											<input
												className="form-control "
												value={this.state.secondLastname}
												type="text"
												name="secondLastname"
												onChange={this.handleInput}
											/>
										</FormGroup>
									</MDBCol>
									<MDBCol className="col-12 col-md-4">
										<FormGroup>
											<label>
												Fecha de nacimiento <font color="red">*</font>
											</label>
											<input
												className="form-control "
												value={this.state.birthdate}
												type="date"
												name="birthdate"
												onChange={this.handleInput}
											/>
										</FormGroup>
									</MDBCol>
								</MDBRow>
								<MDBRow>
									<MDBCol className="col-12 col-md-4">
										<FormGroup>
											<label>
												País <font color="red">*</font>
											</label>
											<Select
												className="selectLetter"
												name="country"
												components={animatedComponents}
												options={auxCountries}
												classNamePrefix="select"
												placeholder="Seleccione un país"
												onChange={this.valueCountries}
												theme={(theme) => ({
													...theme,
													borderRadius: 4,
													font: 'Open Sans',
													height: 9,

													colors: {
														...theme.colors,
														primary25: '#FFA93F',
														primary: '#808080'
													}
												})}
											/>
										</FormGroup>
									</MDBCol>
									<MDBCol className="col-12 col-md-4">
										<FormGroup>
											<label>
												Contraseña {' '}
												<MDBTooltip placement="bottom">
													<MDBBtn color="passwordBtn" className="passwordBtn">
														<MDBIcon icon="info-circle" className="red-text" />
													</MDBBtn>
													<div>Ejemplo de de contraseña correcta: Asssdd.12assA</div>
												</MDBTooltip>{' '}
												<font color="red">*</font>
											</label>
											<input
												className="form-control "
												value={this.state.password}
												type="password"
												name="password"
												onChange={this.handleInput}
											/>
										</FormGroup>
									</MDBCol>
									<MDBCol className="col-12 col-md-4">
										<FormGroup>
											<label>
												Confirmar Contraseña {' '}
												<MDBTooltip placement="bottom">
													<MDBBtn color="passwordBtn" className="passwordBtn">
														<MDBIcon icon="info-circle" className="red-text" />
													</MDBBtn>
													<div>Ejemplo de de contraseña correcta: Asssdd.12assA</div>
												</MDBTooltip>{' '}
												<font color="red">*</font>
											</label>
											<input
												className="form-control "
												value={this.state.confirmPassword}
												type="password"
												name="confirmPassword"
												onChange={this.handleInput}
											/>
										</FormGroup>
									</MDBCol>
								</MDBRow>
								<MDBRow>
									<MDBCol className="col-12 col-md-4">
										<FormGroup>
											<label>
												Telefono #1 <font color="red">*</font>
											</label>
											<input
												className="form-control "
												value={this.state.phone}
												type="text"
												name="phone"
												onChange={this.handleInput}
											/>
										</FormGroup>
									</MDBCol>
									<MDBCol className="col-12 col-md-4">
										<FormGroup>
											<label>Telefono #2</label>
											<input
												className="form-control "
												value={this.state.phone2}
												type="text"
												name="phone2"
												onChange={this.handleInput}
											/>
										</FormGroup>
									</MDBCol>
									<MDBCol className="col-12 col-md-4">
										<FormGroup>
											<label>
												Correo Electrónico <font color="red">*</font>
											</label>
											<input
												className="form-control "
												value={this.state.email}
												type="text"
												name="email"
												onChange={this.handleInput}
											/>
										</FormGroup>
									</MDBCol>
								</MDBRow>
							</MDBCardBody>
							<MDBCardFooter color="white">
								<Link to={'/'}>
									<MDBBtn color="purpleButton" className="modalButton purpleButton">
										<span className="modalButtonText">Cancelar</span>
									</MDBBtn>
								</Link>
								<MDBBtn
									color="greenButton"
									className="modalButton greenButton"
									type="submit"
									onClick={this.validateData}
								>
									<span className="modalButtonText">Guardar</span>
								</MDBBtn>
							</MDBCardFooter>
						</MDBCard>
					</MDBRow>

					{/*-------------------------AQUI TERMINA LO DE LA TABLA----------------------------------------  */}
				</MDBContainer>
			</Fragment>
		);
	}
}

export default UserRegister;
