/**
 * @fileoverview CourseType, this component show the list of the course's types 
 * 
 * @version 1.0
 * 
 * @author Ester Molina R <maria.molina@ucrso.info>
 * History
 * v1.0 - Initial Realease
 * ----
 * The first version of CourseType was written by Ester Molina R. 
 */

import React, { Component, Fragment } from 'react';
import {
	MDBContainer,
	MDBBtn,
	MDBModal,
	MDBModalBody,
	MDBModalHeader,
	MDBModalFooter,
	MDBCol,
	MDBRow,
	MDBIcon,
	MDBDataTable,
	MDBCard, MDBTooltip
} from 'mdbreact';
import { FormGroup } from 'reactstrap';
import { baseUrl } from './baseUrl';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import validations from './Validations';

class CourseType extends Component {
	constructor(props) {
		super(props);
		/**
		 * validations:
		 * @type {object}
		 * Property that contains methods for validation with regular expressions
		 * 
		 * addModal:
		 * @type {boolean}
		 * Property that shows if the modal for adding is open or not
		 * 
		 * deleteModal:
		 * @type {boolean}
		 * Property that shows if the modal for deleting is open or not
		 * 
		 * name:
		 * @type {String}
		 * Property that contains the name of the course type
		 * 
		 * typeID:
		 * @type {integer}
		 * Property that contains the identification of the course type
		 * 
		 * courseTypes:
		 * @type {Array}
		 * Property that contains the list of course type in columns and rows
		 */
		this.state = {
			validations: new validations(),
			addModal: false,
			deleteModal: false,
			name: '',
			typeID: 0,
			courseTypes: {
				columns: [
					{
						label: 'Nombre',
						field: 'typeName',
						width: 150
					},
					{
						label: 'Acciones',
						field: 'actions',
						width: 150
					}
				],
				rows: []
			}
		};
		this.addModal = this.addModal.bind(this);
		this.deleteModal = this.deleteModal.bind(this);
		this.handleInput = this.handleInput.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.delete = this.delete.bind(this);
		this.getTypeCourses = this.getTypeCourses.bind(this);
		this.validateData = this.validateData.bind(this);
	}

	componentDidMount() {
		if (sessionStorage.getItem('userType') == 2) {
			this.getTypeCourses();
		} else if (sessionStorage.getItem('userType') == 3) {
			this.props.history.push('/admin');
		} else {
			this.props.history.push('/');
		}
	}

	/**
	 * Method that perform the validate data action when an ENTER is clicked
	 * @param {event} e 
	 */
	onKeyEvent = (e) => {
		if (e.key === "Enter") {
			this.validateData(e);
		}
	}

	/**
	 * Method that validate the format of the insert data
	 * @param {event} event 
	 */
	validateData(event) {
		if (this.state.name.length === 0) {
			this.notify(event, 'ERROR', 'Debe escribir un nombre para el tipo de curso');
		} else if (!this.state.validations.validateTextField(this.state.name)) {
			this.notify(event, 'ERROR', 'El nombre debe contener solo letras');
		} else {
			this.handleSubmit(event);
		}
	}

	/**
	 * Method that change the state and open the modals
	 * @param {String} text 
	 * @param {integer} id 
	 * @param {String} name 
	 */
	toggle = (text, id, name) => () => {
		let modalAction = text + 'Modal';
		if (id != null) {
			this.setState({
				[modalAction]: !this.state[modalAction],
				typeID: id,
				name: name
			});
		} else {
			if (this.state[modalAction] === true) {
				this.setState({
					[modalAction]: !this.state[modalAction],
					typeID: 0,
					name: ''
				});
			} else {
				this.setState({
					[modalAction]: !this.state[modalAction]
				});
			}
		}
	};

	/**
	 * Method that provide the notifications to the user
	 * @param {event} evt 
	 * @param {String} value 
	 * @param {String} msj 
	 */
	notify = (evt, value, msj) => {
		switch (value) {
			case 'SUCCESS':
				toast.success(msj);
				break;
			case 'ERROR':
				toast.error(msj);
				break;
			case 'WARN':
				toast.warn(msj);
				break;
			case 'INFO':
				toast.info(msj);
				break;
			default:
				toast.info(msj);
		}
	};

	/**
	 * Method that change the state for the insert data
	 * @param {event} e 
	 */
	handleInput(e) {
		const { value, name } = e.target;
		this.setState({
			[name]: value
		});
	}

	/**
	 * Method that charge the list of course's types from the database
	 */
	getTypeCourses() {
		axios
			.get(baseUrl + 'course/getAllTypeCourses')
			.then((response) => {
				var dbCourseTypes = response.data;
				this.setState({
					...this.state,
					courseTypes: {
						...this.state.courseTypes,
						rows: dbCourseTypes.map((dbCourseType) => {
							return {
								...dbCourseType,
								actions: (
									<Fragment>
										<MDBTooltip>
											<MDBBtn
												color="white"
												className="tableControlButton"
												onClick={this.toggle('add', dbCourseType.typeID, dbCourseType.typeName)}
											>
												<MDBIcon icon="pen" size="1x" />
											</MDBBtn>
											<div>
												Editar
										</div>
										</MDBTooltip>
										<MDBTooltip>
											<MDBBtn
												color="white"
												className="tableControlButton"
												onClick={this.toggle('delete', dbCourseType.typeID)}
											>
												<MDBIcon icon="trash" size="1x" className="red-text" id="delete" />
											</MDBBtn>
											<div>
												Eliminar
										</div>
										</MDBTooltip>
									</Fragment>
								)
							};
						})
					}
				});
			})
			.catch((err) => console.error(err));
	}


	/**
	 * Method that submit the insert data
	 * @param {event} event 
	 */
	handleSubmit(event) {
		if (this.state.typeID === 0) {
			axios
				.post(baseUrl + 'course/addCourseType', {
					name: this.state.name
				})
				.then((response) => {

					var lastID = response.data[0].lastID;
					var newRows = this.state.courseTypes.rows;

					newRows.push({

						typeID: lastID,
						typeName: this.state.name,
						actions: (
							<Fragment>
								<MDBBtn
									color="white"
									className="tableControlButton"
									onClick={this.toggle('add', lastID, this.state.name)}
								>
									<MDBIcon icon="pen" size="1x" />
								</MDBBtn>
								<MDBBtn
									color="white"
									className="tableControlButton"
									onClick={this.toggle('delete', lastID)}
								>
									<MDBIcon icon="trash" size="1x" className="red-text" id="delete" />
								</MDBBtn>
							</Fragment>
						)
					});
					this.setState({
						courseTypes: {
							columns: this.state.courseTypes.columns,
							rows: newRows
						},
						addModal: false,
						typeID: 0,
						name: ''


					});
					this.notify(event, 'SUCCESS', 'Se ha agregado correctamente');
				})
				.catch((err) => console.error('Ha ocurrido un error inesperado'));
		} else {
			var newTypesRows = [{}];
			axios
				.post(baseUrl + 'course/editCourseType', {
					name: this.state.name,
					typeID: this.state.typeID
				})
				.then(
					(newTypesRows = this.state.courseTypes.rows.map((courseTypeRow) => {
						if (courseTypeRow.typeID === this.state.typeID) {
							return {
								...courseTypeRow,
								typeName: this.state.name
							};
						} else {
							return courseTypeRow;
						}
					})),
					this.setState({
						typeID: 0,
						name: '',
						courseTypes: {
							columns: this.state.courseTypes.columns,
							rows: newTypesRows
						},
						addModal: false
					}),
					this.notify(event, 'SUCCESS', 'Se ha editado correctamente')
				)
				.catch((err) => console.error('Un error inesperado a ocurrido'));
		}
		event.preventDefault();
	}


	/**
	 * Method that delete the course type from the database
	 * @param {event} event 
	 */
	delete(event) {
		axios(baseUrl + 'course/verifyCourseTypeForDelete', {
			params: { typeID: this.state.typeID }
		})
			.then((results) => {
				var newTypesRows = [];
				var verification = results.data;
				var valid = verification['fun_verifyCourseTypeForDelete(' + this.state.typeID + ')'].data[0];
				if (valid === 1) {
					axios
						.post(baseUrl + 'course/deleteCourseType', {
							typeID: this.state.typeID
						})
						.then(
							(newTypesRows = this.state.courseTypes.rows.filter(
								(row) => row.typeID !== this.state.typeID
							)),
							this.setState({
								typeID: 0,
								name: '',
								deleteModal: false,
								courseTypes: {
									columns: this.state.courseTypes.columns,
									rows: newTypesRows
								}
							}),
							this.notify(event, 'SUCCESS', 'Se ha eliminado correctamente')
						);
				} else {
					this.setState({
						typeID: 0,
						name: '',
						deleteModal: false
					});
					this.notify(event, 'WARN', 'No se ha podido eliminar');
				}
			})
			.catch((err) => console.error('Ha ocurrido un error inesperado'));
		event.preventDefault();
	}


	/**
	 * Method that render the add modal
	 */
	addModal() {
		var title = '';
		if (this.state.typeID === 0) {
			title = 'Agregar tipo de curso';
		} else {
			title = 'Editar tipo de curso';
		}
		return (
			<MDBContainer>
				<MDBModal isOpen={this.state.addModal} toggle={this.toggle('add')}>
					<MDBModalHeader className="modalHeader modalTitlesFont" toggle={this.toggle('add')}>
						{title}
					</MDBModalHeader>
					<MDBModalBody>
						<FormGroup>
							<label className="modalTextFont">
								Tipo de curso <font color="red">*</font>
							</label>
							<input
								className="form-control modalTextFont"
								type="text"
								name="name"
								value={this.state.name}
								onChange={this.handleInput}
								onKeyPress={this.onKeyEvent}
								required
							/>
						</FormGroup>
					</MDBModalBody>
					<MDBModalFooter>
						<MDBBtn color="purpleButton" className="modalButton purpleButton" onClick={this.toggle('add')}>
							<span className="modalButtonText">Cancelar</span>
						</MDBBtn>
						<MDBBtn color="greenButton" className="modalButton greenButton" onClick={this.validateData}>
							<span className="modalButtonText">Guardar</span>
						</MDBBtn>
					</MDBModalFooter>
				</MDBModal>
			</MDBContainer>
		);
	}


	/**
	 * Method that render the delete modal
	 */
	deleteModal() {
		return (
			<MDBContainer>
				<MDBModal isOpen={this.state.deleteModal} toggle={this.toggle('delete')}>
					<MDBModalHeader className="modalHeader modalTitlesFont" toggle={this.toggle('delete')}>
						Eliminar tipo curso
					</MDBModalHeader>
					<MDBModalBody>
						<FormGroup className="text-center">
							<label className="modalTextFont">
								¿Desea eliminar este tipo de curso?<br />Esta acción es irreversible
							</label>
						</FormGroup>
					</MDBModalBody>
					<MDBModalFooter>
						<MDBBtn
							color="purpleButton"
							className="modalButton purpleButton"
							onClick={this.toggle('delete')}
						>
							<span className="modalButtonText">Cancelar</span>
						</MDBBtn>
						<MDBBtn color="redButton" className="modalButton redButton" onClick={this.delete}>
							<span className="modalButtonText">Eliminar</span>
						</MDBBtn>
					</MDBModalFooter>
				</MDBModal>
			</MDBContainer>
		);
	}


	/**
	 * Method that render the HTML
	 */
	render() {
		return (
			<div className="container">
				<this.addModal />
				<this.deleteModal />
				<ToastContainer
					position="top-right"
					autoClose={5000}
					hideProgressBar
					newestOnTop
					closeOnClick
					rtl={false}
					pauseOnVisibilityChange
					draggable
					pauseOnHover
				/>
				<MDBRow>
					<MDBCol className="col-12 col-lg-9 offset-lg-3 mt-3">
						<h3 className="adminTitlesFont">Administración de Tipo de Cursos</h3>
					</MDBCol>
					<MDBCol className="col-12 col-lg-9 offset-lg-3 mb-3">
						<MDBBtn className="addButtom" color="orange" onClick={this.toggle('add')}>
							<MDBIcon icon="plus" color="plusIcon" className="plusIcon" size="lg" />
						</MDBBtn>
						<MDBCard style={{ padding: '20px 20px 20px 20px' }}>
							<MDBDataTable
								data={this.state.courseTypes}
								hover
								striped
								bordered
								entriesLabel={'Mostrar'}
								searchLabel={'Buscar'}
								paginationLabel={['Anterior', 'Siguiente']}
								infoLabel={['Mostrando', 'de', 'de un total de', 'registros']}
								entries={5}
								entriesOptions={[5, 10, 15]}
								small
								responsive
								noBottomColumns={true}
								noRecordsFoundLabel="No se han encontrado datos"
								theadTextWhite={true}
								pagesAmount={4}
							/>
						</MDBCard>
					</MDBCol>
				</MDBRow>
			</div>
		);
	}
}

export default CourseType;
