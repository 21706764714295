/**
 * @fileoverview EnrollmentReport, this component allow to generate a enrollment reports in the data base.
 * 
 * @version 1.0
 * 
 * @author Anthony Vargas Méndez <anthony.vargas@ucrso.info>
 * History
 * v1.0 - Initial Release
 * ----
 * The first version of EnrollmentReport was writen by Anthony Vargas Méndez
 */
import React, { Component, Fragment } from 'react';
import { MDBRow, MDBCol, MDBIcon, MDBBtn, MDBCardHeader } from 'mdbreact';
import { MDBDataTable, MDBCard, MDBCardTitle } from 'mdbreact';
import { FormGroup, Container, Toast, ToastBody, ToastHeader } from 'reactstrap';
import axios from 'axios';
import { baseUrl } from './baseUrl';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
const animatedComponents = makeAnimated();

class EnrollmentReport extends Component {
	state = {
		/**
		 * userID:
		 * @type {Integer}
		 * Propety that indicates the user ID
		 * 
		 * enrollmentID:
		 * @type {Integer}
		 * Propety that indicates the enrollment ID
		 * 
		 * name:
		 * @type {string}
		 * Propety that indicates the user name
		 * 
		 * lastName:
		 * @type {string}
		 * Propety that indicates the user last name
		 * 
		 * billNumber:
		 * @type {string}
		 * Propety that indicates the number of bill
		 * 
		 * billTotal:
		 * @type {Integer}
		 * Propety that indicates the total of the bill
		 * 
		 * date:
		 * @type {string}
		 * Propety that indicates the date of the bill
		 * 
		 * title:
		 * @type {string}
		 * Property that indicates the title of the modal
		 *
		 * message:
		 * @type {string}
		 * Property that indicates the message of the modal
		 * 
		 * show:
		 * @type {boolean}
		 * Property that indicates the show the modal o not
		 *
		 * enrollmentReportList:
		 * @type {object}
		 * Property that indicates the list of the specific enrollment
		 * 
	     * visible:
		 * @type {boolean}
		 * Property that indicates the visible report table
		 * 
	     * enrollmentReport:
		 * @type {array}
		 * Property that indicates the list of enrollments
		 * 
		 * auxList:
		 * @type {array}
		 * Property that indicates the auxiliary list of the bills reports
		 */
		userID: 0,
		enrollmentID: 0,
		name: '',
		lastName: '',
		billNumber: '',
		billTotal: 0,
		date: '',
		show: false,
		title: '',
		message: '',
		enrollmentReportList: {
			columns: [
				{
					label: 'Nombre',
					field: 'name',
					width: 200
				},
				{
					label: 'Apellido',
					field: 'lastName',
					width: 200
				},
				{
					label: 'Número de factura',
					field: 'billNumber',
					width: 200
				},
				{
					label: 'Monto total',
					field: 'billTotal',
					width: 200
				},
				{
					label: 'Moneda',
					field: 'coinName',
					width: 200
				},
				{
					label: 'Tipo de cambio',
					field: 'exchangeRate',
					width: 200
				},
				{
					label: 'Fecha',
					field: 'date',
					width: 200
				}
			],
			rows: []
		},
		enrollmentReport: [],
		auxList: [],
		visible: false
	};
	valueUserEnrollment = this.valueUserEnrollment.bind(this);
	valueDate = this.valueDate.bind(this);
	downloadCSV = this.downloadCSV.bind(this);

	/**
	 * Method that determine a specific filter a enrollments by a user ID
	 * @param {event} event
	 */
	valueUserEnrollment(event) {
		var object = {};
		var auxList = [];
		this.state.enrollmentReport.forEach((auxEnrollment) => {
			let dateExist = false;
			if (auxEnrollment.userID === event.value) {
				auxList.forEach((auxUserEnrollment) => {
					if (auxUserEnrollment.value === auxEnrollment.date) {
						dateExist = true;
					}
				});
				if (!dateExist) {
					object = {
						label: auxEnrollment.date,
						value: auxEnrollment.date
					};
					auxList.push(object);
				}
			}
		});
		this.setState({
			auxList,
			userID: event.value
		});
	}

	/**
	 * Method that determine a specific date to filter a enrollments by a date
	 * @param {event} event
	 */
	valueDate(event) {
		console.log('Fecha: ' + event.value + ', ' + this.state.userID);
		var newEnrollmentReport = [];
		newEnrollmentReport = this.state.enrollmentReport.filter(
			(row) => row.userID == this.state.userID && row.date == event.value
		);
		this.setState({
			enrollmentReportList: {
				columns: this.state.enrollmentReportList.columns,
				rows: newEnrollmentReport
			},
			visible: true
		});
	}

	/**
	 * Method that allow download a specific enrollment report
	 */
	downloadCSV() {
		const { parse } = require('json2csv');
		var fields = [];

		fields = [ 'Nombre', 'Apellido', 'Numero de factura', 'Monto total', 'Fecha' ];

		const opts = { fields };

		const csv = parse(this.state.enrollmentReportList.rows, {
			opts,
			encoding: 'ISO-8859-1',
			excelStrings: true,
			withBOM: true
		});
		var fileDownload = require('js-file-download');
		fileDownload(csv, 'Reporte.csv');
	}

	/**
	 * Method that get all enrollments from de database and set in the enrollmentReport
	 */
	getEnrollmentReport() {
		axios
			.get(baseUrl + 'report/getEnrollmentReports')
			.then((response) => {
				var dbEnrollmentReportList = response.data;
				this.setState({
					enrollmentReport: dbEnrollmentReportList
				});
			})
			.catch((err) => console.error('Hola: ' + err));
	}

	/**
	 * Method that charge a method getEnrollmentReport
	 */
	componentDidMount() {
		if(sessionStorage.getItem('userType') == 2 || sessionStorage.getItem('userType') == 3 ){
		this.getEnrollmentReport();
		}else{
			this.props.history.push('/');
		}
	}

	/**
	 * Method that render the view with the bill reports and their actions
	 * @param {array} auxUserReport
	 */
	render() {
		const auxUserReport = [];
		this.state.enrollmentReport.forEach(function(report) {
			let userExist = false;
			auxUserReport.forEach(function(user) {
				if (user.value === report.userID) {
					userExist = true;
				}
				console.log('entro');
			});
			if (!userExist) {
				auxUserReport.push({
					label: report.name + ' ' + report.lastName,
					value: report.userID
				});
			}
		});

		//----------------------ESTO PARA LA TABLA--------------------------------------------------------/
		return (
			<div>
				<Container>
					<ToastContainer
						position="top-right"
						autoClose={5000}
						hideProgressBar
						newestOnTop
						closeOnClick
						rtl={false}
						pauseOnVisibilityChange
						draggable
						pauseOnHover
					/>
					<MDBRow>
						<MDBCol className="col-12 col-lg-9 offset-lg-3 mt-3">
							<h3 className="adminTitlesFont">Reportes</h3>
						</MDBCol>
						{/*-------------------------AQUI ESTA LA TABLA----------------------------------------  */}
						<MDBCol className="col-12 col-lg-9 offset-lg-3">
							<MDBCard style={{ padding: '20px 20px 20px 20px' }}>
								<MDBCardTitle className="cardHeader">Reporte de Facturas</MDBCardTitle>
								<MDBRow>
									<MDBCol className="col-12 col-md-6">
										<FormGroup>
											<label>Usuario</label>
											<Select
												className="selectLetter selectReport"
												name="userReport"
												components={animatedComponents}
												options={auxUserReport}
												classNamePrefix="select"
												placeholder="Seleccione un usuario"
												onChange={this.valueUserEnrollment}
												theme={theme => ({
													...theme,
													borderRadius: 4,
													font: 'Open Sans',
													height: 9,
													
												
													colors: {
														...theme.colors,
														primary25: '#FFA93F',
														primary: '#808080',
													},
												})}
											/>
										</FormGroup>
									</MDBCol>
									<MDBCol className="col-12 col-md-6">
										<FormGroup>
											<label>Fecha</label>
											<Select
												className="selectLetter selectReport"
												name="date"
												components={animatedComponents}
												options={this.state.auxList}
												classNamePrefix="select"
												placeholder="Seleccione el tipo"
												onChange={this.valueDate}
												theme={theme => ({
													...theme,
													borderRadius: 4,
													font: 'Open Sans',
													height: 9,
													
												
													colors: {
														...theme.colors,
														primary25: '#FFA93F',
														primary: '#808080',
													},
												})}
											/>
										</FormGroup>
									</MDBCol>
								</MDBRow>

								{this.state.visible ? (
									<MDBRow className="auxTablePosition">
										<MDBBtn
											className="downloadEnrollmentButtom"
											color="orange"
											onClick={this.downloadCSV}
										>
											<MDBIcon icon="download" color="plusIcon" className="plusIcon" size="lg" />
										</MDBBtn>
										<MDBCol className="col-12">
											<MDBDataTable
												data={this.state.enrollmentReportList}
												hover
												striped
												bordered
												small
												entriesLabel={'Mostrar'}
												searching={false}
												paginationLabel={[ 'Anterior', 'Siguiente' ]}
												infoLabel={[ 'Mostrando', 'de', 'de un total de', 'registros' ]}
												entries={5}
												entriesOptions={[ 5, 10, 15 ]}
												responsive
												noBottomColumns={true}
												noRecordsFoundLabel="No se han encontrado datos"
												theadTextWhite={true}
											/>
										</MDBCol>
									</MDBRow>
								) : null}
								{/*-------------------------AQUI TERMINA LO DE LA TABLA----------------------------------------  */}
							</MDBCard>
						</MDBCol>
					</MDBRow>
				</Container>
			</div>
		);
	}
}

export default EnrollmentReport;
