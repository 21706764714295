/**
 * @fileoverview  CourseUser consists of presenting the information of courses and comments to the user.
 *
 * @version 1.0
 * @author Estrella López López <estrella.lopez@ucrso.info>
 *
 * History - Initial Release
 * ----
 * The first version of CourseUser was written by  Estrella López López.
 */

import React, { Component } from "react";
import { Card, Container, FormGroup } from "reactstrap";
import "../../node_modules/swiper/swiper-bundle.min.css";
import { baseUrl } from "./baseUrl";
import axios from "axios";
import Select from "react-select";
import {
  MDBCarousel,
  MDBAnimation,
  MDBCarouselCaption,
  MDBCarouselInner,
  MDBCarouselItem,
  MDBView,
  MDBMask,
  MDBContainer,
  MDBCardBody,
  MDBCardTitle,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBBtn,
  Row,
  Col,
} from "mdbreact";

class CourseUser extends Component {
  constructor(props) {
    super(props);
    /**
     * paragraphCourse:
     * @type {String}
     * Property that stores the main course information.
     *
     * category:
     * @type {Integer}
     * Property that stores the id of the category.
     *
     * categories:
     * @type {Array}
     * Property that stores the category list.
     *
     * courseDescription:
     * @type {Array}
     * Property that stores the list of courses
     *
     * courseID:
     * @type {Integer}
     * Property that stores the course id.
     *
     * name:
     * @type {String}
     * Property where the name of the course is saved.
     *
     * comments:
     * @type {Array}
     * Property that stores the comments list.
     *
     * title:
     * @type {Integer}
     * Property that stores the title.
     *
     */
    this.state = {
      paragraphCourse: "",
      category: 0,
      categories: [],
      courseDescription: [],
      courseID: 0,
      name: "",
      comments: [],
    };

    this.loadParagraphCourse = this.loadParagraphCourse.bind(this);
    this.valueCategory = this.valueCategory.bind(this);
    this.courseView = this.courseView.bind(this);
    this.comment = this.comment.bind(this);
    this.loadgetCourse = this.loadgetCourse.bind(this);
    this.loadgetComment = this.loadgetComment.bind(this);
    this.coursefilter = this.coursefilter.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  /**
   * Method that loads the elements to be initially displayed on the website.
   */
  componentDidMount() {
    if (
      sessionStorage.getItem("userType") != 2 &&
      sessionStorage.getItem("userType") != 3
    ) {
      axios.get(baseUrl + "course/getAllCategories").then((response) => {
        this.state.categories = response.data;
        this.setState({ categories: response.data });
      });

      this.loadParagraphCourse();
      this.loadgetCourse();
      this.loadgetComment();
    } else {
      this.props.history.push("/admin");
    }
  }

  /**
   * Method that goes to the database and loads the main information of the course page.
   */
  loadParagraphCourse() {
    axios.get(baseUrl + "courseUser/getCourseMainText").then((response) => {
      this.state.paragraphCourse = response.data[0].textValue;
      this.setState({ paragraphCourse: response.data[0].textValue });
    });
  }

  /**
   * Method that goes to the database and loads the list of courses with their respective information.
   */
  loadgetCourse() {
    axios.get(baseUrl + "courseUser/getCourseDescription").then((response) => {
      this.state.courseDescription = response.data;
      this.setState({ courseDescription: response.data });
    });
  }

  /**
   * Method that brings the comments that have been accepted for publication from the database.
   */
  loadgetComment() {
    axios.get(baseUrl + "courseUser/getAllCommentary").then((response) => {
      this.state.comments = response.data;
      this.setState({ comments: response.data });
    });
  }

  /**
   * Method that assigns the value entered to the variable.
   * @param {event} event
   */
  valueCategory(event) {
    this.state.category = parseInt(event.value);
    this.setState({ category: parseInt(event.value) });
  }

  /**
   * Method that brings the courses that belong to the category that are sent to the database.
   * @param {event} event
   */
  coursefilter(event) {
    axios
      .get(baseUrl + "courseUser/courseFilter/" + event.value)
      .then((response) => {
        this.state.courseDescription = response.data;
        this.setState({ courseDescription: response.data });
      });
  }

  /**
   * Method that redirects to the course detail page in case of having chosen a course.
   * @param {Integer} id
   * @param {String} name
   */
  toggle = (id, name) => () => {
    this.setState({
      courseID: id,
      name: name,
    });
    this.props.history.push({
      pathname: "/CourseDetail/" + id,
    });
  };

  /**
   * Method that displays previously accepted comments in a carousel.
   */
  comment() {
    var tempArray = [];
    this.state.comments.map((elements, key) => {
      tempArray.push(
        <>
          <MDBCarouselItem itemId={key + 1}>
            <MDBView>
              <Card
                style={{
                  width: 900,
                  height: 250,
                  backgroundColor: elements.color,
                  alignContent: "center",
                }}
              ></Card>

              <MDBMask overlay="orange-light" />
            </MDBView>
            <MDBCarouselCaption>
              <p className=" commentText ">
                {" "}
                <MDBIcon icon="quote-left" size="sm" /> {elements.description}{" "}
                <MDBIcon icon="quote-right" size="sm" />
              </p>
              <h4 className="commentTextFullName">{elements.fullname}</h4>
              <br />
            </MDBCarouselCaption>
          </MDBCarouselItem>
        </>
      );
    });
    return tempArray;
  }

  /**
   * Method that shows the layout of how the courses will be placed on the page.
   */
  courseView() {
    var tempArray = [];
    this.state.courseDescription.map((elements, key) => {
      tempArray.push(
        <MDBRow className="mt-3 offset-lg-0">
          <MDBCol md="12">
            <Card style={{ marginTop: 20 }}>
              <Row className="mt-9 col-sm-12 ">
                <div class="col-12 col-lg-6 offset-lg-0 col-md-12 ">
                  <Row className="mt-3">
                    <div class="col-12 offset-lg-1 col-md-12 ">
                      <MDBCardTitle
                        className="userSubtitlesFont "
                        style={{ textAlign: "center" }}
                      >
                        {elements.name}
                      </MDBCardTitle>
                      <MDBCardBody
                        className="paragraph"
                        style={{ textAlign: "justify" }}
                      >
                        {elements.description}
                      </MDBCardBody>
                    </div>
                  </Row>
                  <div class="col-8 offset-lg-9 mt-6 ">
                    <MDBBtn
                      color="grayButton"
                      className="courseButton generalButton grayButton"
                    >
                      <span
                        className="buttonSeeText"
                        onClick={this.toggle(elements.courseID, elements.name)}
                      >
                        Ver más
                      </span>
                    </MDBBtn>
                  </div>
                </div>
                <div class="col-12 offset-lg-1 mt-1 col-md-5 col-sm-12 ">
                  <div class="form-group" style={{ textAlign: "center" }}>
                    <div className="imagenCourseUser">
                      <img
                        src={baseUrl + elements.link}
                        id="valuesImg"
                        width="100%"
                        height="100%"
                        className=" img-fluid z-depth-3 rounded"
                        alt="Responsive image"
                      />
                    </div>
                  </div>
                </div>
                <br />
              </Row>
            </Card>
          </MDBCol>
        </MDBRow>
      );
    });
    return tempArray;
  }

  /**
   * Method that shows the elements found or added to the web page.
   */
  render() {
    return (
      <Container className="mt-5 ">
        <Row className="mt-12">
          <Col className="col-md-2 col-md-offset-7" className="mx-auto" />
          <Col md="12" className="mx-auto">
            <Row className="mt-0">
              <MDBCol className=" col-12 col-lg-9 offset-lg-1 mt-3">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb white">
                    <li class="breadcrumb-item">
                      <a class="black-text" href="/">
                        Inicio
                      </a>
                    </li>
                    <li class="breadcrumb-item active ">Programas</li>
                  </ol>
                </nav>
              </MDBCol>
            </Row>
            <Row className="mt-3 col-sm-12">
              <MDBCol className=" col-12 col-lg-9 offset-lg-1 mt-2 ">
                <h3 className="userTitlesFont">Programas</h3>
                <br />
                <h5>Te ofrecemos:</h5>
              </MDBCol>
            </Row>
            <Row
              className="mt-3 offset-lg-1 col-sm-12 userTextFont"
              style={{ textAlign: "justify" }}
            >
              <Col xs={2} md={6} lg={10}>
                <FormGroup>
                  <MDBAnimation type="fadeIn" delay="1s">
                    <Card style={{ width: 870, height: 100 }}>
                      <div class="form-group" style={{ textAlign: "center" }}>
                        <div>
                          <p
                            style={{
                              fontFamily: "Helvetica",
                              textAlign: "center",
                              marginTop: 20,
                              marginLeft: 10,
                              marginBottom: 5,
                              marginRight: 10,
                            }}
                          >
                            <label>{this.state.paragraphCourse}</label>
                          </p>
                        </div>
                      </div>
                    </Card>
                  </MDBAnimation>
                </FormGroup>
              </Col>
            </Row>
            <Row className="mt-3 offset-lg-1 col-sm-12">
              <Col xs={2} md={6} lg={1}>
                <FormGroup>
                  <label className="category">Categoría </label>
                </FormGroup>
              </Col>
              <Col xs={2} md={6} lg={3}>
                <div class="changeSelect">
                  <Select
                    placeholder="Elegir categoría"
                    className="selectLetter"
                    id="selectCategoryUser"
                    required
                    onChange={this.coursefilter}
                    options={this.state.categories.map(function (json) {
                      return {
                        label: json.categoryName,
                        value: json.categoryID,
                      };
                    })}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 4,
                      font: "Open Sans",
                      alignContent: "center",
                      height: 9,

                      colors: {
                        ...theme.colors,
                        primary25: "#FFA93F",
                        primary: "#808080",
                      },
                    })}
                  />
                </div>
              </Col>
            </Row>
            <Row className="mt-2 offset-lg-1 ">
              <Col xs={2} md={6} lg={11}>
                <Row className="mt-4 offset-lg-0 col-sm-12  ">
                  <div>{this.courseView()}</div>
                </Row>
              </Col>
              <br />
              <br />
            </Row>
            <Row>
              <MDBCol>
                <br />
                <br />
              </MDBCol>
            </Row>
            <br />
            <br />
            <h3 className="userTitlesFont" style={{ textAlign: "center" }}>
              Experiencias
            </h3>

            <MDBCol className="col-lg-8 offset-lg-2 mt-2 sm-12">
              <MDBContainer>
                <MDBCarousel
                  activeItem={1}
                  length={this.state.comments.length}
                  showControls={true}
                  showIndicators={true}
                  responsive={true}
                  className="z-depth-1"
                >
                  <MDBCarouselInner>{this.comment()}</MDBCarouselInner>
                </MDBCarousel>
              </MDBContainer>
            </MDBCol>
            <br />
            <br />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default CourseUser;
