/**
 * @fileoverview  ArticleCategoryUser it consists of displaying the articles by category.
 *
 * @version 1.0
 * @author Estrella López López <estrella.lopez@ucrso.info>
 *
 * History - Initial Release
 * ----
 * The first version of ArticleCategoryUser was written by Estrella López López.
 */

import React, { Component } from "react";
import { Container } from "reactstrap";
import "../../node_modules/swiper/swiper-bundle.min.css";
import { baseUrl } from "./baseUrl";
import axios from "axios";
import icon from "../images/pdf.svg";
import {
  MDBCard,
  MDBCardImage,
  MDBCardTitle,
  MDBCol,
  Row,
  Col,
  MDBCardFooter,
  MDBBtn,
} from "mdbreact";

class ArticleCategoryUser extends Component {
  constructor(props) {
    /**
     * article:
     * @type {Array}
     * Property that stores the articles list.
     *
     * category:
     * @type {String}
     * Property that stores the name of the category.
     *
     * categories:
     * @type {Array}
     * Property that stores the category list.
     *
     * detail:
     * @type {Integer}
     * Property that will store the id of the category.
     *
     * articleName:
     * @type {String}
     * Property that stores the article name.
     *
     * author:
     * @type {Integer}
     * Property that stores the name of the article author.
     *
     * link:
     * @type {String}
     * Property that stores the link of the document brought from the database.
     */
    super(props);
    this.state = {
      article: [],
      category: "",
      categories: [],
      detail: 0,
      articleName: "",
      author: "",
      link: "",
    };

    this.articlesView = this.articlesView.bind(this);
    this.getCategories = this.getCategories.bind(this);
  }

  /**
   * Method that loads all the categories with the respective information found in the database.
   */
  getCategories() {
    axios
      .get(baseUrl + "category/getAllCategoriesMultimedia")
      .then((response) => {
        this.state.categories = response.data;
        this.setState({ categories: response.data });
      });
  }

  /**
   * Method that loads the elements to be initially displayed on the website.
   */
  componentDidMount() {
    if (
      sessionStorage.getItem("userType") != 2 &&
      sessionStorage.getItem("userType") != 3
    ) {
      this.state.detail = this.props.match.params.ID;
      this.setState({ detail: this.props.match.params.ID });

      axios
        .get(baseUrl + "articleUser/articleInformation/" + this.state.detail)
        .then((response) => {
          this.state.article = response.data;
          this.setState({ article: response.data });
        });
      this.getCategories();
    } else {
      this.props.history.push("/admin");
    }
  }

  /**
   * Method that displays the category that was selected.
   */
  categoryInformation() {
    var tempArray = [];

    this.state.categories.map((elements, key) => {
      if (this.state.detail == elements.categoryID) {
        this.state.category = elements.categoryName;
        tempArray.push(
          <MDBCol className="col-12 offset-lg-4 mt-2 col-sm-11">
            <MDBCard style={{ heigh: 40, width: 275 }}>
              <MDBCardImage
                className="img-fluid"
                src={baseUrl + elements.link}
                waves
              />

              <MDBCardFooter color="sunny-morning-gradient">
                <MDBCardTitle style={{ color: "white" }}>
                  {elements.categoryName}
                </MDBCardTitle>
              </MDBCardFooter>
            </MDBCard>
            <br />
          </MDBCol>
        );
      }
    });
    return tempArray;
  }

  /**
   * Method that displays the articles of the selected category.
   */
  articlesView() {
    var tempArray = [];

    this.state.article.map((elements, key) => {
      tempArray.push(
        <div class="col-md-4 mt-4">
          <div class="card profile-card-5 sunny-morning-gradient">
            <div class="card-img-block">
              <img
                class="card-img-top"
                src={baseUrl + elements.linkImagen}
                alt="Card image cap"
              />
            </div>
            <div class="profile-thumb-block">
              <a href={baseUrl + elements.linkVideo} target="_blank">
                <img
                  src={icon}
                  id="img"
                  width="45px"
                  height="45px"
                  alt="profile-image"
                  class="profile"
                />
              </a>
            </div>
            <div class="card-content">
              <h2>
                {elements.author}
                <small>{elements.articleName}</small>
              </h2>
            </div>
          </div>
          <br />
        </div>
      );
    });
    return tempArray;
  }

  /**
   * Method that returns to the previous page
   */
  back() {
    this.props.history.push(`/ArticleUser`);
  }

  /**
   * Method that shows the elements found or added to the web page.
   */
  render() {
    return (
      <Container className="mt-5 ">
        <Row className="mt-12">
          <Col className="col-md-2 col-md-offset-7" className="mx-auto" />
          <Col md="12" className="mx-auto">
            <Row className="mt-0">
              <MDBCol className=" col-12 col-lg-9 offset-lg-0 mt-3">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb white">
                    <li class="breadcrumb-item">
                      <a class="black-text" href="/">
                        Inicio
                      </a>
                    </li>
                    <li class="breadcrumb-item">
                      <a class="black-text" href="/ArticleUser">
                        Artículos
                      </a>
                    </li>
                    <li class="breadcrumb-item active ">
                      {this.state.category}
                    </li>
                  </ol>
                </nav>
              </MDBCol>
            </Row>

            <Row className="mt-3 col-sm-12">
              <MDBCol className=" col-12 offset-lg-1 mt-4 col-sm-10">
                {this.categoryInformation()}
              </MDBCol>
            </Row>
            <div class="d-flex align-content-xl-center flex-wrap">
              {this.articlesView()}
            </div>
          </Col>
          <MDBCol className=" col-5 col-lg-12 offset-lg-1 mt-3">
            <MDBBtn color="purpleButton" className="generalButton purpleButton">
              <span className="modalButtonText" onClick={() => this.back()}>
                Volver
              </span>
            </MDBBtn>
          </MDBCol>
        </Row>
      </Container>
    );
  }
}
export default ArticleCategoryUser;
