/**
 * @fileoverview PasswordRecovery, this component allows to recover the password
 * 
 * @version 1.0
 * 
 * @author Ester Molina R <maria.molina@ucrso.info>
 * History
 * v1.0 - Initial Realease
 * ----
 * The first version of PasswordRecovery was written by Ester Molina R. 
 */

import React, {Component} from 'react';
import { MDBRow,MDBCol,MDBContainer, MDBBtn } from 'mdbreact';
import { FormGroup } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import validations from './Validations';
import axios from 'axios';
import { baseUrl } from './baseUrl';
import md5 from 'md5';

class PasswordRecovery extends Component{
    constructor(props){
        super(props);
        /**
         * validations:
         * @type {Object}
         * Property that contains methods for validate woth regular expressions
         * 
         * email:
         * @type {String}
         * Property that contains the email
         * 
         * password:
         * @type {String}
         * Property that contains the password
         * 
         * confirmPassword:
         * @type {String}
         * Property that contains the confirm password
         * 
         * changePassword:
         * @type {integer}
         * Property that contains if the change are verify
         */
        this.state = {
            validations: new validations(),
            email: "",
            password:"",
            confirmPassword:"",
            changePassword:0
        }
        this.handleInput = this.handleInput.bind(this);
        this.validateEmail = this.validateEmail.bind(this);
        this.verifyEmail = this.verifyEmail.bind(this);
        this.validateData = this.validateData.bind(this);
        this.sendEmail = this.sendEmail.bind(this);
        this.changePassword = this.changePassword.bind(this);
        this.method = this.method.bind(this);
    }

    componentDidMount(){
        if(sessionStorage.getItem('userType') != 2 && sessionStorage.getItem('userType') != 3 && sessionStorage.getItem('userType') != 1){
            if(this.props.match.params.email !== undefined){
                this.setState({
                    email: this.props.match.params.email,
                    changePassword:1
                });
            }
        }
    }

    /**
     * Method that change the state
     * @param {event} e 
     */
    handleInput(e) {
		const { value, name } = e.target;
		this.setState({
			[name]: value
		});
    }
    
    /**
     * Method that notificate the user
     * @param {event} evt 
     * @param {String} value 
     * @param {String} msj 
     */
	notify = (evt, value, msj) => {
		switch (value) {
			case 'SUCCESS':
				toast.success(msj);
				break;
			case 'ERROR':
				toast.error(msj);
				break;
			case 'WARN':
				toast.warn(msj);
				break;
			case 'INFO':
				toast.info(msj);
				break;
			default:
				toast.info(msj);
		}
	};
    

    /**
     * Method that validate the email
     * @param {event} event 
     */
    validateEmail(event){
        if(this.state.email.length === 0){
            this.notify(event,'ERROR','Debe ingresar un correo electrónico');
        }else if(!this.state.validations.validateEmailField(this.state.email)){
            this.notify(event,'ERROR','El correo ingresado no tiene el formato correcto');
        }else{
            this.verifyEmail(event);
        }
    } 

    /**
     * Method that validate the data
     * @param {event} event 
     */
    validateData(event){
        if(this.state.password.length === 0){
            this.notify(event,'ERROR','Debe ingresar una contraseña');
        }else if(!this.state.validations.validatePasswordField(this.state.password)){
            this.notify(event,'ERROR','La contraseña debe tener mayúsculas, minúsculas, números y caracteres');
        }else if(this.state.confirmPassword.length === 0){
            this.notify(event,'ERROR','Debe ingresar la contraseña para confirmar');
        }else if(!this.state.validations.validatePasswordField(this.state.confirmPassword)){
            this.notify(event,'ERROR','La contraseña debe tener mayúsculas, minúsculas, números y caracteres');
        }else if(this.state.password !== this.state.confirmPassword){
            this.notify(event,'ERROR','La contraseña y la confirmación de la contraseña son diferentes, por favor verifique su contraseña');
        }else{
            fetch(baseUrl+'logIn/changePassword',{
                method: 'post',
                body: JSON.stringify({
                    password: md5(this.state.password),
                    email: this.state.email
                }),
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            }); 
            this.notify(event, 'SUCCESS','Su contraseña a sido actualizada de manera satisfactoria'); 
            this.props.history.push('/'); 
        }
    }

    /**
     * Method that verify if the email exists
     * @param {event} event 
     */
    verifyEmail(event){
        axios.get(baseUrl+ 'logIn/verifyEmail', {
        params:{
            email:this.state.email
        }
    }).then(response =>{
        var valid = response.data["fun_verifyValidEmail('"+ this.state.email + "')"];
        if(valid === 1){
            console.log("enviando correo");
            fetch(baseUrl+'logIn/verificationEmail',{
                method: 'post',
                body: JSON.stringify({
                    email: this.state.email,
                    redirection: baseUrl + 'PasswordRecovery/'+this.state.email
                }),
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            }).then(response =>
            this.notify(event,'SUCCESS','Se ha enviado un correo eletrónico para que pueda cambiar su contraseña'));
           
        }
    })
    }

    /**
     * Method that perform the validate email or validate data action when an ENTER is clicked
     * @param {event} e 
     */
    onKeyEvent =(e)=> {
        if (e.key === "Enter") {
            if(this.state.changePassword === 0){
                this.validateEmail(e);
            }else{
                this.validateData(e);
            }
        }
    }

    /**
     * Method that render the send email page
     */
    sendEmail(){
        return(
          <div>
				<MDBRow>
					<MDBCol className="col-12 col-lg-8 offset-lg-2 mt-5">
						<h3 className="adminTitlesFont">Recuperar contraseña</h3>
					</MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol className="col-12 col-lg-8 offset-lg-2 mt-5">
                        <FormGroup>
                            <label htmlFor="email" style={{textAlign:"justify"}}>Digite el correo electrónico registrado en el sistema</label>
                            <input type="email" id="email" name="email" className="form-control" value={this.state.email} onChange={this.handleInput} onKeyPress={this.onKeyEvent}></input>
                        </FormGroup>
                    </MDBCol>
                    <MDBCol className="col-12 col-lg-10 offset-lg-2 mb-5">
                        <FormGroup>
                            <MDBBtn color="blueButton" className="modalButton blueButton" onClick={this.validateEmail}>
                            <span className="modalButtonText">Recuperar</span>
                            </MDBBtn>
                        </FormGroup>
                    </MDBCol>
                </MDBRow>
            </div>
        )
    }


    /**
     * Method that render the change password page
     */
    changePassword(){
        return(
          <div>
				<MDBRow>
					<MDBCol className="col-12 col-lg-8 offset-lg-2 mt-5">
						<h3 className="adminTitlesFont">Cambiar contraseña</h3>
					</MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol className="col-12 col-lg-8 offset-lg-2 mt-5">
                        <FormGroup>
                            <label htmlFor="email" style={{textAlign:"justify"}}>Digite la contraseña</label>
                            <input type="password" id="password" name="password" className="form-control" value={this.state.password} onChange={this.handleInput} onKeyPress={this.onKeyEvent}></input>
                        </FormGroup>
                    </MDBCol>
                    <MDBCol className="col-12 col-lg-8 offset-lg-2 mt-5">
                        <FormGroup>
                            <label htmlFor="email" style={{textAlign:"justify"}}>Digite la contraseña nuevamente</label>
                            <input type="password" id="confirmPassword" name="confirmPassword" className="form-control" value={this.state.confirmPassword} onChange={this.handleInput} onKeyPress={this.onKeyEvent}></input>
                        </FormGroup>
                    </MDBCol>
                    </MDBRow>
                    <MDBRow>
                    <MDBCol className="col-12 col-lg-10 offset-lg-2 mb-5">
                        <FormGroup>
                            <MDBBtn color="blueButton" className="modalButton blueButton" onClick={this.validateData}>
                            <span className="modalButtonText">Guardar</span>
                            </MDBBtn>
                        </FormGroup>
                    </MDBCol>
                </MDBRow>
            </div>
        )
    }

    /**
     * Method that verify which page have to render
     */
    method(){
        if(this.state.changePassword === 0){
            return(
                <this.sendEmail/>
            )
        }else{
            return (
            <this.changePassword/>
            )
        }
    }

    /**
     * Method that render the HTML
     */
    render(){
  
        return(
            <MDBContainer>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnVisibilityChange
                draggable
                pauseOnHover
            />
                <this.method/>
            
            </MDBContainer>
        )
    }
}


export default PasswordRecovery;