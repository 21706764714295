/**
 * @fileoverview EditArticle, this component allow edit a diffenrent
 * Articles in the data base.
 * 
 * @version 1.0
 * 
 * @author Anthony Vargas Méndez <anthony.vargas@ucrso.info>
 * History
 * v1.0 - Initial Release
 * ----
 * The first version of EditArticle was writen by Anthony Vargas Méndez
 */
import React, { Component, Fragment } from 'react';
import { MDBRow, MDBCol, MDBBtn } from 'mdbreact';
import { MDBCard, MDBContainer, MDBCardBody, MDBCardFooter, MDBCardTitle } from 'mdbreact';
import { FormGroup } from 'reactstrap';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import validations from './Validations';
import { Link } from 'react-router-dom';

import { baseUrl } from './baseUrl';

const animatedComponents = makeAnimated();

class EditArticle extends Component {
	state = {
		/**
		 * validations:
		 * @type {object}
		 * Property that validate the inputs in this component
		 * 
		 * articleID:
		 * @type {Integer}
		 * Propety that indicates the article ID
		 * 
		 * articleName:
		 * @type {string}
		 * Propety that indicates the name of the article
		 * 
		 * description:
		 * @type {string}
		 * Propety that indicates the description of the article
		 * 
		 * author:
		 * @type {string}
		 * Propety that indicates the author of the article
		 * 
		 * document:
		 * @type {file}
		 * Propety that indicates the file of the documents of the article
		 * 
		 * image:
		 * @type {file}
		 * Propety that indicates the file of the images of the article
		 * 
		 * categories:
		 * @type {array}
		 * Propety that indicates the all list of categories
		 * 
		 * auxArticleCategories:
		 * @type {array}
		 * Propety that indicates the selected list of specific categories of the article
		 * 
		 * articleCategories:
		 * @type {array}
		 * Propety that indicates and contain all categories of the specific article in the database
		 * 
		 * documentID:
		 * @type {Integer}
		 * Propety that indicates the document ID
		 * 
		 * imageID:
		 * @type {Integer}
		 * Propety that indicates the image ID
		 * 
		 * imageLink:
		 * @type {string}
		 * Propety that indicates the link of the image
		 * 
		 * description:
		 * @type {string}
		 * Propety that indicates the link of the document
		 */
		validations: new validations(),
		articleID: 0,
		categories: [],
		articleCategories: [],
		auxArticleCategories: [],
		articleName: '',
		description: '',
		author: '',
		imageID: 0,
		documentID: 0,
		documentLink: '',
		documentURL: '',
		imageLink: '',
		imageURL: '',
		document: null,
		image: null,
		auxBoolean: false
	};
	getArticleData = this.getArticleData.bind(this);
	getCategories = this.getCategories.bind(this);
	valueCategories = this.valueCategories.bind(this);
	handleImageChange = this.handleImageChange.bind(this);
	handleDocumentChange = this.handleDocumentChange.bind(this);
	handleInput = this.handleInput.bind(this);
	handleSubmit = this.handleSubmit.bind(this);
	validateData = this.validateData.bind(this);
	empty = this.empty.bind(this);

	/**
	 * Method that assigns the value to the variables of the articles
	 * @param {event} e
	 */
	handleInput(e) {
		const { value, name } = e.target;
		this.setState({
			[name]: value
		});
	}

	/**
	 * Method that specify the different alerts in the component
	 * @param {event} event
	 * @param {value} value
	 * @param {string} msj
	 */
	notify = (evt, value, msj) => {
		switch (value) {
			case 'SUCCESS':
				toast.success(msj);
				break;
			case 'ERROR':
				toast.error(msj);
				break;
			case 'WARN':
				toast.warn(msj);
				break;
			case 'INFO':
				toast.info(msj);
				break;
			default:
				toast.info(msj);
		}
	};

	/**
	 * Method that allow use the enter button
	 * @param {event} e
	 */
	onKeyEvent = (e) => {
		if (e.key === 'Enter') {
			this.validateData(e);
		}
	};

	/**
	 * Method that validate the value to the variables in the inpust of the articles
	 * @param {event} event
	 */
	validateData(event) {
		if (this.empty(event)) {
			if (!this.state.validations.validateTextWithNumberField(this.state.articleName)) {
				this.notify(event, 'ERROR', 'El nombre del artículo no debe contener caracteres especiales');
			} else if (!this.state.validations.validateTextWithNumberField(this.state.description)) {
				this.notify(event, 'ERROR', 'La descripción no debe contener caracteres especiales');
			} else if (!this.state.validations.validateTextField(this.state.author)) {
				this.notify(event, 'ERROR', 'El autor solo debe contener letras');
			} else {
				this.handleSubmit(event);
			}
		}
	}

	/**
	 * Method that determine if the value to the variables are empty
	 * @param {event} event
	 */
	empty(event) {
		if (this.state.articleName.length === 0) {
			this.notify(event, 'ERROR', 'El nombre del artículo no debe estar vacío');
			return false;
		} else if (this.state.description.length === 0) {
			this.notify(event, 'ERROR', 'La descripción no debe estar vacía');
			return false;
		} else if (this.state.author.length === 0) {
			this.notify(event, 'ERROR', 'El autor no debe estar vacío');
			return false;
		} else if (
			(this.state.document === null || this.state.document === undefined) &&
			this.state.documentLink.length === 0
		) {
			this.notify(event, 'ERROR', 'Debe seleccionar el documento del artículo');
			return false;
		} else if ((this.state.image === null || this.state.image === undefined) && this.state.imageLink.length === 0) {
			this.notify(event, 'ERROR', 'Debe seleccionar la imagen del artículo');
			return false;
		} else if (this.state.articleCategories.length === 0) {
			this.notify(event, 'ERROR', 'Debe seleccionar al menos una categoria para el artículo');
			return false;
		} else {
			return true;
		}
	}

	/**
	 * Method that set a list of categories in the articleCategories and auxArticleCategories
	 * @param {array} selectedCategories
	 */
	valueCategories(selectedCategories) {
		console.log('LISTA DE CATEGORIAS: ' + this.state.auxArticleCategories);
		if (selectedCategories === null) {
			this.setState({
				articleCategories: [],
				auxArticleCategories: []
			});
		} else {
			this.setState({
				articleCategories: selectedCategories.map(function(category) {
					return { categoryID: category.value, categoryName: category.label };
				}),
				auxArticleCategories: selectedCategories.map(function(category) {
					return category.value;
				}),
				auxBoolean: true
			});
		}
	}

	/**
	 * Method that set a file in the document and change the name of this file document
	 * @param {event} event
	 */
	handleDocumentChange(event) {
		event.preventDefault();
		if (event.target.files && event.target.files.length === 1) {
			const file = event.target.files[0];
			this.setState({
				document: file
			});

			var name = file.name;
			if (window.innerWidth < 500) {
				if (name.length >= 20) {
					name = name.substring(0, 25) + name.substring(name.length - 4, name.length);
				}
			} else {
				if (name.length >= 40) {
					name = name.substring(0, 35) + name.substring(name.length - 4, name.length);
				}
			}
			document.getElementById('labelDocument').textContent = name;
		}
	}

	/**
	 * Method that set a file in the image and change the name of this file image
	 * @param {event} event
	 */
	handleImageChange(event) {
		event.preventDefault();

		if (event.target.files && event.target.files.length === 1) {
			const file = event.target.files[0];
			this.setState({
				image: file
			});
			var name = file.name;
			if (window.innerWidth < 500) {
				if (name.length >= 20) {
					name = name.substring(0, 10) + name.substring(name.length - 4, name.length);
				}
			} else {
				if (name.length >= 40) {
					name = name.substring(0, 25) + name.substring(name.length - 4, name.length);
				}
			}
			document.getElementById('labelImage').textContent = name;
		}
	}

	/**
	 * Method that charge all values of the article and call the api to edit de the article and delete the categorylist of this article
	 */
	handleSubmit() {
		try {
			if (this.state.auxBoolean === true) {
				console.log('ENTRA: ' + this.state.articleID);
				fetch(baseUrl + 'article/deleteCategoryArticle', {
					method: 'post',
					body: JSON.stringify({
						articleID: this.state.articleID
					}),
					headers: { Accept: 'application/json', 'Content-Type': 'application/json' }
				});
			}
			var articleCategories = JSON.stringify(this.state.auxArticleCategories);
			articleCategories = articleCategories.replace(' ', '');
			articleCategories = articleCategories.replace('[', '"');
			articleCategories = articleCategories.replace(']', '"');

			fetch(`${baseUrl}article/editArticle`, {
				method: 'post',
				body: JSON.stringify({
					articleName: this.state.articleName,
					description: this.state.description,
					author: this.state.author,
					articleID: this.state.articleID,
					auxArticleCategories: articleCategories
				}),
				headers: { Accept: 'application/json', 'Content-Type': 'application/json' }
			});
			if (this.state.document !== null && this.state.document !== undefined) {
				const formData = new FormData();
				formData.append('document', this.state.document);
				formData.append('multimediaDocumentID', this.state.documentID);
				formData.append('url', this.state.documentURL);
				axios.post(`${baseUrl}article/editDocumentMultimedia`, formData);
			}
			if (this.state.image !== null && this.state.image !== undefined) {
				const formData = new FormData();
				formData.append('image', this.state.image);
				formData.append('multimediaImageID', this.state.imageID);
				formData.append('url', this.state.imageURL);
				axios.post(`${baseUrl}article/editImageMultimedia`, formData);
			}
			this.props.history.push('/Articles');
		} catch (err) {
			console.log('Error inesperado ' + err);
		}
	}

	/**
	 * Method that charge and indicates the session storage and methods
	 */
	componentDidMount() {
		if (sessionStorage.getItem('userType') == 2) {
			const articleId = this.props.match.params.articleId;
			this.getArticleData(articleId);
			this.getCategories();
			this.setState({
				articleID: articleId
			});
		} else if (sessionStorage.getItem('userType') == 3) {
			this.props.history.push('/admin');
		} else {
			this.props.history.push('/');
		}
	}

	/**
	 * Method that get specific category from de database and set in the var article
	 * @param {Integer} articleID
	 */
	getArticleData(articleID) {
		axios
			.get(baseUrl + 'article/getArticle', {
				params: { articleID: articleID }
			})
			.then((response) => {
				var article = response.data[0];
				this.setState({
					articleName: article.articleName,
					description: article.description,
					author: article.author
				});
			});
		axios
			.get(baseUrl + 'article/getArticleCategory', {
				params: { articleID: articleID }
			})
			.then((response) => {
				var articleCategories = response.data;
				var list = [];
				articleCategories.map((item) => {
					list.push(item.categoryID);
				});
				this.setState({
					articleCategories,
					auxArticleCategories: list
				});
			});

		axios
			.get(baseUrl + 'article/getMultimediaArticle', {
				params: { articleID: articleID }
			})
			.then((response) => {
				var multimedia = response.data;
				multimedia.map((data) => {
					var name = data.link;
					if (window.innerWidth < 500) {
						name = name.substring(20, 25) + name.substring(name.length - 4, name.length);
					} else {
						name = name.substring(20, 35) + name.substring(name.length - 4, name.length);
					}
					if (data.multimediaTypeID === 4) {
						this.setState({
							documentLink: name,
							documentID: data.multimediaID,
							documentURL: '/root/servidor/public/' + data.link
						});
					} else {
						this.setState({
							imageLink: name,
							imageID: data.multimediaID,
							imageURL: '/root/servidor/public/' + data.link
						});
					}
				});
			});
	}

	/**
	 * Method that get all categories from de database and set in the categories
	 */
	getCategories() {
		axios
			.get(baseUrl + 'category/getAllCategories')
			.then((response) => {
				var categories = response.data;
				this.setState({
					categories
				});
			})
			.catch((err) => console.error(err));
	}

	/**
	 * Method that render the view with the all inputs and selects to edit the article
	 * @param {array} categories
	 * @param {array} selectCategories
	 */
	render() {
		const categories = this.state.categories.map(function(category) {
			return {
				label: category.categoryName,
				value: category.categoryID
			};
		});
		const selectCategories = this.state.articleCategories.map(function(category) {
			return {
				label: category.categoryName,
				value: category.categoryID
			};
		});
		return (
			<Fragment>
				<MDBContainer>
					<ToastContainer
						position="top-right"
						autoClose={5000}
						hideProgressBar
						newestOnTop
						closeOnClick
						rtl={false}
						pauseOnVisibilityChange
						draggable
						pauseOnHover
					/>
					<MDBRow>
						<MDBCol className="col-12 col-lg-9 offset-lg-3 mt-3">
							<h3 className="adminTitlesFont">Editar Artículo</h3>
						</MDBCol>
						<MDBCol className="col-12 col-lg-9 offset-lg-3">
							<MDBCard style={{ padding: '20px 20px 20px 20px' }}>
								<MDBCardTitle className="cardHeader">Artículo</MDBCardTitle>
								<MDBCardBody className="adminFont">
									<MDBRow>
										<MDBCol className="col-12 col-md-6">
											<FormGroup>
												<label>
													Nombre del artículo <font color="red">*</font>
												</label>
												<input
													className="form-control "
													value={this.state.articleName}
													type="text"
													name="articleName"
													onChange={this.handleInput}
													onKeyPress={this.onKeyEvent}
												/>
											</FormGroup>
										</MDBCol>
										<MDBCol className="col-12 col-md-6">
											<FormGroup>
												<label>
													Autor/Autora <font color="red">*</font>
												</label>
												<input
													className="form-control "
													value={this.state.author}
													type="text"
													name="author"
													onChange={this.handleInput}
													onKeyPress={this.onKeyEvent}
												/>
											</FormGroup>
										</MDBCol>
									</MDBRow>
									<MDBRow>
										<MDBCol className="col-12 col-md-6">
											<FormGroup>
												<label>
													Seleccione una imagen 1920 x 1280 <font color="red">*</font>
												</label>
												<div className="custom-file">
													<input
														type="file"
														className="custom-file-input"
														lang="es"
														name="image"
														accept=".jpg"
														onChange={this.handleImageChange}
														required
														onKeyPress={this.onKeyEvent}
													/>
													<label
														className="custom-file-label"
														id="labelImage"
														htmlFor="customFile"
													>
														{this.state.imageLink}
													</label>
												</div>
											</FormGroup>
										</MDBCol>
										<MDBCol className="col-12 col-md-6">
											<FormGroup>
												<label>
													Seleccione documento <font color="red">*</font>
												</label>
												<div className="custom-file">
													<input
														type="file"
														className="custom-file-input"
														lang="es"
														id="document"
														name="document"
														accept=".pdf"
														onChange={this.handleDocumentChange}
														required
														onKeyPress={this.onKeyEvent}
													/>
													<label
														className="custom-file-label"
														id="labelDocument"
														htmlFor="customFile"
													>
														{this.state.documentLink}
													</label>
												</div>
											</FormGroup>
										</MDBCol>
									</MDBRow>
									<MDBRow>
										<MDBCol className="col-12 col-md-6">
											<FormGroup>
												<label>
													Categorías <font color="red">*</font>
												</label>
												<Select
													className="selectLetter"
													isMulti
													name="category"
													components={animatedComponents}
													options={categories}
													classNamePrefix="select"
													placeholder="Seleccione la categoría"
													onChange={this.valueCategories}
													value={selectCategories}
													theme={(theme) => ({
														...theme,
														borderRadius: 4,
														font: 'Open Sans',
														height: 9,

														colors: {
															...theme.colors,
															primary25: '#FFA93F',
															primary: '#808080'
														}
													})}
												/>
											</FormGroup>
										</MDBCol>
										<MDBCol className="col-12 col-md-6">
											<FormGroup>
												<label>
													Descripción del Artículo <font color="red">*</font>
												</label>
												<textarea
													rows={4}
													value={this.state.description}
													name="description"
													onChange={this.handleInput}
													onKeyPress={this.onKeyEvent}
												/>
											</FormGroup>
										</MDBCol>
									</MDBRow>
								</MDBCardBody>
								<MDBCardFooter>
									<Link to={'/Articles'}>
										<MDBBtn color="purpleButton" className="modalButton purpleButton">
											<span className="modalButtonText">Cancelar</span>
										</MDBBtn>
									</Link>
									<MDBBtn
										color="greenButton"
										className="modalButton greenButton"
										type="submit"
										onClick={this.validateData}
									>
										<span className="modalButtonText">Guardar</span>
									</MDBBtn>
								</MDBCardFooter>
							</MDBCard>
						</MDBCol>
					</MDBRow>

					{/*-------------------------AQUI TERMINA LO DE LA TABLA----------------------------------------  */}
				</MDBContainer>
			</Fragment>
		);
	}
}

export default withRouter(EditArticle);
