import { MDBContainer, MDBIcon,MDBRow,MDBCol } from 'mdbreact';
import React,{Component} from 'react';
import icon from '../images/pdf.jpg';
import axios from 'axios';
import {baseUrl} from './baseUrl';

/**
 * @fileoverview AboutUsUser, this component show the about us information to the user
 * 
 * @version 1.0
 * 
 * @author Ester Molina R <maria.molina@ucrso.info>
 * History
 * v1.0 - Initial Realease
 * ----
 * The first version of AboutUsUser was written by Ester Molina R. 
 */
class UserCertificates extends Component{
    constructor(props){
        super(props);
        /**
         * certificates:
         * @type {Array}
         * Property that contains the list of certificates
         */
        this.state = {
            certificates:[]
        }

        this.getCertificates = this.getCertificates.bind(this);
    }

    componentDidMount(){
        if(sessionStorage.getItem('userType') == 1){
        this.getCertificates();
        }else if(sessionStorage.getItem('userType') == 2 || sessionStorage.getItem('userType') == 3){
            this.props.history.push('/admin');
        }else{
            this.props.history.push('/');
        }
    }

    /**
     * Method that charge the data from the database
     */
    getCertificates(){
        if(sessionStorage.getItem('userType') == 1){
        axios.get(baseUrl+'perfilConfiguration/getUserCertificates',{
            params:{
                userID: sessionStorage.getItem('user')
            }
        }).then((response) => {
            var certificates = response.data;
            this.setState({
                certificates
            });
        })
    }else if(sessionStorage.getItem('userType') == 2 ||sessionStorage.getItem('userType') == 3){
        this.props.history.push('/admin');
    }else{
        this.props.history.push('/');
    }
    }

/**
 * Method that render the HTML
 */
    render(){
        var certifications = "";
        if(this.state.certificates.length !== 0){
         certifications = this.state.certificates.map((certificate) =>{
            return(
                    <div className="col-10 offset-1 col-md-3 offset-md-0 mt-4">
                        <div className="card profile-card-5 sunny-morning-gradient">
                            <div className="card-img-block">
                                <a href={baseUrl+certificate.link} target="_blank">
                                    <img className="card-img-top certificates" src={icon} alt="Card image cap" />
                                </a>
                            </div>
                            <div className="card-content">
                                <a href={baseUrl+certificate.link} target="_blank">
                                    <h2>{certificate.event}<small>{certificate.course}</small></h2>
                                </a>
                            </div>
                        </div>
                    </div>
            )
        })
    }else{
    certifications = <p style={{ textAlign: 'justify' }}>Aquí se mostrarán sus certificados. Por ahora no tiene ninguno, inscríbase a un curso para recibir uno.</p>
    }
        return(
            <MDBContainer>
                <MDBRow >
                        <MDBCol className=" col-12  mt-3">
                            <nav aria-label="breadcrumb">
                                <ol class="breadcrumb white">
                                    <li class="breadcrumb-item"><a class="black-text" href="#">Inicio</a></li>
                                    <li class="breadcrumb-item active">Certificados</li>
                                </ol>
                            </nav>
                        </MDBCol>
                    </MDBRow>
                <MDBRow>
                    <MDBCol className="col-12 ">
                        <h2 className="userTitlesFont">Mis certificados</h2>
                    </MDBCol>
                </MDBRow>

                <MDBRow className="mt-10">
                    {certifications}
        </MDBRow>
        </MDBContainer>
        )
    }

}
export default UserCertificates;