/**
 * @fileoverview AdminHomeComponent, this component allow to show the Admin Home with the information.
 * 
 * @version 1.0
 * 
 * @author Anthony Vargas Méndez <anthony.vargas@ucrso.info>
 * History
 * v1.0 - Initial Release
 * ----
 * The first version of AdminHomeComponent was writen by Anthony Vargas Méndez
 */
import React, { Component} from 'react';
import { MDBRow, MDBCol, MDBAnimation } from 'mdbreact';
import { MDBCard, MDBCardBody, MDBCardText, MDBCardImage, MDBBtn } from 'mdbreact';
import axios from 'axios';
import { baseUrl } from './baseUrl';
import { Link } from 'react-router-dom';
import DashboardChart from './DashboardChart';

class AdminHomeComponent extends Component {
	state = {
		/**
		 * quantityEvents:
		 * @type {Integer}
		 * Propety that indicates the quantity of events that contains in the database
		 * 
		 * quantityCourses:
		 * @type {Integer}
		 * Propety that indicates the quantity courses that contains in the database
		 * 
		 * quantityBills:
		 * @type {Integer}
		 * Propety that indicates the quantity bills that contains in the database
		 */
		quantityEvents: 0,
		quantityCourses: 0,
		quantityBills: 0
	};

	/**
	 * Method that get all events from de database and set in the quantityEvents
	 */
	getEvents() {
		axios
			.get(baseUrl + 'adminHome/getAllEventForMonth')
			.then((response) => {
				var events = response.data[0];
				this.setState({
					quantityEvents: events.totalEvents
				});
			})
			.catch((err) => console.error('Hola: '));
	}

	/**
	 * Method that get all courses from de database and set in the quantityCourses
	 */
	getCourses() {
		axios
			.get(baseUrl + 'adminHome/getAllQuantityCourses')
			.then((response) => {
				var courses = response.data[0];

				this.setState({
					quantityCourses: courses.totalCourses
				});
			})
			.catch((err) => console.error('Hola: '));
	}

	/**
	 * Method that get all bills from de database and set in the quantityBills
	 */
	getEnrollments() {
		axios
			.get(baseUrl + 'adminHome/getAllEnrollments')
			.then((response) => {
				var enrollments = response.data[0];

				this.setState({
					quantityBills: enrollments.totalEnrollment
				});
			})
			.catch((err) => console.error('Hola: '));
	}

	/**
	 * Method that charge and indicates the session storage and the diferents methods for use in the component
	 */
	componentDidMount() {
		if (sessionStorage.getItem('userType') == 2 || sessionStorage.getItem('userType') == 3) {
			this.getEvents();
			this.getCourses();
			this.getEnrollments();
		} else {
			this.props.history.push('/');
		}
	}

	/**
	 * Method that render the view with the cards and graphics of the Admin Home
	 */
	render() {
		//----------------------ESTO PARA LA TABLA--------------------------------------------------------/
		return (
			<div className="adminContainer">
				<MDBRow>
					<MDBCol className="col-12 col-md-4">
						<MDBAnimation type="bounceInDown">
							<MDBCard className="adminCard">
								<MDBCardImage
									className="blue-gradient d-flex justify-content-center align-items-center flex-column p-4 rounded"
									tag="div"
								>
									<h2 className="white-text">Eventos</h2>
								</MDBCardImage>
								<MDBCardBody className="text-center justify-content-between d-flex flex-column">
									<MDBCardText>
										<p className="adminCardText">
											Existen un total del {this.state.quantityEvents} eventos agendados para este
											mes en el sistema
										</p>
									</MDBCardText>

									<div className="text-center">
										<Link to={'/PrincipalEvent'}>
											<MDBBtn color="primary">Más información</MDBBtn>
										</Link>
									</div>
								</MDBCardBody>
							</MDBCard>
						</MDBAnimation>
					</MDBCol>
					<MDBCol className="col-12 col-md-4">
						<MDBAnimation type="bounceInUp">
							<MDBCard className="adminCard">
								<MDBCardImage
									className="peach-gradient d-flex justify-content-center align-items-center flex-column p-4 rounded"
									tag="div"
								>
									<h2 className="white-text">Cursos</h2>
								</MDBCardImage>
								<MDBCardBody className="text-center justify-content-between d-flex flex-column">
									<MDBCardText>
										<p className="adminCardText">
											Existen un total del {this.state.quantityCourses} cursos registrados,
											disponibles y activos en el sistema
										</p>
									</MDBCardText>

									<div className="text-center">
										<Link to={'/CourseList'}>
											<MDBBtn className="white-text" color="orange" floating>
												Más información
											</MDBBtn>
										</Link>
									</div>
								</MDBCardBody>
							</MDBCard>
						</MDBAnimation>
					</MDBCol>
					<MDBCol className="col-12 col-md-4">
						<MDBAnimation type="bounceInDown">
							<MDBCard className="adminCard">
								<MDBCardImage
									className="purple-gradient d-flex justify-content-center align-items-center flex-column p-4 rounded"
									tag="div"
								>
									<h2 className="white-text">Facturación</h2>
								</MDBCardImage>
								<MDBCardBody className="text-center justify-content-between d-flex flex-column">
									<MDBCardText>
										<p className="adminCardText">
											Existen un total del {this.state.quantityBills} de facturas de usuarios
											registrados en el sistema
										</p>
									</MDBCardText>

									<div className="text-center">
										<Link to={'/EnrollmentReports'}>
											<MDBBtn className="white-text" color="purple" floating>
												Más información
											</MDBBtn>
										</Link>
									</div>
								</MDBCardBody>
							</MDBCard>
						</MDBAnimation>
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol className="col-12 col-md-6">
						<MDBAnimation type="zoomIn">
							<MDBCard className="adminCard">
								<DashboardChart
									title={'Ingresos en colones por mes'}
									apiUrl={baseUrl + 'adminHome/getColonIncome'}
								/>
							</MDBCard>
						</MDBAnimation>
					</MDBCol>
					<MDBCol className="col-12 col-md-6">
						<MDBAnimation type="zoomIn">
							<MDBCard className="adminCard">
								<DashboardChart
									title={'Ingresos en doláres por mes'}
									apiUrl={baseUrl + 'adminHome/getDollarIncome'}
								/>
							</MDBCard>
						</MDBAnimation>
					</MDBCol>
				</MDBRow>
			</div>
		);
	}
}

export default AdminHomeComponent;
