/**
 * @fileoverview baseUrl, this component allow to determine a url of the web site.
 * 
 * @version 1.0
 * 
 * @author Anthony Vargas Méndez <anthony.vargas@ucrso.info>
 * History
 * v1.0 - Initial Release
 * ----
 * The first version of baseUrl was writen by Anthony Vargas Méndez
 */
//export const baseUrl = 'http://localhost:9000/';
export const baseUrl = 'https://www.enconcepto.com/';
//export const baseUrl = `${process.env.REACT_APP_BACKEND_URL}/`;
