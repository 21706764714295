/**
 * @fileoverview AddEmployee, this component allow add a Colaborators and employees in the data base.
 * 
 * @version 1.0
 * 
 * @author Anthony Vargas Méndez <anthony.vargas@ucrso.info>
 * History
 * v1.0 - Initial Release
 * ----
 * The first version of AddEmployee was writen by Anthony Vargas Méndez
 */
import React, { Component, Fragment } from 'react';
import { MDBRow, MDBCol, MDBBtn } from 'mdbreact';
import { MDBCard, MDBContainer, MDBCardTitle, MDBCardBody, MDBCardFooter } from 'mdbreact';
import { FormGroup } from 'reactstrap';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import validations from './Validations';
import md5 from 'md5';

import Select from 'react-select';
import makeAnimated from 'react-select/animated';

import { baseUrl } from './baseUrl';

const animatedComponents = makeAnimated();

class AddEmployee extends Component {
	state = {
		/**
		 * validations:
		 * @type {object}
		 * Property that validate the inputs in this component
		 * 
		 * firstname:
		 * @type {string}
		 * Propety that indicates the name of the colaborator
		 * 
		 * secondName:
		 * @type {string}
		 * Propety that indicates the second name of the colaborator
		 * 
		 * lastname:
		 * @type {string}
		 * Propety that indicates the last name of the colaborator
		 * 
		 * secondLastname:
		 * @type {string}
		 * Propety that indicates the second last name of the colaborator
		 * 
		 * identificationID:
		 * @type {string}
		 * Propety that indicates the identification of the colaborator
		 * 
		 * password:
		 * @type {string}
		 * Propety that indicates the password of the colaborator account
		 * 
		 * birthdate:
		 * @type {string}
		 * Propety that indicates the birth date of the colaborator
		 * 
		 * initDate:
		 * @type {string}
		 * Propety that indicates the date when the colaborator start in the system
		 * 
		 * finishDate:
		 * @type {string}
		 * Propety that indicates the date when the colaborator finish in the system
		 * 
		 * phone:
		 * @type {string}
		 * Propety that indicates the first phone of the colaborator
		 * 
		 * phone2:
		 * @type {string}
		 * Propety that indicates the second phone of the colaborator
		 * 
		 * email:
		 * @type {string}
		 * Propety that indicates the email address of the colaborator
		 * 
		 * userTypeID:
		 * @type {Integer}
		 * Propety that indicates the user type ID
		 * 
		 * countryID:
		 * @type {Integer}
		 * Propety that indicates the country ID
		 * 
		 * userType:
		 * @type {array}
		 * Propety that indicates and contain all user type in the database
		 * 
		 * country:
		 * @type {array}
		 * Propety that indicates and contain all countries in the database
		 */
		validations: new validations(),
		firstname: '',
		secondName: '',
		lastname: '',
		secondLastname: '',
		identificationID: '',
		password: '',
		birthdate: '',
		initDate: '',
		finishDate: '',
		phone: '',
		phone2: '',
		email: '',
		userTypeID: 0,
		countryID: 0,
		userType: [],
		country: [],
		emails: []
	};
	handleInput = this.handleInput.bind(this);
	valueCountries = this.valueCountries.bind(this);
	valueEmployees = this.valueEmployees.bind(this);
	handleSubmit = this.handleSubmit.bind(this);
	validateData = this.validateData.bind(this);
	empty = this.empty.bind(this);
	validateEmail = this.validateEmail.bind(this);

	/**
	 * Method that specify the different alerts in the component
	 */
	notify = (evt, value, msj) => {
		switch (value) {
			case 'SUCCESS':
				toast.success(msj);
				break;
			case 'ERROR':
				toast.error(msj);
				break;
			case 'WARN':
				toast.warn(msj);
				break;
			case 'INFO':
				toast.info(msj);
				break;
			default:
				toast.info(msj);
		}
	};

	/**
	 * Method that allow use the enter button
	 * @param {event} e
	 */
	onKeyEvent = (e) => {
		if (e.key === 'Enter') {
			this.validateData(e);
		}
	};

	/**
	 * Method that validate the value to the variables in the inpust of the colaborators
	 * @param {event} event
	 */
	validateData(event) {
		if (this.empty(event)) {
			if (!this.state.validations.validateTextField(this.state.firstname)) {
				this.notify(
					event,
					'ERROR',
					'El nombre del colaborador o empleado no debe contener caracteres especiales ni números'
				);
			} else if (
				!this.state.validations.validateTextField(this.state.secondName) &&
				this.state.secondName !== ''
			) {
				this.notify(
					event,
					'ERROR',
					'El segundo nombre del colaborador o empleado no debe contener caracteres especiales ni números'
				);
			} else if (!this.state.validations.validateTextField(this.state.lastname)) {
				this.notify(
					event,
					'ERROR',
					'El apellido del colaborador o empleado no debe contener caracteres especiales ni números'
				);
			} else if (!this.state.validations.validateTextField(this.state.secondLastname)) {
				this.notify(
					event,
					'ERROR',
					'El segundo apellido del colaborador o empleado no debe contener caracteres especiales ni números'
				);
			} else if (!this.state.validations.validateNumericField(this.state.identificationID)) {
				this.notify(event, 'ERROR', 'La identificaión solo debe contener números');
			} else if (this.validateEmail(this.state.email) === true) {
				this.notify(event, 'ERROR', 'El correo ingresado ya existe en el sistema');
			} else if (!this.state.validations.validateEmailField(this.state.email)) {
				this.notify(event, 'ERROR', 'El correo ingresado posee un formato incorrecto');
			} else if (!this.state.validations.validateNumericField(this.state.phone)) {
				this.notify(event, 'ERROR', 'El teléfono solo debe contener números');
			} else if (!this.state.validations.validateNumericField(this.state.phone2)) {
				this.notify(event, 'ERROR', 'El segundo teléfono solo debe contener números');
			} else if (!this.state.validations.validateDateField(this.state.finishDate)) {
				this.notify(event, 'ERROR', 'Formato de fecha final incorrecto');
			} else if (!this.state.validations.validateDateField(this.state.initDate)) {
				this.notify(event, 'ERROR', 'Formato de fecha inicial incorrecto');
			} else if (!this.state.validations.validateDateField(this.state.birthdate)) {
				this.notify(event, 'ERROR', 'Formato de fecha de nacimiento incorrecto');
			} else {
				this.handleSubmit(event);
			}
		}
	}

	/**
	 * Method that determine if the value to the variables are empty
	 * @param {event} event
	 */
	empty(event) {
		if (this.state.firstname.length === 0) {
			this.notify(event, 'ERROR', 'El nombre del empleado o colaborador no debe estar vacío');
			return false;
		} else if (this.state.lastname.length === 0) {
			this.notify(event, 'ERROR', 'El apellido no debe estar vacío');
			return false;
		} else if (this.state.identificationID.length === 0) {
			this.notify(event, 'ERROR', 'La identificación no debe estar vacía');
			return false;
		} else if (this.state.birthdate.length === 0) {
			this.notify(event, 'ERROR', 'Debe seleccionar una fecha de nacimiento');
			return false;
		} else if (this.state.phone.length === 0) {
			this.notify(event, 'ERROR', 'El teléfono no debe estar vacío');
			return false;
		} else if (this.state.email.length === 0) {
			this.notify(event, 'ERROR', 'Debe ingresar un correo electrónico');
			return false;
		} else if (this.state.countryID === 0) {
			this.notify(event, 'ERROR', 'Debe seleccionar un país');
			return false;
		} else if (this.state.userTypeID === 0) {
			this.notify(event, 'ERROR', 'Debe seleccionar un tipo de usuario');
			return false;
		} else {
			return true;
		}
	}

	/**
	 * Method that validate if email exist in a database or not
	 * @param {string} auxEmail
	 */
	validateEmail(auxEmail) {
		var validateE = false;
		const emailsList = this.state.emails;
		console.log('Correo aux: ' + auxEmail);
		emailsList.map(function(email) {
			// console.log('Correos: ' + email.value);
			if (email.value === auxEmail) {
				validateE = true;
			}
		});

		if (validateE === true) {
			return true;
		} else {
			return false;
		}
	}

	/**
	 * Method that get all emails from de database and set in the emails
	 */
	getEmails() {
		axios
			.get(baseUrl + 'perfilConfiguration/getEmails')
			.then((response) => {
				var emails = response.data;
				this.setState({
					emails
				});
			})
			.catch((err) => console.error(err));
	}

	/**
	 * Method that get all user type from de database and set in the userType list
	 */
	getUserType() {
		axios
			.get(baseUrl + 'employee/getAllUserType')
			.then((response) => {
				var userType = response.data;

				this.setState({
					userType
				});
			})
			.catch((err) => console.error(err));
	}

	/**
	 * Method that get all countries type from de database and set in the country list
	 */
	getCountry() {
		axios
			.get(baseUrl + 'employee/getAllCountries')
			.then((response) => {
				var country = response.data;

				this.setState({
					country
				});
			})
			.catch((err) => console.error(err));
	}

	/**
	 * Method that charge and indicates the session storage
	 */
	componentDidMount() {
		if (sessionStorage.getItem('userType') == 2) {
			this.getUserType();
			this.getCountry();
			this.getEmails();
		} else if (sessionStorage.getItem('userType') == 3) {
			this.props.history.push('/admin');
		} else {
			this.props.history.push('/');
		}
	}

	/**
	 * Method that assigns the value to the variables of the colaborator
	 * @param {event} e
	 */
	handleInput(e) {
		const { value, name } = e.target;
		this.setState({
			[name]: value
		});
	}

	/**
	 * Method that set a user type ID in the userTypeID
	 * @param {event} event
	 * @param {Integer} userTypeID
	 */
	valueEmployees(event) {
		this.setState({
			userTypeID: event.value
		});
	}

	/**
	 * Method that set a country ID in the countryID
	 * @param {event} event
	 * @param {Integer} countryID
	 */
	valueCountries(event) {
		this.setState({
			countryID: event.value
		});
	}

	/**
	 * Method that charge all values of the colaboratior and call the api to add de the colaborator
	 * @param {event} event
	 */
	handleSubmit(event) {
		event.preventDefault();
		fetch(`${baseUrl}employee/addEmployee`, {
			method: 'post',
			body: JSON.stringify({
				firstname: this.state.firstname,
				secondName: this.state.secondName,
				lastname: this.state.lastname,
				secondLastname: this.state.secondLastname,
				identificationID: this.state.identificationID,
				password: md5(this.state.identificationID),
				birthdate: this.state.birthdate,
				initDate: this.state.initDate,
				finishDate: this.state.finishDate,
				userTypeID: this.state.userTypeID,
				countryID: this.state.countryID,
				phone: this.state.phone,
				phone2: this.state.phone2,
				email: this.state.email
			}),
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			}
		})
			.then(() => {
				fetch(baseUrl + 'employee/employeeConfirmation', {
					method: 'post',
					body: JSON.stringify({
						email: this.state.email,
						identification: this.state.identificationID
					}),
					headers: {
						Accept: 'application/json',
						'Content-Type': 'application/json'
					}
				}).then((response) => {
					this.props.history.push({
						pathname: '/Employees'
					});
				});
			})
			.catch((err) => console.log(err));
	}

	/**
	 * Method that render the view with the all inputs and selects to add the new colaborator
	 * @param {array} auxEmployees
	 * @param {arrar} auxCountries
	 */
	render() {
		const auxEmployees = this.state.userType.map(function(user) {
			return {
				label: user.description,
				value: user.userTypeID
			};
		});

		const auxCountries = this.state.country.map(function(auxCountry) {
			return {
				label: auxCountry.name,
				value: auxCountry.countryID
			};
		});

		return (
			<Fragment>
				<MDBContainer>
					<ToastContainer
						position="top-right"
						autoClose={5000}
						hideProgressBar
						newestOnTop
						closeOnClick
						rtl={false}
						pauseOnVisibilityChange
						draggable
						pauseOnHover
					/>
					<MDBRow>
						<MDBCol className="col-12 col-lg-9 offset-lg-3 mt-3">
							<h3 className="adminTitlesFont">Agregar de Colaborador</h3>
						</MDBCol>
						<MDBCol className="col-12 col-lg-9 offset-lg-3">
							<MDBCard style={{ padding: '20px 20px 20px 20px' }}>
								<MDBCardTitle className="cardHeader">Colaborador</MDBCardTitle>

								<MDBCardBody className="adminFont">
									<MDBRow>
										<MDBCol className="col-12 col-md-4">
											<FormGroup>
												<label>
													Nombre <font color="red">*</font>
												</label>
												<input
													className="form-control "
													value={this.state.firstname}
													type="text"
													name="firstname"
													onChange={this.handleInput}
													onKeyPress={this.onKeyEvent}
												/>
											</FormGroup>
										</MDBCol>
										<MDBCol className="col-12 col-md-4">
											<FormGroup>
												<label>Segundo Nombre</label>
												<input
													className="form-control "
													value={this.state.secondName}
													type="text"
													name="secondName"
													onChange={this.handleInput}
													onKeyPress={this.onKeyEvent}
												/>
											</FormGroup>
										</MDBCol>
										<MDBCol className="col-12 col-md-4">
											<FormGroup>
												<label>
													Identificación <font color="red">*</font>
												</label>
												<input
													className="form-control "
													value={this.state.identificationID}
													type="text"
													name="identificationID"
													onChange={this.handleInput}
													onKeyPress={this.onKeyEvent}
												/>
											</FormGroup>
										</MDBCol>
									</MDBRow>
									<MDBRow>
										<MDBCol className="col-12 col-md-4">
											<FormGroup>
												<label>
													Apellido <font color="red">*</font>
												</label>
												<input
													className="form-control "
													value={this.state.lastname}
													type="text"
													name="lastname"
													onChange={this.handleInput}
													onKeyPress={this.onKeyEvent}
												/>
											</FormGroup>
										</MDBCol>
										<MDBCol className="col-12 col-md-4">
											<FormGroup>
												<label>
													Segundo Apellido <font color="red">*</font>
												</label>
												<input
													className="form-control "
													value={this.state.secondLastname}
													type="text"
													name="secondLastname"
													onChange={this.handleInput}
													onKeyPress={this.onKeyEvent}
												/>
											</FormGroup>
										</MDBCol>
										<MDBCol className="col-12 col-md-4">
											<FormGroup>
												<label>
													Fecha de nacimiento <font color="red">*</font>
												</label>
												<input
													className="form-control "
													value={this.state.birthdate}
													type="date"
													name="birthdate"
													onChange={this.handleInput}
													onKeyPress={this.onKeyEvent}
												/>
											</FormGroup>
										</MDBCol>
									</MDBRow>
									<MDBRow>
										<MDBCol className="col-12 col-md-4">
											<FormGroup>
												<label>
													Telefono #1 <font color="red">*</font>
												</label>
												<input
													className="form-control "
													value={this.state.phone}
													type="text"
													name="phone"
													onChange={this.handleInput}
													onKeyPress={this.onKeyEvent}
												/>
											</FormGroup>
										</MDBCol>
										<MDBCol className="col-12 col-md-4">
											<FormGroup>
												<label>Telefono #2</label>
												<input
													className="form-control "
													value={this.state.phone2}
													type="text"
													name="phone2"
													onChange={this.handleInput}
													onKeyPress={this.onKeyEvent}
												/>
											</FormGroup>
										</MDBCol>
										<MDBCol className="col-12 col-md-4">
											<FormGroup>
												<label>
													Correo Electrónico <font color="red">*</font>
												</label>
												<input
													className="form-control "
													value={this.state.email}
													type="text"
													name="email"
													onChange={this.handleInput}
													onKeyPress={this.onKeyEvent}
												/>
											</FormGroup>
										</MDBCol>
									</MDBRow>
									<MDBRow>
										<MDBCol className="col-12 col-md-6">
											<FormGroup>
												<label>
													País <font color="red">*</font>
												</label>
												<Select
													className="selectLetter"
													name="country"
													components={animatedComponents}
													options={auxCountries}
													classNamePrefix="select"
													placeholder="Seleccione un país"
													onChange={this.valueCountries}
													theme={(theme) => ({
														...theme,
														borderRadius: 4,
														font: 'Open Sans',
														height: 9,

														colors: {
															...theme.colors,
															primary25: '#FFA93F',
															primary: '#808080'
														}
													})}
												/>
											</FormGroup>
										</MDBCol>
										<MDBCol className="col-12 col-md-6">
											<FormGroup>
												<label>
													Tipo <font color="red">*</font>
												</label>
												<Select
													className="selectLetter"
													name="user"
													components={animatedComponents}
													options={auxEmployees}
													classNamePrefix="select"
													placeholder="Seleccione el tipo"
													onChange={this.valueEmployees}
													theme={(theme) => ({
														...theme,
														borderRadius: 4,
														font: 'Open Sans',
														height: 9,

														colors: {
															...theme.colors,
															primary25: '#FFA93F',
															primary: '#808080'
														}
													})}
												/>
											</FormGroup>
										</MDBCol>
									</MDBRow>
									<MDBRow>
										<MDBCol className="col-12 col-md-6">
											<FormGroup>
												<label>
													Fecha de inicio <font color="red">*</font>
												</label>
												<input
													className="form-control "
													value={this.state.initDate}
													type="date"
													name="initDate"
													onChange={this.handleInput}
													onKeyPress={this.onKeyEvent}
												/>
											</FormGroup>
										</MDBCol>
										<MDBCol className="col-12 col-md-6">
											<FormGroup>
												<label>
													Fecha final <font color="red">*</font>
												</label>
												<input
													className="form-control "
													value={this.state.finishDate}
													type="date"
													name="finishDate"
													onChange={this.handleInput}
													onKeyPress={this.onKeyEvent}
												/>
											</FormGroup>
										</MDBCol>
									</MDBRow>
								</MDBCardBody>
								<MDBCardFooter color="white">
									<Link to={'/Employees'}>
										<MDBBtn color="purpleButton" className="modalButton purpleButton">
											<span className="modalButtonText">Cancelar</span>
										</MDBBtn>
									</Link>
									<MDBBtn
										color="greenButton"
										className="modalButton greenButton"
										type="submit"
										onClick={this.validateData}
									>
										<span className="modalButtonText">Guardar</span>
									</MDBBtn>
								</MDBCardFooter>
							</MDBCard>
						</MDBCol>
					</MDBRow>

					{/*-------------------------AQUI TERMINA LO DE LA TABLA----------------------------------------  */}
				</MDBContainer>
			</Fragment>
		);
	}
}

export default AddEmployee;
