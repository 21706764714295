/**
 * @fileoverview AboutUsConfiguration, this component is for configure the data of the about us user view
 *
 * @version 1.0
 *
 * @author Ester Molina R <maria.molina@ucrso.info>
 * History
 * v1.0 - Initial Realease
 * ----
 * The first version of AboutUsConfiguration was written by Ester Molina R.
 */

import React, { Component } from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardTitle,
  MDBBtn,
  MDBCardFooter,
} from "mdbreact";
import { FormGroup } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import imagen from "../images/imagen.jpeg";
import axios from "axios";
import { baseUrl } from "./baseUrl";
import validations from "./Validations";
import { Link } from "react-router-dom";

class AboutUsConfiguration extends Component {
  constructor(props) {
    super(props);
    /**
     * validations:
     * @type {Object}
     * Property that provide methods to validate with regular expresions
     *
     * valueID:
     * @type {integer}
     * Property that has the identification of values' register
     *
     * values:
     * @type {String}
     * Property that has the text about the values
     *
     * multimediaValuesID:
     * @type {integer}
     * Property that has the identification of values' image
     *
     * valuesImageLink:
     * @type {String}
     * Property that has the values' image link
     *
     * valuesImage:
     * @type {Object}
     * Property that has the image file
     *
     * purposeID:
     * @type {integer}
     * Property that has the identification of purpose' register
     *
     * purpose:
     * @type {String}
     * Property that has the text about the purpose
     *
     * multimediaPurposeID:
     * @type {integer}
     * Property that has the identification of purpose' image
     *
     * purposeImageLink:
     * @type {String}
     * Property that has the purpose' image link
     *
     * purposeImage:
     * @type {Object}
     * Property that has the image file
     *
     * biographyID:
     * @type {integer}
     * Property that has the identification of biography' register
     *
     * biography:
     * @type {String}
     * Property that has the text about the biography
     *
     * multimediaBiographyID:
     * @type {integer}
     * Property that has the identification of biography' image
     *
     * biographyImagesLink:
     * @type {String}
     * Property that has the biography' image link
     *
     * biographyImage:
     * @type {Object}
     * Property that has the image file
     *
     * multimediaVideoID:
     * @type {integer}
     * Property that has the identification of frontpage' image
     *
     * videoImagesLink:
     * @type {String}
     * Property that has the frontpage' image link
     *
     * videoImage:
     * @type {Object}
     * Property that has the image file
     */
    this.state = {
      validations: new validations(),
      valueID: 0,
      values: "",
      multimediaValuesID: 0,
      valuesImageLink: "",
      valuesURL: "",
      valuesImage: null,
      purposeID: 0,
      purpose: "",
      multimediaPurposeID: 0,
      purposeImageLink: "",
      purposeURL: "",
      purposeImage: null,
      biographyID: 0,
      biography: "",
      multimediaBiographyID: 0,
      biographyImageLink: "",
      biographyURL: "",
      biographyImage: null,
      // biography2: "",
      // biographyID2: 0,
      // multimediaBiographyID2: 0,
      // biographyImageLink2: "",
      // biographyURL2: "",
      // biographyImage2: null,
      multimediaVideoID: 0,
      videoImageLink: "",
      videoURL: "",
      videoImage: null,
    };

    this.getData = this.getData.bind(this);
    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.validateData = this.validateData.bind(this);
    this.handleMultimedia = this.handleMultimedia.bind(this);
    this.handleText = this.handleText.bind(this);
    this.empty = this.empty.bind(this);
  }

  componentDidMount() {
    if (sessionStorage.getItem("userType") == 2) {
      this.getData();
      document.getElementById("divVideo").style.display = "none";
    } else if (sessionStorage.getItem("userType") == 3) {
      this.props.history.push("/admin");
    } else {
      this.props.history.push("/");
    }
  }

  /**
   * This method charge the data existent in the database
   */
  getData() {
    axios.get(baseUrl + "aboutus/getAboutUsText").then((response) => {
      var textArray = response.data;
      textArray.map((text) => {
        if (text.description === "Valores") {
          this.setState({
            values: text.textValue,
            valueID: text.textID,
          });
        } else if (text.description === "Propósito") {
          this.setState({
            purpose: text.textValue,
            purposeID: text.textID,
          });
        } else if (text.description === "Biografía") {
          this.setState({
            biography: text.textValue,
            biographyID: text.textID,
          });
        // } else if (text.description === "Biografía2") {
        //   this.setState({
        //     biography2: text.textValue,
        //     biographyID2: text.textID,
        //   });
        }
      });
    });

    axios.get(baseUrl + "aboutus/getAboutUsMultimedia").then((response) => {
      var multimediaArray = response.data;
      multimediaArray.map((multimedia) => {
        if (multimedia.description === "Valores") {
          this.setState({
            valuesImageLink: multimedia.link,
            multimediaValuesID: multimedia.multimediaID,
            valuesURL: "/root/servidor/public/" + multimedia.multimediaID,
          });
          if (window.innerWidth < 800) {
            document.getElementById("valuesImageLabel").textContent =
              multimedia.link.substring(20, 22) +
              multimedia.link.substring(
                multimedia.link.length - 4,
                multimedia.link.length
              );
            document.getElementById("valuesImg").src =
              baseUrl + multimedia.link;
          } else {
            document.getElementById("valuesImageLabel").textContent =
              multimedia.link.substring(20, 30) +
              multimedia.link.substring(
                multimedia.link.length - 4,
                multimedia.link.length
              );
            document.getElementById("valuesImg").src =
              baseUrl + multimedia.link;
          }
        } else if (multimedia.description === "Propósito") {
          this.setState({
            purposeImageLink: multimedia.link,
            multimediaPurposeID: multimedia.multimediaID,
            purposeURL: "/root/servidor/public/" + multimedia.multimediaID,
          });
          if (window.innerWidth < 800) {
            document.getElementById("purposeImageLabel").textContent =
              multimedia.link.substring(20, 22) +
              multimedia.link.substring(
                multimedia.link.length - 4,
                multimedia.link.length
              );
            document.getElementById("purposeImg").src =
              baseUrl + multimedia.link;
          } else {
            document.getElementById("purposeImageLabel").textContent =
              multimedia.link.substring(20, 30) +
              multimedia.link.substring(
                multimedia.link.length - 4,
                multimedia.link.length
              );
            document.getElementById("purposeImg").src =
              baseUrl + multimedia.link;
          }
        } else if (multimedia.description === "Biografía") {
          this.setState({
            biographyImageLink: multimedia.link,
            multimediaBiographyID: multimedia.multimediaID,
            biographyURL: "/root/servidor/public/" + multimedia.multimediaID,
          });
          if (window.innerWidth < 800) {
            document.getElementById("biographyImageLabel").textContent =
              multimedia.link.substring(20, 22) +
              multimedia.link.substring(
                multimedia.link.length - 4,
                multimedia.link.length
              );
            document.getElementById("biographyImg").src =
              baseUrl + multimedia.link;
          } else {
            document.getElementById("biographyImageLabel").textContent =
              multimedia.link.substring(20, 30) +
              multimedia.link.substring(
                multimedia.link.length - 4,
                multimedia.link.length
              );
            document.getElementById("biographyImg").src =
              baseUrl + multimedia.link;
          }
        // } else if (multimedia.description === "Biografía2") {
        //   /*Biografía 2*/
        //   this.setState({
        //     biographyImageLink2: multimedia.link,
        //     multimediaBiographyID2: multimedia.multimediaID,
        //     biographyURL2: "/root/servidor/public/" + multimedia.multimediaID,
        //   });
        //   if (window.innerWidth < 800) {
        //     document.getElementById("biographyImage2Label").textContent =
        //       multimedia.link.substring(20, 22) +
        //       multimedia.link.substring(
        //         multimedia.link.length - 4,
        //         multimedia.link.length
        //       );
        //     document.getElementById("biographyImg2").src =
        //       baseUrl + multimedia.link;
        //   } else {
        //     document.getElementById("biographyImage2Label").textContent =
        //       multimedia.link.substring(20, 30) +
        //       multimedia.link.substring(
        //         multimedia.link.length - 4,
        //         multimedia.link.length
        //       );
        //     document.getElementById("biographyImg2").src =
        //       baseUrl + multimedia.link;
        //   }
        } else if (multimedia.description === "Portada Vi") {
          this.setState({
            videoImageLink: multimedia.link,
            multimediaVideoID: multimedia.multimediaID,
            videoURL: "/root/servidor/public/" + multimedia.multimediaID,
          });
          if (multimedia.multimediaID.length !== 0) {
            document.getElementById("divVideo").style.display = "block";
            document.getElementById("videoImageLabel").textContent =
              multimedia.link.substring(20, 30) +
              multimedia.link.substring(
                multimedia.link.length - 4,
                multimedia.link.length
              );
            document.getElementById("videoImg").src = baseUrl + multimedia.link;
          }
        }
      });
    });
  }

  /**
   * This method is in charge to read the file that are upload and put the src in the label img HTML
   * @param {Object} input
   */
  readFile = (input) => {
    if (input.files && input.files[0]) {
      var reader = new FileReader();
      reader.onload = function (e) {
        var filePreview = document.getElementById(input.name);
        filePreview.src = e.target.result;
      };
      reader.readAsDataURL(input.files[0]);
    }
  };

  /**
   * This method change the state if something change in the inputs
   * @param {event} e
   */
  handleInput(e) {
    const { value, name } = e.target;
    this.setState({
      [name]: value,
    });
  }

  /**
   * This method change the images state if something change in the inputs
   * @param {event} event
   */
  handleImageChange(event) {
    if (event.target.files && event.target.files.length === 1) {
      const file = event.target.files[0];
      if (file.size < 20000000) {
        var link = event.target.id + "Link"; 
        var label = event.target.id + "Label"; 
        var name = file.name; 
        if (name.length >= 30) {
          name =
            name.substring(0, 20) +
            name.substring(name.length - 4, name.length);
        }
        document.getElementById(label).textContent = name;
        this.setState({
          [event.target.id]: file,
          [link]: name,
        });
        if ((event.target.id = "videoImage")) {
          document.getElementById("divVideo").style.display = "block";
        }
      } else {
        this.notify(event, "ERROR", "El archivo no debe exceder los 20MB");
      }
      this.readFile(event.target);
    }
  }

  /**
   * This method is for give notifications to the user
   * @param {event} evt
   * @param {String} value
   * @param {String} msj
   */
  notify = (evt, value, msj) => {
    switch (value) {
      case "SUCCESS":
        toast.success(msj);
        break;
      case "ERROR":
        toast.error(msj);
        break;
      case "WARN":
        toast.warn(msj);
        break;
      case "INFO":
        toast.info(msj);
        break;
      default:
        toast.info(msj);
    }
  };

  /**
   * This method perform the validate action when an ENTER is clicked
   * @param {event} e
   */
  onKeyEvent = (e) => {
    if (e.key == "Enter") {
      this.validateData(e);
    }
  };

  /**
   * This method is for validate the insert data is with correct format
   * @param {event} event
   */
  validateData(event) {
    if (this.empty(event)) {
      if (
        !this.state.validations.validateTextWithNumberField(this.state.values)
      ) {
        this.notify(
          event,
          "ERROR",
          "Los valores de la empresa no deben contener caracteres especiales"
        );
      } else if (
        !this.state.validations.validateTextWithNumberField(this.state.purpose)
      ) {
        this.notify(
          event,
          "ERROR",
          "El propósito de la empresa no debe contener caracteres especiales"
        );
      } else if (
        !this.state.validations.validateTextWithNumberField(
          this.state.biography
        )
      ) {
        this.notify(
          event,
          "ERROR",
          "La biografía no debe contener caracteres especiales"
        );
      // } else if (
      //   !this.state.validations.validateTextWithNumberField(
      //     this.state.biography2
      //   )
      // ) {
      //   this.notify(
      //     event,
      //     "ERROR",
      //     "La biografía no debe contener caracteres especiales"
      //   );
      } else {
        try {
          this.handleText(event);
          this.handleMultimedia(event);
          this.setState({
            valuesImage: null,
            purposeImage: null,
            biographyImage: null,
            videoImage: null,
          });
          this.props.history.push("/admin");
        } catch (err) {
          this.notify(event, "WARN", "Vuelva a intentarlo");
        }
      }
    }
  }

  /**
   * This method is for validate that the obligatory fields are not empty
   * @param {event} event
   */
  empty(event) {
    if (this.state.values.length === 0) {
      this.notify(
        event,
        "ERROR",
        "Los valores de la empresa no deben estar vacíos"
      );
    } else if (this.state.purpose.length === 0) {
      this.notify(
        event,
        "ERROR",
        "El propósito de la empresa no debe estar vacío"
      );
    } else if (
      this.state.biography.length === 0 /* ||
      this.state.biography2.length === 0 */
    ) {
      this.notify(event, "ERROR", "La biografía no debe estar vacía");
    } else if (this.state.videoImageLink.length === 0) {
      this.notify(event, "ERROR", "Debe agregar la imagen de portada");
    } else {
      return true;
    }
  }

  /**
   * This method is for add or edit the text fields
   * @param {event} event
   */
  handleText(event) {
    var success = true;
    if (this.state.values.length !== 0) {
      fetch(baseUrl + "aboutus/editText", {
        method: "post",
        body: JSON.stringify({
          textID: this.state.valueID,
          description: this.state.values,
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          success = true;
        })
        .catch((err) => {
          success = false;
        });
    }
    if (this.state.purpose.length !== 0) {
      fetch(baseUrl + "aboutus/editText", {
        method: "post",
        body: JSON.stringify({
          textID: this.state.purposeID,
          description: this.state.purpose,
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          success = true;
        })
        .catch((err) => {
          success = false;
        });
    }
    if (this.state.biography.length !== 0) {
      fetch(baseUrl + "aboutus/editText", {
        method: "post",
        body: JSON.stringify({
          textID: this.state.biographyID,
          description: this.state.biography,
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          success = true;
        })
        .catch((err) => {
          success = false;
        });
    }
    /*Biografía 2*/
    // if (this.state.biography2.length !== 0) {
    //   fetch(baseUrl + "aboutus/editText", {
    //     method: "post",
    //     body: JSON.stringify({
    //       textID: this.state.biographyID2,
    //       description: this.state.biography2,
    //     }),
    //     headers: {
    //       Accept: "application/json",
    //       "Content-Type": "application/json",
    //     },
    //   })
    //     .then((response) => {
    //       success = true;
    //     })
    //     .catch((err) => {
    //       success = false;
    //     });
    // }
    event.preventDefault();
    return success;
  }

  /**
   * This methos is for add or edit the image fields
   * @param {event} event
   */
  handleMultimedia(event) {
    var success = true;
    var formData = "";
    if (
      this.state.multimediaValuesID === 0 &&
      this.state.valuesImage !== null
    ) {
      formData = new FormData();
      formData.append("image", this.state.valuesImage);
      formData.append("description", "Valores");
      formData.append("typeID", 2);
      axios
        .post(`${baseUrl}aboutus/addImage`, formData)
        .then((response) => {
          success = true;
        })
        .catch((err) => {
          success = false;
        });
    } else if (
      this.state.multimediaValuesID !== 0 &&
      this.state.valuesImage !== null
    ) {
      formData = new FormData();
      formData.append("image", this.state.valuesImage);
      formData.append("multimediaImageID", this.state.multimediaValuesID);
      formData.append("url", this.state.valuesURL);
      axios
        .post(baseUrl + "aboutus/editImage", formData)
        .then((response) => {
          success = true;
        })
        .catch((err) => {
          success = false;
        });
    }
    if (
      this.state.multimediaPurposeID === 0 &&
      this.state.purposeImage !== null
    ) {
      formData = new FormData();
      formData.append("image", this.state.purposeImage);
      formData.append("description", "Propósito");
      formData.append("typeID", 2);
      axios
        .post(`${baseUrl}aboutus/addImage`, formData)
        .then((response) => {
          success = true;
        })
        .catch((err) => {
          success = false;
        });
    } else if (
      this.state.multimediaPurposeID !== 0 &&
      this.state.purposeImage !== null
    ) {
      formData = new FormData();
      formData.append("image", this.state.purposeImage);
      formData.append("multimediaImageID", this.state.multimediaPurposeID);
      formData.append("url", this.state.purposeURL);
      axios
        .post(`${baseUrl}aboutus/editImage`, formData)
        .then((response) => {
          success = true;
        })
        .catch((err) => {
          success = false;
        });
    }
    if (
      this.state.multimediaBiographyID === 0 &&
      this.state.biographyImage !== null
    ) {
      formData = new FormData();
      formData.append("image", this.state.biographyImage);
      formData.append("description", "Biografía");
      formData.append("typeID", 2);
      axios
        .post(`${baseUrl}aboutus/addImage`, formData)
        .then((response) => {
          success = true;
        })
        .catch((err) => {
          success = false;
        });
    } else if (
      this.state.multimediaBiographyID !== 0 &&
      this.state.biographyImage !== null
    ) {
      formData = new FormData();
      formData.append("image", this.state.biographyImage);
      formData.append("multimediaImageID", this.state.multimediaBiographyID);
      formData.append("url", this.state.biographyURL);
      axios
        .post(`${baseUrl}aboutus/editImage`, formData)
        .then((response) => {
          success = true;
        })
        .catch((err) => {
          success = false;
        });
    }
    // if (
    //   this.state.multimediaBiographyID2 === 0 &&
    //   this.state.biographyImage2 !== null
    // ) {
    //   formData = new FormData();
    //   formData.append("image", this.state.biographyImage2);
    //   formData.append("description", "Biografía2");
    //   formData.append("typeID", 2);
    //   axios
    //     .post(`${baseUrl}aboutus/addImage`, formData)
    //     .then((response) => {
    //       success = true;
    //     })
    //     .catch((err) => {
    //       success = false;
    //     });
    // } else if (
    //   this.state.multimediaBiographyID2 !== 0 &&
    //   this.state.biographyImage2 !== null
    // ) {
    //   formData = new FormData();
    //   formData.append("image", this.state.biographyImage2);
    //   formData.append("multimediaImageID", this.state.multimediaBiographyID2);
    //   formData.append("url", this.state.biographyURL2);
    //   axios
    //     .post(`${baseUrl}aboutus/editImage`, formData)
    //     .then((response) => {
    //       success = true;
    //     })
    //     .catch((err) => {
    //       success = false;
    //     });
    // }
    if (
      this.state.multimediaVideoID === 0 &&
      this.state.videoImageLink !== null
    ) {
      formData = new FormData();
      formData.append("image", this.state.videoImage);
      formData.append("description", "Portada Video");
      formData.append("typeID", 2);
      axios
        .post(`${baseUrl}aboutus/addImage`, formData)
        .then((response) => {
          success = true;
        })
        .catch((err) => {
          success = false;
        });
    } else if (
      this.state.multimediaVideoID !== 0 &&
      this.state.videoImageLink !== null
    ) {
      formData = new FormData();
      formData.append("image", this.state.videoImage);
      formData.append("multimediaImageID", this.state.multimediaVideoID);
      formData.append("url", this.state.videoURL);
      axios
        .post(`${baseUrl}aboutus/editImage`, formData)
        .then((response) => {
          success = true;
        })
        .catch((err) => {
          success = false;
        });
    }
    event.preventDefault();
    return success;
  }

  /**
   * This method is for render the HTML
   */
  render() {
    return (
      <MDBContainer>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
        <MDBRow>
          <MDBCol className="col-12 col-lg-9 offset-lg-3 mt-3">
            <h3 className="adminTitlesFont">
              Configuración acerca de nosotros
            </h3>
          </MDBCol>
          <MDBCol className="col-12 col-lg-9 offset-lg-3 mb-3">
            <MDBCard style={{ padding: "20px 20px 20px 20px" }}>
              <MDBCardTitle className="cardHeader">
                Información empresarial{" "}
              </MDBCardTitle>
              <MDBRow>
                <MDBCol className="col-12 order-2 order-sm-1 col-sm-8">
                  <FormGroup>
                    <label htmlFor="values">
                      Valores <font color="red">*</font>
                    </label>
                    <textarea
                      rows="5"
                      placeholder="Valores de la empresa"
                      id="values"
                      name="values"
                      value={this.state.values}
                      onChange={this.handleInput}
                    />
                  </FormGroup>
                </MDBCol>
                <MDBCol className="col-12 order-1 order-sm-2 col-sm-4">
                  <FormGroup>
                    <div className="mb-2" style={{ textAlign: "center" }}>
                      <img
                        src={imagen}
                        id="valuesImg"
                        alt="logo"
                        width="75%"
                        height="75%"
                      />
                    </div>
                    <div className="custom-file">
                      <input
                        type="file"
                        className="custom-file-input"
                        accept=".jpg,.svg,.jpeg,.png"
                        lang="es"
                        id="valuesImage"
                        name="valuesImg"
                        onChange={this.handleImageChange}
                      />
                      <label
                        className="custom-file-label"
                        id="valuesImageLabel"
                        for="valuesImage"
                      >
                        Alto máx. 300px
                      </label>
                    </div>
                  </FormGroup>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol className="col-12 order-2 order-sm-1 col-sm-8">
                  <FormGroup>
                    <label htmlFor="values">
                      Propósito <font color="red">*</font>
                    </label>
                    <textarea
                      rows="5"
                      placeholder="Propósito de la empresa"
                      id="purpose"
                      name="purpose"
                      value={this.state.purpose}
                      onChange={this.handleInput}
                    />
                  </FormGroup>
                </MDBCol>
                <MDBCol className="col-12 order-1 order-sm-2 col-sm-4">
                  <FormGroup>
                    <div
                      className="mb-2"
                      id="previewPurposeImage"
                      style={{ textAlign: "center" }}
                    >
                      <img
                        src={imagen}
                        id="purposeImg"
                        alt="logo"
                        width="75%"
                        height="75%"
                      />
                    </div>
                    <div className="custom-file">
                      <input
                        type="file"
                        className="custom-file-input"
                        lang="es"
                        accept=".jpg,.svg,.jpeg,.png"
                        id="purposeImage"
                        name="purposeImg"
                        onChange={this.handleImageChange}
                      />
                      <label
                        className="custom-file-label"
                        id="purposeImageLabel"
                        for="purposeImage"
                      >
                        Alto máx. 300px
                      </label>
                    </div>
                  </FormGroup>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol className="col-12 order-2 order-sm-1 col-sm-8">
                  <FormGroup>
                    <label htmlFor="biography">
                      Biografía <font color="red">*</font>
                    </label>
                    <textarea
                      rows="5"
                      placeholder="Biografía"
                      id="biography"
                      name="biography"
                      value={this.state.biography}
                      onChange={this.handleInput}
                    />
                  </FormGroup>
                </MDBCol>

                <MDBCol className="col-12 order-1 order-sm-2 col-sm-4">
                  <FormGroup>
                    <div className="mb-2" style={{ textAlign: "center" }}>
                      <img
                        src={imagen}
                        id="biographyImg"
                        alt="logo"
                        width="75%"
                        height="75%"
                      />
                    </div>
                    <div className="custom-file">
                      <input
                        type="file"
                        className="custom-file-input"
                        lang="es"
                        accept=".jpg,.svg,.jpeg,.png"
                        id="biographyImage"
                        name="biographyImg"
                        onChange={this.handleImageChange}
                      />
                      <label
                        className="custom-file-label"
                        id="biographyImageLabel"
                        for="biographyImage"
                      >
                        Alto máx. 300px
                      </label>
                    </div>
                  </FormGroup>
                </MDBCol>
              </MDBRow>

              {/* <MDBRow>
                <MDBCol className="col-12 order-2 order-sm-1 col-sm-8">
                  <FormGroup>
                    <label htmlFor="biography2">
                      Biografía 2<font color="red">*</font>
                    </label>
                    <textarea
                      rows="5"
                      placeholder="Biografía"
                      id="biography2"
                      name="biography2"
                      value={this.state.biography2}
                      onChange={this.handleInput}
                    />
                  </FormGroup>
                </MDBCol>
                <MDBCol className="col-12 order-1 order-sm-2 col-sm-4">
                  <FormGroup>
                    <div className="mb-2" style={{ textAlign: "center" }}>
                      <img
                        src={imagen}
                        id="biographyImg2"
                        alt="logo"
                        width="75%"
                        height="75%"
                      />
                    </div>
                    <div className="custom-file">
                      <input
                        type="file"
                        className="custom-file-input"
                        lang="es"
                        accept=".jpg, .svg, .jpeg, .png"
                        id="biographyImage2"
                        name="biographyImg2"
                        onChange={this.handleImageChange}
                      />
                      <label
                        className="custom-file-label"
                        id="biographyImage2Label"
                        for="biographyImage2"
                      >
                        Alto máx. 300px
                      </label>
                    </div>
                  </FormGroup>
                </MDBCol>
              </MDBRow> */}

              <MDBRow>
                <MDBCol className="col-12 mb-2">
                  <label htmlFor="videoLink">
                    Imagen de portada <font color="red">*</font>
                  </label>
                  <div
                    className="mb-2"
                    style={{ textAlign: "center" }}
                    id="divVideo"
                  >
                    <img src={imagen} id="videoImg" alt="logo" width="100%" />
                  </div>
                  <div className="custom-file">
                    <input
                      type="file"
                      className="custom-file-input"
                      lang="es"
                      accept=".jpg,.svg,.jpeg,.png, .gif"
                      id="videoImage"
                      name="videoImg"
                      onChange={this.handleImageChange}
                    />
                    <label
                      className="custom-file-label"
                      id="videoImageLabel"
                      for="videoImage"
                    >
                      Alto máx. 500px
                    </label>
                  </div>
                </MDBCol>
              </MDBRow>
              <MDBCardFooter color="white">
                <Link to={"/admin"}>
                  <MDBBtn
                    color="purpleButton"
                    className="generalButton purpleButton"
                  >
                    <span className="modalButtonText">Cancelar</span>
                  </MDBBtn>
                </Link>
                <MDBBtn
                  color="greenButton"
                  className="generalButton greenButton"
                  onClick={this.validateData}
                >
                  <span className="modalButtonText">Guardar</span>
                </MDBBtn>
              </MDBCardFooter>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
  }
}
export default AboutUsConfiguration;
