import React, { Component } from 'react';
import { MDBBtn, MDBCol, MDBRow, MDBIcon, MDBDataTable, MDBCard, MDBTooltip } from 'mdbreact';


class Help extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <div className="container">
                <MDBRow>
                    <MDBCol className="col-12 col-lg-9 offset-lg-3 mt-3">
                        <h3 className="adminTitlesFont">Videos de ayuda</h3>
                    </MDBCol>
                    <MDBCol className="col-12 col-lg-9 offset-lg-3">


                        <div class="py-5 px-4" >
                            <div class="row d-flex justify-content-center">
                                <div class="col-md-10 col-xl-12">

                                    <div class="accordion md-accordion accordion-5" id="accordionEx5" role="tablist"
                                        aria-multiselectable="true">

                                        <div class="card mb-4">

                                            <div class="card-header p-0 z-depth-1" role="tab" id="heading48">
                                                <a data-toggle="collapse" data-parent="#accordionEx5" href="#collapse48" aria-expanded="true"
                                                    aria-controls="collapse48">
                                                    <i class="fas fa-user fa-2x p-3 mr-4 float-left black-text" aria-hidden="true"></i>
                                                    <h4 class="black-text mb-0 py-3 mt-1">
                                                        Perfil
                                                    </h4>
                                                </a>
                                            </div>

                                            <div id="collapse48" class="collapse" role="tabpanel" aria-labelledby="heading48"
                                                data-parent="#accordionEx5">
                                                <nav>
                                                    <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                                        <a class="nav-item nav-link active" id="nav-Perfil-tab" data-toggle="tab" href="#nav-Perfil" role="tab" aria-controls="nav-Perfil" aria-selected="true">Configuración de Perfil</a>
                                                        <a class="nav-item nav-link" id="nav-PerfilS-tab" data-toggle="tab" href="#nav-PerfilS" role="tab" aria-controls="nav-PerfilS" aria-selected="false">Cerrar Sesión</a>
                                                    </div>
                                                </nav>
                                                <div class="tab-content" id="nav-tabContent">
                                                    <div class="tab-pane fade show active" id="nav-Perfil" role="tabpanel" aria-labelledby="nav-Perfil-tab">
                                                        <div class="card-body" style={{ textAlign: "center" }}>
                                                            <iframe width="100%" height="315" src="https://www.youtube.com/embed/d8OEGy0uh4Q" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>                                                        </div>
                                                    </div>
                                                    <div class="tab-pane fade" id="nav-PerfilS" role="tabpanel" aria-labelledby="nav-PerfilS-tab">
                                                        <div class="card-body" style={{ textAlign: "center" }}>
                                                            <iframe width="100%" height="315" src="https://www.youtube.com/embed/-MDAqy3M-go" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="card mb-4">

                                            <div class="card-header p-0 z-depth-1" role="tab" id="heading31">
                                                <a data-toggle="collapse" data-parent="#accordionEx5" href="#collapse31" aria-expanded="true"
                                                    aria-controls="collapse31">
                                                    <i class="fas fa-info fa-2x p-3 mr-4 float-left black-text" aria-hidden="true"></i>
                                                    <h4 class="black-text mb-0 py-3 mt-1">
                                                        Info. Empresarial
                                                    </h4>
                                                </a>
                                            </div>

                                            <div id="collapse31" class="collapse" role="tabpanel" aria-labelledby="heading31"
                                                data-parent="#accordionEx5">
                                                <nav>
                                                    <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                                        <a class="nav-item nav-link active" id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="true">Página Principal</a>
                                                        <a class="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-profile" role="tab" aria-controls="nav-profile" aria-selected="false">Acerca de nosotros</a>
                                                        <a class="nav-item nav-link" id="nav-contact-tab" data-toggle="tab" href="#nav-contact" role="tab" aria-controls="nav-contact" aria-selected="false">Introducción de cursos</a>
                                                    </div>
                                                </nav>
                                                <div class="tab-content" id="nav-tabContent">
                                                    <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                                        <div class="card-body" style={{ textAlign: "center" }}>
                                                            <iframe width="100%" height="315" src="https://www.youtube.com/embed/qu9P-NHPCtU" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>                                                        </div>
                                                    </div>
                                                    <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                                                        <div class="card-body" style={{ textAlign: "center" }}>
                                                            <iframe width="100%" height="315" src="https://www.youtube.com/embed/-yzV2xgwzy8" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>                                                        </div>
                                                    </div>
                                                    <div class="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
                                                        <div class="card-body" style={{ textAlign: "center" }}>
                                                            <iframe width="100%" height="315" src="https://www.youtube.com/embed/OQB9ZQDdBDY" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="card mb-4">
                                            <div class="card-header p-0 z-depth-1" role="tab" id="heading35">
                                                <a data-toggle="collapse" data-parent="#accordionEx5" href="#collapse35" aria-expanded="true"
                                                    aria-controls="collapse35">
                                                    <i class="fas fa-user-friends fa-2x p-3 mr-4 float-left black-text" aria-hidden="true"></i>
                                                    <h4 class="black-text mb-0 py-3 mt-1">
                                                        Personas/Proveedores
                                                    </h4>
                                                </a>
                                            </div>

                                            <div id="collapse35" class="collapse" role="tabpanel" aria-labelledby="heading35"
                                                data-parent="#accordionEx5">
                                                <nav>
                                                    <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                                        <a class="nav-item nav-link active" id="nav-proveedoresA-tab" data-toggle="tab" href="#nav-proveedoresA" role="tab" aria-controls="nav-proveedoresA" aria-selected="true">Agregar Proveedores</a>
                                                        <a class="nav-item nav-link" id="nav-proveedoresE-tab" data-toggle="tab" href="#nav-proveedoresE" role="tab" aria-controls="nav-proveedoresE" aria-selected="false">Eliminar Proveedores</a>
                                                        <a class="nav-item nav-link" id="nav-proveedoresEd-tab" data-toggle="tab" href="#nav-proveedoresEd" role="tab" aria-controls="nav-proveedoresEd" aria-selected="false">Editar Proveedores</a>
                                                    </div>
                                                </nav>
                                                <div class="tab-content" id="nav-tabContent">
                                                    <div class="tab-pane fade show active" id="nav-proveedoresA" role="tabpanel" aria-labelledby="nav-proveedoresA-tab">
                                                        <div class="card-body" style={{ textAlign: "center" }}>
                                                        <iframe width="100%" height="315" src="https://www.youtube.com/embed/-K9xy9thqCA" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                                        </div>
                                                    </div>
                                                    <div class="tab-pane fade" id="nav-proveedoresE" role="tabpanel" aria-labelledby="nav-proveedoresE-tab">
                                                        <div class="card-body" style={{ textAlign: "center" }}>
                                                        <iframe width="100%" height="315" src="https://www.youtube.com/embed/PXZ_N3NyUiE" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                                        </div>
                                                    </div>
                                                    <div class="tab-pane fade" id="nav-proveedoresEd" role="tabpanel" aria-labelledby="nav-proveedoresEd-tab">
                                                        <div class="card-body" style={{ textAlign: "center" }}>
                                                        <iframe width="100%" height="315" src="https://www.youtube.com/embed/JwrMPytA5o4" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <div class="card mb-4">
                                            <div class="card-header p-0 z-depth-1" role="tab" id="heading36">
                                                <a data-toggle="collapse" data-parent="#accordionEx5" href="#collapse36" aria-expanded="true"
                                                    aria-controls="collapse36">
                                                    <i class="fas fa-user-friends fa-2x p-3 mr-4 float-left black-text" aria-hidden="true"></i>
                                                    <h4 class="black-text mb-0 py-3 mt-1">
                                                        Personas/Colaboradores
                                                    </h4>
                                                </a>
                                            </div>

                                            <div id="collapse36" class="collapse" role="tabpanel" aria-labelledby="heading36"
                                                data-parent="#accordionEx5">
                                                <nav>
                                                    <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                                        <a class="nav-item nav-link active" id="nav-colaboradoresA-tab" data-toggle="tab" href="#nav-colaboradoresA" role="tab" aria-controls="nav-colaboradoresA" aria-selected="true">Agregar Proveedores</a>
                                                        <a class="nav-item nav-link" id="nav-colaboradoresE-tab" data-toggle="tab" href="#nav-colaboradoresE" role="tab" aria-controls="nav-colaboradoresE" aria-selected="false">Eliminar Proveedores</a>
                                                        <a class="nav-item nav-link" id="nav-colaboradoresEd-tab" data-toggle="tab" href="#nav-colaboradoresEd" role="tab" aria-controls="nav-colaboradoresEd" aria-selected="false">Editar Proveedores</a>
                                                    </div>
                                                </nav>
                                                <div class="tab-content" id="nav-tabContent">
                                                    <div class="tab-pane fade show active" id="nav-colaboradoresA" role="tabpanel" aria-labelledby="nav-colaboradoresA-tab">
                                                        <div class="card-body" style={{ textAlign: "center" }}>
                                                        <iframe width="100%" height="315" src="https://www.youtube.com/embed/brtTYilI7nI" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                                        </div>
                                                    </div>
                                                    <div class="tab-pane fade" id="nav-colaboradoresE" role="tabpanel" aria-labelledby="nav-colaboradoresE-tab">
                                                        <div class="card-body" style={{ textAlign: "center" }}>
                                                        <iframe width="100%" height="315" src="https://www.youtube.com/embed/tc_qlqxT-ug" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>                                                        </div>
                                                    </div>
                                                    <div class="tab-pane fade" id="nav-colaboradoresEd" role="tabpanel" aria-labelledby="nav-colaboradoresEd-tab">
                                                        <div class="card-body" style={{ textAlign: "center" }}>
                                                        <iframe width="100%" height="315" src="https://www.youtube.com/embed/SncKJIq8YBg" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="card mb-4">
                                            <div class="card-header p-0 z-depth-1" role="tab" id="heading37">
                                                <a data-toggle="collapse" data-parent="#accordionEx5" href="#collapse37" aria-expanded="true"
                                                    aria-controls="collapse37">
                                                    <i class="fas fa-graduation-cap fa-2x p-3 mr-4 float-left black-text" aria-hidden="true"></i>
                                                    <h4 class="black-text mb-0 py-3 mt-1">
                                                        Cursos/Lista de Cursos
                                                    </h4>
                                                </a>
                                            </div>

                                            <div id="collapse37" class="collapse" role="tabpanel" aria-labelledby="heading37"
                                                data-parent="#accordionEx5">
                                                <nav>
                                                    <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                                        <a class="nav-item nav-link active" id="nav-cursoA-tab" data-toggle="tab" href="#nav-cursoA" role="tab" aria-controls="nav-cursoA" aria-selected="true">Agregar Curso</a>
                                                        <a class="nav-item nav-link" id="nav-cursoE-tab" data-toggle="tab" href="#nav-cursoE" role="tab" aria-controls="nav-cursoE" aria-selected="false">Habilitar/Deshabilitar Cursos</a>
                                                        <a class="nav-item nav-link" id="nav-cursoEd-tab" data-toggle="tab" href="#nav-cursoEd" role="tab" aria-controls="nav-cursoEd" aria-selected="false">Editar Curso</a>
                                                    </div>
                                                </nav>
                                                <div class="tab-content" id="nav-tabContent">
                                                    <div class="tab-pane fade show active" id="nav-cursoA" role="tabpanel" aria-labelledby="nav-cursoA-tab">
                                                        <div class="card-body" style={{ textAlign: "center" }}>
                                                        <iframe width="100%" height="315" src="https://www.youtube.com/embed/ZUsdn_KYcpQ" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                                        </div>
                                                    </div>
                                                    <div class="tab-pane fade" id="nav-cursoE" role="tabpanel" aria-labelledby="nav-cursoE-tab">
                                                        <div class="card-body" style={{ textAlign: "center" }}>
                                                        <iframe width="100%" height="315" src="https://www.youtube.com/embed/WNmccWKwd3Q" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                                        </div>
                                                    </div>
                                                    <div class="tab-pane fade" id="nav-cursoEd" role="tabpanel" aria-labelledby="nav-cursoEd-tab">
                                                        <div class="card-body" style={{ textAlign: "center" }}>
                                                        <iframe width="100%" height="315" src="https://www.youtube.com/embed/Nmdf1dtK4E8" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="card mb-4">
                                            <div class="card-header p-0 z-depth-1" role="tab" id="heading38">
                                                <a data-toggle="collapse" data-parent="#accordionEx5" href="#collapse38" aria-expanded="true"
                                                    aria-controls="collapse38">
                                                    <i class="fas fa-graduation-cap fa-2x p-3 mr-4 float-left black-text" aria-hidden="true"></i>
                                                    <h4 class="black-text mb-0 py-3 mt-1">
                                                        Cursos/Tipo de Curso
                                                    </h4>
                                                </a>
                                            </div>

                                            <div id="collapse38" class="collapse" role="tabpanel" aria-labelledby="heading38"
                                                data-parent="#accordionEx5">
                                                <nav>
                                                    <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                                        <a class="nav-item nav-link active" id="nav-TcursoA-tab" data-toggle="tab" href="#nav-TcursoA" role="tab" aria-controls="nav-TcursoA" aria-selected="true">Agregar Tipo deCurso</a>
                                                        <a class="nav-item nav-link" id="nav-TcursoE-tab" data-toggle="tab" href="#nav-TcursoE" role="tab" aria-controls="nav-TcursoE" aria-selected="false">Eliminar Tipo deCursos</a>
                                                        <a class="nav-item nav-link" id="nav-TcursoEd-tab" data-toggle="tab" href="#nav-TcursoEd" role="tab" aria-controls="nav-TcursoEd" aria-selected="false">Editar Tipo de Curso</a>
                                                    </div>
                                                </nav>
                                                <div class="tab-content" id="nav-tabContent">
                                                    <div class="tab-pane fade show active" id="nav-TcursoA" role="tabpanel" aria-labelledby="nav-TcursoA-tab">
                                                        <div class="card-body" style={{ textAlign: "center" }}>
                                                        <iframe width="100%" height="315" src="https://www.youtube.com/embed/iqRQBJ0wZPA" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                                        </div>
                                                    </div>
                                                    <div class="tab-pane fade" id="nav-TcursoE" role="tabpanel" aria-labelledby="nav-TcursoE-tab">
                                                        <div class="card-body" style={{ textAlign: "center" }}>
                                                        <iframe width="100%" height="315" src="https://www.youtube.com/embed/qcJ7f1M_Qb8" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>                                                        </div>
                                                    </div>
                                                    <div class="tab-pane fade" id="nav-TcursoEd" role="tabpanel" aria-labelledby="nav-TcursoEd-tab">
                                                        <div class="card-body" style={{ textAlign: "center" }}>
                                                        <iframe width="100%" height="315" src="https://www.youtube.com/embed/ySF2PmwMLVM" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="card mb-4">
                                            <div class="card-header p-0 z-depth-1" role="tab" id="heading39">
                                                <a data-toggle="collapse" data-parent="#accordionEx5" href="#collapse39" aria-expanded="true"
                                                    aria-controls="collapse39">
                                                    <i class="fas fa-calendar-alt fa-2x p-3 mr-4 float-left black-text" aria-hidden="true"></i>
                                                    <h4 class="black-text mb-0 py-3 mt-1">
                                                        Eventos
                                                    </h4>
                                                </a>
                                            </div>

                                            <div id="collapse39" class="collapse" role="tabpanel" aria-labelledby="heading39"
                                                data-parent="#accordionEx5">
                                                <nav>
                                                    <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                                        <a class="nav-item nav-link active" id="nav-EventosA-tab" data-toggle="tab" href="#nav-EventosA" role="tab" aria-controls="nav-EventosA" aria-selected="true">Agregar Evento</a>
                                                        <a class="nav-item nav-link" id="nav-EventosEd-tab" data-toggle="tab" href="#nav-EventosEd" role="tab" aria-controls="nav-EventosEd" aria-selected="false">Editar Evento</a>
                                                        <a class="nav-item nav-link" id="nav-EventosM-tab" data-toggle="tab" href="#nav-EventosM" role="tab" aria-controls="nav-EventosM" aria-selected="false">Agregar Multimedia</a>
                                                    </div>
                                                </nav>
                                                <div class="tab-content" id="nav-tabContent">
                                                    <div class="tab-pane fade show active" id="nav-EventosA" role="tabpanel" aria-labelledby="nav-EventosA-tab">
                                                        <div class="card-body" style={{ textAlign: "center" }}>
                                                        <iframe width="100%" height="315" src="https://www.youtube.com/embed/kqkid1XN764" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                                        </div>
                                                    </div>
                                                    <div class="tab-pane fade" id="nav-EventosEd" role="tabpanel" aria-labelledby="nav-EventosEd-tab">
                                                        <div class="card-body" style={{ textAlign: "center" }}>
                                                        <iframe width="100%" height="315" src="https://www.youtube.com/embed/rFqu8B5_zAA" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                                        </div>
                                                    </div>
                                                    <div class="tab-pane fade" id="nav-EventosM" role="tabpanel" aria-labelledby="nav-EventosM-tab">
                                                        <div class="card-body" style={{ textAlign: "center" }}>
                                                        <iframe width="100%" height="315" src="https://www.youtube.com/embed/osR1HYdNurc" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="card mb-4">
                                            <div class="card-header p-0 z-depth-1" role="tab" id="heading47">
                                                <a data-toggle="collapse" data-parent="#accordionEx5" href="#collapse47" aria-expanded="true"
                                                    aria-controls="collapse47">
                                                    <i class="fas fa-calendar-alt fa-2x p-3 mr-4 float-left black-text" aria-hidden="true"></i>
                                                    <h4 class="black-text mb-0 py-3 mt-1">
                                                        Eventos/Participantes
                                                    </h4>
                                                </a>
                                            </div>

                                            <div id="collapse47" class="collapse" role="tabpanel" aria-labelledby="heading47"
                                                data-parent="#accordionEx5">
                                                <nav>
                                                    <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                                        <a class="nav-item nav-link active" id="nav-ParticipantesA-tab" data-toggle="tab" href="#nav-ParticipantesA" role="tab" aria-controls="nav-ParticipantesA" aria-selected="true">información Participantes</a>

                                                    </div>
                                                </nav>
                                                <div class="tab-content" id="nav-tabContent">
                                                    <div class="tab-pane fade show active" id="nav-ParticipantesA" role="tabpanel" aria-labelledby="nav-ParticipantesA-tab">
                                                        <div class="card-body" style={{ textAlign: "center" }}>
                                                        <iframe width="100%" height="315" src="https://www.youtube.com/embed/5SMlkP1ojks" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="card mb-4">
                                            <div class="card-header p-0 z-depth-1" role="tab" id="heading40">
                                                <a data-toggle="collapse" data-parent="#accordionEx5" href="#collapse40" aria-expanded="true"
                                                    aria-controls="collapse40">
                                                    <i class="fas fa-sitemap fa-2x p-3 mr-4 float-left black-text" aria-hidden="true"></i>
                                                    <h4 class="black-text mb-0 py-3 mt-1">
                                                        Categorías
                                                    </h4>
                                                </a>
                                            </div>

                                            <div id="collapse40" class="collapse" role="tabpanel" aria-labelledby="heading40"
                                                data-parent="#accordionEx5">
                                                <nav>
                                                    <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                                        <a class="nav-item nav-link active" id="nav-CategoriasA-tab" data-toggle="tab" href="#nav-CategoriasA" role="tab" aria-controls="nav-CategoriasA" aria-selected="true">Agregar Categorias</a>
                                                        <a class="nav-item nav-link" id="nav-CategoriasE-tab" data-toggle="tab" href="#nav-CategoriasE" role="tab" aria-controls="nav-CategoriasE" aria-selected="false">Eliminar Categorias</a>
                                                        <a class="nav-item nav-link" id="nav-CategoriasEd-tab" data-toggle="tab" href="#nav-CategoriasEd" role="tab" aria-controls="nav-CategoriasEd" aria-selected="false">Editar Categorias</a>
                                                    </div>
                                                </nav>
                                                <div class="tab-content" id="nav-tabContent">
                                                    <div class="tab-pane fade show active" id="nav-CategoriasA" role="tabpanel" aria-labelledby="nav-CategoriasA-tab">
                                                        <div class="card-body" style={{ textAlign: "center" }}>
                                                        <iframe width="100%" height="315" src="https://www.youtube.com/embed/QF7wmS8QKvo" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                                        </div>
                                                    </div>
                                                    <div class="tab-pane fade" id="nav-CategoriasE" role="tabpanel" aria-labelledby="nav-CategoriasE-tab">
                                                        <div class="card-body" style={{ textAlign: "center" }}>
                                                        <iframe width="100%" height="315" src="https://www.youtube.com/embed/Xx1WtkyMcsE" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                                        </div>
                                                    </div>
                                                    <div class="tab-pane fade" id="nav-CategoriasEd" role="tabpanel" aria-labelledby="nav-CategoriasEd-tab">
                                                        <div class="card-body" style={{ textAlign: "center" }}>
                                                            <iframe width="100%" height="315" src="https://www.youtube.com/embed/o0uUdnDquD0" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="card mb-4">
                                            <div class="card-header p-0 z-depth-1" role="tab" id="heading41">
                                                <a data-toggle="collapse" data-parent="#accordionEx5" href="#collapse41" aria-expanded="true"
                                                    aria-controls="collapse41">
                                                    <i class="fas fa-newspaper fa-2x p-3 mr-4 float-left black-text" aria-hidden="true"></i>
                                                    <h4 class="black-text mb-0 py-3 mt-1">
                                                        Artículos
                                                    </h4>
                                                </a>
                                            </div>

                                            <div id="collapse41" class="collapse" role="tabpanel" aria-labelledby="heading41"
                                                data-parent="#accordionEx5">
                                                <nav>
                                                    <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                                        <a class="nav-item nav-link active" id="nav-ArtículosA-tab" data-toggle="tab" href="#nav-ArtículosA" role="tab" aria-controls="nav-ArtículosA" aria-selected="true">Agregar Artículos</a>
                                                        <a class="nav-item nav-link" id="nav-ArtículosE-tab" data-toggle="tab" href="#nav-ArtículosE" role="tab" aria-controls="nav-ArtículosE" aria-selected="false">Eliminar Artículos</a>
                                                        <a class="nav-item nav-link" id="nav-ArtículosEd-tab" data-toggle="tab" href="#nav-ArtículosEd" role="tab" aria-controls="nav-ArtículosEd" aria-selected="false">Editar Artículos</a>
                                                    </div>
                                                </nav>
                                                <div class="tab-content" id="nav-tabContent">
                                                    <div class="tab-pane fade show active" id="nav-ArtículosA" role="tabpanel" aria-labelledby="nav-ArtículosA-tab">
                                                        <div class="card-body" style={{ textAlign: "center" }}>
                                                            <iframe width="100%" height="315" src="https://www.youtube.com/embed/RBz4FWFT-Rw" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                                        </div>
                                                    </div>
                                                    <div class="tab-pane fade" id="nav-ArtículosE" role="tabpanel" aria-labelledby="nav-ArtículosE-tab">
                                                        <div class="card-body" style={{ textAlign: "center" }}>
                                                            <iframe width="100%" height="315" src="https://www.youtube.com/embed/CdP2i6MB_40" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>                                                        </div>
                                                    </div>
                                                    <div class="tab-pane fade" id="nav-ArtículosEd" role="tabpanel" aria-labelledby="nav-ArtículosEd-tab">
                                                        <div class="card-body" style={{ textAlign: "center" }}>
                                                            <iframe width="100%" height="315" src="https://www.youtube.com/embed/-2Ml_zVL6fI" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="card mb-4">
                                            <div class="card-header p-0 z-depth-1" role="tab" id="heading42">
                                                <a data-toggle="collapse" data-parent="#accordionEx5" href="#collapse42" aria-expanded="true"
                                                    aria-controls="collapse42">
                                                    <i class="fas fa-comments fa-2x p-3 mr-4 float-left black-text" aria-hidden="true"></i>
                                                    <h4 class="black-text mb-0 py-3 mt-1">
                                                        Comunidad/Comentarios
                                                    </h4>
                                                </a>
                                            </div>

                                            <div id="collapse42" class="collapse" role="tabpanel" aria-labelledby="heading42"
                                                data-parent="#accordionEx5">
                                                <nav>
                                                    <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                                        <a class="nav-item nav-link active" id="nav-ComentariosA-tab" data-toggle="tab" href="#nav-ComentariosA" role="tab" aria-controls="nav-ComentariosA" aria-selected="true">Publicar Comentarios</a>
                                                        <a class="nav-item nav-link" id="nav-ComentariosE-tab" data-toggle="tab" href="#nav-ComentariosE" role="tab" aria-controls="nav-ComentariosE" aria-selected="false">Eliminar Comentarios</a>
                                                        <a class="nav-item nav-link" id="nav-ComentariosEd-tab" data-toggle="tab" href="#nav-ComentariosEd" role="tab" aria-controls="nav-ComentariosEd" aria-selected="false">Editar Comentarios</a>
                                                    </div>
                                                </nav>
                                                <div class="tab-content" id="nav-tabContent">
                                                    <div class="tab-pane fade show active" id="nav-ComentariosA" role="tabpanel" aria-labelledby="nav-ComentariosA-tab">
                                                        <div class="card-body" style={{ textAlign: "center" }}>
                                                            <iframe width="100%" height="315" src="https://www.youtube.com/embed/f1TG5rqdBk8" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

                                                        </div>
                                                    </div>
                                                    <div class="tab-pane fade" id="nav-ComentariosE" role="tabpanel" aria-labelledby="nav-ComentariosE-tab">
                                                        <div class="card-body" style={{ textAlign: "center" }}>
                                                            <iframe width="100%" height="315" src="https://www.youtube.com/embed/dycVdkMwZOM" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                                        </div>
                                                    </div>
                                                    <div class="tab-pane fade" id="nav-ComentariosEd" role="tabpanel" aria-labelledby="nav-ComentariosEd-tab">
                                                        <div class="card-body" style={{ textAlign: "center" }}>
                                                            <iframe width="100%" height="315" src="https://www.youtube.com/embed/cgW9Xlw5YSI" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="card mb-4">
                                            <div class="card-header p-0 z-depth-1" role="tab" id="heading43">
                                                <a data-toggle="collapse" data-parent="#accordionEx5" href="#collapse43" aria-expanded="true"
                                                    aria-controls="collapse43">
                                                    <i class="fas fa-comments fa-2x p-3 mr-4 float-left black-text" aria-hidden="true"></i>
                                                    <h4 class="black-text mb-0 py-3 mt-1">
                                                        Comunidad/Actividades
                                                    </h4>
                                                </a>
                                            </div>

                                            <div id="collapse43" class="collapse" role="tabpanel" aria-labelledby="heading43"
                                                data-parent="#accordionEx5">
                                                <nav>
                                                    <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                                        <a class="nav-item nav-link active" id="nav-ActividadesA-tab" data-toggle="tab" href="#nav-ActividadesA" role="tab" aria-controls="nav-ActividadesA" aria-selected="true">Publicar Actividades</a>
                                                        <a class="nav-item nav-link" id="nav-ActividadesE-tab" data-toggle="tab" href="#nav-ActividadesE" role="tab" aria-controls="nav-ActividadesE" aria-selected="false">Eliminar Actividades</a>
                                                        <a class="nav-item nav-link" id="nav-ActividadesEd-tab" data-toggle="tab" href="#nav-ActividadesEd" role="tab" aria-controls="nav-ActividadesEd" aria-selected="false">Editar Actividades</a>
                                                    </div>
                                                </nav>
                                                <div class="tab-content" id="nav-tabContent">
                                                    <div class="tab-pane fade show active" id="nav-ActividadesA" role="tabpanel" aria-labelledby="nav-ActividadesA-tab">
                                                        <div class="card-body" style={{ textAlign: "center" }}>
                                                            <iframe width="100%" height="315" src="https://www.youtube.com/embed/0_ipXxyOsSw" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                                        </div>
                                                    </div>
                                                    <div class="tab-pane fade" id="nav-ActividadesE" role="tabpanel" aria-labelledby="nav-ActividadesE-tab">
                                                        <div class="card-body" style={{ textAlign: "center" }}>
                                                            <iframe width="100%" height="315" src="https://www.youtube.com/embed/JHAOy6SJNn8" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                                        </div>
                                                    </div>
                                                    <div class="tab-pane fade" id="nav-ActividadesEd" role="tabpanel" aria-labelledby="nav-ActividadesEd-tab">
                                                        <div class="card-body" style={{ textAlign: "center" }}>
                                                            <iframe width="100%" height="315" src="https://www.youtube.com/embed/nWrGxqhsx88" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="card mb-4">
                                            <div class="card-header p-0 z-depth-1" role="tab" id="heading44">
                                                <a data-toggle="collapse" data-parent="#accordionEx5" href="#collapse44" aria-expanded="true"
                                                    aria-controls="collapse44">
                                                    <i class="fas fa-comments fa-2x p-3 mr-4 float-left black-text" aria-hidden="true"></i>
                                                    <h4 class="black-text mb-0 py-3 mt-1">
                                                        Comunidad/Ofertas de Empleo
                                                    </h4>
                                                </a>
                                            </div>

                                            <div id="collapse44" class="collapse" role="tabpanel" aria-labelledby="heading44"
                                                data-parent="#accordionEx5">
                                                <nav>
                                                    <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                                        <a class="nav-item nav-link active" id="nav-OfertasA-tab" data-toggle="tab" href="#nav-OfertasA" role="tab" aria-controls="nav-OfertasA" aria-selected="true">Publicar Ofertas de Empleo</a>
                                                        <a class="nav-item nav-link" id="nav-OfertasE-tab" data-toggle="tab" href="#nav-OfertasE" role="tab" aria-controls="nav-OfertasE" aria-selected="false">Eliminar Ofertas de Empleo</a>
                                                        <a class="nav-item nav-link" id="nav-OfertasEd-tab" data-toggle="tab" href="#nav-OfertasEd" role="tab" aria-controls="nav-OfertasEd" aria-selected="false">Editar Ofertas de Empleo</a>
                                                    </div>
                                                </nav>
                                                <div class="tab-content" id="nav-tabContent">
                                                    <div class="tab-pane fade show active" id="nav-OfertasA" role="tabpanel" aria-labelledby="nav-OfertasA-tab">
                                                        <div class="card-body" style={{ textAlign: "center" }}>
                                                            <iframe width="100%" height="315" src="https://www.youtube.com/embed/3bQnYVzfKoo" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                                        </div>
                                                    </div>
                                                    <div class="tab-pane fade" id="nav-OfertasE" role="tabpanel" aria-labelledby="nav-OfertasE-tab">
                                                        <div class="card-body" style={{ textAlign: "center" }}>
                                                            <iframe width="100%" height="315" src="https://www.youtube.com/embed/zY8MV0EC_DU" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>                                                        </div>
                                                    </div>
                                                    <div class="tab-pane fade" id="nav-OfertasEd" role="tabpanel" aria-labelledby="nav-OfertasEd-tab">
                                                        <div class="card-body" style={{ textAlign: "center" }}>
                                                            <iframe width="100%" height="315" src="https://www.youtube.com/embed/TWyJUwc9F3I" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="card mb-4">
                                            <div class="card-header p-0 z-depth-1" role="tab" id="heading45">
                                                <a data-toggle="collapse" data-parent="#accordionEx5" href="#collapse45" aria-expanded="true"
                                                    aria-controls="collapse45">
                                                    <i class="fas fa-file-invoice-dollar fa-2x p-3 mr-4 float-left black-text" aria-hidden="true"></i>
                                                    <h4 class="black-text mb-0 py-3 mt-1">
                                                        Cotizaciones
                                                    </h4>
                                                </a>
                                            </div>

                                            <div id="collapse45" class="collapse" role="tabpanel" aria-labelledby="heading45"
                                                data-parent="#accordionEx5">
                                                <nav>
                                                    <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                                        <a class="nav-item nav-link active" id="nav-CotizacionesA-tab" data-toggle="tab" href="#nav-CotizacionesA" role="tab" aria-controls="nav-CotizacionesA" aria-selected="true">Responder Cotizaciones</a>

                                                    </div>
                                                </nav>
                                                <div class="tab-content" id="nav-tabContent">
                                                    <div class="tab-pane fade show active" id="nav-CotizacionesA" role="tabpanel" aria-labelledby="nav-CotizacionesA-tab">
                                                        <div class="card-body" style={{ textAlign: "center" }}>
                                                            <iframe width="100%" height="315" src="https://www.youtube.com/embed/TNQvz7NyYkg" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="card mb-4">
                                            <div class="card-header p-0 z-depth-1" role="tab" id="heading46">
                                                <a data-toggle="collapse" data-parent="#accordionEx5" href="#collapse46" aria-expanded="true"
                                                    aria-controls="collapse46">
                                                    <i class="fas fa-chart-line fa-2x p-3 mr-4 float-left black-text" aria-hidden="true"></i>
                                                    <h4 class="black-text mb-0 py-3 mt-1">
                                                        Reportes
                                                    </h4>
                                                </a>
                                            </div>

                                            <div id="collapse46" class="collapse" role="tabpanel" aria-labelledby="heading46"
                                                data-parent="#accordionEx5">
                                                <nav>
                                                    <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                                        <a class="nav-item nav-link active" id="nav-ReportesF-tab" data-toggle="tab" href="#nav-ReportesF" role="tab" aria-controls="nav-ReportesF" aria-selected="true">Reporte Facturas</a>
                                                        <a class="nav-item nav-link" id="nav-ReportesE-tab" data-toggle="tab" href="#nav-ReportesE" role="tab" aria-controls="nav-ReportesE" aria-selected="false">Reporte Estudiantes</a>
                                                    </div>
                                                </nav>
                                                <div class="tab-content" id="nav-tabContent">
                                                    <div class="tab-pane fade show active" id="nav-ReportesF" role="tabpanel" aria-labelledby="nav-ReportesF-tab">
                                                        <div class="card-body" style={{ textAlign: "center" }}>
                                                            <iframe width="100%" height="315" src="https://www.youtube.com/embed/xNYkGYUTJbM" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

                                                        </div>
                                                    </div>
                                                    <div class="tab-pane fade" id="nav-ReportesE" role="tabpanel" aria-labelledby="nav-ReportesE-tab">
                                                        <div class="card-body" style={{ textAlign: "center" }}>
                                                            <iframe width="100%" height="315" src="https://www.youtube.com/embed/1n6ELi-BjXI" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </MDBCol>
                </MDBRow>
            </div>
        );
    }
}

export default Help;