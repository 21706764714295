/**
 * @fileoverview  Adv_Job it consists of managing the job announcements that people place, it can be deleted, added or edited.
 *
 * @version 1.0
 * @author Estrella López López <estrella.lopez@ucrso.info>
 * 
 * History - Initial Release
 * ----
 * The first version of Adv_Job was written by Estrella López López.
 */

import React, { Component, Fragment } from 'react';
import { MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBDataTable, MDBCard, MDBContainer, MDBRow, MDBCol, MDBIcon, MDBBtn, MDBTooltip } from 'mdbreact';
import { FormGroup, Container } from 'reactstrap';
import axios from 'axios';
import { baseUrl } from './baseUrl';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import validations from './Validations';

class Adv_Job extends Component {
	constructor(props) {
		super(props);
		/**
		 * validations: 
		 * @type {Object}
		 * Property that has methods for validate with regular expressions.
		 * 
		 * advertisementID: 
		 * @type {Integer}
		 * Property that stores the id of the job.
		 * 
		 * description: 
		 * @type {String}
		 * Property that stores the description of the job.
		 * 
		 * advertisementsJob:
		 * @type {Table}
		 * Property that stores the list of advertisements jobs in table form, consists of name, description, 
		 * postulated by and actions.
		 * 
		 * option:
		 * @type {Integer}
		 * Property that stores the option of the status.
		   *	
		 * jobStatus:
		 * @type {String}
		 * Property that stores the job status.
		 */

		this.state = {
			validations: new validations(),
			advertisementID: 0,
			description: '',
			advertisementsJob: {
				columns: [
					{
						label: 'Nombre',
						field: 'name',
						width: 150
					},
					{
						label: 'Description',
						field: 'description',
						width: 150
					},
					{
						label: 'Postulado por',
						field: 'users',
						width: 150
					},
					{
						label: 'Estado',
						field: 'state',
						width: 150
					},
					{
						label: 'Acciones',
						field: 'actions',
						width: 150
					}
				],
				rows: []
			},
			option: 1,
			jobStatus: '',
		};

		this.deleteModal = this.deleteModal.bind(this);
		this.notify = this.notify.bind(this);
		this.delete = this.delete.bind(this);
		this.publish = this.publish.bind(this);
		this.publishModal = this.publishModal.bind(this);
		this.getAdvertisementJob = this.getAdvertisementJob.bind(this);


	}

	/**
	 * Method that loads the elements to be initially displayed on the website.
	 */
	componentDidMount() {
		if (sessionStorage.getItem('userType') == 2) {
			this.getAdvertisementJob();
		} else if (sessionStorage.getItem('userType') == 3) {
			this.props.history.push('/admin');
		} else {
			this.props.history.push('/');
		}
	}

	/**
	 * Method that, depending on the value of the variable, displays the type of message it is, if it is correctly entered, there was an 
	 * error, an informational message, or a warning message.
	 * @param {String} evt
	 * @param {String} value 
	 * @param {String} msj 
	 */
	notify = (evt, value, msj) => {
		switch (value) {
			case 'SUCCESS':
				toast.success(msj);
				break;
			case 'ERROR':
				toast.error(msj);
				break;
			case 'WARN':
				toast.warn(msj);
				break;
			case 'INFO':
				toast.info(msj);
				break;
			default:
				toast.info(msj);
		}
	};


	/**
	 * Method that consists of editing the status of the course in enabled and disabled.
	 * @param {String} text 
	 * @param {Integer} id 
	 * @param {String} name 
	 * @param {Integer} state 
	 * @param {event} event 
	 */
	publicAdvertisement = (text, id, name, state, event) => () => {

		var newTypesRows = [];
		this.state.option = state;
		this.setState({ option: state });

		if (this.state.option == 0) {

			fetch(baseUrl + 'adv_Event/updateStateAdv', {
				method: 'put',
				body: JSON.stringify({
					acceptedYN: 1,
					details: id,
					advertisementID: this.state.advertisementID
				}),
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json'
				}
			})
				.then(
					
				(newTypesRows = this.state.advertisementsJob.rows.filter((row) => row.advertisementID != this.state.advertisementID)),
				this.getAdvertisementJob(),
				this.notify(event, 'SUCCESS', 'Se ha publicado correctamente'),
					this.setState({
						advertisementsJob: {
							columns: this.state.advertisementsJob.columns,
							rows: newTypesRows
						}
					})
				)
				.catch((err) => console.error(err));
		} else if (this.state.option == 1) {
			fetch(baseUrl + 'adv_Event/updateStateAdv', {
				method: 'put',
				body: JSON.stringify({
					state: 0,
					details: id
					, advertisementID: this.state.advertisementID
				}),
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json'
				}
			})
				.then(
					(newTypesRows = this.state.advertisementsJob.rows.filter((row) => row.advertisementID != this.state.advertisementID)),
					this.getAdvertisementJob(),
					this.notify(event, 'SUCCESS', 'Se ha retirado la publicación correctamente'),
					this.setState({
						advertisementsJob: {
							columns: this.state.advertisementsJob.columns,
							rows: newTypesRows
						}
					})
				)


				.catch((err) => console.error(err));
		}

		this.getAdvertisementJob();
	};


	/**
	 * Method that is called, so that according to the state the modal that will open the action is edited or 
	 * added or closed.
	 * @param {String} text 
	 * @param {Integer} id 
	 * @param {String} name 
	 * @param {Integer} state 
	 */
	toggle(text, id, name, state) {
		let modalAction = text + 'Modal';
		if (id != null) {
			if (state == 0) {
				this.setState({
					[modalAction]: !this.state[modalAction],
					advertisementsID: id,
					name: name
				});
				this.props.history.push({
					pathname: '/Adv_Job_Up/' + id
				});
			} else if (state == 1) {

				this.setState({
					[modalAction]: !this.state[modalAction],
					advertisementID: id

				});
			}
		} else {
			if (this.state[modalAction] === true) {

				this.setState({
					[modalAction]: !this.state[modalAction],
					advertisementID: 0
				});
			} else {
				this.setState({
					[modalAction]: !this.state[modalAction]
				});
			}
		}
	}

	/**
	 * Method that removes the advertisement from the database.
	 * @param {event} event 
	 */
	delete(event) {
		var newAdvertisement = [];

		axios
			.post(baseUrl + 'advertisement/deleteAdvertisement', {
				advertisementID: this.state.advertisementID
			})
			.then(
				(newAdvertisement = this.state.advertisementsJob.rows.filter(
					(row) => row.advertisementID != this.state.advertisementID
				)),
				this.setState({
					advertisementID: 0,
					deleteModal: false,
					advertisementsJob: {
						columns: this.state.advertisementsJob.columns,
						rows: newAdvertisement
					}
				}),
				this.notify(event, 'SUCCESS', 'Se ha eliminado correctamente')
			)
			.catch((err) => console.error('Ha ocurrido un error inesperado'));
		event.preventDefault();
	}


	/**
	 * Method shown to the user to remove the ad.
	 */
	deleteModal() {
		return (
			<MDBContainer>
				<MDBModal isOpen={this.state.deleteModal} toggle={() => this.toggle('delete')}>
					<MDBModalHeader className="modalHeader modalTitlesFont" toggle={() => this.toggle('delete')}>
						Eliminar empleo
					</MDBModalHeader>
					<MDBModalBody>
						<FormGroup className="text-center">
							<label className="modalTextFont">
								¿Desea eliminar esta oferta de empleo?<br />Esta acción es irreversible
							</label>
						</FormGroup>
					</MDBModalBody>
					<MDBModalFooter>
						<MDBBtn
							color="purpleButton"
							className="modalButton purpleButton"
							onClick={() => this.toggle('delete')}
						>
							<span className="modalButtonText">Cancelar</span>
						</MDBBtn>
						<MDBBtn color="redButton" className="modalButton redButton" onClick={this.delete}>
							<span className="modalButtonText">Eliminar</span>
						</MDBBtn>
					</MDBModalFooter>
				</MDBModal>
			</MDBContainer>
		);
	}


	/**
	 * Method that loads the announcements of jobs found in the database and places them in a table with the actions,
	 * which are delete and edit, in case of pressing each of them a call is made to the toogle method
	 */
	getAdvertisementJob() {
		axios
			.get(baseUrl + 'adv_Event/getAllAdvJob')
			.then((response) => {
				var dbAdvertisementsJob = response.data;
				var nameTop;
				this.setState({
					...this.state,
					advertisementsJob: {
						...this.state.advertisementsJob,
						rows: dbAdvertisementsJob.map((dbAdvertisementsJob, key) => {
							return {
								...dbAdvertisementsJob,
								state: (
						<MDBTooltip placement="top">
									<MDBBtn
										color="white"
										className=" buttonState tableControlButton"
										onClick={this.publicAdvertisement(
											'state',
											dbAdvertisementsJob.advertisementID,
											dbAdvertisementsJob.name,
											dbAdvertisementsJob.acceptedYN
										)}

										{...(this.state.option = dbAdvertisementsJob.acceptedYN)}
										{...this.state.option == 0 && <div>{(this.jobStatus = 'lock'), (nameTop = 'No Publicado')}</div> }
										{...this.state.option == 1 && <div>{(this.jobStatus = 'lock-open'), (nameTop = 'Publicado')}</div>}

									>
										
											<MDBIcon icon={this.jobStatus} size="1x" className={this.jobStatus} />
										
									
									</MDBBtn>
									<div>
										{nameTop}
									</div>
									</MDBTooltip>
								),
								actions: (
									<Fragment>
										<MDBTooltip>
										<MDBBtn
											color="white"
											className="tableControlButton"
											onClick={() => this.toggle(
												'update',
												dbAdvertisementsJob.advertisementID,
												dbAdvertisementsJob.name,
												0
											)}
										>
											<MDBIcon icon="pen" size="1x" />
										</MDBBtn>
										<div>
										Editar
										</div>
										</MDBTooltip>
										<MDBTooltip>
										<MDBBtn
											color="white"
											className="tableControlButton"

											onClick={() => this.toggle('delete', dbAdvertisementsJob.advertisementID, dbAdvertisementsJob.name, 1)}
										>
											<MDBIcon icon="trash" size="1x" className="red-text" id="delete" />
										</MDBBtn>
										<div>
										Eliminar
										</div>
										</MDBTooltip>
									</Fragment>
								)
							};
						})
					}
				});
			})
			.catch((err) => console.error('Hola: ' + err));
	}

	/**
	 * The method that changes the state variable of the job post, this is posted to the website.
	 */
	publish(event) {
		var newAdvertisement = [];
		fetch(baseUrl + 'advertisement/publishComment', {
			method: 'post',
			body: JSON.stringify({
				advertisementID: this.state.advertisementID
			}),
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			}
		})
			.then(
				(newAdvertisement = this.state.advertisementsJob.rows.filter(
					(row) => row.advertisementID != this.state.advertisementID
				)),
				this.setState({
					advertisementID: 0,
					publishModal: false,
					advertisementsJob: {
						columns: this.state.advertisementsJob.columns,
						rows: newAdvertisement
					}
				}),
				this.notify(event, 'SUCCESS', 'Se ha publicado con éxito')
			)
			.catch((err) => console.error('Ha ocurrido un error inesperado'));
		event.preventDefault();
	}

	/**
	 * The method when called displays a modal with the post information of the comment.
	 */
	publishModal() {
		return (
			<MDBContainer>
				<MDBModal isOpen={this.state.publishModal} toggle={() => this.toggle('publish')}>
					<MDBModalHeader className="modalHeader modalTitlesFont" toggle={() => this.toggle('publish')}>
						Publicar Oferta de empleo
					</MDBModalHeader>
					<MDBModalBody>
						<FormGroup className="text-center">
							<label className="modalTextFont">
								¿Desea publicar esta oferta de empleo?<br />Esta acción es irreversible
							</label>
						</FormGroup>
					</MDBModalBody>
					<MDBModalFooter>
						<MDBBtn
							color="purpleButton"
							className="modalButton purpleButton"
							onClick={() => this.toggle('publish')}
						>
							<span className="modalButtonText">Cancelar</span>
						</MDBBtn>
						<MDBBtn color="greenButton" className="modalButton greenButton" onClick={this.publish}>
							<span className="modalButtonText">Publicar</span>
						</MDBBtn>
					</MDBModalFooter>
				</MDBModal>
			</MDBContainer>
		);
	}

	/**
	* Method that shows the elements found or added to the web page.
	*/
	render() {

		return (
			<div>

				<Container>

					<ToastContainer
						position="top-right"
						autoClose={5000}
						hideProgressBar
						newestOnTop
						closeOnClick
						rtl={false}
						pauseOnVisibilityChange
						draggable
						pauseOnHover
					/>
					<this.publishModal />
					<this.deleteModal />
					<MDBRow>
						<MDBCol className="col-12 col-lg-9 offset-lg-3 mt-3">
							<h3 className="adminTitlesFont">Administración de Ofertas de empleo</h3>
						</MDBCol>
						<MDBCol className="col-12 col-lg-9 offset-lg-3">
							<MDBCard style={{ padding: '20px 20px 20px 20px' }}>
								<MDBDataTable
									data={this.state.advertisementsJob}
									hover
									striped
									bordered
									entriesLabel={'Mostrar'}
									searchLabel={'Buscar'}
									paginationLabel={['Anterior', 'Siguiente']}
									infoLabel={['Mostrando', 'de', 'de un total de', 'registros']}
									entries={5}
									entriesOptions={[5, 10, 15]}
									small
									responsive
									noBottomColumns={true}
									noRecordsFoundLabel="No se han encontrado datos"
									theadTextWhite={true}
									pagesAmount={4}
								/>
							</MDBCard>
						</MDBCol>
					</MDBRow>
				</Container>
			</div>
		);
	}
}

export default Adv_Job;
