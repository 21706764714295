/**
 * @fileoverview  UserHome this component consists of the presentation of the main page of the site.
 *
 * @version 1.0
 * @author Estrella López López <estrella.lopez@ucrso.info>
 *
 * History - Initial Release
 * ----
 * The first version of UserHome was written by  Estrella López L.
 *
 * @version 2.0
 * @author Kevin Álvarez Barrantes <kevin.alvarez@ucrso.info>
 *
 * History - Second Release
 * ----
 * The second version of UserHome was written by  Kevin Álvarez B.
 */

import React, { Component } from "react";

import axios from "axios";
import { baseUrl } from "./baseUrl";
import FullCalendar, {
  interactionSettingsStore,
  interactionSettingsToStore,
} from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { FormGroup } from "reactstrap";
import {
  MDBCarousel,
  MDBCarouselCaption,
  MDBCarouselInner,
  MDBCarouselItem,
  MDBView,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBContainer,
  MDBModalFooter,
  MDBRow,
  MDBCol,
  Container,
  Row,
  Col,
  Card,
  MDBAnimation,
} from "mdbreact";
import Swiper from "swiper/bundle";
import "../../node_modules/swiper/swiper-bundle.min.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "sweetalert2/src/sweetalert2.scss";
import Swal from "sweetalert2";
import LogosCompany2 from "./LogosCompany";

class UserHome extends Component {
  constructor(props) {
    super(props);
    /**
     * image:
     * @type {object}
     * Property that contains the front page image.
     *
     * intro:
     * @type {String}
     * Property that contains the text for the introduction.
     *
     * secondText:
     * @type {String}
     * Property that contains the second text of the page.
     *
     * introID:
     * @type {integer}
     * Property that contains the identification of the introduction.
     *
     * secondID:
     * @type {integer}
     * Property that contains the identification of the second text.
     *
     * link:
     * @type {integer}
     * Property that contains the image link.
     *
     * courseImage:
     * @type {Array}
     * Property that contains the images of course
     *
     * event:
     * @type {Array}
     * Property that stores the list information of event.
     *
     * date:
     * @type {Date}
     * Property that stores the date.
     *
     * eventID:
     * @type {integer}
     * Property that contains the identification of the event
     *
     * events:
     * @type {Array}
     * Property that stores the list of events in the calendar.
     *
     * paymentName:
     * @type {String}
     * Property that contains the text free or Pay.
     *
     * payment:
     * @type {Array}
     * Property that contains the list of payments.
     *
     * eventData:
     * @type {Array}
     * Property that stores the list information of event.
     *
     * * userID:
     * @type {Integer}
     * Property that stores the id of the user.
     *
     * enrollEvent:
     * @type {Array}
     * Property that stores the list of events where users were registered.
     *
     * enroll:
     * @type {Array}
     * Property that stores the list of events where users were registered.
     *
     * statusList:
     * @type {Boolean}
     * Property that stores the status of the course list.
     *
     * amount:
     * @type {Integer}
     * Property that saves the number of hours of the course.
     *
     * status:
     * @type {Integer}
     * Property that saves the count.
     */
    this.state = {
      image: null,
      intro: "",
      secondText: "",
      introID: 0,
      secondID: 0,
      link: "",
      courseImage: [],
      event: [],
      date: new Date(),
      eventID: 0,
      events: [],
      paymentName: "",
      payment: [
        { id: 0, name: "Pago" },
        { id: 1, name: "Gratuito" },
      ],
      eventData: [],
      userID: 0,
      enrollEvent: [],
      enroll: [],
      statusList: false,
      amount: 0,
      status: 0,
    };

    this.getData = this.getData.bind(this);
    this.getEvents = this.getEvents.bind(this);
    this.getImagesCourse = this.getImagesCourse.bind(this);
    this.eventModal = this.eventModal.bind(this);
    this.getEvent = this.getEvent.bind(this);
    this.getMedia = this.getMedia.bind(this);
    this.validateUser = this.validateUser.bind(this);
    this.notify = this.notify.bind(this);
    this.addUserEvent = this.addUserEvent.bind(this);
    this.registraterProcess = this.registraterProcess.bind(this);
    this.validateEnrollUser = this.validateEnrollUser.bind(this);
    this.getAmountParticipants = this.getAmountParticipants.bind(this);
    this.verifyEventStart = this.verifyEventStart.bind(this);
  }

  /**
   * Method that loads the elements to be initially displayed on the website.
   */
  componentDidMount() {
    if (
      sessionStorage.getItem("userType") != 2 &&
      sessionStorage.getItem("userType") != 3
    ) {
      this.getData();
      this.getEvents();
      this.getImagesCourse();
      this.validateEnrollUser();
      this.swiper = new Swiper(".swiper-container", {
        effect: "coverflow",
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: "auto",
        coverflowEffect: {
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        },
        autoplay: {
          delay: 2500,
          disableOnInteraction: false,
        },
        loop: true,
        pagination: {
          el: ".swiper-pagination",
        },
      });
    } else {
      this.props.history.push("/admin");
    }
  }

  /**
   * Method that loads the images of the course through the carousel.
   */
  getMedia() {
    var arrayTemp = [];

    this.state.courseImage.map((elements, key) => {
      arrayTemp.push(
        <MDBCarouselItem className="cardCourse" itemId={key + 1}>
          <MDBView>
            <Card style={{ width: 900, height: 390 }}>
              <div class="form-group" style={{ textAlign: "center" }}>
                <div className="imagenCourse">
                  <img
                    src={baseUrl + elements.link}
                    className="valuesImg"
                    width="75%"
                    class=" img-fluid z-depth-3 rounded"
                    alt="Responsive image"
                  />
                </div>
              </div>
            </Card>
          </MDBView>
          <MDBCarouselCaption></MDBCarouselCaption>
        </MDBCarouselItem>
      );
    });
    return arrayTemp;
  }

  /**
   * Methos that charge the data from the database
   */
  getData() {
    axios.get(baseUrl + "home/getHomeText").then((response) => {
      var textBD = response.data;
      textBD.map((item) => {
        if (item.description === "Principal") {
          this.setState({
            intro: item.textValue,
            introID: item.textID,
          });
        } else {
          this.setState({
            secondText: item.textValue,
            secondID: item.textID,
          });
        }
      });
    });

    axios.get(baseUrl + "home/getHomeMultimedia").then((response) => {
      this.state.link = response.data[0].link;
      this.setState({ link: response.data[0].link });
    });
  }

  /**
   * Method that brings the events to add to the calendar.
   */
  getEvents() {
    axios.get(baseUrl + "event/eventCalendar").then((response) => {
      this.state.events = response.data;
      this.setState({ events: response.data });
    });
  }

  /**
   * Method redirect to the participant list.
   * @param {Integer} id
   */
  getAmountParticipants(id) {
    axios
      .get(baseUrl + "event/getParticipantsAmount", {
        params: {
          eventID: id,
        },
      })
      .then((response) => {
        this.state.amount = response.data[0].amount;
        this.setState({
          amount: response.data[0].amount,
        });
      })
      .catch((err) => console.log("Ha ocurrido un error inesperado"));
  }

  /**
   * Method that brings the events that the user is registered.
   */
  validateEnrollUser() {
    axios
      .get(baseUrl + "event/validateEnrollUser", {
        params: {
          userID: sessionStorage.getItem("user"),
        },
      })
      .then((response) => {
        this.state.enroll = response.data;
        this.setState({
          enroll: response.data,
        });
      });
  }

  /**
   * Method that verifies if the event to be registered by the user exists in the list of user events.
   * @param {Integer} eventID
   */
  verifyEventStart(eventID) {
    if (this.state.enroll == "") {
      this.state.statusList = false;
      this.setState({ statusList: false });
    } else {
      this.state.enroll.map((elements) => {
        if (elements.eventID == eventID) {
          this.state.statusList = true;
          this.setState({ statusList: true });
        } else {
          this.state.status = 1 + this.state.status;
        }
      });

      if (this.state.status == this.state.enroll.length) {
        this.state.statusList = false;
        this.setState({ statusList: false });
      }
    }
  }

  /**
   * Method that verifies that the user is registered before registering.
   * @param {event} event
   */
  registraterProcess(event) {
    if (sessionStorage.getItem("userType")) {
      this.validateUser(event);
    } else {
      this.notify(
        event,
        "WARN",
        "Debe iniciar sesión para incribirse al evento"
      );
    }
  }

  /**
   * Method that adds the user to the enrollment list in the database.
   * @param {event} event
   */
  addUserEvent(event) {
    let modalAction = "event" + "Modal";

    fetch(baseUrl + "event/addEnrollUser", {
      method: "post",
      body: JSON.stringify({
        userID: sessionStorage.getItem("user"),
        eventID: this.state.eventData.eventID,
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then(
      this.setState({
        [modalAction]: !this.state[modalAction],
      }),

      Swal.fire({
        imageUrl:
          "https://mir-s3-cdn-cf.behance.net/project_modules/fs/8d59ff49047525.58a9f80a68097.gif",
        imageWidth: 400,
        imageHeight: 300,
        imageAlt: "Custom image",
        showCloseButton: false,
        showCancelButton: false,
      }),

      this.sendEmail()
    );
    this.validateEnrollUser();
  }

  sendEmail() {
    axios
      .get(baseUrl + "event/getEventInfo", {
        params: {
          eventID: this.state.eventID,
        },
      })
      .then((response) => {
        var event = response.data[0];
        console.log(event);
        fetch(baseUrl + "event/sendInformationEmail", {
          method: "post",
          body: JSON.stringify({
            email: sessionStorage.getItem("email"),
            name: event.name,
            place: event.place,
            link: event.eventLink,
            date: event.date,
            quantity: event.hoursQuantity,
          }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        });
      });
  }

  /**
   * Method that validates if the user is already registered in the event.
   * @param {event} event
   */
  validateUser(event) {
    let modalAction = "event" + "Modal";
    if (this.state.statusList == false) {
      if (this.state.eventData.amount != this.state.amount) {
        this.addUserEvent(event);
      } else {
        this.setState({
          [modalAction]: !this.state[modalAction],
        });
        this.notify(event, "INFO", "No hay cupo disponible");
        return false;
      }
    } else {
      this.setState({
        [modalAction]: !this.state[modalAction],
      });

      this.notify(event, "INFO", "Usted ya se encuentra inscrito en el evento");

      return false;
    }
  }

  /**
   * Method that, depending on the value of the variable, displays the type of message it is, if it is
   * correctly entered, there was an error, an informational message, or a warning message.
   * @param {String} evt
   * @param {String} value
   * @param {String} msj
   */
  notify = (evt, value, msj) => {
    switch (value) {
      case "SUCCESS":
        toast.success(msj);
        break;
      case "ERROR":
        toast.error(msj);
        break;
      case "WARN":
        toast.warn(msj);
        break;
      case "INFO":
        toast.info(msj);
        break;
      default:
        toast.info(msj);
    }
  };

  /**
   *
   * Method that displays a modal when pressed.
   * @param {String} text
   * @param {Integer} id
   * @param {String} name
   * @param {Integer} eventid
   */
  toggle = (text, id, name, eventid) => () => {
    this.state.eventID = eventid;
    this.setState({ eventID: eventid });

    let modalAction = text + "Modal";
    if (id != null) {
      this.setState({
        [modalAction]: !this.state[modalAction],
      });

      this.getEvent(eventid);
      this.getAmountParticipants(eventid);
      this.verifyEventStart(eventid);
      this.validateEnrollUser();
    } else {
      if (this.state[modalAction] === true) {
        this.setState({
          [modalAction]: !this.state[modalAction],
        });
      } else {
        this.setState({
          [modalAction]: !this.state[modalAction],
        });
      }
    }
  };

  /**
   * Method that brings the images of the courses.
   */
  getImagesCourse() {
    axios.get(baseUrl + "courseUser/getCourseMultimedia").then((response) => {
      this.state.courseImage = response.data;
      this.setState({ courseImage: response.data });
    });
  }

  /**
   * Method that brings the information to the event.
   * @param {Integer} eventID
   */
  getEvent(eventID) {
    axios
      .get(baseUrl + "event/getEventUser", {
        params: { eventID: eventID },
      })
      .then((response) => {
        this.state.eventData = response.data[0];
        this.setState({
          eventData: response.data[0],
        });
        this.state.payment.map((pay) => {
          if (pay.id === this.state.eventData.gratuitous) {
            this.setState({
              paymentName: pay.name,
            });
          }
        });
      });
  }

  /**
   * Method that contains an event modal where it shows the user the data to enter an event.
   */
  eventModal() {
    return (
      <MDBContainer>
        <MDBModal isOpen={this.state.eventModal} toggle={this.toggle("event")}>
          <MDBModalHeader
            className="modalHeader modalTitlesFont"
            toggle={this.toggle("event")}
          >
            Evento
          </MDBModalHeader>
          <MDBModalBody>
            <FormGroup className="text-center">
              <label className="modalTextFont">
                {this.state.eventData.eventName}
              </label>
            </FormGroup>

            <Row className="mt-2 col-sm-12 ">
              <MDBCol className=" mt-2  col-2 offset-lg-1 col-md-2">
                <i class="fas fa-align-justify colorIcon"></i>
              </MDBCol>
              <MDBCol className=" mt-2 col-10 offset-lg-0 col-md-9">
                <label className="modalTextFont">
                  {this.state.eventData.description}
                </label>
              </MDBCol>
            </Row>

            <Row className="mt-2 col-sm-12 ">
              <MDBCol className="  col-2 offset-lg-1 col-md-1">
                <i class="fas fa-map-marker-alt colorIcon"></i>
              </MDBCol>
              <MDBCol className="   col-10 offset-lg-1 col-md-6">
                <label className="modalTextFont">
                  {this.state.eventData.place}
                </label>
              </MDBCol>
            </Row>

            <Row className="mt-2 col-sm-12 ">
              <MDBCol className="  col-2 offset-lg-1 col-md-1">
                <i class="far fa-clock  colorIcon "></i>
              </MDBCol>
              <MDBCol className="   col-10 offset-lg-1 col-md-6">
                <label className="modalTextFont">
                  {this.state.eventData.date}
                </label>
              </MDBCol>
            </Row>

            <Row className="mt-2 col-sm-12 ">
              <MDBCol className="  col-2 offset-lg-1 col-md-1">
                <i class="far fa-money-bill-alt  colorIcon "></i>
              </MDBCol>
              <MDBCol className="   col-10 offset-lg-1 col-md-6">
                <label className="modalTextFont">
                  {this.state.paymentName}
                </label>
              </MDBCol>
            </Row>

            <Row className="mt-2 col-sm-12 ">
              <MDBCol className="  col-2 offset-lg-1 col-md-1">
                <i class="fas fa-users colorIcon"></i>
              </MDBCol>
              <MDBCol className="   col-10 offset-lg-1 col-md-6">
                <label className="modalTextFont">
                  {this.state.eventData.quota}
                </label>
              </MDBCol>
            </Row>
            <Row className="mt-2 col-sm-12 ">
              <MDBCol className="  col-2 offset-lg-1 col-md-1">
                <i class="fas fa-language colorIcon"></i>
              </MDBCol>
              <MDBCol className="   col-10 offset-lg-1 col-md-6">
                <label className="modalTextFont">
                  {this.state.eventData.language}
                </label>
              </MDBCol>
            </Row>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBCol className="  col-10 offset-lg-2 col-md-12">
              <a onClick={this.registraterProcess}>
                <h5 className="linkRegister ">Inscribirse al evento</h5>
              </a>
            </MDBCol>
          </MDBModalFooter>
        </MDBModal>
      </MDBContainer>
    );
  }

  /**
   * Method that shows the elements found or added to the web page.
   */

  render() {
    return (
      <div>
        <div class="view">
          <img
            src={baseUrl + this.state.link}
            className="responsive-img"
            alt=""
            id="bannerHome"
          />
          <div class="mask flex-center " id="maskIntro">
            <Col xs={2} md={6} lg={10}></Col>
          </div>
        </div>

        <Container className="mt-5 ">
          <this.eventModal />

          <Row
            className="mt-3 offset-lg-0 col-sm-12 userTextFont "
            style={{ textAlign: "justify" }}
          >
            <MDBCol className="col-lg-11 offset-lg-0 mt-4 col-12 ">
              <br />

              <h2 style={{ textAlign: "center" }}>
                Nuestro lema es Impactar Positivamente
              </h2>

              <br />
              <h3>¿Por qué?</h3>
              <br />
              <MDBContainer>
                <MDBRow className="offset-lg-1">
                  <MDBCol>
                    <MDBAnimation type="fadeIn" delay="2s">
                      <Card style={{ width: 250, height: 220 }}>
                        <div class="form-group" style={{ textAlign: "center" }}>
                          <div>
                            <p
                              style={{
                                fontFamily: "Helvetica",
                                textAlign: "center",
                                marginTop: 30,
                                marginLeft: 5,
                                marginBottom: 5,
                                marginRight: 5,
                              }}
                            >
                              Porque brindamos conocimientos, herramientas de
                              formación y facilitación según el contexto del
                              estudiante.
                            </p>
                          </div>
                        </div>
                      </Card>
                    </MDBAnimation>
                  </MDBCol>
                  <MDBCol>
                    <MDBAnimation type="fadeIn" delay="3s">
                      <Card style={{ width: 250, height: 220 }}>
                        <div class="form-group" style={{ textAlign: "center" }}>
                          <div>
                            <p
                              style={{
                                fontFamily: "Helvetica",
                                textAlign: "center",
                                marginTop: 30,
                                marginLeft: 5,
                                marginBottom: 5,
                                marginRight: 5,
                              }}
                            >
                              Porque el objetivo es capacitarle para que pueda
                              acceder a un crecimiento integral (Profesional |
                              Personal).
                            </p>
                          </div>
                        </div>
                      </Card>
                    </MDBAnimation>
                  </MDBCol>
                  <MDBCol>
                    <MDBAnimation type="fadeIn" delay="4s">
                      <Card style={{ width: 250, height: 220 }}>
                        <div class="form-group" style={{ textAlign: "center" }}>
                          <div>
                            <p
                              style={{
                                fontFamily: "Helvetica",
                                textAlign: "center",
                                marginTop: 30,
                                marginLeft: 5,
                                marginBottom: 5,
                                marginRight: 5,
                              }}
                            >
                              Porque para cada solución contamos con diferentes
                              profesionales para brindarle resultados a su
                              medida.
                            </p>
                          </div>
                        </div>
                      </Card>
                    </MDBAnimation>
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
              <br />
            </MDBCol>
          </Row>
          <br />

          <MDBCol
            className="col-lg-8 offset-lg-2 mt-4 sm-12"
            style={{ textAlign: "center" }}
          >
            <strong
              style={{
                textAlign: "center",
                fontFamily: "Helvetica",
              }}
            >
              Somos la respuesta en el mercado latinoamericano entre la
              exponencial demanda de servicios de atención personalizada y
              modalidad virtual
            </strong>
          </MDBCol>
          <br />
          <p
            style={{
              textAlign: "center",
              fontFamily: "Helvetica",
              color: "orange",
            }} /*className="userTextFont"*/
          >
            {this.state.intro}
          </p>
          <Row
            className="mt-4 offset-lg-0 col-sm-12 userTextFont"
            style={{ textAlign: "justify" }}
          >
            <MDBCol className="col-lg-8 offset-lg-2 mt-4 sm-12">
              <h4>Te ofrecemos:</h4>
              <br />
              <MDBContainer>
                <MDBCarousel
                  activeItem={1}
                  length={this.state.courseImage.length}
                  showControls={true}
                  showIndicators={true}
                  responsive={true}
                  className="z-depth-1"
                >
                  <MDBCarouselInner>{this.getMedia()}</MDBCarouselInner>
                </MDBCarousel>
              </MDBContainer>
            </MDBCol>
          </Row>

          <Row className="mt-5">
            <MDBCol style={{ textAlign: "center" }}>
              <p
                style={{
                  textAlign: "center",
                  fontFamily: "Helvetica",
                }} /*className="userTextFont"*/
              >
                {this.state.secondText}
              </p>
            </MDBCol>

            {/* <Col xs={2} md={6} lg={5}>
              <Card className="mt-4 offset-lg-1">
                <div>
                  <div>
                    <FullCalendar
                      defaultView="dayGridMonth"
                      plugins={[dayGridPlugin, interactionPlugin]}
                      locale="es"
                      events={this.state.events}
                      defaultAllDay={true}
                      eventMouseEnter={(info) => {
                        info.jsEvent.preventDefault();

                        info.el.onclick = this.toggle(
                          "event",
                          0,
                          "ee",
                          info.event.id
                        );

                        // info.jsEvent.onClick = () => this.toggle("event", 0, "ee", info.event.url);

                        //   {}
                        // change the border color just for fun
                        info.el.style.borderColor = "red";
                      }}
                    />
                  </div>
                </div>
              </Card>
              <br />
            </Col> */}
          </Row>
        </Container>
      </div>
    );
  }
}
export default UserHome;
