/**
 * @fileoverview Header, this is the user navbar
 *
 * @version 1.0
 *
 * @author Ester Molina R <maria.molina@ucrso.info>
 * History
 * v1.0 - Initial Realease
 * ----
 * The first version of Header was written by Ester Molina R.
 */
import React, { Component } from "react";
import {
  MDBNavbar,
  MDBNavbarNav,
  MDBNavbarBrand,
  MDBNavItem,
  MDBNavbarToggler,
  MDBCollapse,
  MDBIcon,
  MDBContainer,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBAnimation,
  MDBBtn,
} from "mdbreact";
import {
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
} from "mdbreact";
import { useTranslate } from "react-translate";
import logo from "../images/logo.png";
import { NavLink, withRouter } from "react-router-dom";
import { FormGroup } from "reactstrap";
import axios from "axios";
import { baseUrl } from "./baseUrl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import validations from "./Validations";
import md5 from "md5";

class Header extends Component {
  constructor(props) {
    super(props);
    /**
     * validations:
     * @type {object}
     * Property that contains methods to validate with regular expressions
     *
     * isOpen:
     * @type {boolean}
     * Property that contains if the navbar is open or not
     *
     * show:
     * @type {boolean}
     * Property that contains if the logIn modal is open or not
     *
     * email:
     * @type {String}
     * Property that contains the email of the log In
     *
     * password:
     * @type {String}
     * Property that contains the password of the log In
     *
     * visible:
     * @type {boolean}
     * Property that contains if the dropdown is visible or not
     *
     * name:
     * @type {String}
     * Property that contains the name the user
     */
    this.state = {
      validations: new validations(),
      isOpen: false,
      show: false,
      email: "",
      password: "",
      visible: false,
      name: "",
    };

    this.toggleCollapse = this.toggleCollapse.bind(this);
    this.toggle = this.toggle.bind(this);
    this.login = this.login.bind(this);
    this.lang = this.lang.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.verifyLogIn = this.verifyLogIn.bind(this);
    this.validateData = this.validateData.bind(this);
    this.collapse = this.collapse.bind(this);
  }

  componentDidMount() {
    var auxUserID = sessionStorage.getItem("user");
    console.log("ID DEL USUARIO:" + auxUserID);
    if (auxUserID != null) {
      this.setState({
        visible: true,
        name: sessionStorage.getItem("name"),
      });
    } else {
      this.setState({
        visible: false,
      });
    }
  }

  /**
   * Method that show the navbar
   */
  toggleCollapse() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  /**
   * Method that close the navbar when clicked an option
   */
  collapse() {
    if (window.innerWidth < 1000) {
      this.setState({ isOpen: false });
    }
  }

  /**
   * Method that show the log in modal
   */
  toggle() {
    this.setState({ show: !this.state.show });
    this.collapse();
  }

  /**
   * Method that change the state
   * @param {event} event
   */
  handleInputChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  /**
   * Method that close the session
   */
  close = () => {
    sessionStorage.removeItem("user");
    sessionStorage.removeItem("name");
    sessionStorage.removeItem("email");
    sessionStorage.removeItem("userType");
    this.props.history.push("/");
    this.setState({
      visible: false,
      name: "",
      userID: 0,
    });
    this.collapse();
  };

  /**
   * Method that redirect to the perfil configuration
   */
  perfilConfiguration = () => {
    this.props.history.push("/UserPerfilConfiguration");
    this.collapse();
  };

  /**
   * Method that redirect to the certifications
   */
  certifications = () => {
    this.props.history.push("/Certifications");
    this.collapse();
  };

  /**
   * Method that validate the data of log in
   * @param {event} event
   */
  validateData(event) {
    if (this.state.email.length === 0) {
      this.notify(event, "ERROR", "Debe escribir el correo electrónico");
    } else if (this.state.password.length === 0) {
      this.notify(event, "ERROR", "Debe escribir la contraseña");
    } else if (!this.state.validations.validateEmailField(this.state.email)) {
      this.notify(
        event,
        "ERROR",
        "Ingrese un formato de correo electrónico válido"
      );
    } else {
      this.verifyLogIn(event);
    }
  }

  /**
   * Method that change the state
   * @param {event} event
   */
  handleInputChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  /**
   * Method that perform validate data action when an ENTER is clicked
   * @param {event} e
   */
  onKeyEvent = (e) => {
    if (e.key == "Enter") {
      this.validateData(e);
    }
  };

  /**
   * Method that verify if the log in is correct
   * @param {event} event
   */
  verifyLogIn(event) {
    axios
      .get(baseUrl + "logIn/getLogIn", {
        params: {
          email: this.state.email,
        },
      })
      .then((response) => {
        var user = response.data[0];
        if (user !== undefined) {
          var passwordBD = user.password;
          if (md5(this.state.password) === passwordBD) {
            sessionStorage.setItem("user", user.userID);
            sessionStorage.setItem("name", user.name);
            sessionStorage.setItem("email", this.state.email);
            sessionStorage.setItem("userType", user.userTypeID);
            this.setState({
              name: user.name,
              visible: true,
              email: "",
              password: "",
            });
            if (user.userTypeID === 2 || user.userTypeID === 3) {
              this.toggle();
              window.location.href = "/admin";
            } else {
              this.toggle();
            }
          } else {
            this.notify(event, "ERROR", "Correo o contraseña incorrectos");
          }
        } else {
          this.notify(event, "ERROR", "Correo o contraseña incorrectos");
        }
      });
  }

  /**
   * Method that show notifications to the user
   * @param {event} evt
   * @param {String} value
   * @param {String} msj
   */
  notify = (evt, value, msj) => {
    switch (value) {
      case "SUCCESS":
        toast.success(msj);
        break;
      case "ERROR":
        toast.error(msj);
        break;
      case "WARN":
        toast.warn(msj);
        break;
      case "INFO":
        toast.info(msj);
        break;
      default:
        toast.info(msj);
    }
  };

  /**
   * Method that render the HTML
   */
  render() {
    return (
      <div>
        <this.login />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
        <MDBNavbar className="grayColor bg-custom-2" dark expand="lg ">
          <NavLink to={"/"} style={{ textDecoration: "none" }}>
            <MDBNavbarBrand>
              <img src={logo} alt="logo" className="logoSize" />
            </MDBNavbarBrand>
          </NavLink>
          <MDBNavbarToggler
            onClick={this.toggleCollapse}
            className="toggleButton"
          />
          <MDBCollapse id="navbarCollapse3" isOpen={this.state.isOpen} navbar>
            <this.lang />
          </MDBCollapse>
        </MDBNavbar>
      </div>
    );
  }

  lang() {
    let t = useTranslate("navbar");
    var auxName = this.state.name;
    return (
      <MDBNavbarNav className="navbarFont" right>
        <MDBNavItem className="navItem navVisible">
          <NavLink
            id="navColorFont"
            to={"/"}
            name="Home"
            style={{ textDecoration: "none" }}
            onClick={this.collapse}
          >
            Inicio
          </NavLink>
        </MDBNavItem>
        <MDBNavItem className="navItem navVisible">
          <NavLink
            id="navColorFont"
            to={"/AboutUs"}
            style={{ textDecoration: "none" }}
            name="About Us"
            onClick={this.collapse}
          >
            Nosotros
          </NavLink>
        </MDBNavItem>
        <MDBNavItem className="navItem navVisible">
          <NavLink
            id="navColorFont"
            to={"/CourseUser"}
            style={{ textDecoration: "none" }}
            name="Courses"
            onClick={this.collapse}
          >
            Programas
          </NavLink>
        </MDBNavItem>

        <MDBNavItem className="navItem navVisible">
          <NavLink
            id="navColorFont"
            to={"/EventUser"}
            style={{ textDecoration: "none" }}
            name="Events"
            onClick={this.collapse}
          >
            Eventos
          </NavLink>
        </MDBNavItem>

        <MDBNavItem className="navItem navVisible">
          <NavLink
            id="navColorFont"
            to={"/ArticleUser"}
            style={{ textDecoration: "none" }}
            name="Articles"
            onClick={this.collapse}
          >
            Artículos
          </NavLink>
        </MDBNavItem>
        <MDBNavItem className="navItem navVisible">
          <NavLink
            id="navColorFont"
            to={"/UserCommunity"}
            style={{ textDecoration: "none" }}
            name="Comunity"
            onClick={this.collapse}
          >
            SIAgile
          </NavLink>
        </MDBNavItem>
        {this.state.visible ? (
          <MDBNavItem to="#" className="d-lg-block">
            <MDBDropdown className="dropUserPosition">
              <MDBDropdownToggle
                caret
                color="navbarBtn text-white"
                className="navbarBtn"
              >
                {" "}
                <span className="modalButtonText">{auxName}</span>
              </MDBDropdownToggle>
              <MDBDropdownMenu basic className="DropPosition">
                <MDBDropdownItem onClick={this.perfilConfiguration}>
                  Configuración de Perfil
                </MDBDropdownItem>
                <MDBDropdownItem onClick={this.certifications}>
                  Certificaciones
                </MDBDropdownItem>
                <MDBDropdownItem divider />
                <MDBDropdownItem onClick={this.close}>
                  Cerrar Sesión
                </MDBDropdownItem>
              </MDBDropdownMenu>
            </MDBDropdown>
          </MDBNavItem>
        ) : (
          <MDBNavItem className="navItemBtn">
            <MDBBtn
              color="navbarBtn"
              className="navbarBtn"
              onClick={this.toggle}
            >
              <MDBIcon className="menu-bars" icon="user" />
            </MDBBtn>
          </MDBNavItem>
        )}
      </MDBNavbarNav>
    );
  }

  login() {
    return (
      <MDBContainer>
        <MDBModal isOpen={this.state.show} toggle={this.toggle}>
          <MDBModalHeader className="peach-gradient" toggle={this.toggle}>
            <label className=" modalTitlesFont">Inicio de Sesión</label>
          </MDBModalHeader>
          <MDBModalBody>
            <FormGroup>
              <label className="modalTextFont">
                Correo electrónico <font color="red">*</font>
              </label>
              <input
                className="form-control modalTextFont"
                type="text"
                name="email"
                value={this.state.email}
                required
                onChange={this.handleInputChange}
                onKeyPress={this.onKeyEvent}
              />
            </FormGroup>
            <FormGroup>
              <label className="modalTextFont">
                Contraseña <font color="red">*</font>
              </label>
              <input
                className="form-control modalTextFont"
                type="password"
                name="password"
                value={this.state.password}
                onChange={this.handleInputChange}
                onKeyPress={this.onKeyEvent}
                required
              />
            </FormGroup>
            <FormGroup>
              <a href="/PasswordRecovery">¿Has olvidado tu contraseña?</a>
            </FormGroup>
            <FormGroup>
              <a href="/UserRegister">Registrarse</a>
            </FormGroup>
          </MDBModalBody>
          <MDBAnimation type="zoomIn" style={{ textAlign: "center" }}>
            <img src={logo} alt="logo" className="logoSizeAdmin" />
          </MDBAnimation>
          <MDBModalFooter>
            <MDBBtn
              color="blueButton"
              className="modalButton blueButton"
              onClick={this.validateData}
            >
              <span className="modalButtonText">Iniciar</span>
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </MDBContainer>
    );
  }
}

export default withRouter(Header);
