/**
 * @fileoverview FooterPage, this component allow to create a user footer.
 *
 * @version 1.0
 *
 * @author Anthony Vargas Méndez <anthony.vargas@ucrso.info>
 * History
 * v1.0 - Initial Release
 * ----
 * The first version of FooterPage was writen by Anthony Vargas Méndez
 */
import React from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBFooter,
  MDBBox,
} from "mdbreact";
import logo from "../images/logo.png";
import LogosCompany from "./LogosCompany";
import LogosCarrusel from "./LogosCarrusel";
import { Link } from "react-router-dom";

/**
 * Method that render the view of the footer in the user page
 */
function FooterPage() {
  return (
    <>
      <MDBFooter className="footer">
        <MDBContainer fluid>
          <LogosCarrusel />
          {/* <MDBRow>
            <MDBCol sm="12" className="columLogos">
              <LogosCompany className="logos" />
            </MDBCol>
          </MDBRow> */}
        </MDBContainer>
        <br></br>
        <MDBContainer className="grayColor bg-custom-2" fluid>
          <MDBRow>
            <MDBCol sm="12" md="6" lg="4" className="columFooter">
              <MDBBox>
                <h5 className="titlesFooterFont">Contáctenos</h5>
                <MDBRow className="bottomFooterFontColor contactFooterFont ">
                  <MDBIcon
                    icon="far fa-envelope"
                    className="itemsFooterColor pr-3"
                  />
                  info@enconcepto.com
                </MDBRow>
                <MDBRow className="bottomFooterFontColor contactFooterFont ">
                  <MDBIcon
                    icon="fas fa-phone-square-alt"
                    className="itemsFooterColor pr-3"
                  />
                  (506) 2230 4367
                </MDBRow>
                <MDBRow className="bottomFooterFontColor contactFooterFont ">
                  <MDBIcon
                    icon="fas fa-mobile-alt"
                    className="itemsFooterColor pr-3 mr-1"
                  />
                  (506) 8894 2347
                </MDBRow>
              </MDBBox>
            </MDBCol>
            <MDBCol sm="12" md="6" lg="4" className="columFooter">
              <MDBBox>
                <Link to={"/"}>
                  <img src={logo} alt="logo" className="logoSizeFooter" />
                </Link>
              </MDBBox>
            </MDBCol>
            <MDBCol sm="12" md="6" lg="4" className="columFooter">
              <MDBBox
                justifyContent="flex-start"
                alignItems="center"
                display="flex"
                flex="column"
                mb="3"
              >
                <h5 className="titlesFooterFont">Redes Sociales</h5>
                <MDBRow>
                  <a
                    onClick={() =>
                      window.open(
                        "https://www.facebook.com/EnConcepto",
                        "_blank"
                      )
                    }
                  >
                    <MDBIcon
                      fab
                      icon="fab fa-facebook-square"
                      size="2x"
                      className="facebookColor pr-3"
                    />
                  </a>
                  <a
                    onClick={() =>
                      window.open(
                        "https://www.instagram.com/en_concepto/?hl=es-la",
                        "_blank"
                      )
                    }
                  >
                    <MDBIcon
                      fab
                      icon="fab fa-instagram"
                      size="2x"
                      className="instagramColor pr-3"
                    />
                  </a>
                  <a
                    onClick={() =>
                      window.open(
                        "https://www.linkedin.com/in/enconcepto-b7b6701a2/",
                        "_blank"
                      )
                    }
                  >
                    <MDBIcon
                      fab
                      icon="fab fa-linkedin"
                      size="2x"
                      className="linkedinColor pr-3"
                    />
                  </a>
                </MDBRow>
              </MDBBox>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
        <div className="footer-copyright text-center py-3 footerColorBottom ">
          <MDBContainer
            className="footer-copyright bottomFooterFontColor"
            fluid
          >
            &copy; {new Date().getFullYear()} Copyright: EnConcepto
          </MDBContainer>
        </div>
      </MDBFooter>
    </>
  );
}

export default FooterPage;
