/**
 * @fileoverview  Adv_Job_Up it consists of the edition of the job announcement.
 *
 * @version 1.0
 * @author Estrella López López <estrella.lopez@ucrso.info>
 * 
 * History - Initial Release
 * ----
 * The first version of Adv_Job_Up was written by  Estrella López López.
 */

import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardTitle, MDBBtn, MDBCardFooter, Row } from 'mdbreact';
import { FormGroup } from 'reactstrap';
import Select from 'react-select';
import { baseUrl } from './baseUrl';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import validations from './Validations';
import { Link } from 'react-router-dom';


class Adv_Job_Up extends Component {
    constructor(props) {
        super(props);
        /**
         * validations: 
         * @type {object}
         * Property that has methods for validate with regular expressions.
         * 
         * advertisementID: 
         * @type {Integer}
         * Property that stores the id of the job.
         * 
         * jobName:
         * @type {Sring}
         * Property that stores the name of the job.
         * 
         * jobPlace:
         * @type {String}
         * Property that stores the place of the job.
         * 
         * jobTitle:
         * @type {String}
         * Property that stores the job title.
         * 
         * jobLanguaje:
         * @type {String}
         * Property that stores the languaje of the job.
         * 
         * jobLanguajeID:
         * @type {Sring}
         * Property that stores the languajeID of the job.
         * 
         * jobAmount:
         * @type {String}
         * Property that stores the amount of money of the job.
         * 
         * jobContact:
         * @type {String}
         * Property that stores the contact of the job.
         * 
         * coins: 
         * @type {Array}
         * Property that stores a list of currency types.
         * 
         * coinID: 
         * @type {Integer}
         * Property that stores the id of coin
         * 
         * coinName: 
         * @type {String}
         * Property that stores the type of coin
         * 
         * languages:
         * @type {Array}
         * Property that stores the list of languages ​​that exist in the database.
         * 
         * description: 
         * @type {String}
         * Property that stores the description of the job
         */

        this.state = {
            validations: new validations(),
            advertisementID: 0,
            jobName: "",
            jobPlace: "",
            jobTitle: "",
            jobLanguaje: 1,
            jobLanguajeID: 0,
            jobAmount: 0,
            jobContact: "",
            coins: [{}],
            coinID: 0,
            coinName: "Colones",
            languages: [{}],
            description: ""

        }

        this.back = this.back.bind(this);
        this.getJob = this.getJob.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.emptyJob = this.emptyJob.bind(this);
        this.validateDataJob = this.validateDataJob.bind(this);
        this.notify = this.notify.bind(this);
        this.valueDescription = this.valueDescription.bind(this);
        this.valueNameJob = this.valueNameJob.bind(this);
        this.dataSelect = this.dataSelect.bind(this);
        this.languageSelect = this.languageSelect.bind(this);
        this.getCoins = this.getCoins.bind(this);
        this.validateDataJob = this.validateDataJob.bind(this);
        this.valuePlaceJob = this.valuePlaceJob.bind(this);
        this.valueTitleJob = this.valueTitleJob.bind(this);
        this.valueContactJob = this.valueContactJob.bind(this);
        this.coinSelect = this.coinSelect.bind(this);
        this.valueAmountJob = this.valueAmountJob.bind(this);
        this.showSelect = this.showSelect.bind(this);
        this.showData = this.showData.bind(this);


    }

    /**
     * Method that loads a list of currency types found in the database.
     */
    getCoins() {
        axios.get(baseUrl + 'quotation/getCoins')
            .then(response => {
                var coins = response.data;
                this.setState({
                    coins
                })
            })
            .catch(err => console.error("Ha ocurrido un error inesperado"));
    }

   
    /**
     * Method that assigns the chosen value of the selection to the variable.
     * @param {event} event 
     */
    coinSelect(event) {
        this.state.coinName = event.label;
        this.setState({
            coinID: parseInt(event.value),
            coinName: event.label
        });
    }

    /**
     * Method that loads the list of languages ​​found in the database.
     *  @param {event} event 
     */
    dataSelect(event) {
        var languages = [];

        axios.get(baseUrl + 'event/getLanguage')
            .then(response => {
                languages = response.data;
                this.setState({
                    languages
                });
            })
            .catch(err => console.log("Ha ocurrido un error inesperado"));
    }

    /**
     * Method that assigns the chosen value of the selection to the variable.
     *  @param {event} event 
     */
    languageSelect(event) {
        this.state.jobLanguaje = parseInt(event.value);
        this.setState({ jobLanguaje: event.value });
        this.setState({
            jobLanguaje: event.value
        });

    }

     /**
    * Method that assigns the value entered to the variable.
    * @param {event} event 
    */
    valueContactJob(event) {
        this.state.jobContact = event.target.value;
        this.setState({ jobContact: event.target.value });
    }

 /**
    * Method that assigns the value entered to the variable.
    * @param {event} event 
    */
    valueTitleJob(event) {
        this.state.jobTitle = event.target.value;
        this.setState({ jobTitle: event.target.value });
    }

     /**
    * Method that assigns the value entered to the variable.
    * @param {event} event 
    */
    valueAmountJob(event) {
        this.state.jobAmount = event.target.value;
        this.setState({ jobAmount: event.target.value });
    }

     /**
    * Method that assigns the value entered to the variable.
    * @param {event} event 
    */
    valuePlaceJob(event) {
        this.state.jobPlace = event.target.value;
        this.setState({ jobPlace: event.target.value });
    }

     /**
    * Method that assigns the value entered to the variable.
    * @param {event} event 
    */
    valueNameJob(event) {
        this.state.jobName = event.target.value;
        this.setState({ jobName: event.target.value });
    }

     /**
    * Method that assigns the value entered to the variable.
    * @param {event} event 
    */
    valueDescription(event) {
        this.state.description = event.target.value;
        this.setState({ description: event.target.value });
    }


    /**
     * 
     * Method that, depending on the value of the variable, displays the type of message it is, if it is correctly entered, there was an 
     * error, an informational message, or a warning message.
     * @param {String} evt
     * @param {String} value 
     * @param {String} msj 
     */
    notify = (evt, value, msj) => {
        switch (value) {
            case 'SUCCESS':
                toast.success(msj);
                break;
            case 'ERROR':
                toast.error(msj);
                break;
            case 'WARN':
                toast.warn(msj);
                break;
            case 'INFO':
                toast.info(msj);
                break;
            default:
                toast.info(msj);
        }
    };

    /**
     * Method that loads the elements to be initially displayed on the website.
     */
    componentDidMount() {

        if (sessionStorage.getItem('userType') == 2) {

            this.state.advertisementID = this.props.match.params.ID;
            this.setState({ advertisementID: this.props.match.params.ID });

            this.dataSelect();
            this.getCoins();
            this.showData(true);


            this.state.title = 'Editar Oferta de empleo';
            this.setState({ title: 'Editar Oferta de empleo' });

            this.getJob(this.state.advertisementID)

        } else if (sessionStorage.getItem('userType') == 3) {
            this.props.history.push('/admin');

        } else {
            this.props.history.push('/');
        }

    }

    /**
      * Method that validates the data of job entered by the user, the way it should be written.
      * @param {event} event 
      */
    validateDataJob(event) {
        if (this.emptyJob(event)) {
            if (!this.state.validations.validateTextWithNumberField(this.state.jobName)) {
                this.notify(event, 'ERROR', 'El nombre de la empresa no debe contener caracteres especiales');
            } else if (!this.state.validations.validateTextWithNumberField(this.state.jobPlace)) {
                this.notify(event, 'ERROR', 'El lugar no debe contener caracteres especiales');
            } else if (this.state.description.length != 0 && !this.state.validations.validateTextWithNumberField(this.state.description)) {
                this.notify(event, 'ERROR', 'La descripción  no debe contener caracteres especiales');
            } else if (!this.state.validations.validateTextWithNumberField(this.state.jobContact)) {
                this.notify(event, 'ERROR', 'El contacto no debe contener caracteres especiales');
            } else if (!this.state.validations.validateNumericField(this.state.jobAmount)) {
                this.notify(event, 'ERROR', 'El monto no debe contener caracteres especiales');
            } else if (!this.state.validations.validateTextWithNumberField(this.state.jobTitle)) {
                this.notify(event, 'ERROR', 'El puesto no debe contener caracteres especiales');
            } else {
                this.handleSubmit(event);
            }

        }
    }

    /**
     * Method that validates that the required fields are not empty.
     * @param {event} event 
     */
    emptyJob(event) {
        if (this.state.jobName.length === 0) {
            this.notify(event, 'ERROR', 'El nombre de la empresa no puede estar vacío');
            return false;
        } else if (this.state.jobPlace.length === 0) {
            this.notify(event, 'ERROR', 'El lugar no puede estar vacío');
            return false;
        } else if (this.state.jobTitle.length === 0) {
            this.notify(event, 'ERROR', 'El puesto no puede estar vacío');
            return false;
        } else if (this.state.jobLanguaje.length === 0) {
            this.notify(event, 'ERROR', 'El lenguaje no puede  estar vacío');
            return false;
        } else if (this.state.jobContact.length === 0) {
            this.notify(event, 'ERROR', 'El contacto no puede  estar vacío');
            return false;
        } else if (this.state.description.length === 0) {
            this.notify(event, 'ERROR', 'La descripción no puede  estar vacía');
            return false;
        } else {
            return true;
        }
    }

    /**
     * Method that is responsible for going to the database and editing the selected job.
     * @param {event} event 
     */
    handleSubmit(event) {

        fetch(baseUrl + 'adv_Event/editAdvJob', {
            method: "put",
            body: JSON.stringify({
                name: this.state.jobName,
                place: this.state.jobPlace,
                jobTitle: this.state.jobTitle,
                languageID: this.state.jobLanguajeID,
                amount: this.state.jobAmount,
                contact: this.state.jobContact,
                coinID: this.state.coinID,
                description: this.state.description,
                advertisementID: this.state.advertisementID

            }),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            }

        }).then(this.notify(event, 'SUCCESS', 'Se ha editado correctamente'))

            .catch(() => this.notify(event, 'WARN', 'No se ha podido editar'))

        this.props.history.push('/Adv_Job');

    }



    /**
     * Method that receives the ad ID and loads the relevant information this ad is in the database.
     * @param {Integer} advertisementID 
     */
    getJob(advertisementID) {
        axios.get(baseUrl + 'adv_Event/getAdvJobUser', {

            params: { advertisementID: advertisementID }
        }).then(response => {
            this.state.jobName = response.data[0].name;
            this.setState({ jobName: response.data[0].name });
            this.state.jobPlace = response.data[0].place;
            this.setState({ jobPlace: response.data[0].place });
            this.state.jobTitle = response.data[0].jobTitle;
            this.setState({ jobTitle: response.data[0].jobTitle });
            this.state.jobLanguaje = response.data[0].language;
            this.setState({ jobLanguaje: response.data[0].language });
            this.state.jobLanguajeID = response.data[0].languageID;
            this.setState({ jobLanguajeID: response.data[0].languageID });
            this.state.jobAmount = response.data[0].amount;
            this.setState({ jobAmount: response.data[0].amount });
            this.state.jobContact = response.data[0].contact;
            this.setState({ jobContact: response.data[0].contact });
            this.state.coinName = response.data[0].money;
            this.setState({ coinName: response.data[0].money });
            this.state.coinID = response.data[0].coinID;
            this.setState({ coinID: response.data[0].coinID });
            this.state.description = response.data[0].description;
            this.setState({ description: response.data[0].description });
        });

    }


    /**
     * Method that assigns the value chosen by users to variables.
     * @param {event} event 
     */
    handleChange(event) {

        this.setState({
            [event.target.name]: event.target.value
        });

    }

    /**
    * Method that returns to the previous page
    */
    back() {
        this.props.history.push(`/Adv_Job`);
    }

    /**
     * Method by which I hide elements when the data is loaded for the first time and when it is 
     * hidden it makes them visible.
     * @param {Boolean} show 
     */
    showData(show) {
        if (show) {

            document.getElementById("languaje-addon").style.display = "block";
            document.getElementById("showLanguaje").style.display = "block";
            document.getElementById("language").style.display = "none";
            document.getElementById("coin-addon").style.display = "block";
            document.getElementById("showCoin").style.display = "block";
            document.getElementById("coinSelect").style.display = "none";

        } else {

            document.getElementById("languaje-addon").style.display = "none";
            document.getElementById("showLanguaje").style.display = "none";
            document.getElementById("language").style.display = "block";
            document.getElementById("coin-addon").style.display = "none";
            document.getElementById("showCoin").style.display = "none";
            document.getElementById("coinSelect").style.display = "block";
        }
    }

    /**
     * Method by which I hide elements when the data is loaded for the first time and when it is 
     * hidden it makes them visible.
     */
    showSelect(event) {
        switch (event.target.name) {

            case 'showLanguaje':
                document.getElementById("languaje-addon").style.display = "none";
                document.getElementById("showLanguaje").style.display = "none";
                document.getElementById("coinSelect").style.display = "block";
                break;
            case 'showCoin':
                document.getElementById("coin-addon").style.display = "none";
                document.getElementById("showCoin").style.display = "none";
                document.getElementById("coinSelect").style.display = "block";
                break;
            default:
                break;
        }
    }

    /**
    * Method that shows the elements found or added to the web page.
    */
    render() {
        const languages = this.state.languages.map((language) => {
            return {
                label: language.name, value: language.languageID
            }
        })
        const coins = this.state.coins.map(function (coin) {
            return {
                label: coin.coinName, value: coin.coinID
            };
        })
        return (
            <MDBContainer>

                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnVisibilityChange
                    draggable
                    pauseOnHover
                />

                <MDBRow>
                    <MDBCol className="col-12 col-lg-9 offset-lg-3 mt-3">
                        <h3 className="adminTitlesFont">{this.state.title}</h3>
                    </MDBCol>
                    <MDBCol className="col-12 col-lg-9 offset-lg-3 mb-3">
                        <MDBCard style={{ padding: '20px 20px 20px 20px' }}>
                            <MDBCardTitle className="cardHeader">Datos de la oferta de empleo</MDBCardTitle>
                            <Row className="mt-2 col-sm-12 ">
                                <MDBCol className="col-12 col-md-6">
                                    <FormGroup>
                                        <label className="modalTextFont" htmlFor="eventName">Nombre de la empresa: <font color="red">*</font></label>
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text" id="eventName-addon">
                                                    <i class="fas fa-building"></i>
                                                </span>
                                            </div>
                                            <input
                                                className="form-control "
                                                type="text"
                                                name="name"
                                                placeholder="Nombre de la empresa"
                                                size="15"
                                                required
                                                onChange={this.valueNameJob}
                                                value={this.state.jobName}
                                            />
                                        </div>
                                    </FormGroup>
                                </MDBCol>
                                <MDBCol className="col-12 col-md-6">
                                    <FormGroup>
                                        <label className="modalTextFont" htmlFor="eventName">Ubicación: <font color="red">*</font></label>
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text" id="place-addon">
                                                    <i class="fas fa-map-marker-alt"></i>
                                                </span>
                                            </div>
                                            <input className="form-control" value={this.state.jobPlace} type="text" id="place" name="place" placeholder="Lugar *" aria-describedby="place-addon" onChange={this.valuePlaceJob} required></input>
                                        </div>
                                    </FormGroup>
                                </MDBCol>
                            </Row>


                            <Row className="mt-2 col-sm-12 ">

                                <MDBCol className="col-12 col-md-6">
                                    <FormGroup>
                                        <label className="modalTextFont" htmlFor="eventName">Puesto laboral: <font color="red">*</font></label>
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text" id="link-addon">
                                                    <i class="fas fa-id-badge"></i>
                                                </span>
                                            </div>
                                            <input className="form-control" value={this.state.jobTitle} type="text" id="eventLink" name="eventLink" placeholder="Puesto laboral" arial-describedby="link-addon" onChange={this.valueTitleJob}></input>
                                        </div>
                                    </FormGroup>

                                </MDBCol>

                                <MDBCol className="col-12 col-md-6">
                                    <FormGroup>
                                        <label className="modalTextFont" htmlFor="eventName">Idioma requerido: <font color="red">*</font></label>

                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text" id="languaje-addon" display="none">
                                                    <i className="fas fa-language prefix inputIcons"></i>

                                                </span>
                                            </div>
                                            <input className="form-control" display="none" value={this.state.jobLanguaje} type="text" id="showLanguaje" name="showLanguaje" aria-describedby="languaje-addon" onClick={this.showSelect}></input>
                                        </div>


                                        <div class="changeSelect">

                                            <Select
                                                className="selectLetter"
                                                id="language"
                                                name="language"
                                                placeholder="Elegir idioma*" options={languages}
                                                onChange={this.languageSelect}
                                                required
                                                theme={theme => ({
                                                    ...theme,
                                                    borderRadius: 4,
                                                    font: 'Open Sans',
                                                    height: 9,
                                                    
                                                
                                                    colors: {
                                                        ...theme.colors,
                                                        primary25: '#FFA93F',
                                                        primary: '#808080',
                                                    },
                                                })}
                                            />

                                        </div>
                                    </FormGroup>
                                </MDBCol>


                            </Row>

                            <Row className="mt-2 col-sm-12 ">

                                <MDBCol className="col-12 col-md-6">
                                    <FormGroup>
                                        <label className="modalTextFont" htmlFor="eventName">Salario: <font color="red">*</font></label>
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text" id="link-addon">
                                                    <i class="far fa-money-bill-alt"></i>
                                                </span>
                                            </div>
                                            <input className="form-control" value={this.state.jobAmount} type="text" id="eventLink" name="eventLink" placeholder="Salario" arial-describedby="link-addon" onChange={this.valueAmountJob}></input>

                                        </div>
                                    </FormGroup>

                                </MDBCol>

                                <MDBCol className="col-12 col-md-6">
                                    <FormGroup>
                                        <FormGroup>
                                            <label className="modalTextFont">Moneda</label>
                                            <Select id="coinSelect" className="selectLetter" name="coinSelect" placeholder="Moneda" onChange={this.coinSelect} options={coins}
                                            theme={theme => ({
												...theme,
												borderRadius: 4,
												font: 'Open Sans',
												height: 9,
												
											
												colors: {
													...theme.colors,
													primary25: '#FFA93F',
													primary: '#808080',
												},
											})}></Select>


                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" id="coin-addon" display="none">
                                                        <i className="fas fa-coins prefix inputIcons"></i>

                                                    </span>
                                                </div>
                                                <input className="form-control" display="none" value={this.state.coinName} type="text" id="showCoin" name="showCoin" aria-describedby="coin-addon" onClick={this.showSelect}></input>
                                            </div>
                                        </FormGroup>
                                    </FormGroup>
                                </MDBCol>

                            </Row>

                            <Row className="mt-2 col-sm-12 ">

                                <MDBCol size={6}>
                                    <FormGroup>
                                        <label className="modalTextFont" htmlFor="eventName">Contacto de la empresa: <font color="red">*</font></label>
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text" id="link-addon">
                                                    <i class="fas fa-address-book"></i>

                                                </span>
                                            </div>
                                            <input className="form-control" value={this.state.jobContact} type="text" id="eventLink" name="eventLink" placeholder="Contacto de la empresa" arial-describedby="link-addon" onChange={this.valueContactJob}></input>
                                        </div>                                </FormGroup>
                                </MDBCol>
                                <MDBCol size={6}>
                                    <FormGroup>
                                        <br />
                                        <textarea value={this.state.description} className="userTextFont" rows="2" placeholder="Descripción de la oferta de empleo" id="description" name="description" onChange={this.handleChange}></textarea>
                                    </FormGroup>
                                </MDBCol>
                            </Row>
                            <MDBCardFooter>
                                <Link to={'/Adv_Job'}>
                                    <MDBBtn color="purpleButton" className="generalButton purpleButton" ><span className="modalButtonText">Cancelar</span></MDBBtn>
                                </Link>
                                <MDBBtn color="greenButton" className="generalButton greenButton"><span className="modalButtonText" onClick={this.validateDataJob}>Guardar</span></MDBBtn>
                            </MDBCardFooter>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>

            </MDBContainer>

        )
    }

}
export default Adv_Job_Up;