/**
 * @fileoverview  Adv_EventUp_In it consists of the edition of the event announcement.
 *
 * @version 1.0
 * @author Estrella López López <estrella.lopez@ucrso.info>
 * 
 * History - Initial Release
 * ----
 * The first version of Adv_EventUp_In was written by  Estrella López López.
 */

import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardTitle, MDBBtn, MDBCardFooter } from 'mdbreact';
import { FormGroup } from 'reactstrap';
import { baseUrl } from './baseUrl';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import validations from './Validations';
import { Link } from 'react-router-dom';

class Adv_EventUp_In extends Component {
	constructor(props) {
		super(props);
		/**
		 * validations:
		 * @type {object}
		 * Property that has methods for validate with regular expressions
		 * 
		 * description: 
		 * @type {String}
		 * Property that stores the description of the event.
		 * 
		 * name:
		 * @type {Sring}
		 * Property that stores the name of the event.
		 * 
		 * date:
		 * @type {Date}
		 * Property that stores the date of the event.
		 * 
		 * place:
		 * @type {String}
		 * Property that stores the place of the event.
		 * 
		 * resgisterLink: 
		 * @type {String}
		 * Property that stores the registration link of the activity.
		 * 
		 * advertisementID: 
		 * @type {Integer}
		 * Property that stores the id of the activity.
		 */
		this.state = {
			validations: new validations(),
			description: '',
			name: '',
			date: '',
			place: '',
			resgisterLink: '',
			advertisementID: 0
			// title:"Agregar evento",
		};

		this.showPlaces = this.showPlaces.bind(this);
		this.showSelect = this.showSelect.bind(this);
		this.back = this.back.bind(this);
		this.getEvent = this.getEvent.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.valueName = this.valueName.bind(this);
		this.empty = this.empty.bind(this);
		this.validateData = this.validateData.bind(this);
		this.valueRegister = this.valueRegister.bind(this);
		this.notify = this.notify.bind(this);
	}

	/**
	 * 
	 * Method that, depending on the value of the variable, displays the type of message it is, if it is correctly entered, there was an 
	 * error, an informational message, or a warning message.
	 * @param {String} evt
	 * @param {String} value 
	 * @param {String} msj 
	 */
	notify = (evt, value, msj) => {
		switch (value) {
			case 'SUCCESS':
				toast.success(msj);
				break;
			case 'ERROR':
				toast.error(msj);
				break;
			case 'WARN':
				toast.warn(msj);
				break;
			case 'INFO':
				toast.info(msj);
				break;
			default:
				toast.info(msj);
		}
	};

	/**
	 * Method that loads the elements to be initially displayed on the website.
	 */
	componentDidMount() {
		if (sessionStorage.getItem('userType') == 2) {
			this.state.advertisementID = this.props.match.params.ID;
			this.setState({ advertisementID: this.props.match.params.ID });
			this.showPlaces(true);

			this.state.title = 'Editar evento';
			this.setState({ title: 'Editar evento' });

			this.getEvent(this.state.advertisementID);
		}else if (sessionStorage.getItem('userType') == 3) {
			this.props.history.push('/admin');
		} else {
			this.props.history.push('/');
		}
	}
	/**
 * Method that validates the data entered by the user, the way it should be written.
 * @param {event} event 
 */
	validateData(event) {
		if (this.empty(event)) {
			if (!this.state.validations.validateTextWithNumberField(this.state.name)) {
				this.notify(event, 'ERROR', 'El nombre del evento no debe contener caracteres especiales');
			} else if (!this.state.validations.validateTextWithNumberField(this.state.place)) {
				this.notify(event, 'ERROR', 'El lugar del evento no debe contener caracteres especiales');
			} else if (
				this.state.resgisterLink.length != 0 &&
				!this.state.validations.validateURLField(this.state.resgisterLink)
			) {
				this.notify(event, 'ERROR', 'El formato del link de llamada no es correcto');
			} else if (
				this.state.description.length != 0 &&
				!this.state.validations.validateTextWithNumberField(this.state.description)
			) {
				this.notify(event, 'ERROR', 'La descripción del evento no debe contener caracteres especiales');
			} else {
				this.handleSubmit(event);
			}
		}
	}

	/**
	 * Method that is responsible for going to the database and editing the selected event.
	 *  @param {event} event 
	 */
	handleSubmit(event) {
		fetch(baseUrl + 'adv_Event/editAdvTypeEvent', {
			method: 'put',
			body: JSON.stringify({
				name: this.state.name,
				description: this.state.description,
				date: this.state.date,
				place: this.state.place,
				resgisterLink: this.state.resgisterLink,
				advertisementID: this.state.advertisementID
			}),
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			}
		}).then(this.notify(event, 'SUCCESS', 'Se ha editado correctamente'))

			.catch(() => this.notify(event, 'WARN', 'No se ha podido editar'))

		this.props.history.push('/Adv_Event');

	}

	/**
	 * Method that validates that the required fields are not empty.
	 *  @param {event} event 
	 */
	empty(event) {
		if (this.state.name.length === 0) {
			this.notify(event, 'ERROR', 'El nombre del evento no puede estar vacío');
			return false;
		} else if (this.state.place.length === 0) {
			this.notify(event, 'ERROR', 'El lugar del evento no puede estar vacío');
			return false;
		} else if (this.state.date.length === 0) {
			this.notify(event, 'ERROR', 'La fecha y hora del evento no pueden estar vacías');
			return false;
		} else {
			return true;
		}
	}

	/**
	 * Method that receives the ad ID and loads the relevant information this ad is in the database.
	 * @param {Integer} advertisementID 
	 */
	getEvent(advertisementID) {
		axios
			.get(baseUrl + 'adv_Event/getAdvEventUser', {
				params: { advertisementID: advertisementID }
			})
			.then((response) => {
				this.state.name = response.data[0].name;
				this.setState({ name: response.data[0].name });
				this.state.description = response.data[0].description;
				this.setState({ description: response.data[0].description });
				this.state.date = response.data[0].date;
				this.setState({ date: response.data[0].date });
				this.state.resgisterLink = response.data[0].resgisterLink;
				this.setState({ resgisterLink: response.data[0].resgisterLink });
				this.state.place = response.data[0].place;
				this.setState({ place: response.data[0].place });
			});
	}

	/**
	 * Method that assigns the value to the elements of the event, also splits the 
	 * date and time to send it separately to the database.
	 * @param {event} event 
	 */
	handleChange(event) {
		if (event.target.name === 'date') {
			var dateArray = event.target.value.split('T');
			var date = dateArray[0] + ' ' + dateArray[1];
			this.setState({
				date
			});
		} else {
			this.setState({
				[event.target.name]: event.target.value
			});
		}
	}

	/**
	 * Method that assigns the value entered to the variable.
	 *  @param {event} event 
	 */
	valueName(event) {
		this.state.name = event.target.value;
		this.setState({ name: event.target.value });
	}

	/**
	 * Method that assigns the value entered to the variable.
	 *  @param {event} event 
	 */
	valueRegister(event) {
		this.state.resgisterLink = event.target.value;
		this.setState({ resgisterLink: event.target.value });
	}

	/**
	 * Method by which I hide elements when the data is loaded for the first time and when it is 
	 * hidden it makes them visible.
	 * @param {Boolean} show 
	 */
	showPlaces(show) {
		if (show) {
			document.getElementById('calendar-addon').style.display = 'block';
			document.getElementById('showDate').style.display = 'block';
			document.getElementById('date').style.display = 'none';
		} else {
			document.getElementById('calendar-addon').style.display = 'none';
			document.getElementById('showDate').style.display = 'none';
			document.getElementById('date').style.display = 'block';
		}
	}

	/**
	 * Method by which I hide elements when the data is loaded for the first time and when it is 
	 * hidden it makes them visible.
	 *  @param {event} event 
	 */
	showSelect(event) {
		switch (event.target.name) {
			case 'showDate':
				document.getElementById('calendar-addon').style.display = 'none';
				document.getElementById('showDate').style.display = 'none';
				document.getElementById('date').style.display = 'block';
				break;
			default:
				break;
		}
	}

	/**
	* Method that returns to the previous page
	*/
	back() {
		this.props.history.push(`/Adv_Event`);
	}

	/**
	* Method that shows the elements found or added to the web page.
	*/
	render() {
		return (
			<MDBContainer>
				<ToastContainer
					position="top-right"
					autoClose={5000}
					hideProgressBar
					newestOnTop
					closeOnClick
					rtl={false}
					pauseOnVisibilityChange
					draggable
					pauseOnHover
				/>

				<MDBRow>
					<MDBCol className="col-12 col-lg-9 offset-lg-3 mt-3">
						<h3 className="adminTitlesFont">{this.state.title}</h3>
					</MDBCol>
					<MDBCol className="col-12 col-lg-9 offset-lg-3 mb-3">
						<MDBCard style={{ padding: '20px 20px 20px 20px' }}>
							<MDBCardTitle className="cardHeader">Datos del evento</MDBCardTitle>
							<MDBRow>
								<MDBCol className="col-12 col-md-6">
									<FormGroup>
										<label htmlFor="eventName">
											Nombre del evento: <font color="red">*</font>
										</label>
										<div className="input-group">
											<div className="input-group-prepend">
												<span className="input-group-text" id="eventName-addon">
													<i className="fa fa-font prefix inputIcons" />
												</span>
											</div>
											<input
												className="form-control"
												value={this.state.name}
												type="text"
												id="eventName"
												name="eventName"
												aria-describedby="eventName-addon"
												onChange={this.valueName}
												required
											/>
										</div>
									</FormGroup>
								</MDBCol>
								<MDBCol className="col-12 col-md-6">
									<FormGroup>
										<label htmlFor="eventName">
											Lugar del evento: <font color="red">*</font>
										</label>
										<div className="input-group">
											<div className="input-group-prepend">
												<span className="input-group-text" id="place-addon">
													<i className="fa fa-map-marker-alt prefix inputIcons" />
												</span>
											</div>
											<input
												className="form-control"
												value={this.state.place}
												type="text"
												id="place"
												name="place"
												placeholder="Lugar *"
												aria-describedby="place-addon"
												onChange={this.handleChange}
												required
											/>
										</div>
									</FormGroup>
								</MDBCol>
							</MDBRow>
							<MDBRow>
								<MDBCol className="col-12 col-md-6">
									<FormGroup>
										<label htmlFor="eventName">
											Fecha del evento: <font color="red">*</font>
										</label>
										<div className="input-group">
											<div className="input-group-prepend">
												<span className="input-group-text" id="calendar-addon" display="none">
													<i className="fa fa-calendar-day prefix inputIcons" />
												</span>
											</div>
											<input
												className="form-control"
												display="none"
												value={this.state.date}
												type="text"
												id="showDate"
												name="showDate"
												aria-describedby="calendar-addon"
												onClick={this.showSelect}
											/>
										</div>

										<input
											className="form-control"
											type="datetime-local"
											defaultValue={this.state.dateEvent}
											id="date"
											name="date"
											onChange={this.handleChange}
											required
										/>
									</FormGroup>
								</MDBCol>
								<MDBCol className="col-12 col-md-6">
									<FormGroup>
										<label htmlFor="eventName">
											Link del evento: <font color="red">*</font>
										</label>
										<div className="input-group">
											<div className="input-group-prepend">
												<span className="input-group-text" id="link-addon">
													<i className="fa fa-link prefix inputIcons" />
												</span>
											</div>
											<input
												className="form-control"
												value={this.state.resgisterLink}
												type="text"
												id="eventLink"
												name="eventLink"
												placeholder="Link del evento"
												arial-describedby="link-addon"
												onChange={this.valueRegister}
											/>
										</div>
									</FormGroup>
								</MDBCol>
							</MDBRow>
							<MDBRow>
								<MDBCol size={12}>
									<FormGroup>
										<textarea
											value={this.state.description}
											rows="4"
											placeholder="Descripción del evento"
											id="description"
											name="description"
											onChange={this.handleChange}
										/>
									</FormGroup>
								</MDBCol>
							</MDBRow>
							<MDBCardFooter>
								<Link to={'/Adv_Event'}>
									<MDBBtn color="purpleButton" className="generalButton purpleButton">
										<span className="modalButtonText">Cancelar</span>
									</MDBBtn>
								</Link>
								<MDBBtn color="greenButton" className="generalButton greenButton">
									<span className="modalButtonText" onClick={this.validateData}>
										Guardar
									</span>
								</MDBBtn>
							</MDBCardFooter>
						</MDBCard>
					</MDBCol>
				</MDBRow>
			</MDBContainer>
		);
	}
}
export default Adv_EventUp_In;
