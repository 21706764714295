/**
 * @fileoverview QuotationAnswer, this component is for answer a quotation
 *
 * @version 1.0
 *
 * @author Ester Molina R <maria.molina@ucrso.info>
 * History
 * v1.0 - Initial Realease
 * ----
 * The first version of QuotationAnswer was written by Ester Molina R.
 */

import React, { Component } from "react";
import {
  MDBContainer,
  MDBBtn,
  MDBCol,
  MDBRow,
  MDBCard,
  MDBIcon,
  MDBCardFooter,
} from "mdbreact";
import axios from "axios";
import { baseUrl } from "./baseUrl";
import Select from "react-select";
import { FormGroup } from "reactstrap";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import validations from "./Validations";
import { Link } from "react-router-dom";
//import { email } from "../components/mail";

class QuotationAnswer extends Component {
  constructor(props) {
    super(props);
    /**
     * validations:
     * @type {Object}
     * Property that contains the mathod for validate with regular expressions
     *
     * quotationID:
     * @type {integer}
     * Property that contains the quotation identification
     *
     * quotationCourses:
     * @type {Array}
     * Property that contains the list of courses for a quotation
     *
     * total:
     * @type {decimal}
     * Property that contains the total price of the quotation
     *
     * discount:
     * @type {decimal}
     * Property that contains the discount for the quotation
     *
     * response:
     * @type {String}
     * Property that contains the message of the response
     *
     * coins:
     * @type {Array}
     * Property that contains the list of coins
     *
     * coinID:
     * @type {integer}
     * Property that contains the selected coin identification
     *
     * coinName:
     * @type {String}
     * Property that contains the selected coin name
     */
    this.state = {
      validations: new validations(),
      quotationID: 0,
      quotationCourses: [{}],
      total: 0,
      discount: 0,
      response: "",
      coins: [{}],
      coinID: 0,
      coinName: "Colones",
    };

    this.getQuotationCourses = this.getQuotationCourses.bind(this);
    this.calcTotal = this.calcTotal.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.getCoins = this.getCoins.bind(this);
    this.coinSelect = this.coinSelect.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.redirect = this.redirect.bind(this);
    this.empty = this.empty.bind(this);
    this.emptyPrice = this.emptyPrice.bind(this);
    this.validatePrices = this.validatePrices.bind(this);
    this.validateData = this.validateData.bind(this);
  }

  componentDidMount() {
    if (sessionStorage.getItem("userType") == 2) {
      this.state.quotationID = this.props.match.params.ID;
      this.setState({
        quotationID: this.props.match.params.ID,
      });
      this.getQuotationCourses();
      this.getCoins();
    } else if (sessionStorage.getItem("userType") == 3) {
      this.props.history.push("/admin");
    } else {
      this.props.history.push("/");
    }
  }

  /**
   * Method that perform the validate data action when an ENTER is clicked
   * @param {event} e
   */
  onKeyEvent = (e) => {
    if (e.key == "Enter") {
      this.validateData(e);
    }
  };

  /**
   * Method that validate the data format
   * @param {event} event
   */
  validateData(event) {
    if (this.empty) {
      if (!this.state.validations.validateNumericField(this.state.discount)) {
        this.notify(event, "ERROR", "El descuento debe contener solo números");
      } else if (
        !this.state.validations.validateTextWithNumberField(this.state.response)
      ) {
        this.notify(
          event,
          "ERROR",
          "El comentario no debe contener caracteres especiales"
        );
      } else if (!this.validatePrices) {
        this.notify(
          event,
          "ERROR",
          "El precio de los cursos debe contener solo números"
        );
      } else {
        this.handleSubmit(event);
      }
    }
  }

  /**
   * Method that validate that all courses have a price
   */
  validatePrices() {
    this.state.quotationCourses.map((course) => {
      if (!this.state.validations.validateNumericField(course.price)) {
        return false;
      }
    });
    return true;
  }

  /**
   * Method that verify  obligatory fields are empty
   * @param {event} event
   */
  empty(event) {
    if (this.state.discount.length === 0) {
      this.notify(
        event,
        "ERROR",
        "Debe agregar un valor a descuento, puede ser 0"
      );
      return false;
    } else if (this.state.response.length === 0) {
      this.notify(event, "ERROR", "Debe agregar un comentario a la respuesta");
      return false;
    } else if (this.state.coinID === 0) {
      this.notify(event, "ERROR", "Debe seleccionar una moneda");
      return false;
    } else if (!this.emptyPrice) {
      this.notify(
        event,
        "ERROR",
        "Debe agregar el precio a cada curso, puede ser 0"
      );
      return false;
    } else {
      return true;
    }
  }

  /**
   * Method that verify that are not empty prices
   */
  emptyPrice() {
    this.state.quotationCourses.map((course) => {
      if (
        course.price === null ||
        course.price === "" ||
        course.price.length === 0
      ) {
        return false;
      }
    });
    return true;
  }

  /**
   * Method that select a coin
   * @param {event} event
   */
  coinSelect(event) {
    this.state.coinName = event.label;
    this.setState({
      coinID: parseInt(event.value),
      coinName: event.label,
    });
  }

  /**
   * Method that charge the coins' list from the database
   */
  getCoins() {
    axios
      .get(baseUrl + "quotation/getCoins")
      .then((response) => {
        var coins = response.data;
        this.setState({
          coins,
        });
      })
      .catch((err) => console.error("Ha ocurrido un error inesperado"));
  }

  /**
   * Method that charge the quotation courses from the database
   */
  getQuotationCourses() {
    axios
      .get(baseUrl + "quotation/quotationDetailCourses", {
        params: {
          quotationID: this.state.quotationID,
        },
      })
      .then((response) => {
        var courses = response.data;

        this.setState({
          quotationCourses: courses.map((course) => {
            return {
              ...course,
              price: 0,
            };
          }),
        });
      })
      .catch((err) => console.error("Ha ocurrido un error inesperado"));
  }

  /**
   * Method that calculate the total price of the quotation
   */
  calcTotal() {
    var total = 0;
    this.state.quotationCourses.map((course) => {
      total = total + parseInt(course.price);
    });
    total = total - total * (this.state.discount / 100);
    total = total.toFixed(2);
    this.setState({
      total,
    });
  }

  /**
   * Method that change the state
   * @param {event} event
   */
  handleChange(event) {
    if (event.target.name === "price") {
      if (event.target.value.length === 0) {
        this.state.quotationCourses[event.target.id].price = 0;
      } else {
        this.state.quotationCourses[event.target.id].price = event.target.value;
      }
    } else {
      if (event.target.name === "discount") {
        this.state.discount = event.target.value;
      } else {
        this.setState({
          [event.target.name]: event.target.value,
        });
      }
    }
    this.calcTotal();
  }

  /**
   * Method that submit the data
   * @param {event} event
   */
  handleSubmit(event) {
    var emailText = "";
    var coin = "";

    this.state.quotationCourses.map((course) => {
      fetch(baseUrl + "quotation/updateCoursePrice", {
        method: "post",
        body: JSON.stringify({
          id: course.ID,
          price: course.price,
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }).catch((err) => console.error("Ha ocurrido un error inesperado"));
    });
    fetch(baseUrl + "quotation/response", {
      method: "post",
      body: JSON.stringify({
        quotationID: this.state.quotationID,
        response: this.state.response,
        discount: this.state.discount,
        userID: 1,
        coinID: this.state.coinID,
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then(
        this.state.quotationCourses.map((course) => {
          if (this.state.coinID === 1) {
            coin = "$";
          } else {
            coin = "₡";
          }

          emailText =
            emailText +
            `<tr><td style="border-bottom: 1px solid;">` +
            course.course +
            `</td><td style="border-bottom: 1px solid;">` +
            coin +
            course.price +
            "<tr>";
        }),
        (emailText =
          emailText +
          `<tr><td style="border-bottom: 1px solid;"><strong>Descuento</strong></td><td style="border-bottom: 1px solid;"><strong>` +
          this.state.discount +
          "%</strong><tr>" +
          `<tr><td><strong>Total</strong></td><td><strong>` +
          coin +
          this.state.total +
          "</strong><tr>"),
        fetch(baseUrl + "quotation/sendResponseEmail", {
          method: "post",
          body: JSON.stringify({
            userName: sessionStorage.getItem("name"),
            emailText: emailText,
            response: this.state.response,
            email: sessionStorage.getItem("clientEmail"),
          }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }).then(
          sessionStorage.setItem("qID", this.state.quotationID),
          this.redirect()
        )
      )
      .catch((err) => console.error("Ha ocurrido un error inesperado"));
  }

  /**
   * Method that redirect
   */
  redirect() {
    sessionStorage.removeItem("clientEmail");
    this.props.history.push("/Quotations");
  }

  /**
   * Method that notificate the users
   * @param {event} evt
   * @param {String} value
   * @param {String} msj
   */
  notify = (evt, value, msj) => {
    switch (value) {
      case "SUCCESS":
        toast.success(msj);

        break;
      case "ERROR":
        toast.error(msj);
        break;
      case "WARN":
        toast.warn(msj);
        break;
      case "INFO":
        toast.info(msj);
        break;
      default:
        toast.info(msj);
    }
  };

  /**
   * Method that send the email
   */
  renderResponse = () => {
    const courses = this.state.quotationCourses.map((course, i) => {
      return (
        <tr key={i}>
          <td>{course.course}</td>
          <td>{course.language}</td>
          <td>
            <input
              className="form-control inputLargeQuotation"
              id={i}
              onKeyPress={this.onKeyEvent}
              onChange={this.handleChange}
              type="number"
              name="price"
            ></input>
          </td>
        </tr>
      );
    });

    const coins = this.state.coins.map(function (coin) {
      return {
        label: coin.coinName,
        value: coin.coinID,
      };
    });
    return (
      <div id="forPrint">
        <MDBRow>
          <MDBCol>
            <div id="table">
              <div className="table-responsive">
                <table className="table table-sm table-striped table-hover">
                  <thead className="tableHeader">
                    <tr className="header quotationText">
                      <th scope="col">Curso</th>
                      <th scope="col">Idioma</th>
                      <th scope="col">Precio</th>
                    </tr>
                  </thead>
                  <tbody>{courses}</tbody>
                </table>
              </div>
            </div>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol className="col-12 col-md-4">
            <FormGroup>
              <label>Moneda</label>
              <Select
                className="selectLetter"
                placeholder="Moneda"
                onChange={this.coinSelect}
                options={coins}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 4,
                  font: "Open Sans",
                  height: 9,

                  colors: {
                    ...theme.colors,
                    primary25: "#FFA93F",
                    primary: "#808080",
                  },
                })}
              ></Select>
            </FormGroup>
          </MDBCol>
          <MDBCol className="col-12 col-md-3 offset-md-5">
            <FormGroup>
              <label htmlFor="discount">Descuento</label>
              <input
                className="form-control inputLargeQuotation"
                type="number"
                id="discount"
                name="discount"
                placeholder="0"
                onChange={this.handleChange}
              ></input>
            </FormGroup>
            <label id="price" value={this.state.total}>
              Total: {" " + this.state.total + " " + this.state.coinName}
            </label>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol size={12}>
            <label for="response">Comentario </label>
            <FormGroup>
              <textarea
                id="response"
                rows="5"
                name="response"
                onChange={this.handleChange}
                onKeyPress={this.onKeyEvent}
              ></textarea>
            </FormGroup>
          </MDBCol>
        </MDBRow>
      </div>
    );
  };

  /**
   * Method that render the HTML
   */
  render() {
    return (
      <MDBContainer>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
        <MDBRow>
          <MDBCol className="col-12 col-lg-9 offset-lg-3 mt-3">
            <h3 className="adminTitlesFont">Responder Cotización</h3>
          </MDBCol>
          <MDBCol className="col-12 col-lg-9 offset-lg-3 mb-3">
            <MDBCard style={{ padding: "20px 20px 20px 20px" }}>
              <this.renderResponse />
              <MDBCardFooter color="white">
                <Link to={"/Quotations"}>
                  <MDBBtn
                    color="purpleButton"
                    className="generalButton purpleButton"
                  >
                    <span className="modalButtonText" onClick={this.backButton}>
                      Volver
                    </span>
                  </MDBBtn>
                </Link>
                <MDBBtn color="blueButton" className="generalButton blueButton">
                  <span className="modalButtonText" onClick={this.validateData}>
                    Enviar
                  </span>
                </MDBBtn>
              </MDBCardFooter>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
  }
}
export default QuotationAnswer;
