import { Component } from "react";

class Validations extends Component {
  constructor(props) {
    super(props);
    this.validateTextField = this.validateTextField.bind(this);
    this.validateNumericField = this.validateNumericField.bind(this);
    this.validatePhoneNumberField = this.validatePhoneNumberField.bind(this);
    this.validateEmailField = this.validateEmailField.bind(this);
    this.validateURLField = this.validateURLField.bind(this);
    this.validateTextWithNumberField =
      this.validateTextWithNumberField.bind(this);
    this.validateDateTimeField = this.validateDateTimeField.bind(this);
    this.validateDateField = this.validateDateField.bind(this);
  }

  validateDateField(value) {
    return new RegExp("^(\\d{4})-(\\d{2})-(\\d{2})$").test(value); //año-mes-dia
  }

  validateDateTimeField(value) {
    return new RegExp("^(\\d{4})-(\\d{2})-(\\d{2}) (\\d{1,2}):(\\d{2})$").test(
      value
    );
  }

  validateTextWithNumberField(value) {
    return new RegExp(
      "^[a-zA-ZÀ-ÿ\u00f1\u00d10-9-.:()~|'%@!#$^&+='*-¿?¡!_,?\\s]+$"
    ).test(value); //permite texto, numeros, |, parentesis, guión, guión bajo, dos puntos, asteriscos, ~, puntos y comas.
  }
  validateTextField(value) {
    return new RegExp(
      "^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$"
    ).test(value);
  }
  validateNumericField(value) {
    return new RegExp("^[0-9]*$").test(value);
  }
  validatePasswordField(value) {
    return new RegExp("^(?=.*\\d).{8,16}$").test(value);
  }
  validateURLField(value) {
    return new RegExp(
      "^((http[s]?|ftp):\\/)?\\/?([^:\\/\\s]+)((\\/\\w+)*\\/)([\\w-\\.]+[^#?\\s]+)(.*)?(#[\\w-]+)?$"
    ).test(value);
  }
  validatePhoneNumberField(value) {
    return new RegExp("^([0-9]{8})$").test(value);
  }
  validateEmailField(value) {
    return new RegExp(
      "^(([\\w-]+\\.)+[\\w-]+|([a-zA-Z]{1}|[\\w-]{2,}))@(([a-zA-Z]+[\\w-]+\\.){1,2}[a-zA-Z]{2,4})$"
    ).test(value);
  }
  validateIdentification(value) {
    return (
      new RegExp("^([1-9])(\\d{8})$").test(value) ||
      new RegExp("^1(\\d{3})((..))(\\d{4,9})$").test(value)
    );
  }
  validateRange(value) {
    return new RegExp("^(\\d{1,3})(\\-)(\\d{1,3})$").test(value);
  }
}
export default Validations;
