
/**
 * @fileoverview QuotationDetail, this component show the detail about a quotation 
 * 
 * @version 1.0
 * 
 * @author Ester Molina R <maria.molina@ucrso.info>
 * History
 * v1.0 - Initial Realease
 * ----
 * The first version of QuotationDetail was written by Ester Molina R. 
 */

import React, {Component} from 'react';
import { MDBContainer, MDBBtn ,MDBCol, MDBRow,MDBCard, MDBCardFooter} from 'mdbreact';
import axios from 'axios';
import {baseUrl} from './baseUrl';
import { Link } from 'react-router-dom';


class QuotationDetail extends Component{
    constructor(props){
        super(props);
        /**
         * quotationID:
         * @type {integer}
         * Property that contains the quotation identification
         * 
         * quotation:
         * @type {Array}
         * Property that contains the data about the quotation
         * 
         * quotationCourses:
         * @type {Array}
         * Property that contains the quotation courses
         */
        this.state ={
            quotationID: 0,
            quotation: [],
            quotationCourses:[{}]
        }

        this.getQuotationDetail = this.getQuotationDetail.bind(this);
        this.getQuotationCourses = this.getQuotationCourses.bind(this);
        this.redirect = this.redirect.bind(this);
        this.backButton = this.backButton.bind(this);
    }

     componentDidMount(){
         if(sessionStorage.getItem('userType') == 2){
        this.state.quotationID = this.props.match.params.ID;
        this.setState({
            quotationID : this.props.match.params.ID
        });
         this.getQuotationDetail();
        this.getQuotationCourses();
    }else if(sessionStorage.getItem('userType') == 3){
        this.props.history.push('/admin');
    }else{
        this.props.history.push('/');
    }
    }

    /**
     * Method that charge the data from the database
     */
     getQuotationDetail(){
        axios.get(baseUrl + 'quotation/quotationDetail',
        {
            params:{
                quotationID : this.state.quotationID
            }
        }).then(response =>{
           var quotation = response.data[0];
            this.setState({
                quotation
            });
        })
    }


    /**
     * Method that charge the courses from the database
     */
     getQuotationCourses(){
        axios.get(baseUrl + 'quotation/quotationDetailCourses',
        {
            params:{
                quotationID : this.state.quotationID
            }
        }).then(response =>{
           var quotationCourses = response.data;
            this.setState({
                quotationCourses
            });
        })
    }

    /**
     * Method that redirect
     */
    redirect(){
        sessionStorage.setItem('clientEmail',this.state.quotation.email);
        this.props.history.push({
            pathname:"/AnswerQuotation/" + this.state.quotationID
        });
    }

    /**
     * Method that redirect to back
     */
    backButton(){
        this.props.history.push('/Quotations');
    }

    /**
     * Method that render HTML
     */
    render(){

        const courses = this.state.quotationCourses.map((course,i) => {
            return(
                <tr key={i}>
                    <td>{course.course}</td>
                    <td>{course.language}</td>
                </tr>
            )
        })
        return(
            <MDBContainer>
                <MDBRow>
                    <MDBCol className="col-12 col-lg-9 offset-lg-3 mt-3">
                        <h3 className="adminTitlesFont">Detalle Cotización</h3>
                    </MDBCol>
                    <MDBCol className="col-12 col-lg-9 offset-lg-3 mb-3">
                        <MDBCard style={{ padding: '20px 20px 20px 20px' }}>
                            <MDBRow>
                                <MDBCol className="col-12 col-md-8 order-2 order-md-1 quotationText">
                                    <p><strong>{this.state.quotation.fullName}</strong></p>
                                    <p><strong>Teléfono:</strong> {this.state.quotation.telephone}</p>
                                    <p><strong>Email:</strong> {this.state.quotation.email}</p>
                                </MDBCol>
                                <MDBCol className="col-12 col-md-4 order-1 order-md-2 quotationTextRight">
                                    <p><strong>{this.state.quotation.doDate}</strong></p>
                                    <p><strong>{this.state.quotation.country}</strong></p>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol className="col-8 offset-2">
                                <div className="table-responsive">
                                    <table className="table table-sm table-striped table-hover">
                                    <thead className="tableHeader">
                                        <tr className="header quotationText">
                                            <th scope="col">Curso</th>
                                            <th scope="col">Idioma</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {courses}
                                    </tbody>
                                    </table>
                                    </div>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol className="quotationText">
                                    <p><strong>Observaciones</strong></p>
                                    <p>{this.state.quotation.description}</p>
                                </MDBCol>
                            </MDBRow>
                            <MDBCardFooter color="white">
                                <Link to={'/Quotations'}>
                                    <MDBBtn color="purpleButton" className="generalButton purpleButton" ><span className="modalButtonText" onClick={this.backButton}>Volver</span></MDBBtn>
                                </Link>
                                    <MDBBtn color="blueButton" className="generalButton blueButton"><span className="modalButtonText" onClick={this.redirect}>Responder</span></MDBBtn>
                                </MDBCardFooter>

                        </MDBCard>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        )
    }
}
export default QuotationDetail;