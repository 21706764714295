/**
 * @fileoverview CourseDetail this component consists of showing the user the details of the courses.
 *
 * @version 1.0
 * @author María Ester Molina Richmond <ester.molina@ucrso.info>
 * @author Estrella López López <estrella.lopez@ucrso.info>
 *
 * History - Initial Release
 * ----
 * The first version of CourseDetail was written by  Estrella López López and María Ester Molina Richmond.
 */

import React, { Component } from "react";
import { Container, FormGroup } from "reactstrap";
import { baseUrl } from "./baseUrl";
import axios from "axios";
import Select from "react-select";
import {
  Row,
  Col,
  MDBTooltip,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBContainer,
  MDBModalFooter,
  MDBCol,
  MDBIcon,
  MDBBtn,
} from "mdbreact";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import validations from "./Validations";

class CourseDetail extends Component {
  constructor(props) {
    super(props);
    /**
     * validations:
     * @type {Object}
     * Property that has methods for validate with regular expressions.
     *
     * courseDataModal:
     * @type {boolean}
     * Property that changes state to know if the modal.
     *
     * name:
     * @type {String}
     * Property where the course name is stored
     *
     * courseType:
     * @type {Integer}
     * Property that stores the course Type.
     *
     * description:
     * @type {String}
     * Property that stores the course description.
     *
     * detail:
     * @type {Integer}
     * Property that will store the id of the selected course.
     *
     * hoursQuantity:
     * @type {Integer}
     * Property that stores the number of hours.
     *
     * gratuitousYN:
     * @type {Integer}
     *
     * Property that stores the identification if it is free or not.
     *
     * paymentName:
     * @type {Integer}
     * Property that stores the payment name.
     *
     * payment:
     * @type {Array}
     *
     * Property that saves two forms of payment, either free or with cost.
     *
     * link:
     * @type {String}
     *
     *
     * valuesImage:
     * @type {String}
     *
     *
     * amount:
     * @type {Integer}
     * Property that saves the number of hours of the course.
     *
     * languageID:
     * @type {Integer}
     * Property that saves the id language.
     *
     * languages:
     * @type {Array}
     * Property that stores the list of languages ​​that exist in the database.
     *
     * quotationTemporalID:
     * @type {Integer}
     * Property that stores a temporary quote id
     *
     * materialList:
     * @type {Array}
     *
     */
    //Pendiente por lo de Ester
    this.state = {
      validations: new validations(),
      courseDataModal: false,
      name: "",
      coursetype: "",
      description: "",
      detail: 0,
      hoursQuantity: 0,
      gratuitousYN: 0,
      paymentName: "",
      payment: [
        { id: 0, name: "Pago" },
        { id: 1, name: "Gratuito" },
      ],
      link: "",
      valuesImage: null,
      amount: 0,
      languageID: 0,
      languages: [{}],
      quotationTemporalID: 0,
      materialList: [],
    };

    this.back = this.back.bind(this);
    this.toggle = this.toggle.bind(this);
    this.courseDataModal = this.courseDataModal.bind(this);
    this.toggleData = this.toggleData.bind(this);
    this.languageSelect = this.languageSelect.bind(this);
    this.loadLanguage = this.loadLanguage.bind(this);
    this.loadgetlastElement = this.loadgetlastElement.bind(this);
    this.validateData = this.validateData.bind(this);
    this.notify = this.notify.bind(this);
    this.valueAmount = this.valueAmount.bind(this);
    this.loadCourseMaterial = this.loadCourseMaterial.bind(this);
  }

  /**
   * Method that returns to the previous page
   */
  back() {
    this.props.history.push(`/CourseUser`);
  }

  /**
   *
   * Method that, depending on the value of the variable, displays the type of message it is, if it is correctly entered, there was an
   * error, an informational message, or a warning message.
   * @param {String} evt
   * @param {String} value
   * @param {String} msj
   */
  notify = (evt, value, msj) => {
    switch (value) {
      case "SUCCESS":
        toast.success(msj);
        break;
      case "ERROR":
        toast.error(msj);
        break;
      case "WARN":
        toast.warn(msj);
        break;
      case "INFO":
        toast.info(msj);
        break;
      default:
        toast.info(msj);
    }
  };

  /**
   * Method that gets a quote number from the database that has not yet been created.
   */
  loadgetlastElement() {
    axios.get(baseUrl + "quotationUser/lastItem").then((response) => {
      this.state.quotationTemporalID = response.data[0].quotationTemporalID;
      this.setState({
        quotationTemporalID: response.data[0].quotationTemporalID,
      });
    });
  }

  /**
   *
   * Method that validates that the required fields are not empty and that correct.
   *
   * @param {Integer} id
   * @param {Integer} idLanguaje
   * @param {Integer} amount
   * @param {Integer} quotationTemporalID
   */
  validateData(id, idLanguaje, amount, quotationTemporalID) {
    if (idLanguaje === 0) {
      this.notify(this, "ERROR", "Debe seleccionar un lenguaje");
      return false;
    } else if (!this.state.validations.validateNumericField(amount)) {
      this.notify(this, "ERROR", "La cantidad debe ser un número");
      return false;
    } else if (amount === 0) {
      this.notify(this, "ERROR", "La cantidad no debe estar vacía");
      return false;
    } else if (amount < 1) {
      this.notify(this, "ERROR", "La cantidad no puede ser 0 o menor a 0");
      return false;
    } else {
      this.setState({
        courseID: id,
      });
      this.props.history.push({
        pathname:
          "/QuotationUser/" +
          id +
          "/" +
          idLanguaje +
          "/" +
          amount +
          "/" +
          quotationTemporalID,
      });
    }
  }

  /**
   * Method that loads the elements to be initially displayed on the website.
   */
  componentDidMount() {
    if (
      sessionStorage.getItem("userType") != 2 &&
      sessionStorage.getItem("userType") != 3
    ) {
      this.state.detail = this.props.match.params.ID;
      this.setState({ detail: this.props.match.params.ID });
      this.loadLanguage();
      this.loadgetlastElement();

      axios
        .get(baseUrl + "courseUser/courseDetail/" + this.state.detail)
        .then((response) => {
          this.state.name = response.data[0].name;
          this.setState({ name: response.data[0].name });

          this.state.description = response.data[0].description;
          this.setState({ description: response.data[0].description });

          this.state.coursetype = response.data[0].coursetype;
          this.setState({ coursetype: response.data[0].coursetype });

          this.state.hoursQuantity = response.data[0].hoursQuantity;
          this.setState({ hoursQuantity: response.data[0].hoursQuantity });

          this.state.gratuitousYN = response.data[0].gratuitousYN;
          this.setState({ gratuitousYN: response.data[0].gratuitousYN });
          this.state.payment.map((pay) => {
            if (pay.id === this.state.gratuitousYN) {
              this.setState({
                paymentName: pay.name,
              });
            }
          });

          this.state.link = response.data[0].link;
          this.setState({ link: response.data[0].link });
          this.loadCourseMaterial();
        });
    } else {
      this.props.history.push("/admin");
    }
  }

  /**
   * Method that receives parameters of a course to be quoted and is sent to validate.
   * @param {Integer} id
   * @param {Integer} idLanguaje
   * @param {Integer} amount
   */
  toggle = (id, idLanguaje, amount) => () => {
    if (this.state.quotationTemporalID == null) {
      this.validateData(id, idLanguaje, amount, 1);
    } else {
      this.validateData(id, idLanguaje, amount, this.state.quotationTemporalID);
    }
  };

  loadCourseMaterial() {
    axios
      .get(baseUrl + "course/getMaterial", {
        params: {
          courseID: this.state.detail,
        },
      })
      .then((response) => {
        var list = response.data[0];
        var materialList = [];

        materialList = list.map((item) => {
          return (
            <MDBTooltip placement="bottom">
              <MDBBtn
                className="materialCourse peach-gradient"
                onClick={() => window.open(item.materialLink, "_blank")}
              >
                <MDBIcon className="materialIcon" icon="file-alt"></MDBIcon>
              </MDBBtn>
              <div>{item.name}</div>
            </MDBTooltip>
          );
        });

        this.setState({
          materialList,
        });
      });
  }

  /**
   * Method that shows a modal and allows you to show yourself, close the modal of the course to quote, cancel.
   * @param {String} text
   * @param {Integer} id
   * @param {String} description
   */
  toggleData = (text, id, description) => () => {
    let modalAction = text + "Modal";
    if (id != null) {
      if (description != null) {
        this.setState({
          [modalAction]: !this.state[modalAction],
          advertisementID: id,
          description,
        });
      } else {
        this.setState({
          [modalAction]: !this.state[modalAction],
          advertisementID: id,
        });
      }
    } else {
      if (this.state[modalAction] === true) {
        this.setState({
          [modalAction]: !this.state[modalAction],
          advertisementID: 0,
        });
      } else {
        this.setState({
          [modalAction]: !this.state[modalAction],
        });
      }
    }
  };
  /**
   * Method that assigns the chosen value of the selection to the variable.
   * @param {event} event
   */
  languageSelect(event) {
    this.state.languageID = event.value;
    this.setState({ languageID: event.value });
  }

  /**
   * Method that assigns the value of the number of hours.
   *  @param {event} event
   */
  valueAmount(event) {
    this.state.amount = event.target.value;
    this.setState({ amount: event.target.value });
  }

  /**
   * Method that goes to the database and brings up the list of languages ​​that exist.
   */
  loadLanguage() {
    var languages = [];
    axios
      .get(baseUrl + "event/getLanguage")
      .then((response) => {
        languages = response.data;
        this.setState({
          languages,
        });
      })
      .catch((err) => console.log("Ha ocurrido un error inesperado"));
  }

  /**
   * Method that perform the validate data action when an ENTER is clicked.
   *  @param {event} e
   */
  onKeyEvent = (e) => {
    if (e.key == "Enter") {
      this.validateData(e);
    }
  };

  /**
   * Method that collects the information of the course to be quoted, consists of displaying a modal.
   */
  courseDataModal() {
    const languages = this.state.languages.map((language) => {
      return {
        label: language.name,
        value: language.languageID,
      };
    });
    return (
      <MDBContainer>
        <MDBModal
          className="modal-lg"
          isOpen={this.state.courseDataModal}
          toggleData={this.toggleData("courseData")}
        >
          <MDBModalHeader
            className="modalHeader modalTitlesFont"
            toggleData={this.toggleData("courseData")}
          >
            Datos para cotizar curso
          </MDBModalHeader>
          <MDBModalBody>
            <Row
              className="mt-2 col-sm-12 offset-lg-1"
              style={{ textAlign: "center" }}
            >
              <label className="modalTextFont" htmlFor="eventName">
                Proporcione la siguiente información para el curso solicitado{" "}
                <font color="red"></font>
              </label>
            </Row>
            <Row className="mt-4 col-sm-12">
              <MDBCol className=" col-12 col-lg-4 offset-lg-2 ">
                <label className="smallUserTextBold">Idioma</label>
                <div class="changeSelect">
                  <Select
                    id="language"
                    className="selectLetter"
                    placeholder="Elegir idioma*"
                    options={languages}
                    onChange={this.languageSelect}
                    required
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 4,
                      font: "Open Sans",
                      height: 9,

                      colors: {
                        ...theme.colors,
                        primary25: "#FFA93F",
                        primary: "#808080",
                      },
                    })}
                  />
                </div>
              </MDBCol>

              <MDBCol className=" col-12 col-lg-4 offset-lg-1  ">
                <label className="smallUserTextBold">Cantidad </label>

                <input
                  className="form-control"
                  id="hoursNum"
                  type="number"
                  name="name"
                  required
                  onChange={this.valueAmount}
                  onKeyPress={this.onKeyEvent}
                />
              </MDBCol>
            </Row>
          </MDBModalBody>

          <MDBModalFooter>
            <MDBBtn
              color="purpleButton"
              className="generalButton purpleButton"
              onClick={this.toggleData("courseData")}
            >
              <span className="modalButtonText">Cancelar</span>
            </MDBBtn>

            <MDBBtn
              color="greenButton"
              className="generalButton greenButton"
              onClick={this.toggle(
                this.state.detail,
                this.state.languageID,
                this.state.amount
              )}
            >
              <span className="modalButtonText">Guardar</span>
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </MDBContainer>
    );
  }

  /**
   * Method that shows the elements found or added to the web page.
   */
  render() {
    return (
      <Container className="mt-5 ">
        <this.courseDataModal />
        <Row className="mt-12">
          <Col className="col-md-2 col-md-offset-7" className="mx-auto" />
          <Col md="12" className="mx-auto">
            <Row className="mt-0">
              <MDBCol className=" col-12 col-lg-9 offset-lg-0 mt-3">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb white">
                    <li class="breadcrumb-item">
                      <a class="black-text" href="/">
                        Inicio
                      </a>
                    </li>
                    <li class="breadcrumb-item">
                      <a class="black-text" href="/CourseUser">
                        Cursos
                      </a>
                    </li>
                    <li class="breadcrumb-item active">Detalle del curso</li>
                  </ol>
                </nav>
              </MDBCol>
            </Row>

            <Row className="mt-4  col-sm-12">
              <MDBCol className=" col-12 offset-lg-1 mt-3 col-md-6">
                <FormGroup>
                  <h3 className="userTitlesFont">{this.state.name}</h3>
                  <label className="paragraph" style={{ textAlign: "justify" }}>
                    {this.state.description}
                  </label>
                </FormGroup>

                <FormGroup>
                  <label className="category">Tipo de curso: </label>
                  <label className="paragraph">{this.state.coursetype}</label>
                </FormGroup>

                <FormGroup>
                  <label className="category">Cantidad de horas: </label>
                  <label className="paragraph">
                    {this.state.hoursQuantity}{" "}
                  </label>
                </FormGroup>
                <FormGroup>
                  {/* <label className="paragraph">Si desea saber mayor información, realiza una cotización.</label> */}
                  <label className="paragraph">
                    <strong>Horario:</strong>Pactado entre Estudiantes-Profesor.
                  </label>
                  <label className="paragraph">
                    Se recomienda tomarlo en 2, 3 ó 4 clases.
                  </label>
                </FormGroup>
              </MDBCol>

              <MDBCol className="offset-lg-1 mt-4  col-12 col-md-3">
                <div class="form-group" style={{ textAlign: "center" }}>
                  <div className="imagenCourse">
                    <img
                      src={baseUrl + this.state.link}
                      id="valuesImg"
                      width="100%"
                      height="100%"
                      class=" img-fluid z-depth-3 rounded"
                      alt="Responsive image"
                    />
                  </div>
                </div>

                <MDBBtn
                  color="blueButton"
                  className="courseButton generalButton blueButton"
                >
                  <span
                    className="buttonSeeText"
                    onClick={this.toggleData("courseData")}
                  >
                    Cotizar
                  </span>
                </MDBBtn>
              </MDBCol>

              <MDBCol className=" col-12 offset-lg-1 mt-3 col-md-5">
                <label className="userSubtitlesFont">Material del curso </label>
              </MDBCol>
              <MDBCol className=" col-12 col-lg-12 offset-lg-1 mt-3 col-md-5">
                <label className="paragraph" style={{ textAlign: "justify" }}>
                  Si desea adquirir algún material relacionado al curso los
                  puede ver y comprar aquí
                </label>
                <div id="materials">{this.state.materialList}</div>
              </MDBCol>

              <MDBCol className=" col-5 col-lg-12 offset-lg-1 mt-3">
                <MDBBtn
                  color="purpleButton"
                  className="generalButton purpleButton"
                >
                  <span className="modalButtonText" onClick={() => this.back()}>
                    Volver
                  </span>
                </MDBBtn>
              </MDBCol>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }
}
export default CourseDetail;
