/**
 * @fileoverview  PrincipalEvent this component consists of presenting a list of events and placing a calendar.
 *
 * @version 1.0
 * @author Estrella López López <estrella.lopez@ucrso.info>
 * 
 * History - Initial Release
 * ----
 * The first version of PrincipalEvent was written by  Estrella López López.
 */

import React, { Component } from 'react';
import { baseUrl } from './baseUrl';
import imagen from '../images/imagen.jpeg';
import {
	MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBCard, MDBCardBody, MDBCardTitle, MDBCardText,
	MDBRow, MDBCol, MDBIcon, MDBBtn, MDBDataTable, Container, Row, Col, Card, MDBContainer
} from 'mdbreact';
import { FormGroup } from 'reactstrap';
import Moment from 'moment';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import FullCalendar, { interactionSettingsStore, interactionSettingsToStore } from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import '../css/calendar.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "react-datepicker/dist/react-datepicker.css";
var date = new Date()

class principalEvent extends Component {
	calendarComponentRef = React.createRef();


	constructor(props) {
		super(props);
		/**
		 * event: 
		 * @type {Array}
		 * Property that stores the list information of event.
		 * 
		 * date: 
		 * @type {Date}
		 * Property that stores the date.
		 * 
		 * newDate:
		 * @type {Date}
		 * Property that stores the new date.
		 * 
		 * dateTime: 
		 * @type {DateTime}
		 * Property that stores the date and the time.
		 *
		 * uploadImageModal:
		 * @type {Boolean}
		 * Property to know if it is to update the image.
		 * 
		 * imageLink:
		 * @type {String}
		 * Property that stores the link of the image brought from the database.
		 * 
		 * image:
		 * @type {String}
		 * Property that stores the category image.
		 * 
		 * imageID:
		 * @type {Integer}
		 * Property that stores the id of the image.
		 * 
		 * listEvent:
		 * @type {Array}
		 * Property that stores the list of events in table form, consists of name and actions.
		 * 
		 * events:
		 * @type {Array}
		 * Property that stores the list of events in the calendar.
		 * 
		 * viewDate:
		 * @type {Date}
		 * Property that stores the new date.
		 * 
		 * flag:
		 * @type {Integer}
		 * Property that functions as a change of state.
		 */

		this.state = {
			event: [],
			date: new Date(),
			newDate: '',
			dateTime: '',
			uploadImageModal: false,
			imageLink: '',
			image: null,
			imageID: 0,
			listEvent: {
				columns: [
					{
						field: 'name'
					}
				],
				rows: []
			},

			events: [],
			viewDate: new Date(),
			flag: 0

		};
		this.getAllEvent = this.getAllEvent.bind(this);
		this.getEvents = this.getEvents.bind(this);
		this.getEventEdit = this.getEventEdit.bind(this);
		this.uploadImageModal = this.uploadImageModal.bind(this);
		this.getParticipants = this.getParticipants.bind(this);
		this.handleImageChange = this.handleImageChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.notify = this.notify.bind(this);
		this.getData = this.getData.bind(this);
		this.gotoPast = this.gotoPast.bind(this);
	}

	//onChange = (date) => this.setState({ date });

	/**
	 * Method that displays the modal and if it is to edit the main image of the event can be modified.
	 * @param {String} text 
	 * @param {Integer} id 
	 * @param {String} name 
	 */
	toggle = (text, id, name) => () => {
		let modalAction = text + 'Modal';

		if (this.state.imageID != null && this.state.imagen == null && this.state.imageLink != null && id != 1) {
			this.getData();
			this.setState({
				[modalAction]: !this.state[modalAction]
			});
		} else {

			if (this.state[modalAction] === true) {
				this.setState({
					[modalAction]: !this.state[modalAction]
				});
			} else {
				this.setState({
					[modalAction]: !this.state[modalAction]
				});
			}
		}
	};

	/**
	 * 
	 * Method that, depending on the value of the variable, displays the type of message it is, if it is correctly entered, there was an 
	 * error, an informational message, or a warning message.
	 * @param {String} evt
	 * @param {String} value 
	 * @param {String} msj 
	 */
	notify = (evt, value, msj) => {
		switch (value) {
			case 'SUCCESS':
				toast.success(msj);
				break;
			case 'ERROR':
				toast.error(msj);
				break;
			case 'WARN':
				toast.warn(msj);
				break;
			case 'INFO':
				toast.info(msj);
				break;
			default:
				toast.info(msj);
		}
	};

	componentDidUpdate() {
		if (this.state.flag != 0) {
			this.getEvents();
		}
	}

	/**
	 * Method that loads the elements to be initially displayed on the website.
	 */
	componentDidMount() {
		if (sessionStorage.getItem('userType') == 2 || sessionStorage.getItem('userType') == 3) {
			this.state.flag = this.props.match.params.ID;
			this.setState({ flag: this.props.match.params.ID });
			this.getAllEvent();
			this.eventView();
			this.getEvents();
		} else {
			this.props.history.push('/');
		}
	}

	/**
	 * Method that brings the main information of the events from the database.
	 */
	getAllEvent() {

		axios.get(baseUrl + 'event/eventPreview').then((response) => {
			this.state.event = response.data;
			this.setState({ event: response.data });
		});
	}

	/**
	 * Method that modifies the way the date is displayed so that it can be sent when 
	 * editing an event on another page along with the event ID.
	 * @param {Integer} id 
	 */
	getEventEdit(id) {
		this.state.newDate = Moment(this.state.date).format('YYYY-MM-DD');
		this.dateTime = this.state.newDate + 'T' + '00:00';

		this.props.history.push({
			pathname: '/EventUp_In/' + id + '/' + this.dateTime
		});
	}

	/**
	 * Method that brings the events to add to the calendar.
	 */
	getEvents() {
		axios.get(baseUrl + 'event/eventCalendar').then((response) => {
			this.state.events = response.data;
			this.setState({ events: response.data });
		});
	}

	/**
	 * Method redirect to the participant list.
	 * @param {Integer} id 
	 */
	getParticipants(id) {
		this.props.history.push({
			pathname: '/ParticipantsList/' + id
		});
	}



	/**
	  * Method that shows how events are to be displayed.
	  */
	eventView() {
		axios.get(baseUrl + 'event/eventPreview').then((response) => {
			var event = response.data;
			this.setState({
				...this.state,
				listEvent: {
					...this.state.listEvent,
					rows: event.map((elements, key) => {
						return {
							...elements,
							name: (
								<MDBRow className="mt-3 offset-lg-0">
									<MDBCol md="12">
										<MDBCard class="cardEvent">
											<MDBCardBody className="elegant-color white-text rounded-bottom">
												<div class="row row-striped">
													<div class="col-2 text-right">
														<h1 class="display">
															<span class="badge">{elements.day}</span>
														</h1>
														<h2 className="month" style={{ color: 'white' }}>
															{elements.month}
														</h2>
													</div>

													<div class="col-10">
														<MDBCardTitle style={{ color: 'white' }}>
															{elements.name}
														</MDBCardTitle>
														<ul class="list-inline">
															<li class="list-inline-item">
																<i class=" clockEvent fa fa-clock" aria-hidden="true" />{' '}
																{elements.hour}
															</li>
														</ul>
														<MDBCardText id="texfont">Descripción:</MDBCardText>
														<MDBCardText className="white-text">
															{elements.description}
														</MDBCardText>
														<a
															className="white-text d-flex justify-content-end"
															onClick={() => this.getEventEdit(elements.eventID)}
														>
															<h5 className="linkEdit">
																Editar
																<MDBIcon icon="angle-double-right" className="ml-1" />
															</h5>
														</a>
														<a
															className="white-text d-flex justify-content-end"
															onClick={() => this.getParticipants(elements.eventID)}
														>
															<h5 className="linkEdit">
																Participantes
																<MDBIcon icon="angle-double-right" className="ml-1" />
															</h5>
														</a>
													</div>
												</div>
											</MDBCardBody>
										</MDBCard>
									</MDBCol>
								</MDBRow>
							)
						};
					})
				}
			});
		});
	}

	/**
	 * Method showing how to update and add the event image.
	 */
	uploadImageModal() {
		return (
			<MDBContainer>
				<MDBModal isOpen={this.state.uploadImageModal} toggle={this.toggle('uploadImage')}>
					<MDBModalHeader className="modalHeader modalTitlesFont" toggle={this.toggle('uploadImage', 1)}>
						Agregar multimedia
					</MDBModalHeader>
					<MDBModalBody>
						<FormGroup className="text-center">
							<MDBCol className="col-12">
								<label className="modalTextFont">
									<div class="form-group" style={{ textAlign: 'center' }}>
										<img
											src={imagen}
											alt="logo"
											id="eventImg"
											width="100%"
											height="50%"
											style={{ textAlign: 'center' }}
										/>
										<div className="imageSizeEvent" id="size">
											Tamaño recomendado es 1280 x 499
											</div>
										<label>
											Seleccione una imagen <font color="red">*</font>
										</label>
										<div className="custom-file">
											<input
												type="file"
												className="custom-file-input"
												lang="es"
												id="eventImage"
												name="image"
												accept=".jpg,.svg,.jpeg,.png,.gif"
												onChange={this.handleImageChange}
												onKeyPress={this.onKeyEvent}
												required
											/>
											<label className="custom-file-label" id="labelImage" htmlFor="customFile">
												<div className="imageSizeEvent" id="size">
													Tamaño recomendado es 1280 x 499
											</div>
												{this.state.imageLink == '' && <p>Elegir archivo </p>}
												{this.state.imageLink != null && this.state.imageLink}
											</label>
										</div>
									</div>
								</label>
							</MDBCol>
						</FormGroup>
					</MDBModalBody>
					<MDBModalFooter>
						<MDBBtn
							color="purpleButton"
							className="modalButton purpleButton"
							onClick={this.toggle('uploadImage', 1)}
						>
							<span className="modalButtonText">Cancelar</span>
						</MDBBtn>
						<MDBBtn color="redButton" className="modalButton redButton" onClick={this.handleSubmit}>
							<span className="modalButtonText">Agregar</span>
						</MDBBtn>
					</MDBModalFooter>
				</MDBModal>
			</MDBContainer>
		);
	}



	/**
	 * Method that brings the image of the event.
	 */
	getData() {
		axios.get(baseUrl + 'event/getEventMultimedia').then((response) => {

			var multimedia = response.data[0];
			
				var name = multimedia.link;
				if (window.innerWidth < 500) {
					name = name.substring(8, 10) + name.substring(name.length - 4, name.length);
				} else {
					name = name.substring(8, 12) + name.substring(name.length - 4, name.length);
				}
				document.getElementById('eventImg').src = baseUrl + multimedia.link;
				document.getElementById('eventImg').style.display = 'block';
				this.setState({
					imageLink: name,
					imageID: multimedia.multimediaID,
					imageURL: '/root/servidor/public/' + multimedia.link
				});
		});
	}

	/**
	 * Method that adds or edits the information depending on whether an image already exists.
	 * @param {event} event 
	 */
	handleSubmit(event) {
		let modalAction = 'uploadImage' + 'Modal';
		var formData = '';

		if (this.state.imageID === 0 && this.state.image !== null) {

			formData = new FormData();

			formData.append('image', this.state.image);

			axios.post(`${baseUrl}event/addImageEvent`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			});
			this.setState({
				[modalAction]: !this.state[modalAction]
			});
		} else if (this.state.imageID !== 0 && this.state.image !== null) {
			formData = new FormData();
			formData.append('image', this.state.image);
			formData.append('multimediaImageID', this.state.imageID);
			formData.append('url', this.state.imageURL);
			axios.post(`${baseUrl}event/editImageEvent`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			});
			this.setState({
				[modalAction]: !this.state[modalAction]
			});
		}
		this.notify(event, 'SUCCESS', 'Se ha actualizado con éxito la información');
	}

	/**
	 * Method that assigns the date you want to go to
	 * @param {*Date} data 
	 * 
	 */
	gotoPast = (data) => {
		this.state.viewDate = data;
		this.setState({ viewDate: data })
		let calendarApi = this.calendarComponentRef.current.getApi();
		calendarApi.gotoDate(data); // call a method on the Calendar object
	};

	/**
	 * Method that loads and reads the image in the src structure.
	 */
	readFile1 = (input) => {
		if (input.files && input.files[0]) {
			var reader = new FileReader();
			reader.onload = function (e) {
				var filePreview = document.getElementById('eventImg');
				filePreview.src = e.target.result;
				filePreview.style.display = 'block';
			};
			reader.readAsDataURL(input.files[0]);
		}
	};

	/**
	 * Method that loads the image link to the tag and calls another method to load the image to the src.
	 */
	handleImageChange(event) {
		event.preventDefault();

		if (event.target.files && event.target.files.length === 1) {
			const file = event.target.files[0];
			this.setState({
				image: file
			});
			var name = file.name;
			if (window.innerWidth < 500) {
				if (name.length >= 10) {
					name = name.substring(0, 8) + name.substring(name.length - 4, name.length);
				}
			} else {
				if (name.length >= 20) {
					name = name.substring(0, 10) + name.substring(name.length - 4, name.length);
				}
			}
			document.getElementById('labelImage').textContent = name;
		}
		this.readFile1(event.target);
	}

	/**
	 * Method that shows the elements found or added to the web page.
	 */
	render() {


		const ExampleCustomInput = ({ value, onClick }) => (
			<button class="btn grayButton" onClick={onClick} style={{ color: "white" }}>
				{this.state.viewDate.toLocaleDateString()}
			</button>
		)

		return (
			<div className="container">
				<ToastContainer
					position="top-right"
					autoClose={5000}
					hideProgressBar
					newestOnTop
					closeOnClick
					rtl={false}
					pauseOnVisibilityChange
					draggable
					pauseOnHover
				/>

				<this.uploadImageModal />
				<MDBRow>
					<MDBCol className="col-12 col-lg-9 offset-lg-3 mt-3">
						<h3 className="adminTitlesFont">Administración de Eventos</h3>
					</MDBCol>
					<Container>
						<Row >
							<Col className="col-12 col-lg-9 offset-lg-3 mt-3" >

								<Card>
									<Row>
										<Col className="col-12 col-lg-6">
											<MDBDataTable
												searching={false}
												striped={false}
												sortable={false}
												bordered={false}
												borderless
												hover={false}
												small
												className="mx-auto"
												noRecordsFoundLabel="No se han encontrado datos"
												info={false}
												entries={2}
												displayEntries={false}
												data={this.state.listEvent}
												paginationLabel={['Anterior', 'Siguiente']}
												pagesAmount={4}
											/>
										</Col>
										<Col Col xs={2} md={6} lg={5} className="calendarPosition">
											<Card className="mt-5 offset-lg-1">
												<div>
													<DatePicker
														class="btn grayButton"
														onChange={this.gotoPast}
														dateFormat="yyyy/MM"
														customInput={<ExampleCustomInput />}
														showMonthYearPicker
													/>
													<FullCalendar
														defaultView="dayGridMonth"
														ref={this.calendarComponentRef}
														plugins={[dayGridPlugin, interactionPlugin]}
														locale="es"

														events={this.state.events}
														defaultAllDay={true}
														eventClick={(info) => {
															this.getEventEdit(info.event.id);
														}}

														dateClick={(info) => {
															if(info.dateStr >= Moment(date).format('YYYY-MM-DD')){
															this.state.date = info.dateStr;
															this.setState({ date: info.dateStr });
															info.dayEl.onclick = this.getEventEdit(0);
															}else{
																this.notify(this, 'ERROR', 'La fecha seleccionada ya pasó');
															}
														}}
													/>
												</div>

											</Card>
										</Col>
									</Row>

									<Row>
										<MDBBtn gradient="peach" onClick={this.toggle('uploadImage')}>
											Editar multimedia <MDBIcon icon="file-upload" size="1x" />
										</MDBBtn>
									</Row>
								</Card>

							</Col>
						</Row>
					</Container>
				</MDBRow>
			</div>
		);
	}
}
export default principalEvent;
