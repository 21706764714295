/**
 * @fileoverview AdminPerfilConfiguration, this component allow to update the information of the colaborators and Admin.
 * 
 * @version 1.0
 * 
 * @author Anthony Vargas Méndez <anthony.vargas@ucrso.info>
 * History
 * v1.0 - Initial Release
 * ----
 * The first version of AdminPerfilConfiguration was writen by Anthony Vargas Méndez
 */
import React, { Component, Fragment } from 'react';
import { MDBRow, MDBCol, MDBBtn } from 'mdbreact';
import { MDBCard, MDBContainer, MDBCardTitle, MDBCardBody, MDBCardFooter, MDBTooltip, MDBIcon } from 'mdbreact';
import { FormGroup } from 'reactstrap';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import validations from './Validations';
import md5 from 'md5';

import { format } from 'date-fns';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

import { baseUrl } from './baseUrl';

const animatedComponents = makeAnimated();

class AdminPerfilConfiguration extends Component {
	state = {
		/**
		 * validations:
		 * @type {object}
		 * Property that validate the inputs in this component
		 * 
		 * firstname:
		 * @type {string}
		 * Propety that indicates the name of the colaborator or admin
		 * 
		 * secondName:
		 * @type {string}
		 * Propety that indicates the second name of the colaborator or admin
		 * 
		 * lastname:
		 * @type {string}
		 * Propety that indicates the last name of the colaborator or admin
		 * 
		 * secondLastname:
		 * @type {string}
		 * Propety that indicates the second last name of the colaborator or admin
		 * 
		 * identificationID:
		 * @type {string}
		 * Propety that indicates the identification of the colaborator or admin
		 * 
		 * password:
		 * @type {string}
		 * Propety that indicates the password of the colaborator or admin account
		 * 
		 * actualPassword:
		 * @type {string}
		 * Propety that indicates the actual password of the colaborator or admin account
		 * 
		 * newPassword:
		 * @type {string}
		 * Propety that indicates the new password of the colaborator or admin account
		 * 
		 * confirmPassword:
		 * @type {string}
		 * Propety that indicates the confirmation password of the colaborator or admin account
		 * 
		 * birthdate:
		 * @type {string}
		 * Propety that indicates the birth date of the colaborator or admin
		 * 
		 * phone:
		 * @type {string}
		 * Propety that indicates the first phone of the colaborator or admin
		 * 
		 * phone2:
		 * @type {string}
		 * Propety that indicates the second phone of the colaborator or admin
		 * 
		 * email:
		 * @type {string}
		 * Propety that indicates the email address of the colaborator or admin
		 * 
		 * userTypeID:
		 * @type {Integer}
		 * Propety that indicates the user type ID
		 * 
		 * countryID:
		 * @type {Integer}
		 * Propety that indicates the country ID
		 * 
		 * country:
		 * @type {array}
		 * Propety that indicates and contain all countries in the database
		 * 
		 * countactList:
		 * @type {array}
		 * Propety that indicates and contain all contact list in the database
		 */
		validations: new validations(),
		userID: sessionStorage.getItem('user'),
		emailID: 0,
		phoneID: 0,
		phone2ID: 0,
		firstname: '',
		secondName: '',
		lastname: '',
		secondLastname: '',
		identificationID: '',
		password: '',
		actualPassword: '',
		newPassword: '',
		confirmPassword: '',
		birthdate: '',
		phone: '',
		phone2: '',
		email: '',
		auxEmail: '',
		userTypeID: 0,
		countryID: 0,
		country: [],
		contactList: [],
		emails: []
	};

	handleInput = this.handleInput.bind(this);
	valueCountries = this.valueCountries.bind(this);
	handleSubmit = this.handleSubmit.bind(this);
	getUserData = this.getUserData.bind(this);
	getCountry = this.getCountry.bind(this);
	validateData = this.validateData.bind(this);
	empty = this.empty.bind(this);
	validateEmail = this.validateEmail.bind(this);

	/**
	 * Method that specify the different alerts in the component
	 * @param {event} event
	 * @param {value} value
	 * @param {string} msj
	 */
	notify = (evt, value, msj) => {
		switch (value) {
			case 'SUCCESS':
				toast.success(msj);
				break;
			case 'ERROR':
				toast.error(msj);
				break;
			case 'WARN':
				toast.warn(msj);
				break;
			case 'INFO':
				toast.info(msj);
				break;
			default:
				toast.info(msj);
		}
	};

	/**
	 * Method that allow use the enter button
	 * @param {event} e
	 */
	onKeyEvent = (e) => {
		if (e.key === 'Enter') {
			this.validateData(e);
		}
	};

	/**
	 * Method that validate the value to the variables in the inpust of the perfil configuration
	 * @param {event} event
	 */
	validateData(event) {
		if (this.empty(event)) {
			if (!this.state.validations.validateTextField(this.state.firstname)) {
				this.notify(event, 'ERROR', 'El nombre del usuario no debe contener caracteres especiales ni números');
			} else if (
				!this.state.validations.validateTextField(this.state.secondName) &&
				this.state.secondName !== ''
			) {
				this.notify(
					event,
					'ERROR',
					'El segundo nombre del  usuario no debe contener caracteres especiales ni números'
				);
			} else if (!this.state.validations.validateTextField(this.state.lastname)) {
				this.notify(
					event,
					'ERROR',
					'El apellido del usuario no debe contener caracteres especiales ni números'
				);
			} else if (!this.state.validations.validateTextField(this.state.secondLastname)) {
				this.notify(
					event,
					'ERROR',
					'El segundo apellido del usuario no debe contener caracteres especiales ni números'
				);
			} else if (!this.state.validations.validateNumericField(this.state.identificationID)) {
				this.notify(event, 'ERROR', 'La identificaión solo debe contener números');
			} else if (this.validateEmail(this.state.email) === true && this.state.email !== this.state.auxEmail) {
				this.notify(event, 'ERROR', 'El correo ingresado ya existe en el sistema');
			} else if (!this.state.validations.validateEmailField(this.state.email)) {
				this.notify(event, 'ERROR', 'El correo ingresado posee un formato incorrecto');
			} else if (!this.state.validations.validateNumericField(this.state.phone)) {
				this.notify(event, 'ERROR', 'El teléfono solo debe contener números');
			} else if (!this.state.validations.validateNumericField(this.state.phone2)) {
				this.notify(event, 'ERROR', 'El segundo teléfono solo debe contener números');
			} else if (
				!this.state.validations.validatePasswordField(this.state.newPassword) &&
				this.state.newPassword.length !== 0
			) {
				this.notify(event, 'ERROR', 'La contraseña debe tener mayúsculas, minúsculas, números y caracteres');
			} else if (this.state.newPassword !== this.state.confirmPassword) {
				this.notify(
					event,
					'ERROR',
					'La nueva contraseña y la confirmación de la contraseña son diferentes, por favor verifique su contraseña'
				);
			} else if (
				this.state.password !== md5(this.state.actualPassword) &&
				this.state.actualPassword.length !== 0
			) {
				this.notify(
					event,
					'ERROR',
					'La contraseña registrada en el servidor y la contraseña actual son diferentes, por favor verifique su contraseña'
				);
			} else if (!this.state.validations.validateDateField(this.state.birthdate)) {
				this.notify(event, 'ERROR', 'Formato de fecha de nacimiento incorrecto');
			} else {
				this.handleSubmit(event);
			}
		}
	}

	/**
	 * Method that determine if the value to the variables are empty
	 * @param {event} event
	 */
	empty(event) {
		if (this.state.firstname.length === 0) {
			this.notify(event, 'ERROR', 'El nombre no debe estar vacío');
			return false;
		} else if (this.state.lastname.length === 0) {
			this.notify(event, 'ERROR', 'El apellido no debe estar vacío');
			return false;
		} else if (this.state.identificationID.length === 0) {
			this.notify(event, 'ERROR', 'La identificación no debe estar vacía');
			return false;
		} else if (this.state.birthdate.length === 0) {
			this.notify(event, 'ERROR', 'Debe seleccionar una fecha de nacimiento');
			return false;
		} else if (this.state.actualPassword.length === 0 && this.state.newPassword.length !== 0) {
			this.notify(event, 'ERROR', 'Si desea cambiar su contraseña, la contraseña actual no debe estar vacía');
			return false;
		} else if (this.state.actualPassword.length === 0 && this.state.confirmPassword.length !== 0) {
			this.notify(event, 'ERROR', 'Si desea cambiar su contraseña, la contraseña actual no debe estar vacía');
			return false;
		} else if (this.state.actualPassword.length !== 0 && this.state.newPassword.length === 0) {
			this.notify(event, 'ERROR', 'Si desea cambiar su contraseña, la contraseña nueva no debe estar vacía');
			return false;
		} else if (this.state.actualPassword.length !== 0 && this.state.confirmPassword.length === 0) {
			this.notify(
				event,
				'ERROR',
				'Si desea cambiar su contraseña, la confirmación de contraseña no debe estar vacía'
			);
			return false;
		} else if (this.state.phone.length === 0) {
			this.notify(event, 'ERROR', 'El teléfono no debe estar vacío');
			return false;
		} else if (this.state.email.length === 0) {
			this.notify(event, 'ERROR', 'Debe ingresar un correo electrónico');
			return false;
		} else if (this.state.countryID === 0) {
			this.notify(event, 'ERROR', 'Debe seleccionar un país');
			return false;
		} else {
			return true;
		}
	}

	/**
	 * Method that validate if email exist in a database or not
	 * @param {string} auxEmail
	 * @param {boolean} validateE
	 */
	validateEmail(auxEmail) {
		var validateE = false;
		const emailsList = this.state.emails;
		console.log('Correo aux: ' + auxEmail);
		emailsList.map(function(email) {
			if (email.value === auxEmail) {
				validateE = true;
			}
		});

		if (validateE === true) {
			return true;
		} else {
			return false;
		}
	}

	/**
	 * Method that get all emails from de database and set in the emails
	 */
	getEmails() {
		axios
			.get(baseUrl + 'perfilConfiguration/getEmails')
			.then((response) => {
				var emails = response.data;
				this.setState({
					emails
				});
			})
			.catch((err) => console.error(err));
	}

	/**
	 * Method that get all countries from de database and set in the country
	 */
	getCountry() {
		axios
			.get(baseUrl + 'perfilConfiguration/getAllCountries')
			.then((response) => {
				var country = response.data;
				console.log(country.length);
				this.setState({
					country
				});
			})
			.catch((err) => console.error(err));
	}

	/**
	 * Method that get all personal information of the user from the database and set in the variables
	 * @param {Integer} auxUserID
	 */
	getUserData(auxUserID) {
		axios
			.get(baseUrl + 'perfilConfiguration/getUser', {
				params: { userID: auxUserID }
			})
			.then((response) => {
				var user = response.data[0];
				this.setState({
					firstname: user.firstname,
					secondName: user.secondName,
					lastname: user.lastname,
					secondLastname: user.secondLastname,
					identificationID: user.identificationID,
					password: user.password,
					birthdate: format(new Date(user.birthdate), 'yyyy-MM-dd'),
					countryID: user.countryID,
					userTypeID: user.userTypeID
				});
				console.log(new Date(user.birthdate).toLocaleDateString());
				axios
					.get(baseUrl + 'perfilConfiguration/getUserContact', {
						params: { userID: auxUserID }
					})
					.then((response) => {
						var contactList = response.data;
						this.setState({
							contactList
						});
						contactList.map((contact) => {
							var contactTypeID = contact.contactTypeID;
							if (contactTypeID === 1) {
								this.setState({
									email: contact.value,
									auxEmail: contact.value,
									emailID: contact.contactID
								});
							} else {
								if (contactTypeID === 2 && contact.priority === 1) {
									this.setState({
										phone: contact.value,
										phoneID: contact.contactID
									});
								} else {
									this.setState({
										phone2: contact.value,
										phone2ID: contact.contactID
									});
								}
							}
						});
					});
			});
	}

	/**
	 * Method that assigns the value to the variables of the admin or colaborator perfil configuration
	 * @param {event} e
	 */
	handleInput(e) {
		const { value, name } = e.target;
		this.setState({
			[name]: value
		});
	}

	/**
	 * Method that charge the diferents methods and use the user ID
	 */
	componentDidMount() {
		if(sessionStorage.getItem('userType') == 2 || sessionStorage.getItem('userType') == 3 ){
		var auxUserID = this.state.userID;
		this.getUserData(auxUserID);
		this.getCountry();
		this.getEmails();
		}else{
			this.props.history.push('/');
		}
	}

	/**
	 * Method that set a country ID in the countryID
	 * @param {event} event
	 */
	valueCountries(event) {
		console.log(event.value);
		this.setState({
			countryID: event.value
		});
	}

	/**
	 * Method that charge all values of the admin perfil configuration and call the api to update the admin or colaboration information and their contacts
	 * @param {event} event
	 */
	handleSubmit(event) {
		var auxPassword = '';
		if (this.state.newPassword.length === 0) {
			auxPassword = this.state.password;
		} else {
			auxPassword = md5(this.state.newPassword);
		}
		event.preventDefault();
		fetch(`${baseUrl}perfilConfiguration/editUser`, {
			method: 'post',
			body: JSON.stringify({
				userID: this.state.userID,
				firstname: this.state.firstname,
				secondName: this.state.secondName,
				lastname: this.state.lastname,
				secondLastname: this.state.secondLastname,
				identificationID: this.state.identificationID,
				password: auxPassword,
				birthdate: this.state.birthdate,
				countryID: this.state.countryID,
				userTypeID: this.state.userTypeID
			}),
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			}
		})
			.then(() => {
				fetch(`${baseUrl}perfilConfiguration/editContact`, {
					method: 'post',
					body: JSON.stringify({
						email: this.state.email,
						emailID: this.state.emailID
					}),
					headers: {
						Accept: 'application/json',
						'Content-Type': 'application/json'
					}
				})
					.then(() => {
						fetch(`${baseUrl}perfilConfiguration/editContact`, {
							method: 'post',
							body: JSON.stringify({
								email: this.state.phone,
								emailID: this.state.phoneID
							}),
							headers: {
								Accept: 'application/json',
								'Content-Type': 'application/json'
							}
						})
							.then(() => {
								fetch(`${baseUrl}perfilConfiguration/editContact`, {
									method: 'post',
									body: JSON.stringify({
										email: this.state.phone2,
										emailID: this.state.phone2ID
									}),
									headers: {
										Accept: 'application/json',
										'Content-Type': 'application/json'
									}
								})
									.then(() => {
										this.props.history.push({
											pathname: '/admin'
										});
									})
									.catch((err) => console.log(err));
							})
							.catch((err) => console.log(err));
					})
					.catch((err) => console.log(err));
			})
			.catch((err) => console.log(err));
	}

	/**
	 * Method that render the view with the all inputs and selects to update an admin or colaborator
	 * @param {array} auxCountries
	 * @param {array} auxCrty
	 */
	render() {
		const auxCountries = this.state.country.map(function(auxCountry) {
			return {
				label: auxCountry.name,
				value: auxCountry.countryID
			};
		});

		const auxCrty = auxCountries.filter((e) => e.value === this.state.countryID)[0];
		return (
			<Fragment>
				<MDBContainer>
					<ToastContainer
						position="top-right"
						autoClose={5000}
						hideProgressBar
						newestOnTop
						closeOnClick
						rtl={false}
						pauseOnVisibilityChange
						draggable
						pauseOnHover
					/>
					<MDBRow>
						<MDBCol className="col-12 col-lg-9 offset-lg-3 mt-3">
							<h3 className="adminTitlesFont">Configuración de Perfil</h3>
						</MDBCol>
						<MDBCol className="col-12 col-lg-9 offset-lg-3">
							<MDBCard style={{ padding: '20px 20px 20px 20px' }}>
								<MDBCardTitle className="cardHeader">Usuario</MDBCardTitle>
								<MDBCardBody className="adminFont">
									<MDBRow>
										<MDBCol className="col-12 col-md-4">
											<FormGroup>
												<label>
													Nombre <font color="red">*</font>
												</label>
												<input
													className="form-control "
													value={this.state.firstname}
													type="text"
													name="firstname"
													onChange={this.handleInput}
													onKeyPress={this.onKeyEvent}
												/>
											</FormGroup>
										</MDBCol>
										<MDBCol className="col-12 col-md-4">
											<FormGroup>
												<label>Segundo Nombre</label>
												<input
													className="form-control "
													value={this.state.secondName}
													type="text"
													name="secondName"
													onChange={this.handleInput}
													onKeyPress={this.onKeyEvent}
												/>
											</FormGroup>
										</MDBCol>
										<MDBCol className="col-12 col-md-4">
											<FormGroup>
												<label>
													Identificación <font color="red">*</font>
												</label>
												<input
													className="form-control "
													value={this.state.identificationID}
													type="text"
													name="identificationID"
													onChange={this.handleInput}
													onKeyPress={this.onKeyEvent}
												/>
											</FormGroup>
										</MDBCol>
									</MDBRow>
									<MDBRow>
										<MDBCol className="col-12 col-md-4">
											<FormGroup>
												<label>
													Apellido <font color="red">*</font>
												</label>
												<input
													className="form-control "
													value={this.state.lastname}
													type="text"
													name="lastname"
													onChange={this.handleInput}
													onKeyPress={this.onKeyEvent}
												/>
											</FormGroup>
										</MDBCol>
										<MDBCol className="col-12 col-md-4">
											<FormGroup>
												<label>
													Segundo Apellido <font color="red">*</font>
												</label>
												<input
													className="form-control "
													value={this.state.secondLastname}
													type="text"
													name="secondLastname"
													onChange={this.handleInput}
													onKeyPress={this.onKeyEvent}
												/>
											</FormGroup>
										</MDBCol>
										<MDBCol className="col-12 col-md-4">
											<FormGroup>
												<label>
													Fecha de nacimiento <font color="red">*</font>
												</label>
												<input
													className="form-control "
													value={this.state.birthdate}
													type="date"
													name="birthdate"
													onChange={this.handleInput}
													onKeyPress={this.onKeyEvent}
												/>
											</FormGroup>
										</MDBCol>
									</MDBRow>
									<MDBRow>
										<MDBCol className="col-12 col-md-4">
											<FormGroup>
												<label>
													Telefono #1 <font color="red">*</font>
												</label>
												<input
													className="form-control "
													value={this.state.phone}
													type="text"
													name="phone"
													onChange={this.handleInput}
													onKeyPress={this.onKeyEvent}
												/>
											</FormGroup>
										</MDBCol>
										<MDBCol className="col-12 col-md-4">
											<FormGroup>
												<label>Telefono #2</label>
												<input
													className="form-control "
													value={this.state.phone2}
													type="text"
													name="phone2"
													onChange={this.handleInput}
													onKeyPress={this.onKeyEvent}
												/>
											</FormGroup>
										</MDBCol>
										<MDBCol className="col-12 col-md-4">
											<FormGroup>
												<label>
													Correo Electrónico <font color="red">*</font>
												</label>
												<input
													className="form-control "
													value={this.state.email}
													type="text"
													name="email"
													onChange={this.handleInput}
													onKeyPress={this.onKeyEvent}
												/>
											</FormGroup>
										</MDBCol>
									</MDBRow>
									<MDBRow>
										<MDBCol className="col-12 col-md-6">
											<FormGroup>
												<label>
													País <font color="red">*</font>
												</label>
												<Select
													className="selectLetter"
													name="country"
													components={animatedComponents}
													options={auxCountries}
													classNamePrefix="select"
													placeholder="Seleccione un país"
													onChange={this.valueCountries}
													value={auxCrty}
													theme={(theme) => ({
														...theme,
														borderRadius: 4,
														font: 'Open Sans',
														height: 9,

														colors: {
															...theme.colors,
															primary25: '#FFA93F',
															primary: '#808080'
														}
													})}
												/>
											</FormGroup>
										</MDBCol>
										<MDBCol className="col-12 col-md-6">
											<FormGroup>
												<label>
													Contraseña Actual{' '}
													<MDBTooltip placement="bottom">
														<MDBBtn color="passwordBtn" className="passwordBtn">
															<MDBIcon icon="info-circle" className="red-text" />
														</MDBBtn>
														<div>Ejemplo de de contraseña correcta: Asssdd.12assA</div>
													</MDBTooltip>{' '}
												</label>
												<input
													className="form-control "
													value={this.state.actualPassword}
													type="password"
													name="actualPassword"
													onChange={this.handleInput}
													onKeyPress={this.onKeyEvent}
												/>
											</FormGroup>
										</MDBCol>
									</MDBRow>
									<MDBRow>
										<MDBCol className="col-12 col-md-6">
											<FormGroup>
												<label>
													Nueva Contraseña{' '}
													<MDBTooltip placement="bottom">
														<MDBBtn color="passwordBtn" className="passwordBtn">
															<MDBIcon icon="info-circle" className="red-text" />
														</MDBBtn>
														<div>Ejemplo de de contraseña correcta: Asssdd.12assA</div>
													</MDBTooltip>{' '}
												</label>
												<input
													className="form-control "
													value={this.state.newPassword}
													type="password"
													name="newPassword"
													onChange={this.handleInput}
													onKeyPress={this.onKeyEvent}
												/>
											</FormGroup>
										</MDBCol>
										<MDBCol className="col-12 col-md-6">
											<FormGroup>
												<label>
													Confirmar Contraseña{' '}
													<MDBTooltip placement="bottom">
														<MDBBtn color="passwordBtn" className="passwordBtn">
															<MDBIcon icon="info-circle" className="red-text" />
														</MDBBtn>
														<div>Ejemplo de de contraseña correcta: Asssdd.12assA</div>
													</MDBTooltip>{' '}
												</label>
												<input
													className="form-control "
													value={this.state.confirmPassword}
													type="password"
													name="confirmPassword"
													onChange={this.handleInput}
													onKeyPress={this.onKeyEvent}
												/>
											</FormGroup>
										</MDBCol>
									</MDBRow>
								</MDBCardBody>
								<MDBCardFooter color="white">
									<Link to={'/admin'}>
										<MDBBtn color="purpleButton" className="modalButton purpleButton">
											<span className="modalButtonText">Cancelar</span>
										</MDBBtn>
									</Link>
									<MDBBtn
										color="greenButton"
										className="modalButton greenButton"
										type="submit"
										onClick={this.validateData}
									>
										<span className="modalButtonText">Guardar</span>
									</MDBBtn>
								</MDBCardFooter>
							</MDBCard>
						</MDBCol>
					</MDBRow>

					{/*-------------------------AQUI TERMINA LO DE LA TABLA----------------------------------------  */}
				</MDBContainer>
			</Fragment>
		);
	}
}

export default AdminPerfilConfiguration;
