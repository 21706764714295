/**
 * @fileoverview  UserCommunity this component consists of the community, events, comments, work, it is the presentation for the user.
 *
 * @version 1.0
 * @author Estrella López López <estrella.lopez@ucrso.info>
 *
 * History - Initial Release
 * ----
 * The first version of UserCommunity was written by  Estrella López López.
 */

import React, { Component } from "react";
import axios from "axios";
import { baseUrl } from "./baseUrl";
import { Container, Row, Col, Card } from "mdbreact";
import imagen from "../images/opcion 3.jpg";
import imagenSiAgile from "../images/si.PNG";
import {
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBContainer,
  MDBModalFooter,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBBtn,
  MDBCarousel,
  MDBCarouselCaption,
  MDBCarouselInner,
  MDBCarouselItem,
  MDBView,
  MDBMask,
} from "mdbreact";
import { FormGroup } from "reactstrap";
import validations from "./Validations";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";

class UserCommunity extends Component {
  constructor(props) {
    super(props);
    /**
     * validations:
     * @type {object}
     * Property that has methods for validate with regular expressions
     *
     * description:
     * @type {String}
     * Property that contains the description of the event
     *
     * eventName:
     * @type {String}
     * Property that contains the name of the event
     *
     * date:
     * @type {DateTime}
     * Property that contains the date and time of the event
     *
     * place:
     * @type {String}
     * Property that contains the place of the event
     *
     * eventLink:
     * @type {String}
     * Property that contains the link of the event
     *
     * stateCommunity
     * @type {integer}
     * Property that stores the state of the community.
     *
     * events:
     * @type {Array}
     * Property that stores the list of events.
     *
     * eventData:
     * @type {Array}
     * Property that stores the list information of event.
     *
     * jobName:
     * @type {Sring}
     * Property that stores the name of the job.
     *
     * jobPlace:
     * @type {String}
     * Property that stores the place of the job.
     *
     * jobTitle:
     * @type {String}
     * Property that stores the job title.
     *
     * jobLanguaje:
     * @type {String}
     * Property that stores the languaje of the job.
     *
     * jobAmount:
     * @type {String}
     * Property that stores the amount of money of the job.
     *
     * jobContact:
     * @type {String}
     * Property that stores the contact of the job.
     *
     * coins:
     * @type {Array}
     * Property that stores a list of currency types.
     *
     * coinID:
     * @type {Integer}
     * Property that stores the id of coin.
     *
     * coinName:
     * @type {String}
     * Property that stores the type of coin.
     *
     * languages:
     * @type {Array}
     * Property that stores the list of languages ​​that exist in the database.
     *
     * jobs:
     * @type {Array}
     * Property that stores the list of jobs.
     *
     * jobData:
     * @type {Array}
     * Property that stores the list of jobs.
     *
     * comment:
     * @type {String}
     * Property that contains the comment of user.
     *
     * colorName:
     * @type {String}
     * Property that contains the name of the selected color.
     *
     * colors:
     * @type {Array}
     * Property that contains the list of colors.
     */
    this.state = {
      validations: new validations(),
      description: "",
      eventName: "",
      date: "",
      place: "",
      eventLink: "",
      stateCommunity: 0,
      events: [],
      eventData: [],
      jobName: "",
      jobPlace: "",
      jobTitle: "",
      jobLanguaje: 1,
      jobAmount: "",
      jobContact: "",
      coins: [{}],
      coinID: 0,
      coinName: "Colones",
      languages: [{}],
      jobs: [],
      jobData: [],
      comment: "",
      comments: [],
      colorName: "",
      colors: [
        { id: 0, name: "orange" },
        { id: 1, name: "red" },
        { id: 2, name: "cyan" },
        { id: 3, name: "green" },
        { id: 4, name: "brown" },
        { id: 5, name: "gray" },
        { id: 6, name: "navy" },
        { id: 7, name: "golden" },
        { id: 8, name: "purple" },
        { id: 9, name: "black" },
        { id: 10, name: "teal" },
        { id: 9, name: "lime" },
      ],
    };

    this.getData = this.getData.bind(this);
    this.eventModal = this.eventModal.bind(this);
    this.eventDetailModal = this.eventDetailModal.bind(this);
    this.jobDetailModal = this.jobDetailModal.bind(this);
    this.jobModal = this.jobModal.bind(this);
    this.commentModal = this.commentModal.bind(this);
    this.validateData = this.validateData.bind(this);
    this.validateDataComment = this.validateDataComment.bind(this);
    this.empty = this.empty.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.valueName = this.valueName.bind(this);
    this.valueDescription = this.valueDescription.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.valueEventLink = this.valueEventLink.bind(this);
    this.valuePlace = this.valuePlace.bind(this);
    this.colorSelect = this.colorSelect.bind(this);
    this.valueComment = this.valueComment.bind(this);
    this.valueNameJob = this.valueNameJob.bind(this);
    this.dataSelect = this.dataSelect.bind(this);
    this.languageSelect = this.languageSelect.bind(this);
    this.getCoins = this.getCoins.bind(this);
    this.validateDataJob = this.validateDataJob.bind(this);
    this.valuePlaceJob = this.valuePlaceJob.bind(this);
    this.valueTitleJob = this.valueTitleJob.bind(this);
    this.valueContactJob = this.valueContactJob.bind(this);
    this.coinSelect = this.coinSelect.bind(this);
    this.valueAmountJob = this.valueAmountJob.bind(this);
    this.loadgetEvent = this.loadgetEvent.bind(this);
    this.loadgetJob = this.loadgetJob.bind(this);
    this.comment = this.comment.bind(this);
    this.getEvent = this.getEvent.bind(this);
    this.loadgetComment = this.loadgetComment.bind(this);
    this.getJob = this.getJob.bind(this);
  }
  /**
   * Method that loads the elements to be initially displayed on the website.
   */
  componentDidMount() {
    if (
      sessionStorage.getItem("userType") != 2 &&
      sessionStorage.getItem("userType") != 3
    ) {
      this.getData();
      this.dataSelect();
      this.getCoins();
      this.loadgetEvent();
      this.loadgetJob();
      this.loadgetComment();
    } else {
      this.props.history.push("/admin");
    }
  }

  /**
   * Method that brings the comments that have been accepted for publication from the database.
   */
  loadgetComment() {
    axios.get(baseUrl + "courseUser/getAllCommentary").then((response) => {
      this.state.comments = response.data;
      this.setState({ comments: response.data });
    });
  }

  /**
   * Method that displays previously accepted comments in a carousel.
   */
  comment() {
    var tempArray = [];
    this.state.comments.map((elements, key) => {
      tempArray.push(
        <>
          <MDBCarouselItem itemId={key + 1}>
            <MDBView>
              <Card
                style={{
                  width: 900,
                  height: 250,
                  backgroundColor: elements.color,
                  alignContent: "center",
                }}
              ></Card>

              <MDBMask overlay="orange-light" />
            </MDBView>
            <MDBCarouselCaption>
              <p className=" commentText ">
                {" "}
                <MDBIcon icon="quote-left" size="sm" /> {elements.description}{" "}
                <MDBIcon icon="quote-right" size="sm" />
              </p>
              <h4 className="commentTextFullName">{elements.fullname}</h4>
              <br />
            </MDBCarouselCaption>
          </MDBCarouselItem>
        </>
      );
    });
    return tempArray;
  }

  /**
   * Method that assigns the chosen value of the selection to the variable.
   * @param {event} event
   */
  coinSelect(event) {
    this.state.coinName = event.label;
    this.setState({
      coinID: parseInt(event.value),
      coinName: event.label,
    });
  }

  /**
   * Method that loads the event information from the database.
   * @param {Integer} advertisementID
   */
  getEvent(advertisementID) {
    axios
      .get(baseUrl + "adv_Event/getAdvEventUser", {
        params: { advertisementID: advertisementID },
      })
      .then((response) => {
        this.state.eventData = response.data[0];
        this.setState({
          eventData: response.data[0],
        });
      });
  }

  /**
   * Method that loads the job information from the database.
   * @param {Integer} advertisementID
   */
  getJob(advertisementID) {
    axios
      .get(baseUrl + "adv_Event/getAdvJobUser", {
        params: { advertisementID: advertisementID },
      })
      .then((response) => {
        this.state.jobData = response.data[0];
        this.setState({
          jobData: response.data[0],
        });
      });
  }

  /**
   * Method that loads a list of currency types found in the database.
   */
  getCoins() {
    axios
      .get(baseUrl + "quotation/getCoins")
      .then((response) => {
        var coins = response.data;
        this.setState({
          coins,
        });
      })
      .catch((err) => console.error("Ha ocurrido un error inesperado"));
  }

  /**
   * Method that, depending on the value of the variable, displays the type of message it is, if it is
   * correctly entered, there was an error, an informational message, or a warning message.
   * @param {String} evt
   * @param {String} value
   * @param {String} msj
   */
  notify = (evt, value, msj) => {
    switch (value) {
      case "SUCCESS":
        toast.success(msj);
        break;
      case "ERROR":
        toast.error(msj);
        break;
      case "WARN":
        toast.warn(msj);
        break;
      case "INFO":
        toast.info(msj);
        break;
      default:
        toast.info(msj);
    }
  };

  /**
   * Method that displays the modals and validates if it is found in the community.
   * @param {String} text
   * @param {Integer} state
   * @param {Integer} id
   * @param {String} name
   * @param {Integer} eventID
   * @param {Integer} event
   */
  toggle = (text, state, id, name, eventID, event) => () => {
    if (
      (sessionStorage.getItem("userType") == 1 && state === 1) ||
      state === 0
    ) {
      let modalAction = text + "Modal";
      if (eventID != null) {
        if (id === 0) {
          if (eventID !== 0) {
            this.getEvent(eventID);
          }
          this.state.stateCommunity = 1;
          this.setState({ stateCommunity: 1 });

          this.setState({
            [modalAction]: !this.state[modalAction],
            eventID: id,
          });
        } else if (id === 2) {
          this.state.stateCommunity = 2;
          this.setState({ stateCommunity: 2 });
          this.setState({
            [modalAction]: !this.state[modalAction],
            eventID: id,
          });
        } else if (id === 1) {
          this.state.stateCommunity = 3;
          this.setState({ stateCommunity: 3 });
          if (eventID !== 0) {
            this.getJob(eventID);
          }

          this.setState({
            [modalAction]: !this.state[modalAction],
            eventID: id,
          });
        }
      } else {
        if (this.state[modalAction] === true) {
          this.setState({
            [modalAction]: !this.state[modalAction],
            eventID: 0,
          });
        } else {
          this.setState({
            [modalAction]: !this.state[modalAction],
          });
        }
      }
    } else if (
      sessionStorage.getItem("userType") == 2 ||
      sessionStorage.getItem("userType") == 3
    ) {
      this.props.history.push("/admin");
    } else {
      this.notify(
        event,
        "WARN",
        "Debe iniciar sesión para agregar a la comunidad"
      );
    }
  };

  /**
   * Method that validates the data entered by the user, the way it should be written.
   * @param {event} event
   */
  validateData(event) {
    if (this.empty(event)) {
      if (
        !this.state.validations.validateTextWithNumberField(
          this.state.eventName
        )
      ) {
        this.notify(
          event,
          "ERROR",
          "El nombre del evento no debe contener caracteres especiales"
        );
      } else if (
        !this.state.validations.validateTextWithNumberField(this.state.place)
      ) {
        this.notify(
          event,
          "ERROR",
          "El lugar del evento no debe contener caracteres especiales"
        );
      } else if (
        this.state.eventLink.length != 0 &&
        !this.state.validations.validateURLField(this.state.eventLink)
      ) {
        this.notify(event, "ERROR", "El formato del link de  no es correcto");
      } else if (
        this.state.description.length != 0 &&
        !this.state.validations.validateTextWithNumberField(
          this.state.description
        )
      ) {
        this.notify(
          event,
          "ERROR",
          "La descripción del evento no debe contener caracteres especiales"
        );
      } else if (
        !this.state.validations.validateDateTimeField(this.state.date)
      ) {
        this.notify(event, "ERROR", "La fecha no tiene el formato correcto");
      } else {
        this.handleSubmit(event);
      }
    }
  }

  /**
   * Method that validates that the required event fields are not empty.
   * @param {event} event
   */
  empty(event) {
    if (this.state.eventName.length === 0) {
      this.notify(event, "ERROR", "El nombre del evento no puede estar vacío");
      return false;
    } else if (this.state.place.length === 0) {
      this.notify(event, "ERROR", "El lugar del evento no puede estar vacío");
      return false;
    } else if (this.state.date.length === 0) {
      this.notify(
        event,
        "ERROR",
        "La fecha y hora del evento no pueden estar vacías"
      );
      return false;
    } else if (this.state.eventLink.length === 0) {
      this.notify(
        event,
        "ERROR",
        "El link o el registro del evento no pueden estar vacío"
      );
      return false;
    } else {
      return true;
    }
  }

  /**
   * Method that validates the data entered by the user, the way it should be written.
   * @param {event} event
   */
  validateDataJob(event) {
    if (this.emptyJob(event)) {
      if (
        !this.state.validations.validateTextWithNumberField(this.state.jobName)
      ) {
        this.notify(
          event,
          "ERROR",
          "El nombre de la empresa no debe contener caracteres especiales"
        );
      } else if (
        !this.state.validations.validateTextWithNumberField(this.state.jobPlace)
      ) {
        this.notify(
          event,
          "ERROR",
          "El lugar no debe contener caracteres especiales"
        );
      } else if (
        this.state.description.length != 0 &&
        !this.state.validations.validateTextWithNumberField(
          this.state.description
        )
      ) {
        this.notify(
          event,
          "ERROR",
          "La descripción  no debe contener caracteres especiales"
        );
      } else if (
        !this.state.validations.validateNumericField(this.state.jobAmount)
      ) {
        this.notify(
          event,
          "ERROR",
          "El monto no debe contener caracteres especiales"
        );
      } else if (
        !this.state.validations.validateTextWithNumberField(this.state.jobTitle)
      ) {
        this.notify(
          event,
          "ERROR",
          "El puesto no debe contener caracteres especiales"
        );
      } else {
        this.handleSubmit(event);
      }
    }
  }
  /**
   * Method that validates that the required job fields are not empty.
   * @param {event} event
   */
  emptyJob(event) {
    if (this.state.jobName.length === 0) {
      this.notify(
        event,
        "ERROR",
        "El nombre de la empresa no puede estar vacío"
      );
      return false;
    } else if (this.state.jobPlace.length === 0) {
      this.notify(event, "ERROR", "El lugar no puede estar vacío");
      return false;
    } else if (this.state.jobTitle.length === 0) {
      this.notify(event, "ERROR", "El puesto no puede estar vacío");
      return false;
    } else if (this.state.jobLanguaje.length === 0) {
      this.notify(event, "ERROR", "El lenguaje no puede  estar vacío");
      return false;
    } else if (this.state.jobContact.length === 0) {
      this.notify(event, "ERROR", "El contacto no puede  estar vacío");
      return false;
    } else if (this.state.description.length === 0) {
      this.notify(event, "ERROR", "La descripción no puede  estar vacía");
      return false;
    } else {
      return true;
    }
  }

  /**
   * Method that validates the data entered by the user, the way it should be written.
   * @param {event} event
   */
  validateDataComment(event) {
    if (this.emptyComment(event)) {
      if (
        !this.state.validations.validateTextWithNumberField(this.state.comment)
      ) {
        this.notify(
          event,
          "ERROR",
          "El comentario no debe contener caracteres especiales"
        );
      } else {
        this.handleSubmit(event);
      }
    }
  }

  /**
   * Method that validates that the required comment fields are not empty.
   * @param {event} event
   */
  emptyComment(event) {
    if (this.state.colorName.length === 0) {
      this.notify(event, "ERROR", "El color no puede estar vacío");
      return false;
    } else {
      return true;
    }
  }

  /**
   * Method that adds the values ​​to the database, if an event, job or comment is added.
   * @param {event} event
   */
  handleSubmit(event) {
    let modalAction = "event" + "Modal";
    if (this.state.stateCommunity === 1) {
      fetch(baseUrl + "adv_Event/addAdvEvent", {
        method: "post",
        body: JSON.stringify({
          description: this.state.description,
          name: this.state.eventName,
          date: this.state.date,
          place: this.state.place,
          resgisterLink: this.state.eventLink,
          userID: sessionStorage.getItem("user"),
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then(
          this.setState({
            addModal: false,
            show: false,
          }),
          this.setState({
            [modalAction]: !this.state[modalAction],
            eventID: 0,
          }),
          this.notify(event, "SUCCESS", "Se ha enviado correctamente el evento")
        )
        .catch((err) => console.error(err));
    } else if (this.state.stateCommunity === 2) {
      let modalAction = "comment" + "Modal";

      fetch(baseUrl + "adv_Event/addAdvComment", {
        method: "post",
        body: JSON.stringify({
          description: this.state.comment,
          color: this.state.colorName,
          userID: sessionStorage.getItem("user"),
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then(
          this.setState({
            [modalAction]: !this.state[modalAction],
            eventID: 0,
          }),

          this.setState({
            addModal: false,

            show: true,
          }),
          this.notify(
            event,
            "SUCCESS",
            "Se ha enviado correctamente el comentario"
          )
        )
        .catch((err) => console.error(err));
    } else {
      let modalAction = "job" + "Modal";

      fetch(baseUrl + "adv_Event/addAdvJob", {
        method: "post",
        body: JSON.stringify({
          name: this.state.jobName,
          place: this.state.jobPlace,
          jobTitle: this.state.jobTitle,
          languajeID: this.state.jobLanguaje,
          amount: this.state.jobAmount,
          coinID: this.state.coinID,
          contact: this.state.jobContact,
          description: this.state.description,
          userID: sessionStorage.getItem("user"),
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then(
          this.setState({
            [modalAction]: !this.state[modalAction],
            eventID: 0,
          }),
          this.setState({
            addModal: false,
            show: false,
          }),
          (this.state.jobName = ""),
          this.setState({ jobName: "" }),
          (this.state.jobPlace = ""),
          this.setState({ jobPlace: "" }),
          (this.state.jobTitle = ""),
          this.setState({ jobTitle: "" }),
          (this.state.jobLanguaje = 0),
          this.setState({ jobLanguaje: 0 }),
          (this.state.jobAmount = 0),
          this.setState({ jobAmount: 0 }),
          (this.state.coinID = 0),
          this.setState({ coinID: 0 }),
          (this.state.jobContact = ""),
          this.setState({ jobContact: "" }),
          (this.state.description = ""),
          this.setState({ description: "" }),
          this.notify(
            event,
            "SUCCESS",
            "Se ha enviado correctamente la oferta de empleo"
          )
        )
        .catch((err) => console.error(err));
    }
    event.preventDefault();
  }

  /**
   *
   * Method that assigns the value to the elements of the event, also splits the
   * date and time to send it separately to the database.
   * @param {event} event
   */
  handleChange(event) {
    if (event.target.name === "date") {
      var dateArray = event.target.value.split("T");
      var date = dateArray[0] + " " + dateArray[1];
      this.setState({
        date,
      });
    } else {
      this.setState({
        [event.target.name]: event.target.value,
      });
    }
  }

  getData() {
    axios.get(baseUrl + "home/getHomeText").then((response) => {
      var textBD = response.data;
      textBD.map((item) => {
        if (item.description === "Principal") {
          this.setState({
            intro: item.textValue,
            introID: item.textID,
          });
        } else {
          this.setState({
            secondText: item.textValue,
            secondID: item.textID,
          });
        }
      });
    });

    axios.get(baseUrl + "home/getHomeMultimedia").then((response) => {
      this.state.link = response.data[0].link;
      this.setState({ link: response.data[0].link });
    });
  }
  /**
   * Method that brings the languages and countries entered from the database and saves them in the variables
   * previously created.
   * @param {event} event
   */
  dataSelect(event) {
    var languages = [];

    axios
      .get(baseUrl + "event/getLanguage")
      .then((response) => {
        languages = response.data;
        this.setState({
          languages,
        });
      })
      .catch((err) => console.log("Ha ocurrido un error inesperado"));
  }

  /**
   * Method that select a languaje
   * @param {event} event
   */
  languageSelect(event) {
    this.state.jobLanguaje = parseInt(event.value);
    this.setState({ jobLanguaje: event.value });
    this.setState({
      jobLanguaje: event.value,
    });
  }

  /**
   * Method that assigns the value entered to the variable.
   * @param {event} event
   */
  valueContactJob(event) {
    this.state.jobContact = event.target.value;
    this.setState({ jobContact: event.target.value });
  }

  /**
   * Method that assigns the value entered to the variable.
   * @param {event} event
   */
  valueTitleJob(event) {
    this.state.jobTitle = event.target.value;
    this.setState({ jobTitle: event.target.value });
  }

  /**
   * Method that assigns the value entered to the variable.
   * @param {event} event
   */
  valueAmountJob(event) {
    this.state.jobAmount = event.target.value;
    this.setState({ jobAmount: event.target.value });
  }

  /**
   * Method that assigns the value entered to the variable.
   * @param {event} event
   */
  valuePlaceJob(event) {
    this.state.jobPlace = event.target.value;
    this.setState({ jobPlace: event.target.value });
  }
  valueNameJob(event) {
    this.state.jobName = event.target.value;
    this.setState({ jobName: event.target.value });
  }

  /**
   * Method that assigns the value entered to the variable.
   * @param {event} event
   */
  valueName(event) {
    this.state.eventName = event.target.value;
    this.setState({ eventName: event.target.value });
  }

  /**
   * Method that assigns the value entered to the variable.
   * @param {event} event
   */
  valuePlace(event) {
    this.state.place = event.target.value;
    this.setState({ place: event.target.value });
  }

  /**
   * Method that assigns the value entered to the variable.
   * @param {event} event
   */
  valueComment(event) {
    this.state.comment = event.target.value;
    this.setState({ comment: event.target.value });
  }

  /**
   * Method that assigns the value entered to the variable.
   * @param {event} event
   */
  valueDescription(event) {
    this.state.description = event.target.value;
    this.setState({ description: event.target.value });
  }

  /**
   * Method that assigns the value entered to the variable.
   * @param {event} event
   */
  valueEventLink(event) {
    this.state.eventLink = event;
    this.setState({ eventLink: event });
  }

  /**
   * Method that select a color
   * @param {event} event
   */
  colorSelect(event) {
    this.setState({
      colorName: event.value,
    });
  }

  /**
   * Method that brings the event preview from the database.
   */
  loadgetEvent() {
    axios.get(baseUrl + "adv_Event/advEventPreview").then((response) => {
      this.state.events = response.data;
      this.setState({ events: response.data });
    });
  }

  /**
   * Method that brings the job offer preview from the database.
   */
  loadgetJob() {
    axios.get(baseUrl + "adv_Event/advJobPreview").then((response) => {
      this.state.jobs = response.data;
      this.setState({ jobs: response.data });
    });
  }

  /**
   * Method that presents the detail of the selected event.
   */
  eventDetailModal() {
    return (
      <MDBContainer>
        <MDBModal
          className="modal-lg"
          isOpen={this.state.eventDetailModal}
          toggle={this.toggle("eventDetail", 0)}
        >
          <MDBModalHeader
            className="modalHeader modalTitlesFont"
            toggle={this.toggle("eventDetail", 0)}
          >
            Detalle del evento
          </MDBModalHeader>
          <MDBModalBody>
            <FormGroup className="text-center">
              <label className="modalTextFont">
                {this.state.eventData.name}
              </label>
            </FormGroup>

            <Row className="mt-2 col-sm-12 ">
              <MDBCol className=" mt-2  col-2 offset-lg-1 col-md-1">
                <i class="fas fa-align-justify colorIcon" />
              </MDBCol>
              <MDBCol className="col-10 offset-lg-0 col-md-10">
                <label className="modalTextFont">
                  {this.state.eventData.description}
                </label>
              </MDBCol>
            </Row>

            <Row className="mt-2 col-sm-12 ">
              <MDBCol className="  col-2 offset-lg-1 col-md-1">
                <i class="fas fa-map-marker-alt colorIcon" />
              </MDBCol>
              <MDBCol className="col-10 offset-lg-0 col-md-6">
                <label className="modalTextFont">
                  {this.state.eventData.place}
                </label>
              </MDBCol>
            </Row>

            <Row className="mt-2 col-sm-12 ">
              <MDBCol className="  col-2 offset-lg-1 col-md-1">
                <i class="far fa-clock  colorIcon " />
              </MDBCol>
              <MDBCol className="   col-10 offset-lg-0 col-md-6">
                <label className="modalTextFont">
                  {this.state.eventData.date}
                </label>
              </MDBCol>
            </Row>

            <Row className="mt-2 col-sm-12 ">
              <MDBCol className="  col-2 offset-lg-1 col-md-1">
                <i class="fas fa-link colorIcon " />
              </MDBCol>
              <MDBCol className="   col-10 offset-lg-0 col-md-6">
                <label className="modalTextFont">
                  {this.state.eventData.resgisterLink}
                </label>
              </MDBCol>
            </Row>
          </MDBModalBody>
          {/**  <MDBModalFooter>
                        <MDBCol className="  col-10 offset-lg-2 col-md-12">
                        <a  >
                                        <h5 className="linkRegister " >
                                            Inscribirse al evento
                                           
                                        </h5>
                                    </a>
                         </MDBCol>
                     </MDBModalFooter>*/}
        </MDBModal>
      </MDBContainer>
    );
  }

  /**
   * Method that presents the detail of the selected job offers.
   */
  jobDetailModal() {
    return (
      <MDBContainer>
        <MDBModal
          className="modal-lg"
          isOpen={this.state.jobDetailModal}
          toggle={this.toggle("jobDetail", 0)}
        >
          <MDBModalHeader
            className="modalHeader modalTitlesFont"
            toggle={this.toggle("jobDetail", 0)}
          >
            Detalle de la oferta de empleo
          </MDBModalHeader>
          <MDBModalBody>
            <FormGroup className="text-center">
              <label className="modalTextFont">{this.state.jobData.name}</label>
            </FormGroup>

            <Row className="mt-2 col-sm-12 ">
              <MDBCol className=" mt-2  col-2 offset-lg-1 col-md-1">
                <i class="fas fa-align-justify colorIcon" />
              </MDBCol>
              <MDBCol className="col-10 offset-lg-0 col-md-10">
                <label className="modalTextFont">
                  {this.state.jobData.description}
                </label>
              </MDBCol>
            </Row>

            <Row className="mt-2 col-sm-12 ">
              <MDBCol className="  col-2 offset-lg-1 col-md-1">
                <i class="far fa-money-bill-alt colorIcon" />
              </MDBCol>
              <MDBCol className="col-10 offset-lg-0 col-md-6">
                <label className="modalTextFont">
                  {this.state.jobData.amount + " " + this.state.jobData.money}
                </label>
              </MDBCol>
            </Row>

            <Row className="mt-2 col-sm-12 ">
              <MDBCol className="  col-2 offset-lg-1 col-md-1">
                <i class="fas fa-map-marker-alt colorIcon" />
              </MDBCol>
              <MDBCol className="   col-10 offset-lg-0 col-md-10">
                <label className="modalTextFont">
                  {this.state.jobData.place}
                </label>
              </MDBCol>
            </Row>

            <Row className="mt-2 col-sm-12 ">
              <MDBCol className="  col-2 offset-lg-1 col-md-1">
                <i class="fas fa-language colorIcon" />
              </MDBCol>
              <MDBCol className="   col-10 offset-lg-0 col-md-6">
                <label className="modalTextFont">
                  {this.state.jobData.language}
                </label>
              </MDBCol>
            </Row>

            <Row className="mt-2 col-sm-12 ">
              <MDBCol className="  col-2 offset-lg-1 col-md-1">
                <i class="fas fa-id-badge colorIcon" />
              </MDBCol>
              <MDBCol className="   col-10 offset-lg-0 col-md-6">
                <label className="modalTextFont">
                  {this.state.jobData.jobTitle}
                </label>
              </MDBCol>
            </Row>

            <Row className="mt-2 col-sm-12 ">
              <MDBCol className="  col-2 offset-lg-1 col-md-1">
                <i class="fas fa-address-book colorIcon" />
              </MDBCol>
              <MDBCol className="   col-10 offset-lg-0 col-md-6">
                <label className="modalTextFont">
                  {this.state.jobData.contact}
                </label>
              </MDBCol>
            </Row>
          </MDBModalBody>
          {/**  <MDBModalFooter>
                        <MDBCol className="  col-10 offset-lg-2 col-md-12">
                        <a  >
                                        <h5 className="linkRegister " >
                                            Inscribirse al evento
                                           
                                        </h5>
                                    </a>
                         </MDBCol>
                     </MDBModalFooter>*/}
        </MDBModal>
      </MDBContainer>
    );
  }

  /**
   * Method consisting of the way in which the job offer information is going to be presented.
   */
  jobView() {
    var tempArray = [];
    this.state.jobs.map((elements, key) => {
      tempArray.push(
        <MDBRow className="mt-3 offset-lg-0">
          <MDBCol md="12">
            <MDBCard class="cardEvent">
              <MDBCardBody className="elegant-color white-text rounded-bottom">
                <div class="row row-striped">
                  <div class="col-10 offset-lg-1">
                    <MDBCardTitle style={{ color: "white" }}>
                      {elements.name}
                    </MDBCardTitle>

                    <MDBCardText id="texfont">Descripción:</MDBCardText>
                    <MDBCardText className="white-text">
                      {elements.description}
                    </MDBCardText>

                    <MDBCardText id="texfont">Lugar:</MDBCardText>
                    <MDBCardText className="white-text">
                      {elements.place}
                    </MDBCardText>
                    <a
                      className="white-text d-flex justify-content-end"
                      onClick={this.toggle(
                        "jobDetail",
                        0,
                        1,
                        "ee",
                        elements.advertisementID
                      )}
                    >
                      <h5 className="linkEdit textEvent" id="texfont">
                        Ver
                        <MDBIcon icon="angle-double-right" className="ml-1" />
                      </h5>
                    </a>
                  </div>
                </div>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      );
    });
    return tempArray;
  }

  /**
   * Method consisting of the way in which the event information is going to be presented.
   */
  eventView() {
    var tempArray = [];
    this.state.events.map((elements, key) => {
      tempArray.push(
        <MDBRow className="mt-3 offset-lg-0">
          <MDBCol md="12">
            <MDBCard class="cardEvent">
              <MDBCardBody className="elegant-color white-text rounded-bottom">
                <div class="row row-striped">
                  <div class="col-2 text-right">
                    <h1 class="display">
                      <span class="badge">{elements.day}</span>
                    </h1>
                    <h2 className="month userEventCard">{elements.month}</h2>
                  </div>

                  <div class="col-10">
                    <MDBCardTitle style={{ color: "white" }}>
                      {elements.name}
                    </MDBCardTitle>
                    <ul class="list-inline">
                      <li class="list-inline-item">
                        <i
                          class=" clockEvent fa fa-clock"
                          aria-hidden="true"
                        ></i>{" "}
                        {elements.hour}
                      </li>
                    </ul>
                    <MDBCardText id="texfont">Descripción:</MDBCardText>
                    <MDBCardText className="white-text">
                      {elements.description}
                    </MDBCardText>
                    <a
                      className="white-text d-flex justify-content-end"
                      onClick={this.toggle(
                        "eventDetail",
                        0,
                        0,
                        "ee",
                        elements.advertisementID
                      )}
                    >
                      <h5 className=" textEvent" id="texfont">
                        Ver
                        <MDBIcon icon="angle-double-right" className="ml-1" />
                      </h5>
                    </a>
                  </div>
                </div>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      );
    });
    return tempArray;
  }

  /**
   * Method that contains a job offer modality where it shows the user the data to enter a job offer.
   */
  jobModal() {
    const languages = this.state.languages.map((language) => {
      return {
        label: language.name,
        value: language.languageID,
      };
    });
    const coins = this.state.coins.map(function (coin) {
      return {
        label: coin.coinName,
        value: coin.coinID,
      };
    });
    return (
      <MDBContainer>
        <MDBModal
          className="modal-lg"
          isOpen={this.state.jobModal}
          toggle={this.toggle("job", 0)}
        >
          <MDBModalHeader
            className="modalHeader modalTitlesFont"
            toggle={this.toggle("job", 0)}
          >
            Datos de la oferta de empleo
          </MDBModalHeader>
          <MDBModalBody>
            <FormGroup className="text-center">
              <label className="modalTextFont"></label>
            </FormGroup>

            <Row className="mt-2">
              <MDBCol className="col-12 col-lg-6">
                <FormGroup>
                  <label className="modalTextFont" htmlFor="eventName">
                    Nombre de la empresa: <font color="red">*</font>
                  </label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="eventName-addon">
                        <i class="fas fa-building"></i>
                      </span>
                    </div>
                    <input
                      className="form-control "
                      type="text"
                      name="name"
                      placeholder="Nombre de la empresa"
                      size="15"
                      required
                      onChange={this.valueNameJob}
                      value={this.state.jobName}
                    />
                  </div>
                </FormGroup>
              </MDBCol>
              <MDBCol className="col-12 col-lg-6">
                <FormGroup>
                  <label className="modalTextFont" htmlFor="eventName">
                    Ubicación: <font color="red">*</font>
                  </label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="place-addon">
                        <i class="fas fa-map-marker-alt"></i>
                      </span>
                    </div>
                    <input
                      className="form-control"
                      value={this.state.jobPlace}
                      type="text"
                      id="place"
                      name="place"
                      placeholder="Lugar *"
                      aria-describedby="place-addon"
                      onChange={this.valuePlaceJob}
                      required
                    ></input>
                  </div>
                </FormGroup>
              </MDBCol>
            </Row>

            <Row className="mt-2">
              <MDBCol className="col-12 col-lg-6">
                <FormGroup>
                  <label className="modalTextFont" htmlFor="eventName">
                    Puesto laboral: <font color="red">*</font>
                  </label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="link-addon">
                        <i class="fas fa-id-badge"></i>
                      </span>
                    </div>
                    <input
                      className="form-control"
                      value={this.state.jobTitle}
                      type="text"
                      id="eventLink"
                      name="eventLink"
                      placeholder="Puesto laboral"
                      arial-describedby="link-addon"
                      onChange={this.valueTitleJob}
                    ></input>
                  </div>
                </FormGroup>
              </MDBCol>

              <MDBCol className="col-12 col-lg-6">
                <FormGroup>
                  <label className="modalTextFont" htmlFor="eventName">
                    Idioma requerido: <font color="red">*</font>
                  </label>

                  <div class="changeSelect">
                    <Select
                      id="language"
                      className="selectLetter"
                      placeholder="Elegir idioma*"
                      options={languages}
                      onChange={this.languageSelect}
                      required
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 4,
                        font: "Open Sans",
                        height: 9,

                        colors: {
                          ...theme.colors,
                          primary25: "#FFA93F",
                          primary: "#808080",
                        },
                      })}
                    />
                  </div>
                </FormGroup>
              </MDBCol>
            </Row>

            <Row className="mt-2 ">
              <MDBCol className="col-12 col-lg-6">
                <FormGroup>
                  <label className="modalTextFont" htmlFor="eventName">
                    Salario: <font color="red">*</font>
                  </label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="link-addon">
                        <i class="far fa-money-bill-alt"></i>
                      </span>
                    </div>
                    <input
                      className="form-control"
                      value={this.state.jobAmount}
                      type="text"
                      id="salary"
                      name="salary"
                      placeholder="Salario"
                      arial-describedby="link-addon"
                      onChange={this.valueAmountJob}
                    ></input>
                  </div>
                </FormGroup>
              </MDBCol>

              <MDBCol className="col-12 col-lg-6">
                <FormGroup>
                  <FormGroup>
                    <label className="modalTextFont">Moneda</label>
                    <Select
                      className="selectLetter"
                      placeholder="Moneda"
                      onChange={this.coinSelect}
                      options={coins}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 4,
                        font: "Open Sans",
                        height: 9,

                        colors: {
                          ...theme.colors,
                          primary25: "#FFA93F",
                          primary: "#808080",
                        },
                      })}
                    ></Select>
                  </FormGroup>
                </FormGroup>
              </MDBCol>
            </Row>

            <Row className="mt-2">
              <MDBCol className="col-12 col-lg-6">
                <FormGroup>
                  <label className="modalTextFont" htmlFor="eventName">
                    Contacto de la empresa: <font color="red">*</font>
                  </label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="link-addon">
                        <i class="fas fa-address-book"></i>
                      </span>
                    </div>
                    <input
                      className="form-control"
                      value={this.state.jobContact}
                      type="text"
                      id="eventLink"
                      name="eventLink"
                      placeholder="Contacto de la empresa"
                      arial-describedby="link-addon"
                      onChange={this.valueContactJob}
                    ></input>
                  </div>{" "}
                </FormGroup>
              </MDBCol>
              <MDBCol className="col-12 col-lg-6">
                <FormGroup>
                  <br />
                  <textarea
                    value={this.state.description}
                    onChange={this.valueDescription}
                    rows="2"
                    placeholder="  Descripción de la oferta de empleo"
                    id="description"
                    name="description"
                    onChange={this.handleChange}
                  ></textarea>
                </FormGroup>
              </MDBCol>
            </Row>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="purpleButton" className="generalButton purpleButton">
              <span className="modalButtonText" onClick={this.toggle("job", 0)}>
                Cancelar
              </span>
            </MDBBtn>

            <MDBBtn color="greenButton" className="generalButton greenButton">
              <span className="modalButtonText" onClick={this.validateDataJob}>
                Guardar
              </span>
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </MDBContainer>
    );
  }

  /**
   * Method that contains an event modal where it shows the user the data to enter an event.
   */
  eventModal() {
    return (
      <MDBContainer>
        <MDBModal
          className="modal-lg"
          isOpen={this.state.eventModal}
          toggle={this.toggle("event", 0)}
        >
          <MDBModalHeader
            className="modalHeader modalTitlesFont"
            toggle={this.toggle("event", 0)}
          >
            Datos del Evento
          </MDBModalHeader>
          <MDBModalBody>
            <FormGroup className="text-center">
              <label className="modalTextFont"></label>
            </FormGroup>

            <Row className="mt-2">
              <MDBCol className="col-12 col-lg-6">
                <FormGroup>
                  <label className="modalTextFont" htmlFor="eventName">
                    Nombre del evento: <font color="red">*</font>
                  </label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="eventName-addon">
                        <i className="fa fa-font prefix inputIcons"></i>
                      </span>
                    </div>
                    <input
                      className="form-control"
                      type="text"
                      id="eventName"
                      placeholder="Nombre del evento *"
                      name="eventName"
                      aria-describedby="eventName-addon"
                      onChange={this.valueName}
                      required
                    ></input>
                  </div>
                </FormGroup>
              </MDBCol>
              <MDBCol className="col-12 col-lg-6">
                <FormGroup>
                  <label className="modalTextFont" htmlFor="eventName">
                    Lugar del evento: <font color="red">*</font>
                  </label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="place-addon">
                        <i className="fa fa-map-marker-alt prefix inputIcons"></i>
                      </span>
                    </div>
                    <input
                      className="form-control"
                      type="text"
                      id="place"
                      name="place"
                      placeholder="Lugar *"
                      aria-describedby="place-addon"
                      onChange={this.valuePlace}
                      required
                    ></input>
                  </div>
                </FormGroup>
              </MDBCol>
            </Row>

            <Row className="mt-2">
              <MDBCol className="col-12 col-lg-6">
                <FormGroup>
                  <label className="modalTextFont" htmlFor="eventName">
                    Fecha del evento: <font color="red">*</font>
                  </label>
                  <input
                    className="form-control"
                    type="datetime-local"
                    defaultValue={this.state.dateEvent}
                    id="date"
                    name="date"
                    onChange={this.handleChange}
                    required
                  ></input>
                </FormGroup>
              </MDBCol>

              <MDBCol className="col-12 col-lg-6">
                <FormGroup>
                  <label className="modalTextFont" htmlFor="eventName">
                    Link del evento: <font color="red">*</font>
                  </label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="link-addon">
                        <i className="fa fa-link prefix inputIcons"></i>
                      </span>
                    </div>
                    <input
                      className="form-control"
                      type="text"
                      id="eventLink"
                      name="eventLink"
                      placeholder="Link del evento *"
                      arial-describedby="link-addon"
                      onChange={this.handleChange}
                    ></input>
                  </div>
                </FormGroup>
              </MDBCol>
            </Row>

            <Row className="mt-2">
              <MDBCol size={12}>
                <FormGroup>
                  <textarea
                    rows="3"
                    placeholder="  Descripción del evento *"
                    id="description"
                    name="description"
                    onChange={this.valueDescription}
                  ></textarea>
                </FormGroup>
              </MDBCol>
            </Row>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="purpleButton" className="generalButton purpleButton">
              <span
                className="modalButtonText"
                onClick={this.toggle("event", 0)}
              >
                Cancelar
              </span>
            </MDBBtn>

            <MDBBtn color="greenButton" className="generalButton greenButton">
              <span className="modalButtonText" onClick={this.validateData}>
                Guardar
              </span>
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </MDBContainer>
    );
  }

  /**
   * Method that contains a comment modal where it shows the user the data to enter a comment.
   */
  commentModal() {
    const colorEvent = this.state.colors.map((color) => {
      return {
        label: color.name,
        value: color.name,
      };
    });
    return (
      <MDBContainer>
        <MDBModal
          className="modal-lg"
          isOpen={this.state.commentModal}
          toggle={this.toggle("comment", 0)}
        >
          <MDBModalHeader
            className="modalHeader modalTitlesFont"
            toggle={this.toggle("comment", 0)}
          >
            Agregar comentario
          </MDBModalHeader>
          <MDBModalBody>
            <Row>
              <Col className="col-12 col-lg-9">
                <label className="modalTextFont" htmlFor="eventName">
                  Comentario: <font color="red">*</font>
                </label>
              </Col>
            </Row>

            <Row className="mt-2">
              <MDBCol className="col-12 col-lg-9">
                <FormGroup>
                  <textarea
                    rows="4"
                    placeholder="  Comentario "
                    id="comment"
                    name="comment"
                    onChange={this.valueComment}
                  ></textarea>
                </FormGroup>
              </MDBCol>
              <MDBCol className="col-12 col-lg-3 mt-30">
                <FormGroup>
                  <Select
                    className="selectLetter"
                    id="colorSelect"
                    placeholder="color"
                    options={colorEvent}
                    onChange={this.colorSelect}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 4,
                      font: "Open Sans",
                      height: 9,

                      colors: {
                        ...theme.colors,
                        primary25: "#FFA93F",
                        primary: "#808080",
                      },
                    })}
                  ></Select>
                </FormGroup>
              </MDBCol>
            </Row>
            <br />
          </MDBModalBody>

          <MDBModalFooter>
            <MDBBtn
              color="purpleButton"
              className="generalButton purpleButton"
              onClick={this.toggle("comment", 0)}
            >
              <span className="modalButtonText">Cancelar</span>
            </MDBBtn>

            <MDBBtn color="greenButton" className="generalButton greenButton">
              <span
                className="modalButtonText"
                onClick={this.validateDataComment}
              >
                Guardar
              </span>
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </MDBContainer>
    );
  }

  /**
   * Method that shows the elements found or added to the web page.
   */
  render() {
    return (
      <div>
        <div class="view">
          <img src={imagen} className="responsive-img" alt="" id="bannerHome" />

          <div class="mask flex-center " id="maskIntroCommunity">
            {/* <Col xs={2} md={6} lg={10}>*/}

            {/*  <Output />*/}

            {/*  </Col>*/}
          </div>
        </div>
        <Container className="mt-5 ">
          {/* <this.eventModal />
          <this.jobModal /> */}
          <this.commentModal />
          {/* <this.eventDetailModal />
          <this.jobDetailModal /> */}
          <ToastContainer
            position="top-right"
            autoClose={2000}
            hideProgressBar
            newestOnTop
            closeOnClick={false}
            rtl={false}
            pauseOnVisibilityChange
            draggable
            pauseOnHover
          />

          <Row className="mt-0">
            <MDBCol className=" col-12 col-lg-9 offset-lg-0 mt-3">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb white">
                  <li class="breadcrumb-item">
                    <a class="black-text" href="/">
                      Inicio
                    </a>
                  </li>
                  <li class="breadcrumb-item active ">Movimiento SIAgile</li>
                </ol>
              </nav>
            </MDBCol>
          </Row>
          <Row className="mt-3 col-sm-12">
            <img
              src={imagenSiAgile}
              className=" img-fluid z-depth-3 rounded responsive-img offset-lg-9"
              alt=""
              id="SiAgile"
            />

            <MDBCol className=" col-12 col-lg-9 offset-lg-1 mt-2 ">
              <br />
              <h3 className="userTitlesFont">SI Agile:</h3>
            </MDBCol>
          </Row>

          <br />
          <MDBCol className="col-lg-10 offset-lg-1 mt-2 sm-12">
            <p
              style={{
                textAlign: "Justify",
                fontFamily: "Helvetica",
              }} /*className="userTextFont"*/
            >
              Social Impact Agile es un movimiento enfocado en realizar
              proyectos Sociales, impulsado por un equipo de profesionales en
              Agilidad y Desarrollo aplicable a cualquier esfuerzo que busque
              mejorar la calidad de vida de una o más personas. ​SiAgile fue
              creado con participación de Rocío Briceño, Alistair Cockburn y
              Gerardo Blizter quienes activamente trabajan en este movimiento.
            </p>
            <p
              style={{
                textAlign: "Justify",
                fontFamily: "Helvetica",
              }} /*className="userTextFont"*/
            >
              SIAgile también reconoce el aporte en parte de las reuniones del
              Ricardo Sanchez y Ernesto Mondelo aunque ya no forman parte del
              equipo. ​ Actualmente SIAgile cuenta con el apoyo de
              organizaciones y grupos de voluntarios en diferentes partes del
              mundo, conformando una red global de especialistas en agilidad
              aplicada al impacto social.
            </p>

            <br />
            <br />
            <br />
            <p
              style={{
                textAlign: "center",
                fontFamily: "Helvetica",
              }} /*className="userTextFont"*/
            >
              <strong>
                ¡Nos encantaría conocer tu opinión sobre nuestros servicios o la
                comunidad Si Agile, por está razón habilitamos la opción de
                comentarios para que puedas contarnos lo que piensas!
              </strong>
            </p>
            {/* <MDBContainer>
              <MDBCarousel
                activeItem={1}
                length={this.state.comments.length}
                showControls={true}
                showIndicators={true}
                responsive={true}
                className="z-depth-1"
              >
                <MDBCarouselInner>{this.comment()}</MDBCarouselInner>
              </MDBCarousel>
            </MDBContainer> */}
          </MDBCol>
          <br />
          <br />
          <div class="container">
            <div class="row mt-3">
              <MDBCol className=" col-12 col-lg-9 offset-lg-1 mt-2 ">
                <div className="tab" role="tabpanel">
                  <ul className="nav nav-tabs" role="tablist">
                    {/* <li role="presentation" className="active">
                      <a
                        href="#Section1"
                        aria-controls="home"
                        role="tab"
                        data-toggle="tab"
                      >
                        Eventos
                      </a>
                    </li>
                    <li role="presentation">
                      <a
                        href="#Section2"
                        aria-controls="profile"
                        role="tab"
                        data-toggle="tab"
                      >
                        Oferta de empleo
                      </a>
                    </li> */}
                    <li role="presentation">
                      <a
                        href="#Section3"
                        aria-controls="messages"
                        role="tab"
                        data-toggle="tab"
                      >
                        Comentarios
                      </a>
                    </li>
                  </ul>

                  <div className="tab-content tabs">
                    {/* <div
                      role="tabpanel"
                      className="tab-pane fade show active"
                      id="Section1"
                    >
                      <br />

                      <h3>Eventos</h3>
                      <br />
                      <a
                        className="white-text d-flex justify-content-end"
                        onClick={this.toggle("event", 1, 0, "ee", 0)}
                      >
                        <h5 className="linkComunnity">
                          Agregar un evento
                          <MDBIcon icon="angle-double-right" className="ml-1" />
                        </h5>
                      </a>
                      {this.eventView()}
                    </div>

                    <div
                      role="tabpanel"
                      className="tab-pane fade"
                      id="Section2"
                    >
                      <br />

                      <h3>Oferta de Empleo</h3>
                      <br />
                      <a
                        className="white-text d-flex justify-content-end"
                        onClick={this.toggle("job", 1, 1, "ee", 0)}
                      >
                        <h5 className="linkComunnity">
                          Agregar oferta de empleo
                          <MDBIcon icon="angle-double-right" className="ml-1" />
                        </h5>
                      </a>
                      {this.jobView()}
                    </div> */}
                    <div
                      role="tabpanel"
                      className="tab-pane fade"
                      id="Section3"
                    >
                      {/* <h3>Comentarios</h3> */}
                      <a
                        className="white-text d-flex justify-content-end"
                        onClick={this.toggle("comment", 1, 2, "ee", 0)}
                      >
                        <h5 className="linkComunnity">
                          Agregar comentario
                          <MDBIcon icon="angle-double-right" className="ml-1" />
                        </h5>
                      </a>{" "}
                    </div>
                  </div>
                </div>
              </MDBCol>
            </div>
          </div>
        </Container>
      </div>
    );
  }
}
export default UserCommunity;
