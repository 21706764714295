/**
 * @fileoverview Quotations, this component show the list of quotations
 *
 * @version 1.0
 *
 * @author Ester Molina R <maria.molina@ucrso.info>
 * History
 * v1.0 - Initial Realease
 * ----
 * The first version of Quotations was written by Ester Molina R.
 */
import React, { Component } from "react";
import { MDBCol, MDBRow, MDBDataTable, MDBCard } from "mdbreact";
import { baseUrl } from "./baseUrl";
import axios from "axios";

class Quotation extends Component {
  constructor(props) {
    super(props);
    /**
     * quotations:
     * @type {Array}
     * Property that contains the list of quotations
     */
    this.state = {
      quotations: {
        columns: [
          {
            label: "Solicitante",
            field: "fullName",
            width: 150,
          },
          {
            label: "Fecha de envío",
            field: "doDate",
            width: 150,
          },
          {
            label: "País",
            field: "country",
            width: 150,
          },
          {
            label: "Estado",
            field: "state",
            width: 150,
          },
          {
            label: "Fecha de respuesta",
            field: "responseDate",
            width: 150,
          },
        ],
        rows: [],
      },
      products: [],
    };
    this.getQuotations = this.getQuotations.bind(this);
    this.redirect = this.redirect.bind(this);
    //this.chargeQuotations = this.chargeQuotations.bind(this);
  }

  componentDidMount() {
    if (sessionStorage.getItem("userType") == 2) {
      this.getQuotations();
    } else if (sessionStorage.getItem("userType") == 3) {
      this.props.history.push("/admin");
    } else {
      this.props.history.push("/");
    }
  }

  /**
   * Method that redirect
   * @param {integer} quotationID
   */
  redirect(quotationID) {
    this.props.history.push({
      pathname: "/QuotationDetail/" + quotationID,
    });
  }

  /**
   * Method that charge the data from the database
   */
  getQuotations() {
    var dbQuotations = [];
    axios
      .get(baseUrl + "quotation/registerUserQuotation")
      .then((response) => {
        var dbQuotations1 = response.data;
        dbQuotations1.map((dbQuotation) => {
          dbQuotations.push(dbQuotation);
        });
        axios
          .get(baseUrl + "quotation/nonRegisterUserQuotation")
          .then((response) => {
            var dbQuotations2 = response.data;
            dbQuotations2.map((dbQuotation) => {
              dbQuotations.push(dbQuotation);
            });

            var dbQuotationsOrder = [];
            dbQuotationsOrder = dbQuotations.sort(
              (a, b) => b.quotationID - a.quotationID
            );
            console.log("desordenado", dbQuotations);
            console.log("ordenado", dbQuotationsOrder);

            this.setState({
              ...this.state,
              quotations: {
                ...this.state.quotations,
                rows: dbQuotationsOrder.map((quotation) => {
                  return {
                    ...quotation,
                    clickEvent: () => this.redirect(quotation.quotationID),
                  };
                }),
              },
            });
          })
          .catch((err) => console.error(err));
      })
      .catch((err) => console.error(err));
  }

  componentDidUpdate() {
    if (
      sessionStorage.getItem("qID") != null ||
      sessionStorage.getItem("qID") != undefined
    ) {
      this.getQuotations();
      sessionStorage.removeItem("qID");
    }
  }

  /**
   * Method that render the HTML
   */
  render() {
    return (
      <div className="container">
        <MDBRow>
          <MDBCol className="col-12 col-lg-9 offset-lg-3 mt-3">
            <h3 className="adminTitlesFont">Cotizaciones</h3>
          </MDBCol>
          <MDBCol className="col-12 col-lg-9 offset-lg-3 mb-3">
            <MDBCard style={{ padding: "20px 20px 20px 20px" }}>
              <MDBDataTable
                responsive
                data={this.state.quotations}
                hover
                striped
                bordered
                entriesLabel={"Mostrar"}
                searchLabel={"Buscar"}
                paginationLabel={["Anterior", "Siguiente"]}
                infoLabel={["Mostrando", "de", "de un total de", "registros"]}
                entries={5}
                entriesOptions={[5, 10, 15]}
                small
                noBottomColumns={true}
                noRecordsFoundLabel="No se han encontrado datos"
                theadTextWhite={true}
                pagesAmount={4}
              />
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </div>
    );
  }
}

export default Quotation;
