/**
 * @fileoverview AdminHeader, this component is the navbar and side navbar of the admin module
 *
 * @version 1.0
 *
 * @author Ester Molina R <maria.molina@ucrso.info>
 * History
 * v1.0 - Initial Realease
 * ----
 * The first version of AdminHeader was written by Ester Molina R.
 */
import React, { Component } from "react";
import {
  Nav,
  NavItem,
  NavLink,
  NavbarText,
  NavbarBrand,
  Collapse,
} from "reactstrap";
import {
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBBtn,
} from "mdbreact";
import { NavLink as RRNavLink, withRouter } from "react-router-dom";
import {
  MDBIcon,
  MDBAnimation,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
} from "mdbreact";
import logo from "../images/logo.png";

class AdminHeader extends Component {
  constructor(props) {
    super(props);

    /**
     * sidebar:
     * @type {boolean}
     * Property that shows if the sidebar is open or not
     *
     * isOpenInfo:
     * @type {boolean}
     * Property that shows if the options of company information are open or not
     *
     * isOpenPeople:
     * @type {boolean}
     * Property that shows if the options of people are open or not
     *
     * isOpenCourse:
     * @type {boolean}
     * Property that shows if the options of course are open or not
     *
     * isOpenCommunity:
     * @type {boolean}
     * Property that shows if the options of community are open or open
     *
     * isOpenAdmin:
     * @type {boolean}
     * Property that shows if the options of admin account are open or not
     *
     * employee:
     * @type {boolean}
     * Property that verify if the user is an administrator or an employee
     *
     * isOpenReport:
     * @type {boolean}
     * Property that shows if the options of reports are open or not
     *
     * name:
     * @type {String}
     * Property that constains the name of the user
     */
    this.state = {
      sidebar: false,
      isOpenInfo: false,
      isOpenPeople: false,
      isOpenCourse: false,
      isOpenCommunity: false,
      isOpenAdmin: false,
      employee: false,
      isOpenReport: false,
      name: sessionStorage.getItem("name"),
    };
  }

  componentDidMount() {
    if (sessionStorage.getItem("userType") == 2) {
      this.setState({
        employee: false,
      });
    } else if (sessionStorage.getItem("userType") == 3) {
      this.setState({
        employee: true,
      });
    }
    if (window.innerWidth > 1000) {
      this.showSidebar();
    }
  }

  /**
   * Method that change the state of sidebar
   */
  showSidebar = () => {
    this.setState({
      sidebar: !this.state.sidebar,
    });
  };

  /**
   * Method that change the state of isOpenInfo and put false the others
   */
  toggleInfo = () => {
    this.setState({
      isOpenInfo: !this.state.isOpenInfo,
      isOpenAdmin: false,
      isOpenCommunity: false,
      isOpenCourse: false,
      isOpenPeople: false,
      isOpenReport: false,
    });
  };

  /**
   * Method that change the state of isOpenPeople and put false the others
   */
  togglePeople = () => {
    this.setState({
      isOpenPeople: !this.state.isOpenPeople,
      isOpenAdmin: false,
      isOpenCommunity: false,
      isOpenCourse: false,
      isOpenInfo: false,
      isOpenReport: false,
    });
  };

  /**
   * Method that change the state of isOpenCourse and put false the others
   */
  toggleCourse = () => {
    this.setState({
      isOpenCourse: !this.state.isOpenCourse,
      isOpenAdmin: false,
      isOpenCommunity: false,
      isOpenInfo: false,
      isOpenPeople: false,
      isOpenReport: false,
    });
  };

  /**
   * Method that change the state of isOpenCommunity and put false the others
   */
  toggleCommunity = () => {
    this.setState({
      isOpenCommunity: !this.state.isOpenCommunity,
      isOpenAdmin: false,
      isOpenCourse: false,
      isOpenInfo: false,
      isOpenPeople: false,
      isOpenReport: false,
    });
  };

  /**
   * Method that change the state of isOpenAdmin and put false the others
   */
  toggleAdmin = () => {
    this.setState({
      isOpenAdmin: !this.state.isOpenAdmin,
      isOpenCommunity: false,
      isOpenCourse: false,
      isOpenInfo: false,
      isOpenPeople: false,
      isOpenReport: false,
    });
  };

  /**
   * Method that close the sesion, clear the sesion variables and redirect to the home
   */
  close = () => {
    sessionStorage.removeItem("user");
    sessionStorage.removeItem("name");
    sessionStorage.removeItem("email");
    sessionStorage.removeItem("userType");
    this.props.history.push("/");
  };

  /**
   * Method that redirect to perfil configuration
   */
  perfilConfiguration = () => {
    this.props.history.push("/AdminPerfilConfiguration");
  };

  help = () => {
    this.props.history.push("/Help");
  };

  /**
   * Method that change the state of isOpenReport and put false the others
   */
  toggleReports = () => {
    this.setState({
      isOpenReport: !this.state.isOpenReport,
      isOpenAdmin: false,
      isOpenCommunity: false,
      isOpenCourse: false,
      isOpenInfo: false,
      isOpenPeople: false,
    });
  };

  /**
   * This method render the HTML
   */
  render() {
    var auxName = this.state.name;
    return (
      <div>
        <MDBNavbar className="grayColor bg-custom-2" dark expand="lg ">
          <MDBNavbarBrand className="d-none d-sm-none d-md-none d-lg-block">
            <RRNavLink to={"/admin"} style={{ textDecoration: "none" }}>
              <MDBAnimation type="zoomIn">
                <img src={logo} alt="logo" className="logoSizeAdmin" />
              </MDBAnimation>
            </RRNavLink>
          </MDBNavbarBrand>
          <NavLink>
            <MDBIcon
              className="d-block d-sm-block d-md-block d-lg-none menu-bars"
              icon="bars"
              id="menuButton"
            />
          </NavLink>
          <MDBNavbarNav className="navbarFont" right>
            <NavbarBrand className="d-block d-sm-block d-md-block d-lg-none">
              <img src={logo} alt="logo" className="logoSizeAdmin" />
            </NavbarBrand>
            <NavItem to="#" className="d-none d-sm-none d-md-none d-lg-block">
              <MDBDropdown>
                <MDBDropdownToggle
                  caret
                  color="navbarBtn text-white"
                  className="navbarBtn"
                >
                  <span className="modalButtonText">{auxName}</span>
                </MDBDropdownToggle>
                <MDBDropdownMenu basic className="DropPosition">
                  <MDBDropdownItem onClick={this.perfilConfiguration}>
                    Configuración de Perfil
                  </MDBDropdownItem>
                  <MDBDropdownItem divider />
                  <MDBDropdownItem onClick={this.close}>
                    Cerrar Sesión
                  </MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown>
            </NavItem>
            <NavItem to="#" className="d-none d-sm-none d-md-none d-lg-block">
              <MDBBtn
                color="navbarBtn text-white"
                className="navbarBtn modalButtonText"
                onClick={this.help}
              >
                <span className="modalButtonText">
                  <MDBIcon icon="question-circle" size="1x" />
                </span>
              </MDBBtn>
            </NavItem>
          </MDBNavbarNav>
        </MDBNavbar>
        <Nav className="nav-menu">
          <ul className="nav-menu-items">
            <NavItem
              className="nav-text"
              id="navText"
              hidden={this.state.employee}
            >
              <NavLink className="navLinkText" onClick={this.toggleInfo}>
                <MDBIcon className="menuIcons" icon="info" />
                Info. Empresarial
                <MDBIcon className="arrowIcon" icon="angle-down" />
              </NavLink>
            </NavItem>
            <Collapse isOpen={this.state.isOpenInfo}>
              <RRNavLink
                to={"/HomeConfiguration"}
                style={{ textDecoration: "none" }}
              >
                <NavItem className="nav-text-sub navVisible" id="navText">
                  <NavbarText className="navLinkText" id="navText">
                    Página Principal
                  </NavbarText>
                </NavItem>
              </RRNavLink>
              <RRNavLink
                to={"/AboutUsConfiguration"}
                style={{ textDecoration: "none" }}
              >
                <NavItem className="nav-text-sub navVisible" id="navText">
                  <NavbarText className="navLinkText" id="navText">
                    Acerca de nosotros
                  </NavbarText>
                </NavItem>
              </RRNavLink>
              <RRNavLink
                to={"/IntroCourses"}
                style={{ textDecoration: "none" }}
              >
                <NavItem className="nav-text-sub navVisible" id="navText">
                  <NavbarText className="navLinkText" id="navText">
                    Introducción de cursos
                  </NavbarText>
                </NavItem>
              </RRNavLink>
            </Collapse>
            <NavItem
              className="nav-text"
              id="navText"
              hidden={this.state.employee}
            >
              <NavLink className="navLinkText" onClick={this.togglePeople}>
                <MDBIcon className="menuIcons" icon="user-friends" />
                Personas
                <MDBIcon className="arrowIcon" icon="angle-down" />
              </NavLink>
            </NavItem>
            <Collapse isOpen={this.state.isOpenPeople}>
              <RRNavLink to={"/Providers"} style={{ textDecoration: "none" }}>
                <NavItem className="nav-text-sub navVisible" id="navText">
                  <NavbarText className="navLinkText" id="navText">
                    Proveedores
                  </NavbarText>
                </NavItem>
              </RRNavLink>
              <RRNavLink to={"/Employees"} style={{ textDecoration: "none" }}>
                <NavItem className="nav-text-sub navVisible" id="navText">
                  <NavbarText className="navLinkText" id="navText">
                    Colaboradores
                  </NavbarText>
                </NavItem>
              </RRNavLink>
            </Collapse>
            <NavItem
              className="nav-text"
              id="navText"
              hidden={this.state.employee}
            >
              <NavLink className="navLinkText" onClick={this.toggleCourse}>
                <MDBIcon className="menuIcons" icon="graduation-cap" />
                Cursos
                <MDBIcon className="arrowIcon" icon="angle-down" />
              </NavLink>
            </NavItem>
            <Collapse isOpen={this.state.isOpenCourse}>
              <RRNavLink to={"/CourseList"} style={{ textDecoration: "none" }}>
                <NavItem className="nav-text-sub navVisible" id="navText">
                  <NavbarText className="navLinkText" id="navText">
                    Lista de cursos
                  </NavbarText>
                </NavItem>
              </RRNavLink>
              <RRNavLink to={"/CourseType"} style={{ textDecoration: "none" }}>
                <NavItem className="nav-text-sub navVisible" id="navText">
                  <NavbarText className="navLinkText" id="navText">
                    Tipos de cursos
                  </NavbarText>
                </NavItem>
              </RRNavLink>
            </Collapse>
            <RRNavLink
              to={"/PrincipalEvent/0"}
              style={{ textDecoration: "none" }}
            >
              <NavItem className="nav-text navVisible" id="navText">
                <NavbarText className="navLinkText" id="navText">
                  <MDBIcon className="menuIcons" icon="calendar-alt" />
                  Eventos
                </NavbarText>
              </NavItem>
            </RRNavLink>
            <RRNavLink to={"/Categories"} style={{ textDecoration: "none" }}>
              <NavItem
                className="nav-text navVisible"
                id="navText"
                hidden={this.state.employee}
              >
                <NavbarText className="navLinkText" id="navText">
                  <MDBIcon className="menuIcons" icon="sitemap" />
                  Categorías
                </NavbarText>
              </NavItem>
            </RRNavLink>
            <RRNavLink to={"/Articles"} style={{ textDecoration: "none" }}>
              <NavItem
                className="nav-text navVisible"
                id="navText"
                hidden={this.state.employee}
              >
                <NavbarText className="navLinkText" id="navText">
                  <MDBIcon className="menuIcons" icon="newspaper" />
                  Artículos
                </NavbarText>
              </NavItem>
            </RRNavLink>
            <NavItem
              className="nav-text"
              onClick={this.toggleCommunity}
              id="navText"
              hidden={this.state.employee}
            >
              <NavLink className="navLinkText">
                <MDBIcon className="menuIcons" icon="comments" />
                Comunidad
                <MDBIcon className="arrowIcon" icon="angle-down" />
              </NavLink>
            </NavItem>
            <Collapse isOpen={this.state.isOpenCommunity}>
              <RRNavLink
                to={"/Advertisements"}
                style={{ textDecoration: "none" }}
              >
                <NavItem className="nav-text-sub navVisible" id="navText">
                  <NavbarText className="navLinkText" id="navText">
                    Comentarios
                  </NavbarText>
                </NavItem>
              </RRNavLink>
              {/* <RRNavLink to={'/Adv_Event'} style={{ textDecoration: 'none' }}>
								<NavItem className="nav-text-sub navVisible" id="navText">
									<NavbarText className="navLinkText" id="navText">
										Actividades
									</NavbarText>
								</NavItem>
							</RRNavLink>
							<RRNavLink to={'/Adv_Job'} style={{ textDecoration: 'none' }}>
								<NavItem className="nav-text-sub navVisible" id="navText">
									<NavbarText className="navLinkText" id="navText">
										Ofertas de Empleo
									</NavbarText>
								</NavItem>
							</RRNavLink> */}
            </Collapse>
            <RRNavLink to={"/Quotations"} style={{ textDecoration: "none" }}>
              <NavItem
                className="nav-text navVisible"
                id="navText"
                hidden={this.state.employee}
              >
                <NavbarText className="navLinkText" id="navText">
                  <MDBIcon className="menuIcons" icon="file-invoice-dollar" />
                  Cotizaciones
                </NavbarText>
              </NavItem>
            </RRNavLink>
            <NavItem
              className="nav-text"
              onClick={this.toggleReports}
              id="navText"
            >
              <NavLink className="navLinkText">
                <MDBIcon className="menuIcons" icon="chart-line" />
                Reportes
                <MDBIcon className="arrowIcon" icon="angle-down" />
              </NavLink>
            </NavItem>
            <Collapse isOpen={this.state.isOpenReport}>
              <RRNavLink
                to={"/EnrollmentReports"}
                style={{ textDecoration: "none" }}
              >
                <NavItem className="nav-text-sub navVisible" id="navText">
                  <NavbarText className="navLinkText" href="#" id="navText">
                    Facturas
                  </NavbarText>
                </NavItem>
              </RRNavLink>
              <RRNavLink to={"/UsersReport"} style={{ textDecoration: "none" }}>
                <NavItem className="nav-text-sub navVisible" id="navText">
                  <NavbarText className="navLinkText" href="#" id="navText">
                    Estudiantes
                  </NavbarText>
                </NavItem>
              </RRNavLink>
            </Collapse>
            <NavItem
              onClick={this.toggleAdmin}
              className="nav-text d-block d-sm-block d-md-block d-lg-none"
              id="navText"
            >
              <NavbarText className="navLinkText" href="#" id="navText">
                <MDBIcon className="menuIcons" icon="user" />
                Administrador
                <MDBIcon className="arrowIcon" icon="angle-down" />
              </NavbarText>
            </NavItem>
            <Collapse isOpen={this.state.isOpenAdmin}>
              <RRNavLink
                to={"/AdminPerfilConfiguration"}
                style={{ textDecoration: "none" }}
              >
                <NavItem className="nav-text-sub navVisible" id="navText">
                  <NavbarText className="navLinkText" id="navText">
                    Perfil
                  </NavbarText>
                </NavItem>
              </RRNavLink>
              <NavItem
                className="nav-text-sub navVisible"
                id="navText"
                onClick={this.close}
              >
                <NavbarText className="navLinkText" id="navText">
                  Cerrar Sesión
                </NavbarText>
              </NavItem>
            </Collapse>
          </ul>
        </Nav>
      </div>
    );
  }
}

export default withRouter(AdminHeader);
