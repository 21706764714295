import React, { Component, Fragment } from 'react';
import { MDBRow, MDBCol, MDBIcon, MDBBtn, MDBTooltip } from 'mdbreact';
import { MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBDataTable, MDBCard, MDBContainer } from 'mdbreact';
import { FormGroup, Container, Toast, ToastBody, ToastHeader } from 'reactstrap';
import axios from 'axios';
import { baseUrl } from './baseUrl';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import validations from './Validations';

class Advertisement extends Component {
	state = {
		validations: new validations(),
		deleteModal: false,
		editModal: false,
		publishModal: false,
		advertisementID: 0,
		description: '',
		advertisements: {
			columns: [
				{
					label: 'Usuario',
					field: 'users',
					width: 150
				},
				{
					label: 'Comentario',
					field: 'description',
					width: 150
				},
				{
					label: 'Estado',
					field: 'state',
					width: 150
				},
				{
					label: 'Acciones',
					field: 'actions',
					width: 150
				}
			],
			rows: []
		},
		option: 1,
		commentStatus: '',
	};
	deleteModal = this.deleteModal.bind(this);
	delete = this.delete.bind(this);
	editModal = this.editModal.bind(this);
	edit = this.edit.bind(this);
	handleInput = this.handleInput.bind(this);
	publish = this.publish.bind(this);
	publishModal = this.publishModal.bind(this);

	componentDidMount() {
		if (sessionStorage.getItem('userType') == 2) {
			this.getAdvertisement();
		} else if (sessionStorage.getItem('userType') == 3) {
			this.props.history.push('/admin');
		} else {
			this.props.history.push('/');
		}
	}

	handleInput(e) {
		const { value, name } = e.target;
		this.setState({
			[name]: value
		});
	}

	notify = (evt, value, msj) => {
		switch (value) {
			case 'SUCCESS':
				toast.success(msj);
				break;
			case 'ERROR':
				toast.error(msj);
				break;
			case 'WARN':
				toast.warn(msj);
				break;
			case 'INFO':
				toast.info(msj);
				break;
			default:
				toast.info(msj);
		}
	};

	toggle(text, id, description) {

		let modalAction = text + 'Modal';
		if (id != null) {
			if (description != null) {
				this.setState({
					[modalAction]: !this.state[modalAction],
					advertisementID: id,
					description
				});
			} else {

				this.setState({
					[modalAction]: !this.state[modalAction],
					advertisementID: id

				});
			}
		} else {

			if (this.state[modalAction] === true) {
				this.setState({
					[modalAction]: !this.state[modalAction],
					advertisementID: 0
				});
			} else {

				this.setState({

					[modalAction]: !this.state[modalAction]
				});
			}
		}
	}

	delete(event) {
		var newAdvertisement = [];
		axios
			.post(baseUrl + 'advertisement/deleteAdvertisement', {
				advertisementID: this.state.advertisementID
			})
			.then(
				(newAdvertisement = this.state.advertisements.rows.filter(
					(row) => row.advertisementID != this.state.advertisementID
				)),
				this.setState({
					advertisementID: 0,
					deleteModal: false,
					advertisements: {
						columns: this.state.advertisements.columns,
						rows: newAdvertisement
					}
				}),
				this.notify(event, 'SUCCESS', 'Se ha eliminado correctamente')
			)
			.catch((err) => console.error('Ha ocurrido un error inesperado'));
		event.preventDefault();
	}

	publish(event) {
		var newAdvertisement = [];
		fetch(baseUrl + 'advertisement/publishComment', {
			method: 'post',
			body: JSON.stringify({
				advertisementID: this.state.advertisementID
			}),
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			}
		})
			.then(
				(newAdvertisement = this.state.advertisements.rows.filter(
					(row) => row.advertisementID != this.state.advertisementID
				)),
				this.setState({
					advertisementID: 0,
					publishModal: false,
					advertisements: {
						columns: this.state.advertisements.columns,
						rows: newAdvertisement
					}
				}),
				this.notify(event, 'SUCCESS', 'Se ha publicado con éxito')
			)
			.catch((err) => console.error('Ha ocurrido un error inesperado'));
		event.preventDefault();
	}

	publicAdvertisement = (text, id, name, state, event) => () => {

		var newTypesRows = [];
		this.state.option = state;
		this.setState({ option: state });

		if (this.state.option == 0) {

			fetch(baseUrl + 'adv_Event/updateStateAdv', {
				method: 'put',
				body: JSON.stringify({
					acceptedYN: 1,
					details: id,
					advertisementID: this.state.advertisementID
				}),
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json'
				}
			})
				.then(

					(newTypesRows = this.state.advertisements.rows.filter((row) => row.advertisementID != this.state.advertisementID)),
					this.getAdvertisement(),
					this.notify(event, 'SUCCESS', 'Se ha publicado correctamente'),
					this.setState({
						advertisements: {
							columns: this.state.advertisements.columns,
							rows: newTypesRows
						}
					})
				)
				.catch((err) => console.error(err));
		} else if (this.state.option == 1) {
			fetch(baseUrl + 'adv_Event/updateStateAdv', {
				method: 'put',
				body: JSON.stringify({
					state: 0,
					details: id
					, advertisementID: this.state.advertisementID
				}),
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json'
				}
			})
				.then(
					(newTypesRows = this.state.advertisements.rows.filter((row) => row.advertisementID != this.state.advertisementID)),
					this.getAdvertisement(),
					this.notify(event, 'SUCCESS', 'Se ha retirado la publicación correctamente'),
					this.setState({
						advertisements: {
							columns: this.state.advertisements.columns,
							rows: newTypesRows
						}
					})
				)


				.catch((err) => console.error(err));
		}

		this.getAdvertisement();
	};


	edit(event) {
		if (this.state.description.length === 0) {
			this.notify(event, 'ERROR', 'El comentario no debe estar vacío');
		} else if (!this.state.validations.validateTextWithNumberField(this.state.description)) {
			this.notify(event, 'ERROR', 'El comentario no debe contener caracteres especiales');
		} else {
			var newRows = [{}];
			fetch(baseUrl + 'advertisement/editComment', {
				method: 'post',
				body: JSON.stringify({
					advertisementID: this.state.advertisementID,
					description: this.state.description
				}),
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json'
				}
			})
				.then(
					(newRows = this.state.advertisements.rows.map((ad) => {
						if (ad.advertisementID === this.state.advertisementID) {
							return {
								...ad,
								description: this.state.description
							};
						} else {
							return ad;
						}
					})),
					this.setState({
						editModal: false,
						description: '',
						advertisementID: 0,
						advertisements: {
							columns: this.state.advertisements.columns,
							rows: newRows
						}
					}),
					this.notify(event, 'SUCCESS', 'Se ha modificado con éxito el comentario')
				)
				.catch((err) => this.notify(event, 'WARN', 'No se pudo modifcar el comentario'));
			event.preventDefault();
		}
	}

	getAdvertisement() {
		axios
			.get(baseUrl + 'advertisement/getAdvertisement')
			.then((response) => {
				var dbAdvertisements = response.data;
				var nameTop;
				this.setState({
					...this.state,
					advertisements: {
						...this.state.advertisements,
						rows: dbAdvertisements.map((dbAdvertisements) => {
							return {
								...dbAdvertisements,

								state: (
									<MDBTooltip>
									<MDBBtn
										color="white"
										className=" buttonState tableControlButton"
										onClick={this.publicAdvertisement(
											'state',
											dbAdvertisements.advertisementID,
											dbAdvertisements.name,
											dbAdvertisements.acceptedYN
										)}

										
										{...(this.state.option = dbAdvertisements.acceptedYN)}
										{...this.state.option == 0 && <div>{(this.commentStatus = 'lock') , (nameTop = 'No Publicado')}</div>}
										{...this.state.option == 1 && <div>{(this.commentStatus = 'lock-open'), (nameTop = 'Publicado')}</div>}

									>
										<MDBIcon icon={this.commentStatus} size="1x" className={this.commentStatus} />
									</MDBBtn>
													<div>
													{nameTop}
												</div>
												</MDBTooltip>
								),





								actions: (
									<Fragment>
										<MDBTooltip>
										<MDBBtn
											color="white"
											className="tableControlButton"
											onClick={() =>
												this.toggle(
													'edit',
													dbAdvertisements.advertisementID,
													dbAdvertisements.description
												)}
										>
											<MDBIcon icon="pen" size="1x" />
										</MDBBtn>

										<div>
										Editar
										</div>
										</MDBTooltip>
										<MDBTooltip>
										<MDBBtn
											color="white"
											className="tableControlButton"
											onClick={() => this.toggle('delete', dbAdvertisements.advertisementID)}
										>
											<MDBIcon icon="trash" size="1x" className="red-text" id="delete" />
										</MDBBtn>
										<div>
										Eliminar
										</div>
										</MDBTooltip>
									</Fragment>
								)
							};
						})
					}
				});
			})
			.catch((err) => console.error('Hola: ' + err));
	}

	deleteModal() {
		return (
			<MDBContainer>
				<MDBModal isOpen={this.state.deleteModal} toggle={() => this.toggle('delete')}>
					<MDBModalHeader className="modalHeader modalTitlesFont" toggle={() => this.toggle('delete')}>
						Eliminar Comentario
					</MDBModalHeader>
					<MDBModalBody>
						<FormGroup className="text-center">
							<label className="modalTextFont">
								¿Desea eliminar este comentario?<br />Esta acción es irreversible
							</label>
						</FormGroup>
					</MDBModalBody>
					<MDBModalFooter>
						<MDBBtn
							color="purpleButton"
							className="modalButton purpleButton"
							onClick={() => this.toggle('delete')}
						>
							<span className="modalButtonText">Cancelar</span>
						</MDBBtn>
						<MDBBtn color="redButton" className="modalButton redButton" onClick={this.delete}>
							<span className="modalButtonText">Eliminar</span>
						</MDBBtn>
					</MDBModalFooter>
				</MDBModal>
			</MDBContainer>
		);
	}

	publishModal() {
		return (
			<MDBContainer>
				<MDBModal isOpen={this.state.publishModal} toggle={() => this.toggle('publish')}>
					<MDBModalHeader className="modalHeader modalTitlesFont" toggle={() => this.toggle('publish')}>
						Publicar Comentario
					</MDBModalHeader>
					<MDBModalBody>
						<FormGroup className="text-center">
							<label className="modalTextFont" >
								¿Desea publicar este comentario?<br />Esta acción es irreversible
							</label>
						</FormGroup>
					</MDBModalBody>
					<MDBModalFooter>
						<MDBBtn
							color="purpleButton"
							className="modalButton purpleButton"
							onClick={() => this.toggle('publish')}
						>
							<span className="modalButtonText">Cancelar</span>
						</MDBBtn>
						<MDBBtn color="greenButton" className="modalButton greenButton" onClick={this.publish}>
							<span className="modalButtonText">Publicar</span>
						</MDBBtn>
					</MDBModalFooter>
				</MDBModal>
			</MDBContainer>
		);
	}

	editModal() {
		return (
			<MDBContainer>
				<MDBModal isOpen={this.state.editModal} toggle={() => this.toggle('edit')}>
					<MDBModalHeader className="modalHeader modalTitlesFont" toggle={() => this.toggle('edit')}>
						Editar Comentario
					</MDBModalHeader>
					<MDBModalBody>
						<FormGroup>
							<label className="modalTextFont">Comentario</label>
							<textarea
								name="description"
								rows={2}
								value={this.state.description}
								onChange={this.handleInput}
								onKeyPress={this.onKeyEventEdit}
							/>
						</FormGroup>
					</MDBModalBody>
					<MDBModalFooter>
						<MDBBtn
							color="purpleButton"
							className="modalButton purpleButton"
							onClick={() => this.toggle('edit')}
						>
							<span className="modalButtonText">Cancelar</span>
						</MDBBtn>
						<MDBBtn color="greenButton" className="modalButton greenButton" onClick={this.edit}>
							<span className="modalButtonText">Editar</span>
						</MDBBtn>
					</MDBModalFooter>
				</MDBModal>
			</MDBContainer>
		);
	}

	onKeyEventEdit = (e) => {
		if (e.key == "Enter") {
			this.edit(e);
		}
	}


	render() {
		//----------------------ESTO PARA LA TABLA--------------------------------------------------------/
		return (
			<div>
				{/*-------------------------AQUI ESTA LA TABLA----------------------------------------  */}
				<Container>
					<this.deleteModal />
					<this.editModal />
					<this.publishModal />
					<ToastContainer
						position="top-right"
						autoClose={5000}
						hideProgressBar
						newestOnTop
						closeOnClick
						rtl={false}
						pauseOnVisibilityChange
						draggable
						pauseOnHover
					/>
					<MDBRow>
						<MDBCol className="col-12 col-lg-9 offset-lg-3 mt-3">
							<h3 className="adminTitlesFont">Administración de Comentarios</h3>
						</MDBCol>
						<MDBCol className="col-12 col-lg-9 offset-lg-3">
							<MDBCard style={{ padding: '20px 20px 20px 20px' }}>
								<MDBDataTable
									data={this.state.advertisements}
									hover
									striped
									bordered
									entriesLabel={'Mostrar'}
									searchLabel={'Buscar'}
									paginationLabel={['Anterior', 'Siguiente']}
									infoLabel={['Mostrando', 'de', 'de un total de', 'registros']}
									entries={5}
									entriesOptions={[5, 10, 15]}
									small
									responsive
									noBottomColumns={true}
									noRecordsFoundLabel="No se han encontrado datos"
									theadTextWhite={true}
									pagesAmount={4}
								/>
							</MDBCard>
						</MDBCol>
					</MDBRow>

					{/*-------------------------AQUI TERMINA LO DE LA TABLA----------------------------------------  */}
				</Container>
			</div>
		);
	}
}

export default Advertisement;
