/**
 * @fileoverview  EventUser consists of presenting the information of events and calendar to the user.
 *
 * @version 1.0
 * @author Estrella López López <estrella.lopez@ucrso.info>
 * 
 * History - Initial Release
 * ----
 * The first version of EventUser was written by  Estrella López L.
 */


import React, { Component } from 'react';
import { baseUrl } from './baseUrl';
import {
	MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBCard, MDBCardBody, MDBCardTitle, MDBCardText,
	MDBCol, MDBIcon, MDBContainer, Container, Row, Col, Card, MDBCarousel, MDBCarouselCaption, MDBCarouselInner,
	MDBCarouselItem, MDBView
} from 'mdbreact';
import { FormGroup } from 'reactstrap';
import axios from 'axios';
import FullCalendar, { getRectCenter, interactionSettingsStore, interactionSettingsToStore } from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import '../css/calendar.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'sweetalert2/src/sweetalert2.scss'
import Swal from 'sweetalert2'

class EventUser extends Component {
	constructor(props) {
		super(props);
		/**
		 * event: 
		 * @type {Array}
		 * Property that stores the list information of event.
		 * 
		 * date: 
		 * @type {Date}
		 * Property that stores the date.
		 * 
		 * eventID:
		 * @type {integer}
		 * Property that contains the identification of the event
		 * 
		 * link:
		 * @type {String}
		 * Property that stores the link of the image brought from the database.
		 * 
		 * events:
		 * @type {Array}
		 * Property that stores the list of events.
		 *
		 * paymentName:
		 * @type {String}
		 * Property that contains the text free or Pay.
		 * 
		 * payment:
		 * @type {Array}
		 * Property that contains the list of payments.
		 * 
		 * eventData: 
		 * @type {Array}
		 * Property that stores the list information of event.
		 * 
		 * userID:
		 * @type {Integer}
		 * Property that stores the id of the user.
		 * 
		 * enrollEvent:
		 * @type {Array}
		 * Property that stores the list of events where users were registered.
		 * 
		 * enroll:
		 * @type {Array}
		 * Property that stores the list of events where users were registered.
		 * 
		 * statusList:
		 * @type {Boolean}
		 * Property that stores the status of the course list.
		 * 
		 * amount:
		 * @type {Integer}
		 * Property that saves the number of hours of the course.
		 * 
		 * status:
		 * @type {Integer}
		 * Property that saves the count.
		 */
		this.state = {
			event: [],
			date: new Date(),
			eventID: 0,
			link: "",
			events: [],
			paymentName: '',
			payment: [{ id: 0, name: 'Pago' }, { id: 1, name: 'Gratuito' }],
			eventData: [],
			userID: 0,
			enrollEvent: [],
			enroll: [],
			statusList: false,
			amount: 0,
			status: 0,

		};

		this.getAllEvent = this.getAllEvent.bind(this);
		this.getEvents = this.getEvents.bind(this);
		this.eventModal = this.eventModal.bind(this);
		this.eventCard = this.eventCard.bind(this);
		this.getEvent = this.getEvent.bind(this);
		this.getData = this.getData.bind(this);
		this.validateUser = this.validateUser.bind(this);
		this.notify = this.notify.bind(this);
		this.addUserEvent = this.addUserEvent.bind(this);
		this.registraterProcess = this.registraterProcess.bind(this);
		this.validateEnrollUser = this.validateEnrollUser.bind(this);
		this.getAmountParticipants = this.getAmountParticipants.bind(this);
		this.verifyEventStart = this.verifyEventStart.bind(this);
	}


	/**
	 * Method that displays the modals and validates if it is found in the community.
	 * @param {String} text 
	 * @param {Integer} id 
	 * @param {String} name 
	 * @param {Integer} eventid 
	 */
	toggle = (text, id, name, eventid) => () => {

		let modalAction = text + 'Modal';




		if (id != null) {
			this.setState({
				[modalAction]: !this.state[modalAction],
				eventID: eventid
			});

			this.getEvent(eventid);
			this.getAmountParticipants(eventid);
			this.verifyEventStart(eventid);

		} else {

			if (this.state[modalAction] === true) {
				this.setState({
					[modalAction]: !this.state[modalAction]
				});
				this.state.eventID = 0;
				this.setState({ eventID: 0 });

			} else {
				this.setState({
					[modalAction]: !this.state[modalAction]
				});

			}
		}
	};
	componentDidUpdate(prevProps, prevState) {
		if (prevState.eventID !== this.state.eventID) {


			this.validateEnrollUser();
		}
	}

	/**
	 * Method that loads the elements to be initially displayed on the website.
	 */
	componentDidMount() {
		if (sessionStorage.getItem('userType') != 2 && sessionStorage.getItem('userType') != 3) {


			this.getAllEvent();
			this.getEvents();
			this.getData();
			this.validateEnrollUser();

		} else {
			this.props.history.push('/admin');
		}
	}

	/**
	* Method that brings the event preview from the database.
	*/
	getAllEvent() {
		axios.get(baseUrl + 'event/eventPreview').then((response) => {
			this.state.event = response.data;
			this.setState({ event: response.data });
		});
	}

	/**
	 * Method that brings the events to add to the calendar.
	 */
	getEvents() {
		axios.get(baseUrl + 'event/eventCalendar').then((response) => {
			this.state.events = response.data;
			this.setState({ events: response.data });
		});
	}


	/**
	 * Method that brings the multimedia of the event.
	 */
	getData() {

		axios.get(baseUrl + 'event/getEventMultimedia')
			.then(response => {
				this.state.link = response.data[0].link;
				this.setState({ link: response.data[0].link });
			})
	}


	/**
	 * Method redirect to the participant list.
	 * @param {Integer} id 
	 */
	getAmountParticipants(id) {

		axios
			.get(baseUrl + 'event/getParticipantsAmount', {
				params: {
					eventID: id
				}
			})
			.then((response) => {
				this.state.amount = response.data[0].amount;
				this.setState({
					amount: response.data[0].amount
				});
			})
			.catch((err) => console.log('Ha ocurrido un error inesperado'));



	}



	/**
	 * Method that brings the events that the user is registered.
	 */
	validateEnrollUser() {


		axios
			.get(baseUrl + 'event/validateEnrollUser', {
				params: {
					userID: sessionStorage.getItem('user'),
				}

			})
			.then((response) => {
				this.state.enroll = response.data;
				this.setState({
					enroll: response.data
				});
			});


	}

	/**
	 * Method that verifies if the event to be registered by the user exists in the list of user events.
	 * @param {Integer} eventID 
	 */
	verifyEventStart(eventID) {


		if (this.state.enroll == '') {

			this.state.statusList = false;
			this.setState({ statusList: false });
		} else {



			this.state.enroll.map((elements) => {


				if (elements.eventID == eventID) {

					this.state.statusList = true;
					this.setState({ statusList: true });


				} else if (elements.eventID != eventID) {
					this.state.status = 1 + this.state.status
					this.setState({ status: 1 + this.state.status });

				}

			})

			if (this.state.status == this.state.enroll.length) {

				this.state.statusList = false;
				this.setState({ statusList: false });
			}


		}

	}



	/**
	 * Method that verifies that the user is registered before registering.
	 * @param {event} event 
	 */
	registraterProcess(event) {
		let modalAction = 'event' + 'Modal';

		if ((sessionStorage.getItem('userType'))) {

			this.validateUser(event);
		} else {
			this.setState({
				[modalAction]: !this.state[modalAction]
			});
			this.notify(event, 'WARN', 'Debe iniciar sesión para incribirse al evento');
		}

	}

	/**
	 * Method that adds the user to the enrollment list in the database.
	 * @param {event} event 
	 */
	addUserEvent(event) {

		let modalAction = 'event' + 'Modal';


		fetch(baseUrl + 'event/addEnrollUser', {
			method: "post",
			body: JSON.stringify({
				userID: sessionStorage.getItem('user'),
				eventID: this.state.eventData.eventID

			}),
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json"
			}
		}).then(


			this.setState({
				[modalAction]: !this.state[modalAction]
			}),

			Swal.fire({
				title: 'Gracias por su participación',
				text: 'Se le estará enviando un correo con más información',
				imageUrl: 'https://mir-s3-cdn-cf.behance.net/project_modules/fs/8d59ff49047525.58a9f80a68097.gif',
				imageWidth: 400,
				imageHeight: 300,
				imageAlt: 'Custom image',
				showCloseButton: false,
				showCancelButton: false,
			}),
			this.sendEmail()
		)


		this.validateEnrollUser();
	}


	sendEmail() {
		console.log(this.state.eventID);
		axios.get(baseUrl + "event/getEventInfo", {
			params: {
				eventID: this.state.eventID
			}
		}).then(response => {
			var event = response.data[0];
			if (event.payment === 0) {
				fetch(baseUrl + 'event/sendInformationEmail', {
					method: "post",
					body: JSON.stringify({
						email: sessionStorage.getItem('email'),
						name: event.name,
						place: event.place,
						date: event.date,
						quantity: event.hoursQuantity
					}),
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json"
					}
				})
				this.validateEnrollUser();
			} else {
				fetch(baseUrl + 'event/sendInformationEmailFree', {
					method: "post",
					body: JSON.stringify({
						email: sessionStorage.getItem('email'),
						name: event.name,
						place: event.place,
						link: event.eventLink,
						date: event.date,
						quantity: event.hoursQuantity
					}),
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json"
					}
				})

				this.state.status = 0;
				this.setState({ status: 0 });
			}
		});
	}

	/**
	 * Method that validates if the user is already registered in the event.
	 * @param {event} event 
	 */
	validateUser(event) {

		let modalAction = 'event' + 'Modal';
		if (this.state.statusList == false) {


			if (this.state.eventData.amount != this.state.amount) {

				this.setState({
					[modalAction]: !this.state[modalAction]
				});
				this.addUserEvent(event);
			} else {
				this.state.status = 0;
				this.setState({ status: 0 });
				this.notify(event, 'INFO', 'No hay cupo disponible');
				return false;
			}

		} else if (this.state.statusList == true) {
			this.setState({
				[modalAction]: !this.state[modalAction]
			});
			this.state.status = 0;
			this.setState({ status: 0 });
			this.notify(event, 'INFO', 'Usted ya se encuentra inscrito en el evento');
			return false;
		}
	}

	/**
	* Method that, depending on the value of the variable, displays the type of message it is, if it is 
	* correctly entered, there was an error, an informational message, or a warning message.
	* @param {String} evt
	* @param {String} value 
	* @param {String} msj 
	*/
	notify = (evt, value, msj) => {
		switch (value) {
			case 'SUCCESS':

				toast.success(msj);
				break;
			case 'ERROR':
				toast.error(msj);
				break;
			case 'WARN':
				toast.warn(msj);
				break;
			case 'INFO':
				toast.info(msj);
				break;
			default:
				toast.info(msj);
		}
	};

	/**
	 * Method presents the events that exist in the form of a carousel.
	 */
	eventCard() {
		var tempArray = [];
		this.state.event.map((elements, key) => {
			tempArray.push(
				<MDBCarouselItem itemId={key + 1}>
					<MDBView>

						<MDBCard class="cardEvent" style={{ width: 680, height: 350, alignContent: 'center' }}>
							<MDBCardBody className="elegant-color white-text rounded-bottom" />
						</MDBCard>


					</MDBView>
					<MDBCarouselCaption>
						<div class="row row-striped">
							<div class=" col-3 col-md-2 text-right">
								<h1 class="display">
									<span class="badge">{elements.day}</span>
								</h1>
								<h2 className="month userEventCard">{elements.month}</h2>
							</div>

							<div class="col-9 col-md-10" style={{ textAlign: 'left' }}>
								<MDBCardTitle className="userEventCard">{elements.name}</MDBCardTitle>
								<ul class="list-inline">
									<li class="list-inline-item">
										<i class=" clockEvent fa fa-clock" aria-hidden="true" /> {elements.hour}
									</li>
								</ul>
								<MDBCardText className=" textEvent">Descripción:</MDBCardText>
								<MDBCardText className="textEventDescription">{elements.description}</MDBCardText>
								<a
									className="white-text d-flex justify-content-end"
									onClick={this.toggle('event', 0, 'ee', elements.eventID)}
								>
									<h5 className=" textEvent">
										Inscribirse
										<MDBIcon icon="angle-double-right" className="ml-1" />
									</h5>
								</a>
							</div>
						</div>
					</MDBCarouselCaption>
				</MDBCarouselItem>
			);
		});
		return tempArray;
	}

	/**
	* Method that brings the information to the event.
	* @param {Integer} eventID 
	*/
	getEvent(eventID) {

		axios
			.get(baseUrl + 'event/getEventUser', {
				params: { eventID: eventID }
			})
			.then((response) => {
				this.state.eventData = response.data[0];
				this.setState({
					eventData: response.data[0]
				});
				this.state.payment.map((pay) => {
					if (pay.id === this.state.eventData.gratuitous) {
						this.setState({
							paymentName: pay.name
						});
					}
				});
			});


	}


	/**
	 * Method that contains an event modal where it shows the user the data to enter an event.
	 */
	eventModal() {

		return (

			<MDBContainer>


				<MDBModal isOpen={this.state.eventModal} toggle={this.toggle('event')}>
					<MDBModalHeader className="modalHeader modalTitlesFont" toggle={this.toggle('event')}>
						Evento
					</MDBModalHeader>
					<MDBModalBody>

						<FormGroup className="text-center">
							<label className="modalTextFont">{this.state.eventData.eventName}</label>
						</FormGroup>

						<Row className="mt-2 col-sm-12 ">
							<MDBCol className=" mt-2  col-2 offset-lg-1 col-md-2">
								<i class="fas fa-align-justify colorIcon" />
							</MDBCol>
							<MDBCol className=" mt-2 col-10 offset-lg-0 col-md-9">
								<label className="modalTextFont">{this.state.eventData.description}</label>
							</MDBCol>
						</Row>

						<Row className="mt-2 col-sm-12 ">
							<MDBCol className="  col-2 offset-lg-1 col-md-1">
								<i class="fas fa-map-marker-alt colorIcon" />
							</MDBCol>
							<MDBCol className="   col-10 offset-lg-1 col-md-6">
								<label className="modalTextFont">{this.state.eventData.place}</label>
							</MDBCol>
						</Row>

						<Row className="mt-2 col-sm-12 ">
							<MDBCol className="  col-2 offset-lg-1 col-md-1">
								<i class="far fa-clock  colorIcon " />
							</MDBCol>
							<MDBCol className="   col-10 offset-lg-1 col-md-6">
								<label className="modalTextFont">{this.state.eventData.date}</label>
							</MDBCol>
						</Row>

						<Row className="mt-2 col-sm-12 ">
							<MDBCol className="  col-2 offset-lg-1 col-md-1">
								<i class="far fa-money-bill-alt  colorIcon " />
							</MDBCol>
							<MDBCol className="   col-10 offset-lg-1 col-md-6">
								<label className="modalTextFont">{this.state.paymentName}</label>
							</MDBCol>
						</Row>

						<Row className="mt-2 col-sm-12 ">
							<MDBCol className="  col-2 offset-lg-1 col-md-1">
								<i class="fas fa-users colorIcon" />
							</MDBCol>
							<MDBCol className="   col-10 offset-lg-1 col-md-6">
								<label className="modalTextFont">{this.state.eventData.quota}</label>
							</MDBCol>
						</Row>
						<Row className="mt-2 col-sm-12 ">
							<MDBCol className="  col-2 offset-lg-1 col-md-1">
								<i class="fas fa-language colorIcon" />
							</MDBCol>
							<MDBCol className="   col-10 offset-lg-1 col-md-6">
								<label className="modalTextFont">{this.state.eventData.language}</label>
							</MDBCol>
						</Row>

					</MDBModalBody>
					<MDBModalFooter>
						<MDBCol className="  col-10 offset-lg-2 col-md-12">
							<a onClick={this.registraterProcess}>
								<h5 className="linkRegister ">Inscribirse al evento</h5>

							</a>
						</MDBCol>
					</MDBModalFooter>
				</MDBModal>
			</MDBContainer>
		);
	}


	/**
	* Method that shows the elements found or added to the web page.
	*/
	render() {
		return (

			<div>
				<ToastContainer
					position="top-right"
					autoClose={2000}
					hideProgressBar
					newestOnTop
					closeOnClick={false}
					rtl={false}
					pauseOnVisibilityChange
					draggable
					pauseOnHover
				/>

				<div class="view">
					<img src={baseUrl + this.state.link} className="responsive-img" alt="" id="bannerHome" />
					<div class="mask flex-center " id="maskIntro" >
						<Col xs={2} md={6} lg={10}>
						</Col>
					</div>
				</div>

				<Container className="mt-5 ">
					<this.eventModal />
					<Row className="mt-12">
						<Col md="12" className="mx-auto">

							<Row className="mt-0">
								<MDBCol className=" col-12 col-lg-9 offset-lg-0 mt-3">
									<nav aria-label="breadcrumb">
										<ol class="breadcrumb white">
											<li class="breadcrumb-item"><a class="black-text" href="/">Inicio</a></li>
											<li class="breadcrumb-item active">Eventos</li>

										</ol>
									</nav>
								</MDBCol>
							</Row>


							<Row className="mt-3 col-sm-12 ">

								<MDBCol className=" mt-2  col-12 offset-lg-1 col-md-12">
									<h3 className="userTitlesFont">Eventos</h3>
								</MDBCol>
							</Row>

							<Row className="mt-4 col-sm-12 paragraph">
								<Col className="col-12 col-md-10 offset-lg-1">
									<FormGroup>

										<label>Si deseas participar de un evento le invito a iniciar sesión </label>
									</FormGroup>
								</Col>
							</Row>


							<Row className="mt-3  col-sm-12">
								<MDBCol className=" mt-2  col-12 offset-lg-5 col-md-6">
									<h3 className="mediumUserTextBold">Calendario</h3>
								</MDBCol>
							</Row>


							<Col Col xs={2} md={12} lg={10}>
								<Card className="mt-5 offset-lg-1">
									<div >
										<div >

											<FullCalendar
												defaultView="dayGridMonth"
												plugins={[dayGridPlugin, interactionPlugin]}

												locale='es'
												events={this.state.events}

												defaultAllDay={true}

												eventMouseEnter={(info) => {
													info.jsEvent.preventDefault();

													info.el.onclick = this.toggle("event", 0, "ee", info.event.id)

													info.el.style.borderColor = 'red';
												}
												}

											/>
										</div>
									</div>
								</Card>
								<br />
								<br />
							</Col>

							<MDBCol className="col-lg-8 offset-lg-2 mt-2 sm-12">
								<MDBContainer>
									<MDBCarousel
										activeItem={1}
										length={this.state.event.length}
										showControls={true}
										showIndicators={true}
										responsive={true}
										className="z-depth-1"
									>
										<MDBCarouselInner>{this.eventCard()}</MDBCarouselInner>
									</MDBCarousel>
								</MDBContainer>
							</MDBCol>
							<br />
							<br />

							{/*</div>*/}
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}
export default EventUser;
