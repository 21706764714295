/**
 * @fileoverview AboutUsUser, this component show the about us information to the user
 *
 * @version 1.0
 *
 * @author Ester Molina R <maria.molina@ucrso.info>
 * History
 * v1.0 - Initial Realease
 * ----
 * The first version of AboutUsUser was written by Ester Molina R.
 *
 * @version 2.0
 *
 * @author Kevin Álvarez Barrantes <kevin.alvarez@ucrso.info>
 * History
 * v2.0 - Initial Realease
 * ----
 * The second version of AboutUsUser was written by Kevin Álvarez B.
 */

import { Card, MDBCol, MDBContainer, MDBRow, MDBAnimation } from "mdbreact";
import React, { Component } from "react";
import axios from "axios";
import { baseUrl } from "./baseUrl";
import imagenAAM from "../images/aam.png";
import imagenCA from "../images/ca.png";
import imagenDOI from "../images/doi.png";

class AboutUsUser extends Component {
  constructor(props) {
    super(props);

    /**
     * values:
     * @type {String}
     * Property that contains the values' text
     *
     * valuesClassName:
     * @type {String}
     * Property that contains the className for the values' HTML
     *
     * valuesImageLink:
     * @type {String}
     * Property that contains the values' image link
     *
     * valuesImgClassName:
     * @type {String}
     * Property that contains the className for the values' image HTML
     *
     * hiddenValuesImg:
     * @type {boolean}
     * Property that contains if the image is shown or not
     *
     * purpose:
     * @type {String}
     * Property that contains the purpose' text
     *
     * purposeClassName:
     * @type {String}
     * Property that contains the className for the purpose' HTML
     *
     * purposeImageLink:
     * @type {String}
     * Property that contains the purpose' image link
     *
     * purposeImgClassName:
     * @type {String}
     * Property that contains the className for the purpose' image HTML
     *
     * hiddenPurposeImg:
     * @type {boolean}
     * Property that contains if the image is shown or not
     *
     * biography:
     * @type {String}
     * Property that contains the biography' text
     *
     * biographyClassName:
     * @type {String}
     * Property that contains the className for the biography' HTML
     *
     * biographyImageLink:
     * @type {String}
     * Property that contains the biography' image link
     *
     * biographyImgClassName:
     * @type {String}
     * Property that contains the className for the biography' image HTML
     *
     * hiddenbiographyImg:
     * @type {boolean}
     * Property that contains if the image is shown or not
     *
     * videoLink
     * @type {String}
     * Property that has the front page image link
     */
    this.state = {
      values: "",
      valuesClassName:
        "col-10 offset-1 order-2 order-md-1 col-md-9 offset-md-0 infoDiv",
      valuesImageLink: "",
      valueImgClassName:
        "col-10 offset-1 order-1 order-md-2 col-md-3 offset-md-0 infoImg",
      hiddenValuesImg: false,
      purpose: "",
      purposeClassName: "col-10 offset-1 col-md-9 offset-md-0 infoDiv",
      purposeImageLink: "",
      purposeImgClassName: "col-10 offset-1  col-md-3 offset-md-0 infoImg",
      hiddenPurposeImg: false,
      biography: "",
      // biography2: "",
      biographyClassName: "",
      //"col-10 offset-1 order-2 order-md-1 col-md-9 offset-md-0 ",
      biographyClassName2:
        "col-10 offset-1 order-2 order-md-1 col-md-9 offset-md-0 infoDiv",
      biographyImageLink: "",
      // biographyImageLink2: "",
      biographyImgClassName: "col offset-3 col-md-6",
      //biographyImgClassName2: "col-10 offset-1  col-md-3 offset-md-0 infoImg",
      hiddenBiographyImg: false,
      // hiddenBiographyImg2: false,
      videoLink: "",
    };
    this.getData = this.getData.bind(this);
  }

  componentDidMount() {
    this.getData();
  }

  /**
   * This method charge the data from the database
   */
  getData() {
    axios.get(baseUrl + "aboutus/getAboutUsText").then((response) => {
      var textArray = response.data;
      textArray.map((text) => {
        if (text.description === "Valores") {
          this.setState({
            values: text.textValue,
          });
        } else if (text.description === "Propósito") {
          this.setState({
            purpose: text.textValue,
          });
        } else if (text.description === "Biografía") {
          this.setState({
            biography: text.textValue,
          });
          // } else {
          //   this.setState({
          //     biography2: text.textValue,
          //   });
        }
      });
    });

    axios.get(baseUrl + "aboutus/getAboutUsMultimedia").then((response) => {
      var multimediaArray = response.data;
      if (multimediaArray.length !== 0) {
        multimediaArray.map((multimedia) => {
          if (multimedia.description === "Valores") {
            this.setState({
              valuesImageLink: multimedia.link,
            });
            document.getElementById("valuesImg").src =
              baseUrl + multimedia.link;
          } else if (multimedia.description === "Propósito") {
            this.setState({
              purposeImageLink: multimedia.link,
            });
            document.getElementById("purposeImg").src =
              baseUrl + multimedia.link;
          } else if (multimedia.description === "Biografía") {
            this.setState({
              biographyImageLink: multimedia.link,
            });
            document.getElementById("biographyImg").src =
              baseUrl + multimedia.link;
            // } else if (multimedia.description === "Biografía2") {
            //   this.setState({
            //     biographyImageLink2: multimedia.link,
            //   });
            //   document.getElementById("biographyImg2").src =
            //     baseUrl + multimedia.link;
          } else if (multimedia.description === "Portada Vi") {
            this.setState({
              videoLink: multimedia.link,
            });
          }
        });
      } else {
        this.setState({
          hiddenValuesImg: true,
          hiddenPurposeImg: true,
          hiddenBiographyImg: true,
          // hiddenBiographyImg2: true,
          valuesClassName:
            "col-10 offset-1 order-md-1 col-md-12 offset-md-0 infoDiv",
          purposeClassName:
            "col-10 offset-1 order-md-1 col-md-12 offset-md-0 infoDiv",
          biographyClassName:
            "col-10 offset-1 order-md-1 col-md-12 offset-md-0 infoDiv",
          // biographyClassName2:
          // "col-10 offset-1 order-md-1 col-md-12 offset-md-0 infoDiv",
        });
      }
    });
  }

  /**
   * This method render the HTML
   */
  render() {
    return (
      <div
        style={{
          alignContent: "center",
          justifyContent: "center",
          WebkitAlignItems: "center",
        }}
      >
        <MDBAnimation type="fadeIn">
          <img
            src={baseUrl + this.state.videoLink}
            className="responsive-img"
            alt=""
            id="bannerHome"
          />
        </MDBAnimation>
        <MDBContainer>
          <MDBRow>
            <MDBCol className=" col-12  mt-3">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb white">
                  <li class="breadcrumb-item">
                    <a class="black-text" href="#">
                      Inicio
                    </a>
                  </li>
                  <li class="breadcrumb-item active">Nosotros</li>
                </ol>
              </nav>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <div className={this.state.valuesClassName}>
              <br />
              <br />
              <Card style={{ width: 800, height: 140 }}>
                <p
                  className="font-weight-bold "
                  style={{
                    marginTop: 10,
                    marginLeft: 15,
                    marginBottom: 2,
                    marginRight: 5,
                  }}
                >
                  <strong>Valores:</strong>
                </p>
                <p
                  style={{
                    marginTop: 10,
                    marginLeft: 15,
                    marginBottom: 2,
                    marginRight: 5,
                    textAlign: "center",
                  }}
                >
                  {this.state.values}
                </p>
              </Card>
            </div>

            <div
              className={this.state.valueImgClassName}
              hidden={this.state.hiddenValuesImg}
            >
              <MDBAnimation type="fadeIn" delay="3s">
                <img
                  id="valuesImg"
                  width="100%"
                  className=" img-fluid z-depth-3 rounded responsive-img"
                />
              </MDBAnimation>
            </div>
          </MDBRow>
          <MDBRow>
            <div
              className={this.state.purposeImgClassName}
              hidden={this.state.hiddenPurposeImg}
            >
              <MDBAnimation type="fadeIn" delay="3s">
                <img
                  id="purposeImg"
                  width="100%"
                  className=" img-fluid z-depth-3 rounded"
                />
              </MDBAnimation>
            </div>
            <div className={this.state.purposeClassName}>
              <br />
              <br />
              <Card style={{ width: 810, height: 140 }}>
                <p
                  className="font-weight-bold "
                  style={{
                    marginTop: 10,
                    marginLeft: 15,
                    marginBottom: 2,
                    marginRight: 5,
                  }}
                >
                  <strong>Propósito:</strong>
                </p>
                <p
                  style={{
                    marginTop: 10,
                    marginLeft: 15,
                    marginBottom: 2,
                    marginRight: 5,
                    textAlign: "center",
                  }}
                >
                  {this.state.purpose}
                </p>
              </Card>
            </div>
          </MDBRow>
          <br />
          <br />
          <h2 style={{ textAlign: "center" }}>Fundadora de EnConcepto</h2>
          <br />
          <br />
          <MDBRow>
            <MDBCol size="6">
              <div
                className={this.state.biographyImgClassName}
                hidden={this.state.biographyValuesImg}
              >
                <MDBAnimation type="fadeIn" delay="1s">
                  <img
                    id="biographyImg"
                    width="100%"
                    className=" img-fluid z-depth-3 rounded mx-auto d-block"
                  />
                </MDBAnimation>
              </div>
              <div className={this.state.biographyClassName}>
                <p className="font-weight-bold ">
                  <br />
                  <br />

                  <strong>Marcela Briceño:</strong>
                  <p>Consultora, Coach y Trainer</p>
                </p>
                <p style={{ textAlign: "justify" }}>{this.state.biography}</p>
                <p style={{ textAlign: "justify" }}>
                  Formación en: Scrum, Agile, Coaching, 6 Sigma, HYL,
                  Habilidades Blandas, Equipos virtuales y Comunicación, Gestión
                  y organización de Eventos, Servicio al Cliente, Procesos de
                  Negocio, Gestión de riesgos y Administración de Empresas.
                </p>
              </div>
            </MDBCol>

            <MDBCol size="6">
              <p style={{ textAlign: "justify" }}>
                Licenciada en Turismo en Administración de Hoteles y
                Restaurantes de la Universidad Hispanoamericana, Egresada de
                Administración y como Asesor de Seguros del Instituto
                Tecnológico de Costa Rica, Social Impact Assessment, del BID y
                profesora de crecimiento personal HYL.
              </p>
              <p style={{ textAlign: "justify" }}>
                Fundadora de Agilidad en Familia, movimiento que se ha expandido
                por Latinoamérica en donde se integran valores y agilidad para
                niños y niñas. Líder activo de la comunidad Heart of Agile en
                Costa Rica y Perú y miembro fundador del equipo de Disciplined
                Agile Costa Rica,
              </p>
              <p style={{ textAlign: "justify" }}>
                Creadora de las primeras comunidades de Socios Voluntarios,
                durante su rol como Vicepresidenta de Voluntariado para el PMI
                Chapter Costa Rica y actual representante voluntario país como
                Youth and Social Impact Coordinator y apoya como lider en el
                programa Entrenando a Entrenadores de materiales virtuales.
              </p>
              <p style={{ textAlign: "justify" }}>
                Editora en Jefe de la Revista Doing Proyects in Panama y líder
                de eventos de la Comunidad de interés de liderazgo femenino,
                Capitulo Buenos Aires, Argentina.
              </p>
            </MDBCol>
          </MDBRow>
          <p className="font-weight-bold ">
            <br />
            <strong>EnConcepto es miembro de:</strong>
          </p>
          <br />
          <MDBRow className="mt-1 col-sm-12">
            <div class="col-md">
              <a href="https://www.agilealliance.org/">
                <img
                  src={imagenAAM}
                  className=" img-fluid z-depth-3 rounded responsive-img offset-lg-0"
                  alt=""
                  width="255"
                  height="40"
                  id="AAM"
                />
              </a>
            </div>
            <div class="col-md">
              <a href="https://connectamericas.com/">
                <img
                  src={imagenCA}
                  className=" img-fluid z-depth-3 rounded responsive-img offset-lg-0"
                  alt=""
                  width="304"
                  height="236"
                  id="CA"
                />
              </a>
            </div>
            <div class="col-md">
              <a href="https://www.devopsinstitute.com/">
                <img
                  src={imagenDOI}
                  className=" img-fluid z-depth-3 rounded responsive-img offset-lg-2"
                  alt=""
                  width="304"
                  height="236"
                  id="DOI"
                />
              </a>
            </div>
          </MDBRow>
          <br />
          {/* <MDBRow>
            <div
              className={this.state.biographyImgClassName2}
              hidden={this.state.biographyValuesImg2}
            >
              <MDBAnimation type="fadeIn" delay="3s">
                <img
                  id="biographyImg2"
                  width="100%"
                  className=" img-fluid z-depth-3 rounded"
                />
              </MDBAnimation>
            </div>
            <div className={this.state.biographyClassName2}>
              <p className="font-weight-bold ">
                <strong>Rocío Briceño:</strong>
              </p>
              <p style={{ textAlign: "justify" }}>{this.state.biography2}</p>
            </div>
          </MDBRow> */}
        </MDBContainer>
      </div>
    );
  }
}

export default AboutUsUser;
