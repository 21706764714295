/**
 * @fileoverview  QuotationUser this component consists of presenting the quote that the user is going to make to the site administrator.
 *
 * @version 1.0
 * @author Estrella López López <estrella.lopez@ucrso.info>
 *
 * History - Initial Release
 * ----
 * The first version of QuotationUser was written by  Estrella López López.
 */

import React, { Component } from "react";
import { Card, Container, FormGroup } from "reactstrap";
import { Row, Col, MDBBtn, MDBCol } from "mdbreact";
import { baseUrl } from "./baseUrl";
import axios from "axios";
import Select from "react-select";
import IntlTelInput from "react-intl-tel-input-v2";
import "intl-tel-input/build/css/intlTelInput.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import validations from "./Validations";

class QuotationUser extends Component {
  constructor(props) {
    super(props);
    /**
     * validations:
     * @type {InstanceType}
     * Property that allows us to instantiate to use the validations in this class.
     *
     * lastName:
     * @type {String}
     * Property that stores the user's last name.
     *
     * categoryID:
     * @type {Integer}
     * Property that stores the id of the category.
     *
     * categories:
     * @type {Array}
     * Property that stores the category list.
     *
     * languageID:
     * @type {Integer}
     * Property that saves the id language.
     *
     * name:
     * @type {String}
     * Property that stores the user's name.
     *
     * languages:
     * @type {Array}
     * Property that stores the list of languages ​​that exist in the database.
     *
     * courses:
     * @type {Array}
     * Property that stores the list of courses ​​that exist in the database.
     *
     * countries:
     * @type {Array}
     * Property that stores the list of countries ​​that exist in the database.
     *
     * countryID:
     * @type {Integer}
     * Property that stores the id of the selected country.
     *
     * courseID:
     * @type {Integer}
     * Property that stores the id of the selected course.
     *
     * courseName:
     * @type {String}
     * Property that stores the name of the course.
     *
     * email:
     * @type {String}
     * Property that stores the user's email.
     *
     * description:
     * @type {String}
     * Property that stores the quotation description.
     *
     * value:
     * @type {Array}
     * Property that stores phone extensions, in addition to their number.
     *
     * detail:
     * @type {Integer}
     * Property that will store the id of the selected course.
     *
     * courseType:
     * @type {Integer}
     * Property that stores the course Type.
     *
     * description1:
     * @type {String}
     * Property that stores the quotation description, but comments or observations.
     *
     * hoursQuantity:
     * @type {Integer}
     * Property that stores the number of hours.
     *
     * gratuitousYN:
     * @type {Integer}
     * Property that stores the identification if it is free or not.
     *
     * paymentName:
     * @type {Integer}
     * Property that stores the payment name.
     *
     * payment:
     * @type {Array}
     * Property that saves two forms of payment, either free or with cost.
     *
     * link:
     * @type {String}
     *
     * data:
     * @type {Array}
     * Property that will store a list of courses chosen by the user.
     *
     * courseNameQuotationID:
     * @type {Integer}
     * Property that stores the id of the course to be quoted.
     *
     * statusList:
     * @type {Boolean}
     * Property that stores the status of the course list.
     *
     * courseListQuotation:
     * @type {Array}
     * Property that stores the list of courses of the quote.
     *
     * amount:
     * @type {Integer}
     * Property that saves the number of hours of the course.
     *
     * quotationTemporalID:
     * @type {Integer}
     * Property that stores a temporary quote id.
     *
     * clientID:
     * @type {Integer}
     * Property that stores the id of the client that requires a quote.
     */

    this.state = {
      validations: new validations(),
      lastname: "",
      categoryID: 0,
      categories: [],
      languageID: 0,
      name: "",
      languages: [{}],
      courses: [{}],
      countries: [{}],
      countryID: 0,
      courseID: 0,
      courseName: "",
      email: "",
      description: "",
      value: { iso2: "cr", dialCode: "506", phone: "" },
      detail: 0,
      coursetype: "",
      description1: "",
      hoursQuantity: 0,
      gratuitousYN: 0,
      paymentName: "",
      payment: [
        { id: 0, name: "Pago" },
        { id: 1, name: "Gratuito" },
      ],
      link: "",
      data: [],
      courseNameQuotation: 0,
      statusList: true,
      courseListQuotation: [],
      amount: 0,
      quotationTemporalID: 0,
      clientID: 0,
    };

    this.coursefilter = this.coursefilter.bind(this);
    this.dataSelect = this.dataSelect.bind(this);
    this.languageSelect = this.languageSelect.bind(this);
    this.courseSelect = this.courseSelect.bind(this);
    this.countrySelect = this.countrySelect.bind(this);
    this.addQuotationUser = this.addQuotationUser.bind(this);
    this.valueName = this.valueName.bind(this);
    this.valueLastName = this.valueLastName.bind(this);
    this.valueDescription = this.valueDescription.bind(this);
    this.valueEmail = this.valueEmail.bind(this);
    this.valueAmount = this.valueAmount.bind(this);
    this.valuetelephone = this.valuetelephone.bind(this);
    this.back = this.back.bind(this);
    this.notify = this.notify.bind(this);
    this.validateData = this.validateData.bind(this);
    this.empty = this.empty.bind(this);
    this.loadCategories = this.loadCategories.bind(this);
    this.deleteElementList = this.deleteElementList.bind(this);
    this.courseInformation = this.courseInformation.bind(this);
    this.addElementList = this.addElementList.bind(this);
    this.validateExistCourse = this.validateExistCourse.bind(this);
    this.deleteListQuotation = this.deleteListQuotation.bind(this);
    this.addQuotationUserForCourse = this.addQuotationUserForCourse.bind(this);
    this.addQuotation = this.addQuotation.bind(this);
    this.registerUser = this.registerUser.bind(this);
    this.addListQuotation = this.addListQuotation.bind(this);
    this.addQuotationUserID = this.addQuotationUserID.bind(this);
  }

  /**
   * Method that returns to the previous page
   */
  back() {
    this.props.history.push(`/CourseUser`);
  }

  /**
   * Method that loads the elements to be initially displayed on the website.
   */
  componentDidMount() {
    if (
      sessionStorage.getItem("userType") != 2 &&
      sessionStorage.getItem("userType") != 3
    ) {
      this.state.detail = this.props.match.params.ID;
      this.setState({ detail: this.props.match.params.ID });
      this.state.languageID = this.props.match.params.LANGUAGEID;
      this.setState({ languageID: this.props.match.params.LANGUAGEID });
      this.state.amount = this.props.match.params.AMOUNT;
      this.setState({ amount: this.props.match.params.AMOUNT });
      this.state.quotationTemporalID = this.props.match.params.QUOTATIONID;
      this.setState({
        quotationTemporalID: this.props.match.params.QUOTATIONID,
      });
      this.loadCategories();
      this.dataSelect();

      this.courseInformation(
        this.state.amount,
        this.state.detail,
        this.state.languageID,
        this.state.quotationTemporalID
      );

      this.state.amount = 0;
      this.setState({ amount: 0 });

      this.state.languageID = 0;
      this.setState({ languageID: 0 });
    } else {
      this.props.history.push("admin");
    }
  }

  /**
   * Method that will add the elements entered from the quote to the courseListQuotation list.
   * @param {Integer} amount
   * @param {Integer} detail
   * @param {Integer} languageID
   * @param {String} courseName
   * @param {Integer} quotationID
   */
  addListQuotation(amount, detail, languageID, courseName, quotationID) {
    this.state.courseListQuotation.push({
      amount: amount,
      courseID: detail,
      languageID: languageID,
      courseName: courseName,
      quotationID: quotationID,
    });
  }

  /**
   * Method that goes to the database and brings the information that a course has, in this case the addListQuotation method is also called, so that it adds the course to the list that is available.
   * @param {Integer} amount
   * @param {Integer} detail
   * @param {Integer} languageID
   * @param {Integer} quotationID
   */
  courseInformation(amount, detail, languageID, quotationID) {
    axios
      .get(baseUrl + "courseUser/courseDetail/" + this.state.detail)
      .then((response) => {
        this.state.courseName = response.data[0].name;
        this.addListQuotation(
          amount,
          detail,
          languageID,
          response.data[0].name,
          quotationID
        );
        this.state.data.push(response.data[0].name);
        this.setState({ courseName: response.data[0].name });

        this.state.description1 = response.data[0].description;
        this.setState({ description1: response.data[0].description });

        this.state.coursetype = response.data[0].coursetype;
        this.setState({ coursetype: response.data[0].coursetype });

        this.state.hoursQuantity = response.data[0].hoursQuantity;
        this.setState({ hoursQuantity: response.data[0].hoursQuantity });

        this.state.gratuitousYN = response.data[0].gratuitousYN;
        this.setState({ gratuitousYN: response.data[0].gratuitousYN });
        this.state.payment.map((pay) => {
          if (pay.id === this.state.gratuitousYN) {
            this.setState({
              paymentName: pay.name,
            });
          }
        });

        this.state.link = response.data[0].link;
        this.setState({ link: response.data[0].link });
      });

    if (
      sessionStorage.getItem("userType") != 2 &&
      sessionStorage.getItem("userType") != 3
    ) {
      axios.get(baseUrl + "course/getAllCategories").then((response) => {
        this.state.categories = response.data;
        this.setState({ categories: response.data });
      });
      this.dataSelect();
    } else {
      this.props.history.push("/admin");
    }
  }

  /**
   * Method that brings the courses that belong to the category that are sent to the database.
   * @param {event} event
   */
  coursefilter(event) {
    this.state.categoryID = event.value;
    this.setState({ categoryID: event.value });

    axios
      .get(baseUrl + "courseUser/courseFilter/" + event.value)
      .then((response) => {
        this.state.courses = response.data;
        this.setState({ courses: response.data });
      })
      .catch((err) => console.log("Ha ocurrido un error inesperado"));
  }

  /**
   * Method that brings the categories entered from the database and saves them in the previously created variable.
   */
  loadCategories() {
    axios.get(baseUrl + "course/getAllCategories").then((response) => {
      this.state.categories = response.data;
      this.setState({ categories: response.data });
    });
  }

  /**
   * Method that brings the languages and countries entered from the database and saves them in the variables
   * previously created.
   * @param {event} event
   */
  dataSelect(event) {
    var languages = [];
    var countries = [];

    axios
      .get(baseUrl + "event/getLanguage")
      .then((response) => {
        languages = response.data;
        this.setState({
          languages,
        });
      })
      .catch((err) => console.log("Ha ocurrido un error inesperado"));

    axios
      .get(baseUrl + "employee/getAllCountries")
      .then((response) => {
        countries = response.data;
        countries.push({ countryID: null, name: "Ninguno" });
        this.setState({
          countries,
        });
      })
      .catch((err) => console.log("Ha ocurrido un error inesperado"));
  }

  /**
   * Method that assigns the chosen value of the selection to the variable.
   * @param {event} event
   */
  languageSelect(event) {
    this.state.languageID = event.value;
    this.setState({ languageID: event.value });
    this.setState({
      languageID: event.value,
    });
  }

  /**
   * Method that assigns the chosen value of the selection to the variable.
   * @param {event} event
   */
  courseSelect(event) {
    this.state.courseNameQuotation = event.value;
    this.setState({ courseNameQuotation: event.value });
    this.setState({
      courseID: event.value,
    });
    this.state.courseName = event.label;
  }

  /**
   * Method that assigns the chosen value of the selection to the variable.
   * @param {event} event
   */
  countrySelect(event) {
    this.setState({
      countryID: event.value,
    });
  }

  /**
   * Method where the description and the user are added to the quote and it goes to the database.
   * It is used when the user is registered.
   */
  addQuotationUserID() {
    fetch(baseUrl + "quotationUser/addQuotationUserID", {
      method: "post",
      body: JSON.stringify({
        description: this.state.description,
        clientID: sessionStorage.getItem("user"),
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
  }

  /**
   * Method where the personal information of the user making the quote is added to the database.
   * It is used when the user is not registered.
   */
  addQuotationUser() {
    fetch(baseUrl + "quotationUser/addQuotationUser", {
      method: "post",
      body: JSON.stringify({
        name: this.state.name,
        lastname: this.state.lastname,
        email: this.state.email,
        telephone: this.state.value.dialCode + this.state.value.phone,
        description: this.state.description,
        countryID: this.state.countryID,
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
  }

  /**
   * Method that loops through the courseListQuotation list and calls the method that sends it to the database.
   */
  addQuotationUserForCourse() {
    this.state.courseListQuotation.map((elements, key) => {
      this.addQuotation(
        elements.languageID,
        elements.courseID,
        elements.amount,
        elements.quotationID
      );
    });
  }

  /**
   * Method that adds the data of each course to be quoted to the database.
   * @param {Integer} lenguageID
   * @param {Integer} courseID
   * @param {Integer} amount
   * @param {Integer} quotationID
   */
  addQuotation(lenguageID, courseID, amount, quotationID) {
    fetch(baseUrl + "quotationUser/addQuotationForCourse", {
      method: "post",
      body: JSON.stringify({
        quotationID: quotationID,
        languageID: lenguageID,
        courseID: courseID,
        amount: amount,
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
  }

  /**
   * Method that validates the data entered by the user, the way it should be written.
   * @param {event} event
   */
  validateData(event) {
    if (sessionStorage.getItem("user") === null) {
      if (this.empty(event)) {
        if (
          !this.state.validations.validateTextWithNumberField(this.state.name)
        ) {
          this.notify(
            event,
            "ERROR",
            "El nombre no debe contener carateres especiales"
          );
        } else if (
          !this.state.validations.validateTextWithNumberField(
            this.state.lastname
          )
        ) {
          this.notify(
            event,
            "ERROR",
            "El apellido no debe contener carateres especiales"
          );
        } else if (
          !this.state.validations.validateEmailField(this.state.email)
        ) {
          this.notify(
            event,
            "ERROR",
            "La estructura de correo no es la correcta"
          );
        } else if (this.state.courseListQuotation == "") {
          this.notify(
            event,
            "ERROR",
            "Debe al menos elegir un curso para cotizar"
          );
        } else {
          this.addQuotationUser();
          // fetch(baseUrl + "quotation/sendQuotationEmail", {
          //   method: "post",
          //   headers: {
          //     Accept: "application/json",
          //     "Content-Type": "application/json",
          //   },
          // })
          //   .then(console.log("El correo se envio con exito"))
          //   .catch(console.log("El envio del correo fallo"));
          this.notify(event, "SUCCESS", "Se ha enviado su solicitud");
        }
      }
    } else {
      if (this.state.courseListQuotation == "") {
        this.notify(
          event,
          "ERROR",
          "Debe al menos elegir un curso para cotizar"
        );
      } else {
        this.addQuotationUserID();
        // fetch(baseUrl + "quotation/sendQuotationEmail", {
        //   method: "post",
        //   headers: {
        //     Accept: "application/json",
        //     "Content-Type": "application/json",
        //   },
        // })
        //   .then(console.log("El correo se envio con exito"))
        //   .catch(console.log("El envio del correo fallo"));
        this.notify(event, "SUCCESS", "Se ha enviado su solicitud");
      }
    }
  }

  /**
   * Method that validates that the required quotation fields are not empty.
   * @param {event} event
   */
  empty(event) {
    if (this.state.name.length === 0) {
      this.notify(event, "ERROR", "El nombre no debe estar vacío");
      return false;
    } else if (this.state.lastname.length === 0) {
      this.notify(event, "ERROR", "El apellido no debe estar vacío");
      return false;
    } else if (this.state.email.length === 0) {
      this.notify(event, "ERROR", "El correo no debe estar vacío");
      return false;
    } else if (this.state.value.phone === "") {
      this.notify(event, "ERROR", "Debe agregar un número de teléfono");
      return false;
    } else if (this.state.countryID === 0) {
      this.notify(event, "ERROR", "Debe seleccionar un país");
      return false;
    } else {
      return true;
    }
  }

  /**
   *
   * Method that, depending on the value of the variable, displays the type of message it is, if it is correctly entered, there was an
   * error, an informational message, or a warning message.
   * @param {String} evt
   * @param {String} value
   * @param {String} msj
   */
  notify = (evt, value, msj) => {
    switch (value) {
      case "SUCCESS":
        if (msj == "Se ha enviado su solicitud") {
          setTimeout(() => {
            this.addQuotationUserForCourse();
          }, 3000);
          toast.success(msj);
          this.back();
        } else {
          toast.success(msj);
        }

        break;
      case "ERROR":
        toast.error(msj);
        break;
      case "WARN":
        toast.warn(msj);
        break;
      case "INFO":
        toast.info(msj);
        break;
      default:
        toast.info(msj);
    }
  };

  /**
   * Method that assigns the value entered to the variable.
   * @param {event} event
   */
  valueName(event) {
    this.state.name = event.target.value;
    this.setState({ name: event.target.value });
  }

  /**
   * Method that assigns the value entered to the variable.
   * @param {event} event
   */
  valueLastName(event) {
    this.state.lastname = event.target.value;
    this.setState({ lastname: event.target.value });
  }

  /**
   * Method that assigns the value entered to the variable.
   * @param {event} event
   */
  valueEmail(event) {
    this.state.email = event.target.value;
    this.setState({ email: event.target.value });
  }

  /**
   * Method that assigns the value entered to the variable.
   * @param {event} event
   */
  valueAmount(event) {
    this.state.amount = event.target.value;
    this.setState({ amount: event.target.value });
  }

  /**
   * Method that assigns the value entered to the variable.
   * @param {event} event
   */
  valueDescription(event) {
    this.state.description = event.target.value;
    this.setState({ description: event.target.value });
  }

  /**
   * Method that assigns the value entered to the variable.
   * @param {event} event
   */
  valuetelephone(event) {
    this.state.telephone = event;
    this.setState({ telephone: event });
  }

  /**
   * Method that assigns the value entered to the variable.
   * @param {event} event
   */
  onChange = (value) => this.setState({ value });

  /**
   * Method that validates that the course to quote is chosen only once in the same quote,
   * depending on the result the statusList variable is modified
   * @param {String} nameCourse
   */
  validateExistCourse(nameCourse) {
    {
      this.state.data.map((elements, key) => {
        if (nameCourse == elements) {
          this.state.statusList = false;
          this.setState({ statusList: false });
        } else {
          this.state.statusList = true;
          this.setState({ statusList: true });
        }
      });
    }
  }

  /**
   * Method that validates the data of the course to be quoted if it is correct, validates that the course
   * is not there and then adds it to the list of courses to be quoted
   * @param {String} nameCourse
   */
  addElementList(nameCourse) {
    if (this.state.categoryID === 0) {
      this.notify(this, "ERROR", "Debe seleccionar una categoría");
      return false;
    } else if (this.state.courseNameQuotation === 0) {
      this.notify(this, "ERROR", "Debe seleccionar un curso");
      return false;
    } else if (this.state.languageID === 0) {
      this.notify(this, "ERROR", "Debe seleccionar un lenguaje");
      return false;
    } else if (
      !this.state.validations.validateNumericField(this.state.amount)
    ) {
      this.notify(this, "ERROR", "La cantidad debe ser un número");
      return false;
    } else if (this.state.amount === 0) {
      this.notify(this, "ERROR", "La cantidad no debe estar vacía");
      return false;
    } else if (this.state.amount < 1) {
      this.notify(this, "ERROR", "La cantidad no puede ser 0 o menor a 0");
      return false;
    } else {
      this.validateExistCourse(nameCourse);
      if (this.state.statusList == true) {
        var tempArray = this.state.data;
        var tempArrayCourse = [{}];

        tempArray.push(nameCourse);
        this.state.data = tempArray;
        this.setState({ data: tempArray });

        this.state.courses = tempArrayCourse;
        this.setState({ courses: tempArrayCourse });

        this.addListQuotation(
          this.state.amount,
          this.state.courseID,
          this.state.languageID,
          nameCourse,
          this.state.quotationTemporalID
        );
      } else {
        this.notify(this, "ERROR", "El curso seleccionado ya ha sido agregado");
        return false;
      }
    }
  }

  /**
   * Method that removes the item from the list that the user sees, also calls the method
   * of removing the list from the quote.
   * @param {String} nameCourse
   * @param {Integer} index
   */
  deleteElementList(nameCourse, index) {
    var tempArray = this.state.data;
    this.state.data.splice(index, 1);
    this.state.data = tempArray;
    this.setState({ data: tempArray });

    this.deleteListQuotation(nameCourse);
  }

  /**
   * Method that removes the other list of quotes to be traversed to send it to the database.
   * @param {String} nameCourse
   */
  deleteListQuotation(nameCourse) {
    this.state.courseListQuotation.map((elements, key) => {
      if (nameCourse === elements.courseName) {
        this.state.courseListQuotation.splice(key, 1);
      }
    });
  }

  /**
   * Method that shows the user the personal data that must be entered if they are not registered.
   */
  registerUser() {
    const country = this.state.countries.map((country) => {
      return {
        label: country.name,
        value: country.countryID,
      };
    });

    const inputProps = { className: "form-control" };
    return (
      <div>
        <Row className="mt-3 col-sm-12 paragraph">
          <Col className="col-12 col-md-9 offset-lg-1">
            <FormGroup>
              <label>
                Para realizar la cotización debe ingresar los siguientes datos ó
                puede registrarse en la página y tener acceso a la comunidad.{" "}
              </label>
            </FormGroup>
          </Col>
        </Row>

        <Row className="mt-4 col-sm-12">
          <MDBCol className="col-12 col-lg-4 offset-lg-1  ">
            <FormGroup>
              <label style={{ color: "red" }}>*</label>
              <label className="smallUserTextBold"> Nombre </label>
              <input
                className="form-control "
                type="text"
                name="name"
                value={this.state.name}
                onChange={this.valueName}
                required
              />
            </FormGroup>
          </MDBCol>
          <MDBCol className="col-12 col-lg-4 offset-lg-1 ">
            <FormGroup>
              <label style={{ color: "red" }}>*</label>
              <label className="smallUserTextBold">Apellido </label>
              <input
                className="form-control "
                value={this.state.lastname}
                type="text"
                name="name"
                onChange={this.valueLastName}
                required
              />
            </FormGroup>
          </MDBCol>
        </Row>

        <Row className="mt-4 col-sm-12 ">
          <MDBCol className=" col-12 col-lg-4 offset-lg-1 ">
            <FormGroup>
              <label style={{ color: "red" }}>*</label>
              <label className="smallUserTextBold">Correo electrónico </label>
              <input
                className="form-control "
                type="text"
                name="name"
                value={this.state.email}
                onChange={this.valueEmail}
                required
              />
            </FormGroup>
          </MDBCol>
          <MDBCol className=" col-12 col-lg-4 offset-lg-1  ">
            <label style={{ color: "red" }}>*</label>
            <label className="smallUserTextBold ">Teléfono </label>

            <IntlTelInput
              inputProps={inputProps}
              value={this.state.value}
              onChange={this.onChange}
            />
            <br />
          </MDBCol>
        </Row>

        <Row className="mt-4 col-sm-12  ">
          <MDBCol className=" col-12 col-lg-4 offset-lg-1  ">
            <FormGroup>
              <label style={{ color: "red" }}>*</label>
              <label className="smallUserTextBold">País </label>
              <div class="changeSelect">
                <Select
                  style={{ zindex: "60" }}
                  className="selectLetter quotationCountry"
                  id="course"
                  placeholder="Elegir país"
                  options={country}
                  onChange={this.countrySelect}
                  required
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 4,
                    font: "Open Sans",
                    height: 9,

                    colors: {
                      ...theme.colors,
                      primary25: "#FFA93F",
                      primary: "#808080",
                    },
                  })}
                />
              </div>
            </FormGroup>
          </MDBCol>
        </Row>

        <Row className="mt-4 col-sm-12">
          <MDBCol className=" col-12 col-lg-10 offset-lg-1 ">
            <hr />
          </MDBCol>
        </Row>
      </div>
    );
  }

  /**
   * Method that shows the elements found or added to the web page.
   */
  render() {
    const languages = this.state.languages.map((language) => {
      return {
        label: language.name,
        value: language.languageID,
      };
    });

    const inputProps = { className: "form-control" };
    return (
      <Container className="mt-5 ">
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
        <Row className="mt-12">
          <Col
            className="col-12 col-lg-9 offset-lg-3 mt-3"
            className="mx-auto"
          />
          <Col md="12" className="mx-auto">
            <Row className="mt-0">
              <MDBCol className=" col-12 col-lg-9 offset-lg-0 mt-3">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb white">
                    <li class="breadcrumb-item">
                      <a class="black-text" href="/">
                        Inicio
                      </a>
                    </li>
                    <li class="breadcrumb-item">
                      <a class="black-text" href="/CourseUser">
                        Cursos
                      </a>
                    </li>
                    <li class="breadcrumb-item active">Cotizar Curso</li>
                  </ol>
                </nav>
              </MDBCol>
            </Row>

            <Row className="mt-3 col-sm-12">
              <MDBCol className=" col-12 col-lg-4 offset-lg-1  ">
                <label className="smallUserTextBold">Cursos a cotizar</label>
              </MDBCol>
              <Row className="mt-3 col-sm-12">
                <MDBCol className=" col-12 col-lg-2 offset-lg-1">
                  {this.state.data.map((elements, key) => {
                    return (
                      <MDBCol style={{ maxWidth: "12rem" }}>
                        <Card style={{ heigh: 40, width: 150 }}>
                          <div class="card-header sunny-morning-gradient">
                            <button
                              type="button"
                              class="close"
                              data-dismiss="modal"
                              aria-label="Close"
                              onClick={() =>
                                this.deleteElementList(elements, key)
                              }
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div class="card-body">{elements}</div>
                        </Card>
                        <br />
                      </MDBCol>
                    );
                  })}
                </MDBCol>
              </Row>
            </Row>

            <Row className="mt-3 col-sm-12">
              <MDBCol className=" col-12  offset-lg-1 mt-2 ">
                {/* <h3 className="userTitlesFont">Cotizar</h3> */}
              </MDBCol>
            </Row>
            {sessionStorage.getItem("user") == null && this.registerUser()}

            <Row className="mt-4 col-sm-12">
              <MDBCol className=" col-12 col-lg-10 offset-lg-1 ">
                <label className="smallUserTextBold">
                  Si desea agregar otro curso a la cotización, puede hacerlo
                  aquí:{" "}
                </label>
              </MDBCol>
            </Row>

            <Row className="mt-4 col-sm-12">
              <MDBCol className=" col-12 col-lg-4 offset-lg-1  ">
                <label className="smallUserTextBold">Categoría </label>
                <div class="changeSelect">
                  <Select
                    placeholder="Elegir categoría"
                    className="selectLetter"
                    id="selectCategoryUser"
                    required
                    onChange={this.coursefilter}
                    options={this.state.categories.map(function (json) {
                      return {
                        label: json.categoryName,
                        value: json.categoryID,
                      };
                    })}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 4,
                      font: "Open Sans",
                      height: 9,

                      colors: {
                        ...theme.colors,
                        primary25: "#FFA93F",
                        primary: "#808080",
                      },
                    })}
                  />
                </div>
              </MDBCol>
              <MDBCol className=" col-12 col-lg-4 offset-lg-1  ">
                <label className="smallUserTextBold">Curso </label>
                <div class="changeSelect">
                  <Select
                    className="selectLetter"
                    id="course"
                    placeholder="Elegir curso"
                    options={this.state.courses.map(function (json) {
                      return { label: json.name, value: json.courseID };
                    })}
                    onChange={this.courseSelect}
                    required
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 4,
                      font: "Open Sans",
                      height: 9,

                      colors: {
                        ...theme.colors,
                        primary25: "#FFA93F",
                        primary: "#808080",
                      },
                    })}
                  />
                </div>
              </MDBCol>
            </Row>

            <Row className="mt-4 col-sm-12">
              <MDBCol className=" col-12 col-lg-4 offset-lg-1 ">
                <label className="smallUserTextBold">Idioma</label>
                <div class="changeSelect">
                  <Select
                    id="language"
                    className="selectLetter"
                    placeholder="Elegir idioma*"
                    options={languages}
                    onChange={this.languageSelect}
                    required
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 4,
                      font: "Open Sans",
                      height: 9,

                      colors: {
                        ...theme.colors,
                        primary25: "#FFA93F",
                        primary: "#808080",
                      },
                    })}
                  />
                </div>
              </MDBCol>

              <MDBCol className=" col-12 col-lg-4 offset-lg-1  ">
                <label className="smallUserTextBold">Cantidad </label>

                <input
                  className="form-control"
                  id="hoursNum"
                  type="number"
                  name="name"
                  required
                  onChange={this.valueAmount}
                  onKeyPress={this.onKeyEvent}
                />
              </MDBCol>
            </Row>
            <Row className="mt-3 col-sm-12">
              <MDBCol className=" col-12 col-lg-8 offset-lg-1">
                <MDBBtn
                  color="greenButton"
                  className="greenButton generalButton"
                  onClick={() => this.addElementList(this.state.courseName)}
                >
                  <span className="modalButtonText">Agregar</span>
                </MDBBtn>
              </MDBCol>
            </Row>

            <Row className="mt-4 col-sm-12 ">
              <MDBCol className=" col-12 col-lg-12 offset-lg-1  ">
                <label className="smallUserTextBold">
                  Observaciones o comentarios, posibles horarios(Opcional){" "}
                </label>
              </MDBCol>
              <MDBCol className=" col-12 col-lg-9 offset-lg-1 ">
                <FormGroup>
                  <textarea
                    id="text"
                    value={this.state.description}
                    onChange={this.valueDescription}
                    rows="5"
                  ></textarea>
                </FormGroup>
              </MDBCol>
            </Row>
            <Row className="mt-3 col-sm-12">
              <MDBCol className=" col-6 col-lg-6 offset-lg-1">
                <MDBBtn
                  color="purpleButton"
                  className="generalButton purpleButton"
                >
                  <span className="modalButtonText" onClick={() => this.back()}>
                    Volver
                  </span>
                </MDBBtn>

                <MDBBtn color="blueButton" className="generalButton blueButton">
                  <span
                    className="modalButtonText"
                    onClick={() => this.validateData()}
                  >
                    Enviar
                  </span>
                </MDBBtn>
              </MDBCol>
              <br />
              <br />
              <br />
              <br />
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }
}
export default QuotationUser;
