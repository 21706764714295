/**
 * @fileoverview UserReport, this component allow to download an user report.
 * 
 * @version 1.0
 * 
 * @author Anthony Vargas Méndez <anthony.vargas@ucrso.info>
 * History
 * v1.0 - Initial Release
 * ----
 * The first version of UserReport was writen by Anthony Vargas Méndez
 */
import React, { Component, Fragment } from 'react';
import { MDBRow, MDBCol, MDBIcon, MDBBtn, MDBCardHeader } from 'mdbreact';
import { MDBDataTable, MDBCard, MDBCardTitle } from 'mdbreact';
import { FormGroup, Container, Toast, ToastBody, ToastHeader } from 'reactstrap';
import axios from 'axios';
import { baseUrl } from './baseUrl';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
const animatedComponents = makeAnimated();

class UserReport extends Component {
	state = {
		/**
		 * courseID:
		 * @type {Integer}
		 * Propety that indicates the course ID
		 * 
		 * courseName:
		 * @type {string}
		 * Propety that indicates the course name
		 * 
		 * countryID:
		 * @type {Integer}
		 * Propety that indicates the country ID
		 * 
		 * countryName:
		 * @type {string}
		 * Propety that indicates the country name
		 * 
		 * userCount:
		 * @type {Integer}
		 * Propety that indicates the quantity of users
		 * 
		 * ageValue:
		 * @type {Integer}
		 * Propety that indicates the value of the range age
		 * 
		 * userCompleteReport:
		 * @type {array}
		 * Propety that indicates specific user report list
		 * 
		 * countryList:
		 * @type {array}
		 * Propety that indicates the list of countries
		 * 
		 * visible:
		 * @type {boolean}
		 * Propety that indicates state of the visible parts
		 * 
		 * userReportList:
		 * @type {object}
		 * Propety that indicates the all list of user
		 * 
		 * ageRange:
		 * @type {object}
		 * Propety that indicates the list of range age
		 */
		courseID: 0,
		courseName: '',
		countryID: 0,
		countryName: '',
		userCount: 0,
		ageValue: 0,
		userCompleteReport: [],
		countryList: [],
		visible: false,
		userReportList: {
			columns: [
				{
					label: 'Curso',
					field: 'Nombre',
					width: 150
				},

				{
					label: 'País',
					field: 'Pais',
					width: 150
				},
				{
					label: 'Rango de edad',
					field: 'Rango',
					width: 150
				},
				{
					label: 'Cantidad de usuarios',
					field: 'Cantidad',
					width: 150
				}
			],
			rows: []
		},

		ageRange: [
			{
				label: 'De 3 a 12 años',
				value: 1
			},
			{
				label: 'De 13 años a 21 años',
				value: 2
			},
			{
				label: 'De 22 años a 45 años',
				value: 3
			},
			{
				label: 'De 46 años en adelante',
				value: 4
			}
		]
	};
	valueSelectCourse = this.valueSelectCourse.bind(this);
	valueSelectCountry = this.valueSelectCountry.bind(this);
	valueSelectReport = this.valueSelectReport.bind(this);
	valueSelectAgeRange = this.valueSelectAgeRange.bind(this);
	valueChargeValueReport = this.valueChargeValueReport.bind(this);
	downloadCSV = this.downloadCSV.bind(this);
	empty = this.empty.bind(this);

	/**
	 * Method that specify the different alerts in the component
	 */
	notify = (evt, value, msj) => {
		switch (value) {
			case 'SUCCESS':
				toast.success(msj);
				break;
			case 'ERROR':
				toast.error(msj);
				break;
			case 'WARN':
				toast.warn(msj);
				break;
			case 'INFO':
				toast.info(msj);
				break;
			default:
				toast.info(msj);
		}
	};

	/**
	 * Method that allow download a specific user report
	 */
	downloadCSV() {
		const { parse } = require('json2csv');
		var fields = [];

		fields = [ 'Nombre', 'Pais', 'Rango', 'Cantidad' ];

		const opts = { fields };

		const csv = parse(this.state.userReportList.rows, {
			opts,
			encoding: 'ISO-8859-1',
			excelStrings: true,
			withBOM: true
		});
		var fileDownload = require('js-file-download');
		fileDownload(csv, 'Reporte de cursos.csv');
	}

	/**
	 * Method that determine if the value to the variables are empty
	 */
	empty(event) {
		if (this.state.courseID === 0) {
			this.notify(event, 'ERROR', 'Debe seleccionar un curso para generar el reporte');
			return false;
		} else {
			this.valueChargeValueReport();
		}
	}

	/**
	 * Method that get all user from de database and set in the userCompleteReport
	 */
	getAllCompleteUserRepor() {
		axios
			.get(baseUrl + 'report/getAllUserReport')
			.then((response) => {
				var userCompleteReport = response.data;
				this.setState({
					userCompleteReport
				});
			})
			.catch((err) => console.error('Hola: ' + err));
	}

	/**
	 * Method that set a course and filter the countries that has a same course
	 */
	valueSelectCourse(event) {
		var object = {};
		var countryList = [];
		var courseName = '';
		this.state.userCompleteReport.forEach((auxReport) => {
			let countryExist = false;
			if (auxReport.courseID === event.value) {
				countryList.forEach((auxCountryReport) => {
					if (auxCountryReport.value === auxReport.auxCountryID) {
						countryExist = true;
					}
				});
				if (!countryExist) {
					object = {
						label: auxReport.countryName,
						value: auxReport.auxCountryID
					};
					countryList.push(object);
				}
			}
		});
		this.state.userCompleteReport.map((report) => {
			if (report.courseID === event.value) {
				courseName = report.courseName;
			}
		});
		this.setState({
			countryList,
			courseID: event.value,
			courseName
		});
	}

	/**
	 * Method that set a country ID and country name 
	 */
	valueSelectCountry(event) {
		var auxcountryName = '';
		this.state.userCompleteReport.map((report) => {
			if (report.auxCountryID === event.value) {
				auxcountryName = report.countryName;
			}
		});
		this.setState({
			countryID: event.value,
			countryName: auxcountryName
		});
		console.log(auxcountryName);
	}

	/**
	 * Method that set a ageValue and calculate a quantity of user that has a same age in this range
	 */
	valueSelectReport() {
		var auxMinAge = 0;
		var auxMaxAge = 0;
		var totalUserForCourse = 0;
		var object = {};
		var newRowList = [];

		if (this.state.ageValue === 1) {
			auxMinAge = 3;
			auxMaxAge = 12;
		} else if (this.state.ageValue === 2) {
			auxMinAge = 13;
			auxMaxAge = 21;
		} else if (this.state.ageValue === 3) {
			auxMinAge = 22;
			auxMaxAge = 45;
		} else if (this.state.ageValue === 4) {
			auxMinAge = 46;
			auxMaxAge = 105;
		}

		if (this.state.countryID !== 0 && this.state.ageValue !== 0) {
			this.state.userCompleteReport.map((report) => {
				if (
					report.courseID === this.state.courseID &&
					report.auxCountryID === this.state.countryID &&
					report.userAge >= auxMinAge &&
					report.userAge <= auxMaxAge
				) {
					totalUserForCourse = totalUserForCourse + 1;
				}
			});
			object = {
				Nombre: this.state.courseName,
				Pais: this.state.countryName,
				Rango: 'De ' + auxMinAge + ' años hasta ' + auxMaxAge + ' años',
				Cantidad: totalUserForCourse
			};
			newRowList.push(object);
			this.setState({
				userReportList: {
					columns: this.state.userReportList.columns,
					rows: newRowList
				}
			});
		} else if (this.state.countryID === 0 && this.state.ageValue !== 0) {
			this.state.userCompleteReport.map((report) => {
				if (
					report.courseID === this.state.courseID &&
					report.userAge >= auxMinAge &&
					report.userAge <= auxMaxAge
				) {
					totalUserForCourse = totalUserForCourse + 1;
				}
			});
			object = {
				Nombre: this.state.courseName,
				Pais: 'No aplica',
				Rango: 'De ' + auxMinAge + ' años hasta ' + auxMaxAge + ' años',
				Cantidad: totalUserForCourse
			};
			newRowList.push(object);
			this.setState({
				userReportList: {
					columns: this.state.userReportList.columns,
					rows: newRowList
				}
			});
		} else if (this.state.countryID !== 0 && this.state.ageValue === 0) {
			this.state.userCompleteReport.map((report) => {
				if (report.courseID === this.state.courseID && report.auxCountryID === this.state.countryID) {
					totalUserForCourse = totalUserForCourse + 1;
				}
			});
			object = {
				Nombre: this.state.courseName,
				Pais: this.state.countryName,
				Rango: 'Todas las edades',
				Cantidad: totalUserForCourse
			};
			newRowList.push(object);
			this.setState({
				userReportList: {
					columns: this.state.userReportList.columns,
					rows: newRowList
				}
			});
		} else if (this.state.countryID === 0 && this.state.ageValue === 0) {
			this.state.userCompleteReport.map((report) => {
				if (report.courseID === this.state.courseID) {
					totalUserForCourse = totalUserForCourse + 1;
				}
			});
			object = {
				Nombre: this.state.courseName,
				Pais: 'No aplica',
				Rango: 'Todas las edades',
				Cantidad: totalUserForCourse
			};
			newRowList.push(object);
			this.setState({
				userReportList: {
					columns: this.state.userReportList.columns,
					rows: newRowList
				}
			});
		}
	}
	/**
	 * Method that set an ageValue
	 */
	valueSelectAgeRange(event) {
		this.setState({
			ageValue: event.value
		});
	}

	/**
	 * Method that change a value of the visible variable
	 */
	valueChargeValueReport() {
		this.valueSelectReport();
		this.setState({
			visible: true
		});
	}

	/**
	 * Method that charge a getAllCompleteUserReport
	 */
	componentDidMount() {
		if(sessionStorage.getItem('userType') == 2 || sessionStorage.getItem('userType') == 3){
		this.getAllCompleteUserRepor();
		}else{
			this.props.history.push('/');
		}
	}

	/**
	 * Method that render the view with the users reports and their actions
	 */
	render() {
		const auxUserReport = [];
		this.state.userCompleteReport.forEach(function(report) {
			let courseExist = false;
			auxUserReport.forEach(function(user) {
				if (user.value === report.courseID) {
					courseExist = true;
				}
			});
			if (!courseExist) {
				auxUserReport.push({
					label: report.courseName,
					value: report.courseID
				});
			}
		});

		//----------------------ESTO PARA LA TABLA--------------------------------------------------------/
		return (
			<div>
				<Container>
					<ToastContainer
						position="top-right"
						autoClose={5000}
						hideProgressBar
						newestOnTop
						closeOnClick
						rtl={false}
						pauseOnVisibilityChange
						draggable
						pauseOnHover
					/>
					<MDBRow>
						<MDBCol className="col-12 col-lg-9 offset-lg-3 mt-3">
							<h3 className="adminTitlesFont">Reportes</h3>
						</MDBCol>
						{/*-------------------------AQUI ESTA LA TABLA----------------------------------------  */}
						<MDBCol className="col-12 col-lg-9 offset-lg-3">
							<MDBCard style={{ padding: '20px 20px 20px 20px' }}>
								<MDBCardTitle className="cardHeader">Reporte de Estudiantes por curso</MDBCardTitle>
								<MDBRow>
									<MDBCol className="col-12 col-md-4">
										<FormGroup>
											<label>Curso</label>
											<Select
												className="selectLetter selectReport"
												name="userReport"
												components={animatedComponents}
												options={auxUserReport}
												classNamePrefix="select"
												placeholder="Seleccione un curso"
												onChange={this.valueSelectCourse}
												theme={(theme) => ({
													...theme,
													borderRadius: 4,
													font: 'Open Sans',
													height: 9,
													colors: {
														...theme.colors,
														primary25: '#FFA93F',
														primary: '#808080'
													}
												})}
											/>
										</FormGroup>
									</MDBCol>
									<MDBCol className="col-12 col-md-4">
										<FormGroup>
											<label>País</label>
											<Select
												className="selectLetter selectReport"
												name="country"
												components={animatedComponents}
												options={this.state.countryList}
												classNamePrefix="select"
												placeholder="Seleccione un país"
												onChange={this.valueSelectCountry}
												theme={(theme) => ({
													...theme,
													borderRadius: 4,
													font: 'Open Sans',
													height: 9,

													colors: {
														...theme.colors,
														primary25: '#FFA93F',
														primary: '#808080'
													}
												})}
											/>
										</FormGroup>
									</MDBCol>
									<MDBCol className="col-12 col-md-4">
										<FormGroup>
											<label>Rango de Edad</label>
											<Select
												className="selectLetter selectReport"
												name="userReport"
												components={animatedComponents}
												options={this.state.ageRange}
												classNamePrefix="select"
												placeholder="Seleccione una edad"
												onChange={this.valueSelectAgeRange}
												theme={(theme) => ({
													...theme,
													borderRadius: 4,
													font: 'Open Sans',
													height: 9,

													colors: {
														...theme.colors,
														primary25: '#FFA93F',
														primary: '#808080'
													}
												})}
											/>
										</FormGroup>
									</MDBCol>
								</MDBRow>
								<MDBRow>
									<MDBBtn
										className="generateReportButtom text-white"
										color="orange"
										onClick={this.empty}
									>
										<MDBIcon icon="list-alt" /> Generar Reporte
									</MDBBtn>
								</MDBRow>
								{this.state.visible ? (
									<Fragment>
										<MDBRow>
											{' '}
											<MDBBtn
												className="downloadUserButtom"
												color="orange"
												onClick={this.downloadCSV}
											>
												<MDBIcon
													icon="download"
													color="plusIcon"
													className="plusIcon"
													size="lg"
												/>
											</MDBBtn>
										</MDBRow>
										<MDBRow className="auxTablePosition userTableReport">
											<MDBDataTable
												displayEntries={false}
												data={this.state.userReportList}
												hover
												striped
												bordered
												small
												searching={false}
												responsive
												noBottomColumns={true}
												noRecordsFoundLabel="No se han encontrado datos"
												theadTextWhite={true}
												info={false}
												paging={false}
											/>
										</MDBRow>
									</Fragment>
								) : null}
								{/*-------------------------AQUI TERMINA LO DE LA TABLA----------------------------------------  */}
							</MDBCard>
						</MDBCol>
					</MDBRow>
				</Container>
			</div>
		);
	}
}

export default UserReport;
