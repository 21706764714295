/**
 * @fileoverview EventUp_In, this component is the form to add or edit events
 * 
 * @version 1.0
 * 
 * @author Ester Molina R <maria.molina@ucrso.info>
 * History
 * v1.0 - Initial Realease
 * ----
 * The first version of EventUp_In was written by Ester Molina R. 
 */

import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardTitle, MDBBtn, MDBInput, MDBCardFooter } from 'mdbreact';
import { FormGroup } from 'reactstrap';
import Select from 'react-select';
import { baseUrl } from './baseUrl';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import validations from './Validations';
import { Link } from 'react-router-dom';

class EventUp_In extends Component {
	constructor(props) {
		super(props);
		/**
		 * validations:
		 * @type {object}
		 * Property that has methods for validate with regular expressions
		 * 
		 * eventName:
		 * @type {String}
		 * Property that contains the name of the event
		 * 
		 * courseID:
		 * @type {integer}
		 * Property that contains the identification of the course
		 * 
		 * courseName:
		 * @type {String}
		 * Property that contains the name of the course
		 * 
		 * eventID:
		 * @type {integer}
		 * Property that contains the identification of the event
		 * 
		 * place:
		 * @type {String}
		 * Property that contains the place of the event
		 * 
		 * date:
		 * @type {DateTime}
		 * Property that contains the date and time of the event
		 * 
		 * languageID
		 * @type {integer}
		 * Property that contains the language identification
		 * 
		 * languageName
		 * @type {integer}
		 * Property that contains the language name
		 * 
		 * gratuitousID
		 * @type {integer}
		 * Property that contains if the event is free or not
		 * 
		 * paymentName:
		 * @type {String}
		 * Property that contains the text free or Pay
		 * 
		 * colorName:
		 * @type {String}
		 * Property that contains the name of the selected color
		 * 
		 * eventLink:
		 * @type {String}
		 * Property that contains the link of the event
		 * 
		 * quota:
		 * @type {integer}
		 * Property that contains the limit of people allow in the event
		 * 
		 * description:
		 * @type {String}
		 * Property that contains the description of the event
		 * 
		 * company:
		 * @type {String}
		 * Property that contains the company that receive the event
		 * 
		 * teacherID:
		 * @type {integer}
		 * Property that contains the teacher identification
		 * 
		 * dateEvent:
		 * @type {Date}
		 * Property that contains the date selected in the calendar
		 * 
		 * teacherName:
		 * @type {String}
		 * Property that contains the teacher name
		 * 
		 * languages:
		 * @type {Array}
		 * Property that contains the list of languages
		 * 
		 * courses:
		 * @type {Array}
		 * Property that contains the list of courses
		 * 
		 * teachers:
		 * @type {Array}
		 * Property that contains the list of teachers
		 * 
		 * payment:
		 * @type {Array}
		 * Property that contains the list of payments
		 * 
		 * pageTitle:
		 * @type {String}
		 * Property that contains the page title
		 * 
		 * colors:
		 * @type {Array}
		 * Property that contains the list of colors
		 */
		this.state = {
			validations: new validations(),
			eventName: '',
			courseID: 0,
			courseName: '',
			eventID: 0,
			place: '',
			languageID: 0,
			languageName: '',
			gratuitousID: 0,
			paymentName: '',
			colorName: '',
			eventLink: '',
			quota: '',
			description: '',
			company: '',
			teacherID: 0,
			dateEvent: '',
			teacherName: '',
			languages: [{}],
			courses: [{}],
			teachers: [{}],
			payment: [{ id: 0, name: 'Pago' }, { id: 1, name: 'Gratuito' }],
			pageTitle: 'Agregar evento',
			date: '',
			colors: [
				{ id: 0, name: 'orange' },
				{ id: 1, name: 'red' },
				{ id: 2, name: 'cyan' },
				{ id: 3, name: 'green' },
				{ id: 4, name: 'brown' },
				{ id: 5, name: 'gray' },
				{ id: 6, name: 'navy' },
				{ id: 7, name: 'golden' },
				{ id: 8, name: 'purple' },
				{ id: 9, name: 'black' },
				{ id: 10, name: 'teal' },
				{ id: 9, name: 'lime' }
			]
		};
		this.getData = this.getData.bind(this);
		this.showPlaces = this.showPlaces.bind(this);
		this.showSelect = this.showSelect.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.teacherSelect = this.teacherSelect.bind(this);
		this.courseSelect = this.courseSelect.bind(this);
		this.paymentSelect = this.paymentSelect.bind(this);
		this.languageSelect = this.languageSelect.bind(this);
		this.colorSelect = this.colorSelect.bind(this);
		this.back = this.back.bind(this);
		this.validateData = this.validateData.bind(this);
		this.empty = this.empty.bind(this);
	}

	componentDidMount() {
		if (sessionStorage.getItem('userType') == 2 || sessionStorage.getItem('userType') == 3) {
			var eventID = this.props.match.params.ID;
			var dateEvent = this.props.match.params.DATE;
			this.setState({
				eventID,
				dateEvent
			});
			this.showPlaces(false);
			this.getData(eventID);
		} else {
			this.props.history.push('/');
		}
	}

	/**
	 * Method that perform the validate data action when an ENTER is clicked
	 * @param {event} e 
	 */
	onKeyEvent = (e) => {
		if (e.key == 'Enter') {
			this.validateData(e);
		}
	};

	/**
	 * Method that validate the data format
	 * @param {event} event 
	 */
	validateData(event) {

		if (this.empty(event)) {
			var date = this.state.dateEvent.split('T');
			var newValue = date[0] + ' ' + date[1];
			if (!this.state.validations.validateTextWithNumberField(this.state.eventName)) {
				this.notify(event, 'ERROR', 'El nombre del evento no debe contener caracteres especiales');
			} else if (!this.state.validations.validateTextWithNumberField(this.state.place)) {
				this.notify(event, 'ERROR', 'El lugar del evento no debe contener caracteres especiales');
			} else if (
				this.state.eventLink.length != 0 &&
				!this.state.validations.validateURLField(this.state.eventLink)
			) {
				this.notify(event, 'ERROR', 'El formato del link de llamada no es correcto');
			} else if (!this.state.validations.validateNumericField(this.state.quota)) {
				this.notify(event, 'ERROR', 'El cupo del evento deben ser sólo números');
			} else if (
				this.state.description.length != 0 &&
				!this.state.validations.validateTextWithNumberField(this.state.description)
			) {
				this.notify(event, 'ERROR', 'La descripción del evento no debe contener caracteres especiales');
			} else if (
				this.state.company.length !== 0 &&
				!this.state.validations.validateTextWithNumberField(this.state.company)
			) {
				this.notify(event, 'ERROR', 'El nombre de la empresa no debe contener caracteres especiales');
			} else if (!this.state.validations.validateDateTimeField(newValue)) {
				this.notify(event, 'ERROR', 'La fecha no tiene el formato correcto');
			} else {
				this.handleSubmit(event);
			}
		}
	}

	/**
	 * Method that verify if the obligatory data is empty
	 * @param {event} event 
	 */
	empty(event) {
		if (this.state.eventName.length === 0) {
			this.notify(event, 'ERROR', 'El nombre del evento no puede estar vacío');
			return false;
		} else if (this.state.courseID === 0) {
			this.notify(event, 'ERROR', 'Debe seleccionar un curso o la opción de ninguno');
			return false;
		} else if (this.state.place.length === 0) {
			this.notify(event, 'ERROR', 'El lugar del evento no puede estar vacío');
			return false;
		} else if (this.state.dateEvent.length === 0) {
			this.notify(event, 'ERROR', 'La fecha y hora del evento no pueden estar vacías');
			return false;
		} else if (this.state.languageID === 0) {
			this.notify(event, 'ERROR', 'Debe seleccionar un idioma para el evento');
			return false;
		} else if (this.state.quota.length === 0) {
			this.notify(event, 'ERROR', 'El cupo del evento no puede estar vacío');
			return false;
		} else if (this.state.teacherID === 0) {
			this.notify(event, 'ERROR', 'Debe seleccionar un encargado del evento');
			return false;
		} else {
			return true;
		}
	}

	/**
	 * Method that charge the data from the database
	 * @param {event} eventID 
	 */
	getData(eventID) {
		var languages = [];
		var courses = [];
		var teachers = [];

		 axios
			.get(baseUrl + 'event/getLanguage')
			.then((response) => {
				languages = response.data;
				this.setState({
					languages
				});
				axios
					.get(baseUrl + 'course/selectCourse')
					.then((response) => {
						courses = response.data;
						courses.push({ courseID: null, name: 'Ninguno' });
						this.setState({
							courses
						});

						axios
							.get(baseUrl + 'event/getTeachers')
							.then((response) => {
								teachers = response.data;
								this.setState({
									teachers
								});

								if (eventID !== "0") {
									axios
										.get(baseUrl + 'event/getEventData', {
											params: { eventID: eventID }
										})
										.then((response) => {
											var event = response.data[0];
											languages.map((language) => {
												if (language.languageID === event.language) {
													this.setState({
														languageName: language.name
													});
												}
											});

											courses.map((course) => {
												if (course.courseID === event.courseID) {
													this.setState({
														courseName: course.name
													});
												}
											});

											teachers.map((teacher) => {
												if (teacher.userID === event.teacher) {
													this.setState({
														teacherName: teacher.fullName
													});
												}
											});

											this.state.payment.map((pay) => {
												if (pay.id === event.gratuitous) {
													this.setState({
														paymentName: pay.name
													});
												}
											});

											this.state.colors.map((color) => {
												if (color.name === event.color) {
													this.setState({
														colorName: color.name
													});
												}
											});
											var date = event.date.split(' ');
											var newValue = date[0] + 'T' + date[1];
											this.setState({
												eventName: event.eventName,
												eventLink: event.eventLink,
												place: event.place,
												company: event.company,
												teacherID: event.teacher,
												languageID: event.language,
												quota: event.quota,
												gratuitousID: event.gratuitous,
												description: event.description,
												courseID: event.courseID,
												dateEvent: newValue,
												pageTitle: 'Editar evento'
											});
											this.showPlaces(true);
										});

								} else {
									this.showPlaces(false);
								}
							})
					})
			})
			.catch((err) => console.log('Ha ocurrido un error inesperado'));

	}

	/**
 * This Method that show the input that are hidden
 * @param {event} show 
 */
	showPlaces(show) {
		if (show) {
			document.getElementById('course-addon').style.display = 'block';
			document.getElementById('showCourse').style.display = 'block';
			document.getElementById('course').style.display = 'none';

			document.getElementById('calendar-addon').style.display = 'block';
			document.getElementById('showDate').style.display = 'block';
			document.getElementById('dateEvent').style.display = 'none';

			document.getElementById('language-addon').style.display = 'block';
			document.getElementById('showLanguage').style.display = 'block';
			document.getElementById('language').style.display = 'none';

			document.getElementById('money-addon').style.display = 'block';
			document.getElementById('showPayment').style.display = 'block';
			document.getElementById('gratuitous').style.display = 'none';

			document.getElementById('user-addon').style.display = 'block';
			document.getElementById('showUser').style.display = 'block';
			document.getElementById('teacher').style.display = 'none';
		} else {
			document.getElementById('course-addon').style.display = 'none';
			document.getElementById('showCourse').style.display = 'none';
			document.getElementById('course').style.display = 'block';

			document.getElementById('calendar-addon').style.display = 'none';
			document.getElementById('showDate').style.display = 'none';
			document.getElementById('dateEvent').style.display = 'block';

			document.getElementById('language-addon').style.display = 'none';
			document.getElementById('showLanguage').style.display = 'none';
			document.getElementById('language').style.display = 'block';

			document.getElementById('money-addon').style.display = 'none';
			document.getElementById('showPayment').style.display = 'none';
			document.getElementById('gratuitous').style.display = 'block';

			document.getElementById('user-addon').style.display = 'none';
			document.getElementById('showUser').style.display = 'none';
			document.getElementById('teacher').style.display = 'block';
		}
	}

	/**
	 * Method that shows the selects that are hidden
	 * @param {event} event 
	 */
	showSelect(event) {
		switch (event.target.name) {
			case 'showCourse':
				document.getElementById('course-addon').style.display = 'none';
				document.getElementById('showCourse').style.display = 'none';
				document.getElementById('course').style.display = 'block';
				break;
			case 'showDate':
				document.getElementById('calendar-addon').style.display = 'none';
				document.getElementById('showDate').style.display = 'none';
				document.getElementById('dateEvent').style.display = 'block';
				break;
			case 'showLanguage':
				document.getElementById('language-addon').style.display = 'none';
				document.getElementById('showLanguage').style.display = 'none';
				document.getElementById('language').style.display = 'block';
				break;
			case 'showPayment':
				document.getElementById('money-addon').style.display = 'none';
				document.getElementById('showPayment').style.display = 'none';
				document.getElementById('gratuitous').style.display = 'block';
				break;
			case 'showUser':
				document.getElementById('user-addon').style.display = 'none';
				document.getElementById('showUser').style.display = 'none';
				document.getElementById('teacher').style.display = 'block';
			default:
				break;
		}
	}

	/**
	 * Method that changes the state
	 * @param {event} event 
	 */
	handleChange(event) {
		this.setState({
			[event.target.name]: event.target.value
		});
	}

	/**
	 * Method that select a color
	 * @param {event} event 
	 */
	colorSelect(event) {
		this.setState({
			colorName: event.value
		});
	}

	/**
	 * Method that select a course
	 * @param {event} event 
	 */
	courseSelect(event) {
		this.setState({
			courseID: event.value
		});
	}

	/**
	 * Method that select a language
	 * @param {event} event 
	 */
	languageSelect(event) {
		this.setState({
			languageID: event.value
		});
	}

	/**
	 * Method that select a teacher
	 * @param {event} event 
	 */
	teacherSelect(event) {
		this.setState({
			teacherID: event.value
		});
	}

	/**
	 * Method that select a payment
	 * @param {event} event 
	 */
	paymentSelect(event) {
		console.log(event.value);
		this.setState({
			gratuitousID: event.value
		});
	}

	/**
	 * Method that submit an event
	 * @param {event} event 
	 */
	handleSubmit(event) {
		var date = this.state.dateEvent.split('T');
		var newValue = date[0] + ' ' + date[1];
		if (this.state.eventID !== "0") {
			fetch(baseUrl + 'event/editEvent', {
				method: 'post',
				body: JSON.stringify({
					eventID: this.state.eventID,
					date: newValue,
					name: this.state.eventName,
					eventLink: this.state.eventLink,
					place: this.state.place,
					company: this.state.company,
					teacherID: this.state.teacherID,
					languageID: this.state.languageID,
					quota: this.state.quota,
					gratuitous: this.state.gratuitousID,
					description: this.state.description,
					courseID: this.state.courseID,
					colorName: this.state.colorName
				}),
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json'
				}
			})
				.then(this.props.history.push('/PrincipalEvent/1'))
		} else {
			fetch(baseUrl + 'event/addEvent', {
				method: 'post',
				body: JSON.stringify({
					date: newValue,
					name: this.state.eventName,
					eventLink: this.state.eventLink,
					place: this.state.place,
					company: this.state.company,
					teacherID: this.state.teacherID,
					languageID: this.state.languageID,
					quota: this.state.quota,
					gratuitous: this.state.gratuitousID,
					description: this.state.description,
					courseID: this.state.courseID,
					colorName: this.state.colorName
				}),
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json'
				}
			})
				.then(this.props.history.push('/PrincipalEvent/1'))
		}
		event.preventDefault();
	}

	/**
	 * Method that shows notifications to the user
	 * @param {event} evt 
	 * @param {String} value 
	 * @param {String} msj 
	 */
	notify = (evt, value, msj) => {
		switch (value) {
			case 'SUCCESS':
				toast.success(msj);
				break;
			case 'ERROR':
				toast.error(msj);
				break;
			case 'WARN':
				toast.warn(msj);
				break;
			case 'INFO':
				toast.info(msj);
				break;
			default:
				toast.info(msj);
		}
	};

	/**
	 * Method that redirect back
	 */
	back() {
		this.props.history.push(`/PrincipalEvent`);
	}

	/**
	 * Method that render the HTML
	 */
	render() {
		const colorEvent = this.state.colors.map((color) => {
			return {
				label: color.name,
				value: color.name
			};
		});

		const courses = this.state.courses.map((course) => {
			return {
				label: course.name,
				value: course.courseID
			};
		});

		const languages = this.state.languages.map((language) => {
			return {
				label: language.name,
				value: language.languageID
			};
		});

		const payment = this.state.payment.map((pay) => {
			return {
				label: pay.name,
				value: pay.id
			};
		});

		const teachers = this.state.teachers.map((teacher) => {
			return {
				label: teacher.fullName,
				value: teacher.userID
			};
		});

		var dateArray = this.state.dateEvent.split('T');
		var newDate = dateArray[0] + " " + dateArray[1];
		return (
			<MDBContainer>
				<ToastContainer
					position="top-right"
					autoClose={5000}
					hideProgressBar
					newestOnTop
					closeOnClick
					rtl={false}
					pauseOnVisibilityChange
					draggable
					pauseOnHover
				/>
				<MDBRow>
					<MDBCol className="col-12 col-lg-9 offset-lg-3 mt-3">
						<h3 className="adminTitlesFont">{this.state.pageTitle}</h3>
					</MDBCol>
					<MDBCol className="col-12 col-lg-9 offset-lg-3 mb-3">
						<MDBCard style={{ padding: '20px 20px 20px 20px' }}>
							<MDBCardTitle className="cardHeader">Datos del evento</MDBCardTitle>
							<MDBRow>
								<MDBCol className="col-12 col-md-6">
									<FormGroup>
										<label htmlFor="eventName">
											Nombre del evento: <font color="red">*</font>
										</label>
										<div className="input-group">
											<div className="input-group-prepend">
												<span className="input-group-text" id="eventName-addon">
													<i className="fa fa-font prefix inputIcons" />
												</span>
											</div>
											<input
												className="form-control"
												value={this.state.eventName}
												type="text"
												id="eventName"
												name="eventName"
												aria-describedby="eventName-addon"
												onChange={this.handleChange}
												onKeyPress={this.onKeyEvent}
												required
											/>
										</div>
									</FormGroup>
								</MDBCol>
								<MDBCol className="col-12 col-md-6">
									<FormGroup>
										<label htmlFor="course">
											Vincular curso: <font color="red">*</font>
										</label>
										<div className="input-group">
											<div className="input-group-prepend">
												<span className="input-group-text" id="course-addon" display="none">
													<i className="fa fa-book-open prefix inputIcons" />
												</span>
											</div>
											<input
												className="form-control"
												display="none"
												value={this.state.courseName}
												type="text"
												id="showCourse"
												name="showCourse"
												aria-describedby="course-addon"
												onClick={this.showSelect}
											/>
										</div>
										<Select
											className="selectLetter"
											id="course"
											placeholder="Curso"
											options={courses}
											onChange={this.courseSelect}
											required
											theme={theme => ({
												...theme,
												borderRadius: 4,
												font: 'Open Sans',
												height: 9,
												
											
												colors: {
													...theme.colors,
													primary25: '#FFA93F',
													primary: '#808080',
												},
											})}
										/>
									</FormGroup>
								</MDBCol>
							</MDBRow>
							<MDBRow>
								<MDBCol className="col-12 col-md-6">
									<FormGroup>
										<label htmlFor="place">
											Lugar del evento: <font color="red">*</font>
										</label>
										<div className="input-group">
											<div className="input-group-prepend">
												<span className="input-group-text" id="place-addon">
													<i className="fa fa-map-marker-alt prefix inputIcons" />
												</span>
											</div>
											<input
												className="form-control"
												value={this.state.place}
												type="text"
												id="place"
												name="place"
												placeholder="Lugar"
												aria-describedby="place-addon"
												onChange={this.handleChange}
												onKeyPress={this.onKeyEvent}
												required
											/>
										</div>
									</FormGroup>
								</MDBCol>
								<MDBCol className="col-12 col-md-6">
									<FormGroup>
										<label htmlFor="date">
											Fecha del evento: <font color="red">*</font>
										</label>
										<div className="input-group">
											<div className="input-group-prepend">
												<span className="input-group-text" id="calendar-addon" display="none">
													<i className="fa fa-calendar-day prefix inputIcons" />
												</span>
											</div>
											<input
												className="form-control"
												display="none"
												value={newDate}
												type="text"
												id="showDate"
												name="showDate"
												aria-describedby="calendar-addon"
												onClick={this.showSelect}
											/>
										</div>

										<input
											className="form-control"
											type="datetime-local"
											defaultValue={this.state.dateEvent}
											id="dateEvent"
											name="dateEvent"
											onChange={this.handleChange}
											onKeyPress={this.onKeyEvent}
											required
										/>
									</FormGroup>
								</MDBCol>
							</MDBRow>
							<MDBRow>
								<MDBCol className="col-12 col-md-6">
									<FormGroup>
										<label htmlFor="language">
											Idioma: <font color="red">*</font>
										</label>
										<div className="input-group">
											<div className="input-group-prepend">
												<span className="input-group-text" id="language-addon" display="none">
													<i className="fa fa-language prefix inputIcons" />
												</span>
											</div>
											<input
												className="form-control"
												display="none"
												value={this.state.languageName}
												type="text"
												id="showLanguage"
												name="showLanguage"
												aria-describedby="language-addon"
												onClick={this.showSelect}
											/>
										</div>
										<Select
											className="selectLetter"
											id="language"
											placeholder="Idioma"
											options={languages}
											onChange={this.languageSelect}
											required
											theme={theme => ({
												...theme,
												borderRadius: 4,
												font: 'Open Sans',
												height: 9,
												
											
												colors: {
													...theme.colors,
													primary25: '#FFA93F',
													primary: '#808080',
												},
											})}
										/>
									</FormGroup>
								</MDBCol>
								<MDBCol className="col-12 col-md-6">
									<FormGroup>
										<label htmlFor="gratuitous">
											Pago: <font color="red">*</font>
										</label>
										<div className="input-group">
											<div className="input-group-prepend">
												<span className="input-group-text" id="money-addon" display="none">
													<i className="fa fa-money-bill-alt prefix inputIcons" />
												</span>
											</div>
											<input
												className="form-control"
												display="none"
												value={this.state.paymentName}
												type="text"
												id="showPayment"
												name="showPayment"
												aria-describedby="money-addon"
												onClick={this.showSelect}
											/>
										</div>
										<Select
											className="selectLetter"
											id="gratuitous"
											placeholder="Gratuito"
											options={payment}
											onChange={this.paymentSelect}
											required
											theme={theme => ({
												...theme,
												borderRadius: 4,
												font: 'Open Sans',
												height: 9,
												
											
												colors: {
													...theme.colors,
													primary25: '#FFA93F',
													primary: '#808080',
												},
											})}
										/>
									</FormGroup>
								</MDBCol>
							</MDBRow>
							<MDBRow>
								<MDBCol className="col-12 col-md-6">
									<FormGroup>
										<label htmlFor="eventLink">
											Link del evento:
										</label>
										<div className="input-group">
											<div className="input-group-prepend">
												<span className="input-group-text" id="link-addon">
													<i className="fa fa-link prefix inputIcons" />
												</span>
											</div>
											<input
												className="form-control"
												value={this.state.eventLink}
												type="text"
												id="eventLink"
												name="eventLink"
												placeholder="Link del evento"
												arial-describedby="link-addon"
												onChange={this.handleChange}
												onKeyPress={this.onKeyEvent}
											/>
										</div>
									</FormGroup>
								</MDBCol>
								<MDBCol className="col-12 col-md-6">
									<FormGroup>
										<label htmlFor="quota">
											Cupo máximo: <font color="red">*</font>
										</label>
										<div className="input-group">
											<div className="input-group-prepend">
												<span className="input-group-text" id="quota-addon">
													<i className="fa fa-address-book prefix inputIcons" />
												</span>
											</div>
											<input
												className="form-control"
												value={this.state.quota}
												type="number"
												id="quota"
												name="quota"
												placeholder="Cupo máximo"
												arial-describedby="quota-addon"
												onChange={this.handleChange}
												required
												onKeyPress={this.onKeyEvent}
											/>
										</div>
									</FormGroup>
								</MDBCol>
							</MDBRow>
							<MDBRow>
								<MDBCol className="col-12 col-md-6">
									<FormGroup>
										<label htmlFor="company">
											Empresa receptora:
										</label>
										<div className="input-group">
											<div className="input-group-prepend">
												<span className="input-group-text" id="company-addon">
													<i className="fa fa-building prefix inputIcons" />
												</span>
											</div>
											<input
												className="form-control"
												value={this.state.company}
												type="text"
												id="company"
												name="company"
												placeholder="Empresa receptora"
												arial-describedby="company-addon"
												onChange={this.handleChange}
											/>
										</div>
									</FormGroup>
								</MDBCol>
								<MDBCol className="col-12 col-md-6">
									<FormGroup>
										<label htmlFor="teacher">
											Encargado del evento: <font color="red">*</font>
										</label>
										<div className="input-group">
											<div className="input-group-prepend">
												<span className="input-group-text" id="user-addon" display="none">
													<i className="fa fa-user prefix inputIcons" />
												</span>
											</div>
											<input
												className="form-control"
												value={this.state.teacherName}
												type="text"
												id="showUser"
												name="showUser"
												aria-describedby="user-addon"
												onClick={this.showSelect}
												display="none"
											/>
										</div>
										<Select
											className="selectLetter"
											id="teacher"
											placeholder="Encargado"
											options={teachers}
											onChange={this.teacherSelect}
											required
											theme={theme => ({
												...theme,
												borderRadius: 4,
												font: 'Open Sans',
												height: 9,
												
											
												colors: {
													...theme.colors,
													primary25: '#FFA93F',
													primary: '#808080',
												},
											})}
										/>
									</FormGroup>
								</MDBCol>
							</MDBRow>
							<MDBRow>
								<MDBCol className="col-12 col-md-9">
									<FormGroup>
										<label htmlFor="description">
											Descripción del evento:
										</label>
										<textarea
										className="userTextFont"
											value={this.state.description}
											rows="2"
											placeholder="  Descripción del evento"
											id="description"
											name="description"
											onChange={this.handleChange}
											onKeyPress={this.onKeyEvent}
										/>
									</FormGroup>
								</MDBCol>
								<MDBCol className="col-12 col-md-3">
									<FormGroup>
										<label htmlFor="color">
											Color: <font color="red">*</font>
										</label>
										<Select
											//id="color"
											placeholder="color"
											options={colorEvent}
											onChange={this.colorSelect}
											theme={theme => ({
												...theme,
												borderRadius: 4,
												font: 'Open Sans',
												height: 9,
												
											
												colors: {
													...theme.colors,
													primary25: '#FFA93F',
													primary: '#808080',
												},
											})}
										/>
									</FormGroup>
								</MDBCol>
							</MDBRow>
							<MDBCardFooter color="white">
								<Link to={'/PrincipalEvent/1'}>
									<MDBBtn color="purpleButton" className="generalButton purpleButton">
										<span className="modalButtonText">Cancelar</span>
									</MDBBtn>
								</Link>
								<MDBBtn color="greenButton" className="generalButton greenButton">
									<span className="modalButtonText" onClick={this.validateData}>
										Guardar
									</span>
								</MDBBtn>
							</MDBCardFooter>
						</MDBCard>
					</MDBCol>
				</MDBRow>
			</MDBContainer>
		);
	}
}
export default EventUp_In;
