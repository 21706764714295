/**
 * @fileoverview CourseList this component shows the list of courses.
 *
 * @version 1.0
 * @author Estrella López López <estrella.lopez@ucrso.info>
 * 
 * History - Initial Release
 * ----
 * The first version of CourseList was written by  Estrella López L.
 */

import React, { Component, Fragment } from 'react';
import { MDBBtn, MDBCol, MDBRow, MDBIcon, MDBDataTable, MDBCard, MDBTooltip } from 'mdbreact';
import { Toast, ToastBody, ToastHeader } from 'reactstrap';
import { baseUrl } from './baseUrl';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class CourseList extends Component {

	constructor(props) {
		super(props);
		/**
		 * name: 
		 * @type {String}
		 * Property that stores the course name.
		 * 
		 * show:
		 * @type {boolean}
		 * State-changing property to display.
		 * 
		 * title: 
		 * @type {String}
		 * Property that stores the title modal.
		 * 
		 * message: 
		 * @type {String}
		 * Property that stores the message.
		 *
		 * detail:
		 * @type {Integer}
		 * Property that will store the id of the selected course.
		 * 
		 * option:
		 * @type {Integer}
		 * Property that stores the option of the status.
		 * 
		 * courseStatus:
		 * @type {String}
		 * Property that stores the course status.
		 * 
		 * course:
		 * @type {Array}
		 * Property that stores the list of course in table form, consists of name and actions.
		 */
		this.state = {
			name: '',
			show: false,
			title: '',
			message: '',
			detail: 0,
			option: 1,
			courseStatus: '',
			course: {
				columns: [
					{
						label: 'Nombre',
						field: 'name',
						width: 150
					},
					{
						label: 'Categoría',
						field: 'category',
						width: 150
					},
					{
						label: 'Estado',
						field: 'state',
						width: 150
					},
					{
						label: 'Acciones',
						field: 'actions',
						width: 150
					}
				],
				rows: []
			},
			products: []
		};

		this.getCourses = this.getCourses.bind(this);
		this.addCourse = this.addCourse.bind(this);
		this.notify = this.notify.bind(this);
	}

	/**
	 * Method that loads the elements to be initially displayed on the website.
	 */
	componentDidMount() {
		if (sessionStorage.getItem('userType') == 2) {
			this.getCourses();
		} else if (sessionStorage.getItem('userType') == 3) {
			this.props.history.push('/admin');
		} else {
			this.props.history.push('/');
		}
	}

	/**
	 * Method that redirects to another page to add course.
	 */
	addCourse() {
		this.props.history.push({
			pathname: '/CourseUp_In/0'
		});
	}

	/**
	 * 
	 * Method that, depending on the value of the variable, displays the type of message it is, if it is correctly entered, there was an 
	 * error, an informational message, or a warning message.
	 * @param {String} evt
	 * @param {String} value 
	 * @param {String} msj 
	 */
	notify = (evt, value, msj) => {
		switch (value) {
			case 'SUCCESS':
				if (msj != 'Se ha habilita correctamente' && msj != 'Se ha deshabilitado correctamente') {

					toast.success(msj);
				} else {
					this.getCourses();
					toast.success(msj);
					//	window.location.reload();
				}
				break;
			case 'ERROR':
				toast.error(msj);
				break;
			case 'WARN':
				toast.warn(msj);
				break;
			case 'INFO':
				toast.info(msj);
				break;
			default:
				toast.info(msj);
		}
	};

	/**
	 * Method that displays a modal and redirects you to the course edit page.
	 * @param {String} text 
	 * @param {Integer} id 
	 * @param {String} name 
	 * @param {Integer} state 
	 */
	toggle = (text, id, name, state) => () => {
		let modalAction = text + 'Modal';
		if (id != null) {
			this.setState({
				[modalAction]: !this.state[modalAction],
				name: name
			});

			this.props.history.push({
				pathname: '/CourseUp_In/' + id
			});
		} else {
		}
	};

	/**
	 * Method that consists of editing the status of the course in enabled and disabled.
	 * @param {String} text 
	 * @param {Integer} id 
	 * @param {String} name 
	 * @param {Integer} state 
	 * @param {event} event 
	 */
	courseStatu = (text, id, name, state, event) => () => {
		var newTypesRows = [];
		this.state.option = state;
		this.setState({ option: state });

		if (this.state.option == 0) {
			fetch(baseUrl + 'course/updateStateCourse', {
				method: 'put',
				body: JSON.stringify({
					state: 1,
					details: id,
					courseID: this.state.courseID
				}),
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json'
				}
			})
				.then(

					(newTypesRows = this.state.course.rows.filter((row) => row.courseID != this.state.courseID)),
					this.getCourses(),
					this.notify(event, 'SUCCESS', 'Se ha habilita correctamente'),
					this.setState({
						course: {
							columns: this.state.course.columns,
							rows: newTypesRows
						}
					})
				)
				.catch((err) => console.error(err));
		} else if (this.state.option == 1) {
			fetch(baseUrl + 'course/updateStateCourse', {
				method: 'put',
				body: JSON.stringify({
					state: 0,
					details: id
				}),
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json'
				}
			})
				.then(
					(newTypesRows = this.state.course.rows.filter((row) => row.courseID != this.state.courseID)),
					this.getCourses(),
					this.notify(event, 'SUCCESS', 'Se ha deshabilitado correctamente'),
					this.setState({
						course: {
							columns: this.state.course.columns,
							rows: newTypesRows
						}
					})
				)


				.catch((err) => console.error(err));
		}

		this.getCourses();
	};

	toggleToast = () => {
		this.setState({
			show: !this.state.show
		});
	};


	/**
	 * Method that brings the information of all the courses in the database.
	 */
	getCourses() {
		axios
			.get(baseUrl + 'course/selectCourse')
			.then((response) => {
				var dbCourse = response.data;
				var nameTop;

				this.setState({
					...this.state,
					course: {
						...this.state.course,
						rows: dbCourse.map((dbCourse, key) => {
							return {
								...dbCourse,
								state: (
									<MDBTooltip placement="top">
										<MDBBtn 
											color="white"
											className="buttonState tableControlButton"
											onClick={this.courseStatu(
												'state',
												dbCourse.courseID,
												dbCourse.name,
												dbCourse.state
											)}

											{...(this.state.option = dbCourse.state)}
											{...this.state.option == 0 && <div>{(this.courseStatus = 'toggle-off'), (nameTop = 'Deshabilitado')}</div>}
											{...this.state.option == 1 && <div>{(this.courseStatus = 'toggle-on'), (nameTop = 'Habilitado')}</div>}
										>
											<MDBIcon icon={this.courseStatus} size="1x" className={this.courseStatus} />
										</MDBBtn>
										<div>
											{nameTop}
										</div>
									</MDBTooltip>
								),
								actions: (

									<Fragment>
										<MDBTooltip>
											<MDBBtn
												color="white"
												className="tableControlButton"
												onClick={this.toggle('update', dbCourse.courseID, dbCourse.name)}
											>
												<MDBIcon icon="pen" size="1x" />
											</MDBBtn>
											<div>
												Editar
										</div>
										</MDBTooltip>
									</Fragment>
								)
							};
						})
					}
				});
			})
			.catch((err) => console.error(err));
	}



	/**
	* Method that shows the elements found or added to the web page.
	*/
	render() {
		return (
			<div className="container">
				<Toast isOpen={this.state.show} toggle={this.toggleToast}>
					<ToastHeader toggle={this.toggleToast}>
						{' '}
						<strong className="mr-auto">{this.state.title}</strong>
					</ToastHeader>
					<ToastBody>{this.state.message}</ToastBody>
				</Toast>
				<MDBRow>
					<MDBCol className="col-12 col-lg-9 offset-lg-3 mt-3">
						<h3 className="adminTitlesFont">Administración de Cursos</h3>
					</MDBCol>
					<ToastContainer
						position="top-right"
						autoClose={5000}
						hideProgressBar
						newestOnTop
						closeOnClick
						rtl={false}
						pauseOnVisibilityChange
						draggable
						pauseOnHover
					/>
					<MDBCol className="col-12 col-lg-9 offset-lg-3">
						<MDBBtn className="addButtom" color="orange" onClick={this.addCourse}>
							<MDBIcon icon="plus" color="plusIcon" className="plusIcon" size="lg" />
						</MDBBtn>
						<MDBCard style={{ padding: '20px 20px 20px 20px' }}>
							<MDBDataTable
								responsive
								data={this.state.course}
								hover
								striped
								bordered
								entriesLabel={'Mostrar'}
								searchLabel={'Buscar'}
								paginationLabel={['Anterior', 'Siguiente']}
								infoLabel={['Mostrando', 'de', 'de un total de', 'registros']}
								entries={5}
								entriesOptions={[5, 10, 15]}
								small
								noBottomColumns={true}
								noRecordsFoundLabel="No se han encontrado datos"
								theadTextWhite={true}
								pagesAmount={4}
							/>
						</MDBCard>
					</MDBCol>
				</MDBRow>
			</div>
		);
	}
}

export default CourseList;
