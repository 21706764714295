/**
 * @fileoverview ParticipantsList, this component show the list of participants to an specific event
 * 
 * @version 1.0
 * 
 * @author Ester Molina R <maria.molina@ucrso.info>
 * History
 * v1.0 - Initial Realease
 * ----
 * The first version of ParticipantsList was written by Ester Molina R. 
 */

import React, { Component } from 'react';
import {
	MDBContainer,
	MDBBtn,
	MDBModal,
	MDBModalBody,
	MDBModalHeader,
	MDBModalFooter,
	MDBCol,
	MDBRow,
	MDBIcon,
	MDBDataTable,
	MDBCard
} from 'mdbreact';
import { FormGroup } from 'reactstrap';
import { baseUrl } from './baseUrl';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import validations from './Validations';
import Select from 'react-select';
import { Link } from 'react-router-dom';

class ParticipantsList extends Component {
	constructor(props) {
		super(props);
		/**
		 * validations:
		 * @type {Object}
		 * Property that contains methods for validate with regular expressions
		 * 
		 * eventID:
		 * @type {integer}
		 * Property that contains the event identification
		 * 
		 * participantID:
		 * @type {integer}
		 * Property that contains the participant identification
		 * 
		 * participantList:
		 * @type {Array}
		 * Property that contains the list of the participants
		 * 
		 * billNumber:
		 * @type {String}
		 * Property that contains the bill number 
		 * 
		 * billTotal:
		 * @type {integer}
		 * Property that contains the total of the bill
		 * 
		 * coinID:
		 * @type {integer}
		 * Property that contains the coin identification
		 * 
		 * multimediaID:
		 * @type {integer}
		 * Property that contains the identification of the certificate
		 * 
		 * certificateLink:
		 * @type {String}
		 * Property that contains the certificate link
		 * 
		 * coins:
		 * @type {Array}
		 * Property that contains the list of coins
		 * 
		 * show:
		 * @type {boolean}
		 * Property that contains if the modal is open or not
		 * 
		 * title:
		 * @type {String}
		 * Property that contains the modal title
		 * 
		 * name:
		 * @type {String}
		 * Property that contains the event name
		 * 
		 * exchangeRate
		 * @type {decimal}
		 * Property that contains the exchange rate value
		 */
		this.state = {
			validations: new validations(),
			eventID: 0,
			participantID: 0,
			participantList: {
				columns: [
					{
						label: 'Participante',
						field: 'fullName',
						width: 150
					}
				],
				rows: []
			},
			billNumber: '',
			billTotal: 0,
			coinID: 0,
			multimediaID: 0,
			certificateLink: undefined,
			link: '',
			certificateNumber: '',
			coins: [ {} ],
			show: false,
			title: '',
			name: '',
			exchangeRate: 0.0,
			certicateURL:''
		};
		this.getParticipantList = this.getParticipantList.bind(this);
		this.handleInput = this.handleInput.bind(this);
		this.handleDocument = this.handleDocument.bind(this);
		this.coinSelect = this.coinSelect.bind(this);
		this.getCoins = this.getCoins.bind(this);
		this.modal = this.modal.bind(this);
		this.toggle = this.toggle.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.empty = this.empty.bind(this);
		this.validateData = this.validateData.bind(this);
		this.downloadCSV = this.downloadCSV.bind(this);
		this.getEventName = this.getEventName.bind(this);
		this.getDollarExchangeRate = this.getDollarExchangeRate.bind(this);
	}

	componentDidMount() {
		if (sessionStorage.getItem('userType') == 2 || sessionStorage.getItem('userType') == 3) {
			var eventID = this.props.match.params.ID;
			this.setState({
				eventID
			});
			this.getParticipantList(eventID);
			this.getCoins();
			this.getEventName(eventID);
			this.getDollarExchangeRate();
		} else {
			this.props.history.push('/');
		}
	}

	/**
	 * Method that perform the validate data action when an ENTER  is clicked
	 */
	onKeyEvent = (e) => {
		if (e.key == 'Enter') {
			this.validateData(e);
		}
	};


	/**
	 * Method that validate the data format
	 * @param {event} event 
	 */
	validateData(event) {
		if (this.empty) {
			if (!this.state.validations.validateTextWithNumberField(this.state.billNumber)) {
				this.notify(event, 'ERROR', 'El número de factura no debe contener caracteres especiales');
			} else if (!this.state.validations.validateNumericField(this.state.billTotal)) {
				this.notify(event, 'ERROR', 'El total de factura debe contener solo números');
			} else if (!this.state.validations.validateTextWithNumberField(this.state.certificateNumber)) {
				this.notify(event, 'ERROR', 'El número de certificado no debe contener caracteres especiales');
			} else {
				this.handleSubmit(event);
			}
		}
	}

	/**
	 * Method that verify if obligatory fields are empty
	 * @param {event} event 
	 */
	empty(event) {
		if (this.state.billNumber.length === 0) {
			this.notify(event, 'ERROR', 'Debe agregar un número de factura');
			return false;
		} else if (this.state.billTotal.length === 0 || this.state.billTotal === 0) {
			this.notify(event, 'ERROR', 'Debe agregar el total de factura y debe ser diferente de 0');
			return false;
		} else if (this.state.coinID === 0) {
			this.notify(event, 'ERROR', 'Debe seleccionar una moneda');
			return false;
		} else if (this.state.certificateNumber.length === 0) {
			this.notify(event, 'ERROR', 'Debe agregar el número de certificado');
			return false;
		} else if (this.state.document === undefined || this.state.document === null) {
			this.notify(event, 'ERROR', 'Debe seleccionar el certificado a agregar');
			return false;
		} else {
			return true;
		}
	}

	/**
	 * Method that notificate the users
	 * @param {event} evt 
	 * @param {String} value 
	 * @param {String} msj 
	 */
	notify = (evt, value, msj) => {
		switch (value) {
			case 'SUCCESS':
				toast.success(msj);
				break;
			case 'ERROR':
				toast.error(msj);
				break;
			case 'WARN':
				toast.warn(msj);
				break;
			case 'INFO':
				toast.info(msj);
				break;
			default:
				toast.info(msj);
		}
	};

	/**
	 * Method that change the certificate state
	 * */
	handleDocument(event) {
		event.preventDefault();
		if (event.target.files && event.target.files.length === 1) {
			const file = event.target.files[0];

			var name = file.name;
			if (window.innerWidth < 500) {
				if (name.length >= 25) {
					name = name.substring(0, 20) + name.substring(name.length - 4, name.length);
				}
			} else {
				if (name.length >= 40) {
					name = name.substring(0, 35) + name.substring(name.length - 4, name.length);
				}
			}
			document.getElementById('labelDocument').textContent = name;
			this.setState({
				certificateLink: file,
				link: name
			});
		}
	}

	/**
	 * Method that submit the data
	 * @param {event} event 
	 */
	 handleSubmit(event) {
		var auxExchageRate = 0;
		if (this.state.coinID === 1) {
			auxExchageRate = this.state.exchangeRate;
			console.log('entra al if ' + auxExchageRate);
		} else {
			auxExchageRate = 0.0;
		}
		event.preventDefault();
		const formData = new FormData();
		formData.append('billNumber', this.state.billNumber);
		formData.append('exchangeRate', auxExchageRate);
		formData.append('billTotal', this.state.billTotal);
		formData.append('certificateNumber', this.state.certificateNumber);
		formData.append('coinID', this.state.coinID);
		formData.append('participantID', this.state.participantID);
		formData.append('multimediaID', this.state.multimediaID);
		formData.append('document', this.state.certificateLink);
		formData.append('url', this.state.certicateURL)
		if (this.state.title === 'Agregar detalles') {
			axios
				.post(`${baseUrl}event/addDetails`, formData)
				.then(
					
					this.notify(event, 'SUCCESS', 'Se ha agregado correctamente')
				)
				.catch((err) => console.log(err));
		} else {
			if (this.state.certificateLink === undefined || this.state.certificateLink === null) {
				fetch(`${baseUrl}event/editDetailsNoLink`, {
					method: 'post',
					body: JSON.stringify({
						billNumber: this.state.billNumber,
						exchangeRate: auxExchageRate,
						billTotal: this.state.billTotal,
						certificateNumber: this.state.certificateNumber,
						coinID: this.state.coinID,
						participantID: this.state.participantID
					}),
					headers: {
						Accept: 'application/json',
						'Content-Type': 'application/json'
					}
				})
					.then(
						this.notify(event, 'SUCCESS', 'Se ha editado correctamente')
					)
					.catch((err) => console.log(err));
			} else {
				axios
					.post(`${baseUrl}event/editDetails`, formData)
					.then(
						this.notify(event, 'SUCCESS', 'Se ha editado correctamente'),
						
					)
					.catch((err) => console.log(err));
			}
		}
		var newRows = this.state.participantList.rows.map((item) => {
			if (item.enrollmentID === this.state.participantID) {
				return {
					...item,
						billNumber: this.state.billNumber,
						billTotal: this.state.billTotal,
						certificateNumber: this.state.certificateNumber,
						link: this.state.link,
						coinID: this.state.coinID,
						multimediaID: this.state.multimediaID
				};
			} else {
				return item;
			}
		});

		this.setState({
			participantList: {
				columns: this.state.participantList.columns,
				rows: newRows
			},
			show: false,
						participantID:0,
						billNumber:0,
						billTotal:0,
						certificateNumber: '',
						link: '',
						coinID: 0,
						multimediaID: 0,
						certificate:undefined
		})

	}

	/**
	 * Method that charge the data from the database
	 * @param {event} eventID 
	 */
	getParticipantList(eventID) {
		console.log("cargando");
	 axios
			.get(baseUrl + 'event/getParticipants', {
				params: {
					eventID: eventID
				}
			})
			.then((response) => {
				var participants = response.data;
				this.setState({
					...this.state,
					participantList: {
						...this.state.participantList,
						rows: participants.map((participant) => {
							return {
								...participant,
								clickEvent: () => this.toggle(participant.enrollmentID)
							};
						})
					}
				});
			})
			.catch((err) => console.log('Ha ocurrido un error inesperado'));
	}

	/**
	 * Method that change the state
	 * @param {event} e 
	 */
	handleInput(e) {
		const { value, name } = e.target;
		this.setState({
			[name]: value
		});
	}

	/**
	 * Method that take the exchage rate
	 */
	getDollarExchangeRate() {
		var exchangeRate = 0;
		axios
			.get('https://tipodecambio.paginasweb.cr/api')
			.then((response) => {
				var auxExchangeRate = response.data;
				exchangeRate = parseFloat(auxExchangeRate.venta);
				this.setState({
					exchangeRate
				});
			})
			.catch((err) => console.error('Hola: ' + err));
	}

	/**
	 * Method that change the coin state
	 * @param {event} event 
	 */
	coinSelect(event) {
		this.setState({
			coinID: parseInt(event.value)
		});
	}

	/**
	 * Method that charge the coins from the database
	 */
	getCoins() {
		axios
			.get(baseUrl + 'quotation/getCoins')
			.then((response) => {
				var coins = response.data;
				this.setState({
					coins
				});
			})
			.catch((err) => console.error('Ha ocurrido un error inesperado'));
	}

	/**
	 * Method that open the modal
	 * @param {integer} participantID 
	 */
	toggle(participantID) {
		this.state.participantList.rows.map((participant) => {
			if (participantID === participant.enrollmentID) {
				if (
					participant.billNumber === null &&
					participant.billTotal === null &&
					participant.certificateNumber === null &&
					participant.coinID === null &&
					participant.multimediaID === null &&
					participant.link === null
				) {
					this.setState({
						show: !this.state.show,
						participantID,
						title: 'Agregar detalles',
						billNumber: participant.billNumber,
						billTotal: participant.billTotal,
						certificateNumber: participant.certificateNumber,
						link: name,
						coinID: participant.coinID,
						multimediaID: participant.multimediaID
					});
				} else {
					var name =
						participant.link.substring(20, 40) +
						participant.link.substring(participant.link.length - 4, participant.link.length);
					this.setState({
						show: !this.state.show,
						participantID,
						billNumber: participant.billNumber,
						billTotal: participant.billTotal,
						certificateNumber: participant.certificateNumber,
						link: name,
						coinID: participant.coinID,
						multimediaID: participant.multimediaID,
						title: 'Editar detalles',
						certicateURL:'/root/servidor/public/' + participant.link
					});
				}
			} else {
				this.setState({
					show: !this.state.show
				});
			}
		});
	}

	/**
	 * Method that charge the event Name
	 * @param {integer} eventID 
	 */
	getEventName(eventID) {
		axios
			.get(baseUrl + 'event/getEventName', {
				params: {
					eventID: eventID
				}
			})
			.then((response) => {
				var name = response.data[0];
				console.log(name.name);
				this.setState({
					name: name.name
				});
			})
			.catch((err) => console.log('Ha ocurrido un error inesperado'));
	}

	/**
	 * Method for download the list
	 */
	downloadCSV() {
		var list = [];
		var obj = {};

		this.state.participantList.rows.map((participant) => {
			obj = { Participante: participant.fullName, Contacto: participant.contact, Email: participant.email };
			list.push(obj);
		});

		const { parse } = require('json2csv');
		var fields = [];

		fields = [ 'Participante', 'Contacto', 'Email' ];

		const opts = { fields };

		const csv = parse(list, { opts, encoding: 'ISO-8859-1', excelStrings: true, withBOM: true });
		var fileDownload = require('js-file-download');
		fileDownload(csv, 'participantes '+ this.state.name +'.csv');
	}

	/**
	 * Method that render the modal
	 */
	modal() {
		const coins = this.state.coins.map(function(coin) {
			return {
				label: coin.coinName,
				value: coin.coinID
			};
		});
		const coin = coins.filter((e) => e.value == this.state.coinID)[0];
		return (
			<MDBContainer>
				<MDBModal isOpen={this.state.show} toggle={this.toggle}>
					<MDBModalHeader className="modalHeader modalTitlesFont" toggle={this.toggle}>
						{this.state.title}
					</MDBModalHeader>
					<MDBModalBody>
						<MDBRow>
							<MDBCol>
								<FormGroup>
									<label className="modalTextFont">
										Número de factura asociada <font color="red">*</font>
									</label>
									<input
										className="form-control modalTextFont"
										type="text"
										name="billNumber"
										value={this.state.billNumber}
										onChange={this.handleInput}
										required
										onKeyPress={this.onKeyEvent}
									/>
								</FormGroup>
							</MDBCol>
						</MDBRow>
						<MDBRow>
							<MDBCol size={6}>
								<FormGroup>
									<label className="modalTextFont">
										Total <font color="red">*</font>
									</label>
									<input
										className="form-control modalTextFont"
										type="number"
										name="billTotal"
										value={this.state.billTotal}
										onChange={this.handleInput}
										required
										onKeyPress={this.onKeyEvent}
									/>
								</FormGroup>
							</MDBCol>
							<MDBCol size={6}>
								<FormGroup>
									<label className="modalTextFont">
										Moneda <font color="red">*</font>
									</label>
									<Select
										id="coinSelect"
										placeholder="Moneda"
										onChange={this.coinSelect}
										options={coins}
										value={coin}
										theme={theme => ({
											...theme,
											borderRadius: 4,
											font: 'Open Sans',
											height: 9,
											
										
											colors: {
												...theme.colors,
												primary25: '#FFA93F',
												primary: '#808080',
											},
										})}
									/>
								</FormGroup>
							</MDBCol>
						</MDBRow>
						<MDBRow>
							<MDBCol>
								<FormGroup>
									<label className="modalTextFont">
										Número de certificado <font color="red">*</font>
									</label>
									<input
										className="form-control modalTextFont"
										type="text"
										name="certificateNumber"
										value={this.state.certificateNumber}
										onChange={this.handleInput}
										onKeyPress={this.onKeyEvent}
										required
									/>
								</FormGroup>
							</MDBCol>
						</MDBRow>
						<MDBRow>
							<MDBCol>
								<FormGroup>
									<label className="modalTextFont">
										Certificado <font color="red">*</font>
									</label>
									<div className="custom-file">
										<input
											type="file"
											className="custom-file-input"
											lang="es"
											id="document"
											name="certificateLink"
											accept=".pdf"
											onChange={this.handleDocument}
											required
											onKeyPress={this.onKeyEvent}
										/>
										<label className="custom-file-label" id="labelDocument" htmlFor="customFile">
											{this.state.link}
										</label>
									</div>
								</FormGroup>
							</MDBCol>
						</MDBRow>
					</MDBModalBody>
					<MDBModalFooter>
						<MDBBtn color="purpleButton" className="modalButton purpleButton" onClick={this.toggle}>
							<span className="modalButtonText">Cancelar</span>
						</MDBBtn>
						<MDBBtn color="greenButton" className="modalButton greenButton" onClick={this.validateData}>
							<span className="modalButtonText">Guardar</span>
						</MDBBtn>
					</MDBModalFooter>
				</MDBModal>
			</MDBContainer>
		);
	}

	/**
	 * Method that render the HTML
	 */
	render() {
		return (
			<MDBContainer>
				<this.modal />
				<ToastContainer
					position="top-right"
					autoClose={5000}
					hideProgressBar
					newestOnTop
					closeOnClick
					rtl={false}
					pauseOnVisibilityChange
					draggable
					pauseOnHover
				/>
				<MDBRow>
					<MDBCol className="col-12 col-lg-9 offset-lg-3 mt-3">
						<h3 className="adminTitlesFont">Lista de participantes</h3>
					</MDBCol>
					<MDBCol className="col-12 col-lg-9 offset-lg-3 mb-3">
						<MDBBtn className="addButtom" color="orange" onClick={this.downloadCSV}>
							<MDBIcon icon="download" color="plusIcon" className="plusIcon" size="lg" />
						</MDBBtn>
						<MDBCard style={{ padding: '20px 20px 20px 20px' }}>
							<MDBDataTable
								data={this.state.participantList}
								hover
								striped
								bordered
								entriesLabel={'Mostrar'}
								searchLabel={'Buscar'}
								paginationLabel={[ 'Anterior', 'Siguiente' ]}
								infoLabel={[ 'Mostrando', 'de', 'de un total de', 'registros' ]}
								entries={5}
								entriesOptions={[ 5, 10, 15 ]}
								small
								responsive
								noBottomColumns={true}
								noRecordsFoundLabel="No se han encontrado datos"
								theadTextWhite={true}
								pagesAmount={4}
							/>
							<MDBRow>
								<Link to={'/PrincipalEvent/1'}>
									<MDBBtn color="purpleButton" className="modalButton purpleButton">
										<span className="modalButtonText">Volver</span>
									</MDBBtn>
								</Link>
							</MDBRow>
						</MDBCard>
					</MDBCol>
				</MDBRow>
			</MDBContainer>
		);
	}
}
export default ParticipantsList;
