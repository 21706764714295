/**
 * @fileoverview EditProvider, this component allow edit a Providers in the data base.
 * 
 * @version 1.0
 * 
 * @author Anthony Vargas Méndez <anthony.vargas@ucrso.info>
 * History
 * v1.0 - Initial Release
 * ----
 * The first version of EditProvider was writen by Anthony Vargas Méndez
 */
import React, { Component, Fragment } from 'react';
import { MDBRow, MDBCol, MDBBtn } from 'mdbreact';
import { MDBCard, MDBContainer, MDBCardTitle, MDBCardBody, MDBCardFooter } from 'mdbreact';
import { FormGroup } from 'reactstrap';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import validations from './Validations';
import { format } from 'date-fns';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

import { baseUrl } from './baseUrl';

const animatedComponents = makeAnimated();

class EditProvider extends Component {
	state = {
		/**
		 * validations:
		 * @type {object}
		 * Property that validate the inputs in this component
		 * 
		 * name:
		 * @type {string}
		 * Propety that indicates the name of the provider
		 *
		 		 * phoneID:
		 * @type {Integer}
		 * Propety that indicates the first phone ID
		 * 
		 * phone2ID:
		 * @type {Integer}
		 * Propety that indicates the second phone ID
		 * 
		 * emailID:
		 * @type {Integer}
		 * Propety that indicates the email ID
		 *  
		 * observation:
		 * @type {string}
		 * Propety that indicates observations of the new provider
		 * 
		 * exactDirection:
		 * @type {string}
		 * Propety that indicates the exact direction of the provider
		 * 
		 * identificationID:
		 * @type {string}
		 * Propety that indicates the identification of the provider
		 * 
		 * finishDate:
		 * @type {string}
		 * Propety that indicates the date when the colaborator finish in the system
		 * 
		 * phone:
		 * @type {string}
		 * Propety that indicates the first phone of the colaborator
		 * 
		 * phone2:
		 * @type {string}
		 * Propety that indicates the second phone of the colaborator
		 * 
		 * email:
		 * @type {string}
		 * Propety that indicates the email address of the colaborator
		 *  
		 * countryID:
		 * @type {Integer}
		 * Propety that indicates the country ID
		 * 
		 * countries:
		 * @type {array}
		 * Propety that indicates and contain all countries in the database
		 * 
		 * provinceID:
		 * @type {Integer}
		 * Propety that indicates the province ID
		 * 
		 * province:
		 * @type {array}
		 * Propety that indicates and contain all provinces in the database
		 * 
		 * cantonID:
		 * @type {Integer}
		 * Propety that indicates the canton ID
		 * 
		 * cantonList:
		 * @type {array}
		 * Propety that indicates and contain all cantons of the specific province in the database
		 * 
		 * canton:
		 * @type {array}
		 * Propety that indicates and contain all cantons in the database
		 * 
		 * districtID:
		 * @type {Integer}
		 * Propety that indicates the district ID
		 * 
		 * districtList:
		 * @type {array}
		 * Propety that indicates and contain all districts of the specific canton in the database
		 * 
		 * district:
		 * @type {array}
		 * Propety that indicates and contain all districts in the database
		 * 
		 * enterpriseID:
		 * @type {Integer}
		 * Propety that indicates the enterprise ID
		 * 
		 * enterprises:
		 * @type {array}
		 * Propety that indicates and contain all enterprise in the database
		 * 
		 * visible:
		 * @type {boolean}
		 * Propety that indicates the value true or false of this variable
		 * 
		 * addressID:
		 * @type {Integer}
		 * Propety that indicates the address ID
		 * 
		 * contactList:
		 * @type {array}
		 * Propety that indicates the list of contact
		 */
		validations: new validations(),
		name: '',
		identificationID: '',
		phone: '',
		phoneID: 0,
		phone2: '',
		phone2ID: 0,
		email: '',
		auxEmail: '',
		emailID: 0,
		finishDate: '',
		countryID: 0,
		countries: [],
		province: [],
		provinceID: 0,
		canton: [],
		cantonList: [],
		cantonID: 0,
		district: [],
		districtID: 0,
		districtList: [],
		observation: '',
		exactDirection: '',
		enterpriseID: 0,
		enterprises: [],
		visible: false,
		contactList: [],
		addressID: 0,
		emails: []
	};
	handleInput = this.handleInput.bind(this);
	valueCountries = this.valueCountries.bind(this);
	valueProvince = this.valueProvince.bind(this);
	valueCanton = this.valueCanton.bind(this);
	valueDistrict = this.valueDistrict.bind(this);
	valueEnterprise = this.valueEnterprise.bind(this);
	handleSubmit = this.handleSubmit.bind(this);
	validateData = this.validateData.bind(this);
	empty = this.empty.bind(this);
	validateEmail = this.validateEmail.bind(this);

	/**
	 * Method that specify the different alerts in the component
	 * @param {event} evt
	 * @param {value} value
	 * @param {string} msj
	 */
	notify = (evt, value, msj) => {
		switch (value) {
			case 'SUCCESS':
				toast.success(msj);
				break;
			case 'ERROR':
				toast.error(msj);
				break;
			case 'WARN':
				toast.warn(msj);
				break;
			case 'INFO':
				toast.info(msj);
				break;
			default:
				toast.info(msj);
		}
	};

	/**
	 * Method that allow use the enter button
	 * @param {event} e
	 */
	onKeyEvent = (e) => {
		if (e.key === 'Enter') {
			this.validateData(e);
		}
	};

	/**
	 * Method that validate the value to the variables in the inpust of the providers
	 * @param {event} event
	 */
	validateData(event) {
		if (this.empty(event)) {
			if (!this.state.validations.validateTextField(this.state.name)) {
				this.notify(
					event,
					'ERROR',
					'El nombre del proveedor no debe contener caracteres especiales ni números'
				);
			} else if (
				this.state.observation.length !== 0 &&
				!this.state.validations.validateTextWithNumberField(this.state.observation)
			) {
				this.notify(event, 'ERROR', 'Las observaciones del proveedor no debe contener caracteres especiales');
			} else if (!this.state.validations.validateTextWithNumberField(this.state.exactDirection)) {
				this.notify(
					event,
					'ERROR',
					'La dirección exacta del proveedor no debe contener caracteres especiales ni números'
				);
			} else if (!this.state.validations.validateNumericField(this.state.identificationID)) {
				this.notify(event, 'ERROR', 'La identificaión solo debe contener números');
			} else if (this.validateEmail(this.state.email) === true && this.state.email !== this.state.auxEmail) {
				this.notify(event, 'ERROR', 'El correo ingresado ya existe en el sistema');
			} else if (!this.state.validations.validateEmailField(this.state.email)) {
				this.notify(event, 'ERROR', 'El correo ingresado posee un formato incorrecto');
			} else if (!this.state.validations.validateNumericField(this.state.phone)) {
				this.notify(event, 'ERROR', 'El teléfono solo debe contener números');
			} else if (!this.state.validations.validateNumericField(this.state.phone2)) {
				this.notify(event, 'ERROR', 'El segundo teléfono solo debe contener números');
			} else if (!this.state.validations.validateDateField(this.state.finishDate)) {
				this.notify(event, 'ERROR', 'Formato de fecha final incorrecto');
			} else {
				this.handleSubmit(event);
			}
		}
	}

	/**
	 * Method that determine if the value to the variables are empty
	 * @param {event} event
	 */
	empty(event) {
		if (this.state.name.length === 0) {
			this.notify(event, 'ERROR', 'El nombre del proveedor no debe estar vacío');
			return false;
		} else if (this.state.identificationID.length === 0) {
			this.notify(event, 'ERROR', 'La identificación no debe estar vacía');
			return false;
		} else if (this.state.exactDirection.length === 0) {
			this.notify(event, 'ERROR', 'La dirección exacta no debe estar vacía');
			return false;
		} else if (this.state.finishDate.length === 0) {
			this.notify(event, 'ERROR', 'Debe seleccionar una fecha');
			return false;
		} else if (this.state.phone.length === 0) {
			this.notify(event, 'ERROR', 'El teléfono no debe estar vacío');
			return false;
		} else if (this.state.email.length === 0) {
			this.notify(event, 'ERROR', 'Debe ingresar un correo electrónico');
			return false;
		} else if (this.state.countryID === 0) {
			this.notify(event, 'ERROR', 'Debe seleccionar un país');
			return false;
		} else if (this.state.enterpriseID === 0) {
			this.notify(event, 'ERROR', 'Debe seleccionar una empresa');
			return false;
		} else if (this.state.countryID === 1 && this.state.provinceID === 0) {
			this.notify(event, 'ERROR', 'Debe seleccionar una provincia');
			return false;
		} else if (this.state.countryID === 1 && this.state.cantonID === 0) {
			this.notify(event, 'ERROR', 'Debe seleccionar un cantón');
			return false;
		} else if (this.state.countryID === 1 && this.state.districtID === 0) {
			this.notify(event, 'ERROR', 'Debe seleccionar un distrito');
			return false;
		} else {
			return true;
		}
	}

	/**
	 * Method that validate if email exist in a database or not
	 * @param {string} auxEmail
	 */
	validateEmail(auxEmail) {
		var validateE = false;
		const emailsList = this.state.emails;
		console.log('Correo aux: ' + auxEmail);
		emailsList.map(function(email) {
			if (email.value === auxEmail) {
				validateE = true;
			}
		});

		if (validateE === true) {
			return true;
		} else {
			return false;
		}
	}

	/**
	 * Method that get all emails from de database and set in the emails
	 */
	getEmails() {
		axios
			.get(baseUrl + 'perfilConfiguration/getEmails')
			.then((response) => {
				var emails = response.data;
				this.setState({
					emails
				});
			})
			.catch((err) => console.error(err));
	}

	/**
	 * Method that charge and indicates the session storage and the diferents methods
	 */
	componentDidMount() {
		if (sessionStorage.getItem('userType') == 2) {
			const auxProviderID = this.props.match.params.providerID;
			console.log('PROVEEDOR:' + auxProviderID);
			this.getProvider(auxProviderID);
			this.getCountry();
			this.getProvince();
			this.getCanton();
			this.getDistrict();
			this.getEnterprise();
			this.getEmails();
			this.getProviderAddress(auxProviderID);
			this.setState({
				providerID: auxProviderID
			});
		} else if (sessionStorage.getItem('userType') == 3) {
			this.props.history.push('/admin');
		} else {
			this.props.history.push('/');
		}
	}

	/**
	 * Method that assigns the value to the variables of the provider
	 * @param {event} e
	 */
	handleInput(e) {
		const { value, name } = e.target;
		this.setState({
			[name]: value
		});
	}

	/**
	 * Method that set a country ID in the countryID and change the visible variable
	 * @param {event} event
	 */
	valueCountries(event) {
		if (event.value === 1) {
			this.setState({
				visible: true
			});
		} else {
			this.setState({
				visible: false
			});
		}
		this.setState({
			countryID: event.value
		});
	}

	/**
	 * Method that set a enterprise ID in the enterpriseID
	 * @param {event} event
	 */
	valueEnterprise(event) {
		console.log(event.value);
		this.setState({
			enterpriseID: event.value
		});
	}

	/**
	 * Method that set a province ID in the provinceID and charge a cantonList with a specific cantons of this provinceID
	 * @param {event} event
	 */
	valueProvince(event) {
		var object = {};
		var cantonList = [];
		this.state.canton.map((place) => {
			if (place.localGeoSupID === event.value) {
				object = {
					label: place.description,
					value: place.localGeoID
				};
				cantonList.push(object);
			}
		});
		this.setState({
			provinceID: event.value,
			cantonList
		});
	}

	/**
	 * Method that set a canton ID in the cantonID and charge a districtList with a specific districts of this cantonID
	 * @param {event} event
	 */
	valueCanton(event) {
		var object = {};
		var districtList = [];
		this.state.district.map((place) => {
			if (place.localGeoSupID === event.value) {
				object = {
					label: place.description,
					value: place.localGeoID
				};
				districtList.push(object);
			}
		});
		this.setState({
			cantonID: event.value,
			districtList
		});
	}

	/**
	 * Method that set a district ID in the districtID 
	 * @param {event} event
	 */
	valueDistrict(event) {
		this.setState({
			districtID: event.value
		});
	}

	/**
	 * Method that get all provinces from de database and set in the province
	 */
	getProvince() {
		axios
			.get(baseUrl + 'provider/getProvince')
			.then((response) => {
				var province = response.data;
				this.setState({
					province
				});
			})
			.catch((err) => console.error(err));
	}

	/**
	 * Method that get all cantons from de database and set in the canton
	 */
	getCanton() {
		axios
			.get(baseUrl + 'provider/getCanton')
			.then((response) => {
				var canton = response.data;
				this.setState({
					canton
				});
			})
			.catch((err) => console.error(err));
	}

	/**
	 * Method that get all districts from de database and set in the district
	 */
	getDistrict() {
		axios
			.get(baseUrl + 'provider/getDistrict')
			.then((response) => {
				var district = response.data;
				this.setState({
					district
				});
			})
			.catch((err) => console.error(err));
	}

	/**
	 * Method that get all countries type from de database and set in the countries
	 */
	getCountry() {
		axios
			.get(baseUrl + 'provider/getAllCountries')
			.then((response) => {
				var countries = response.data;
				this.setState({
					countries
				});
			})
			.catch((err) => console.error(err));
	}

	/**
	 * Method that get all enterprises from de database and set in the enterprises
	 */
	getEnterprise() {
		axios
			.get(baseUrl + 'provider/getEnterprise')
			.then((response) => {
				var enterprises = response.data;
				this.setState({
					enterprises
				});
			})
			.catch((err) => console.error(err));
	}

	/**
	 * Method that get address provider from de database and set in the address
	 * @param {Integer} auxProviderID
	 */
	getProviderAddress(auxProviderID) {
		axios
			.get(baseUrl + 'provider/getProviderAddress', {
				params: { providerID: auxProviderID }
			})
			.then((response) => {
				var address = response.data[0];
				this.setState({
					addressID: address.addressID,
					exactDirection: address.addressDirection,
					districtID: address.localGeoID,
					countryID: address.countryID,
					cantonID: address.cantonID,
					provinceID: address.provinceID
				});
				if (this.state.countryID === 1) {
					this.setState({
						visible: true
					});
				} else {
					this.setState({
						visible: false
					});
				}
			});
	}

	/**
	 * Method that get specific provider from de database and set in the provider
	 * @param {Integer} auxProviderID
	 */
	getProvider(auxProviderID) {
		axios
			.get(baseUrl + 'provider/getProvider', {
				params: { providerID: auxProviderID }
			})
			.then((response) => {
				var provider = response.data[0];
				this.setState({
					name: provider.name,
					identificationID: provider.identificationID,
					finishDate: format(new Date(provider.date), 'yyyy-MM-dd'),
					enterpriseID: provider.enterpriseID,
					observation: provider.observation
				});
				axios
					.get(baseUrl + 'provider/getProviderContact', {
						params: { providerID: auxProviderID }
					})
					.then((response) => {
						var contactList = response.data;
						this.setState({
							contactList
						});
						contactList.map((contact) => {
							var contactTypeID = contact.contactTypeID;
							if (contactTypeID === 1) {
								this.setState({
									email: contact.value,
									auxEmail: contact.value,
									emailID: contact.contactID
								});
							} else {
								if (contactTypeID === 2 && contact.priority === 1) {
									this.setState({
										phone: contact.value,
										phoneID: contact.contactID
									});
								} else {
									this.setState({
										phone2: contact.value,
										phone2ID: contact.contactID
									});
								}
							}
						});
					});
			});
	}

	/**
	 * Method that charge all values of the providers and call the api to update the provider
	 * @param {event} event
	 */
	handleSubmit(event) {
		event.preventDefault();
		fetch(`${baseUrl}provider/EditProvider`, {
			method: 'post',
			body: JSON.stringify({
				providerID: this.state.providerID,
				name: this.state.name,
				identificationID: this.state.identificationID,
				finishDate: this.state.finishDate,
				observation: this.state.observation,
				enterpriseID: this.state.enterpriseID
			}),
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			}
		})
			.then(() => {
				fetch(`${baseUrl}provider/editProviderContact`, {
					method: 'post',
					body: JSON.stringify({
						email: this.state.email,
						emailID: this.state.emailID
					}),
					headers: {
						Accept: 'application/json',
						'Content-Type': 'application/json'
					}
				})
					.then(() => {
						fetch(`${baseUrl}provider/editProviderContact`, {
							method: 'post',
							body: JSON.stringify({
								email: this.state.phone,
								emailID: this.state.phoneID
							}),
							headers: {
								Accept: 'application/json',
								'Content-Type': 'application/json'
							}
						})
							.then(() => {
								fetch(`${baseUrl}provider/editProviderContact`, {
									method: 'post',
									body: JSON.stringify({
										email: this.state.phone2,
										emailID: this.state.phone2ID
									}),
									headers: {
										Accept: 'application/json',
										'Content-Type': 'application/json'
									}
								})
									.then(() => {
										fetch(`${baseUrl}provider/editProviderAddress`, {
											method: 'post',
											body: JSON.stringify({
												exactDirection: this.state.exactDirection,
												districtID: this.state.districtID,
												providerID: this.state.providerID,
												countryID: this.state.countryID,
												addressID: this.state.addressID
											}),
											headers: {
												Accept: 'application/json',
												'Content-Type': 'application/json'
											}
										})
											.then(() => {
												this.props.history.push({
													pathname: '/Providers'
												});
											})
											.catch((err) => console.log(err));
									})
									.catch((err) => console.log(err));
							})
							.catch((err) => console.log(err));
					})
					.catch((err) => console.log(err));
			})
			.catch((err) => console.log(err));
	}

	/**
	 * Method that render the view with the all inputs and selects to update the provider
	 * @param {array} auxDistrict
	 * @param {array} auxProvinces
	 * @param {array} auxEnterprises
	 * @param {array} auxCountry
	 * @param {array} auxCanton
	 */
	render() {
		const auxProvinces = this.state.province.map(function(place) {
			return {
				label: place.description,
				value: place.localGeoID
			};
		});

		const auxCanton = this.state.canton.map(function(place) {
			return {
				label: place.description,
				value: place.localGeoID
			};
		});

		const auxDistrict = this.state.district.map(function(place) {
			return {
				label: place.description,
				value: place.localGeoID
			};
		});

		const auxCountry = this.state.countries.map(function(country) {
			return {
				label: country.name,
				value: country.countryID
			};
		});
		const auxEnterprises = this.state.enterprises.map(function(enterprise) {
			return {
				label: enterprise.name,
				value: enterprise.enterpriseID
			};
		});

		const auxEnterprise = auxEnterprises.filter((e) => e.value === this.state.enterpriseID)[0];
		const auxCtry = auxCountry.filter((e) => e.value === this.state.countryID)[0];
		const auxDist = auxDistrict.filter((e) => e.value === this.state.districtID)[0];
		const auxCnt = auxCanton.filter((e) => e.value === this.state.cantonID)[0];
		const auxPrv = auxProvinces.filter((e) => e.value === this.state.provinceID)[0];

		return (
			<Fragment>
				<MDBContainer>
					<ToastContainer
						position="top-right"
						autoClose={5000}
						hideProgressBar
						newestOnTop
						closeOnClick
						rtl={false}
						pauseOnVisibilityChange
						draggable
						pauseOnHover
					/>
					<MDBRow>
						<MDBCol className="col-12 col-lg-9 offset-lg-3 mt-3">
							<h3 className="adminTitlesFont">Editar Proveedor</h3>
						</MDBCol>
						<MDBCol className="col-12 col-lg-9 offset-lg-3">
							<MDBCard style={{ padding: '20px 20px 20px 20px' }}>
								<MDBCardTitle className="cardHeader">
									<h2>Proveedor</h2>
								</MDBCardTitle>

								<MDBCardBody className="adminFont">
									<MDBRow>
										<MDBCol className="col-12 col-md-4">
											<FormGroup>
												<label>
													Nombre Completo <font color="red">*</font>
												</label>
												<input
													className="form-control "
													value={this.state.name}
													type="text"
													name="name"
													onChange={this.handleInput}
													onKeyPress={this.onKeyEvent}
												/>
											</FormGroup>
										</MDBCol>
										<MDBCol className="col-12 col-md-4">
											<FormGroup>
												<label>
													Identificación <font color="red">*</font>
												</label>
												<input
													className="form-control "
													value={this.state.identificationID}
													type="number"
													name="identificationID"
													onChange={this.handleInput}
													onKeyPress={this.onKeyEvent}
												/>
											</FormGroup>
										</MDBCol>
										<MDBCol className="col-12 col-md-4">
											<FormGroup>
												<label>
													Fecha de salida <font color="red">*</font>
												</label>
												<input
													className="form-control "
													value={this.state.finishDate}
													type="date"
													name="finishDate"
													onChange={this.handleInput}
													onKeyPress={this.onKeyEvent}
												/>
											</FormGroup>
										</MDBCol>
									</MDBRow>
									<MDBRow>
										<MDBCol className="col-12 col-md-4">
											<FormGroup>
												<label>
													Telefono #1 <font color="red">*</font>
												</label>
												<input
													className="form-control "
													value={this.state.phone}
													type="number"
													name="phone"
													onChange={this.handleInput}
													onKeyPress={this.onKeyEvent}
												/>
											</FormGroup>
										</MDBCol>
										<MDBCol className="col-12 col-md-4">
											<FormGroup>
												<label>Telefono #2</label>
												<input
													className="form-control "
													value={this.state.phone2}
													type="number"
													name="phone2"
													onChange={this.handleInput}
													onKeyPress={this.onKeyEvent}
												/>
											</FormGroup>
										</MDBCol>
										<MDBCol className="col-12 col-md-4">
											<FormGroup>
												<label>
													Correo Electrónico <font color="red">*</font>
												</label>
												<input
													className="form-control "
													value={this.state.email}
													type="email"
													name="email"
													onChange={this.handleInput}
													onKeyPress={this.onKeyEvent}
												/>
											</FormGroup>
										</MDBCol>
									</MDBRow>
									<MDBRow>
										<MDBCol className="col-12 col-md-6">
											<FormGroup>
												<label>
													País <font color="red">*</font>
												</label>
												<Select
													className="selectLetter"
													name="district"
													components={animatedComponents}
													options={auxCountry}
													classNamePrefix="select"
													placeholder="Seleccione un país"
													onChange={this.valueCountries}
													value={auxCtry}
													theme={(theme) => ({
														...theme,
														borderRadius: 4,
														font: 'Open Sans',
														height: 9,

														colors: {
															...theme.colors,
															primary25: '#FFA93F',
															primary: '#808080'
														}
													})}
												/>
											</FormGroup>
										</MDBCol>
										<MDBCol className="col-12 col-md-6">
											<FormGroup>
												<label>
													Empresa <font color="red">*</font>
												</label>
												<Select
													className="selectLetter"
													name="enterprise"
													components={animatedComponents}
													options={auxEnterprises}
													classNamePrefix="select"
													placeholder="Seleccione una empresa"
													onChange={this.valueEnterprise}
													value={auxEnterprise}
													theme={(theme) => ({
														...theme,
														borderRadius: 4,
														font: 'Open Sans',
														height: 9,

														colors: {
															...theme.colors,
															primary25: '#FFA93F',
															primary: '#808080'
														}
													})}
												/>
											</FormGroup>
										</MDBCol>
									</MDBRow>
									{this.state.visible ? (
										<MDBRow>
											<MDBCol className="col-12 col-md-4">
												<FormGroup>
													<label>
														Provincia <font color="red">*</font>
													</label>
													<Select
														className="selectLetter"
														name="province"
														components={animatedComponents}
														options={auxProvinces}
														classNamePrefix="select"
														placeholder="Seleccione una provincia"
														onChange={this.valueProvince}
														value={auxPrv}
														theme={(theme) => ({
															...theme,
															borderRadius: 4,
															font: 'Open Sans',
															height: 9,

															colors: {
																...theme.colors,
																primary25: '#FFA93F',
																primary: '#808080'
															}
														})}
													/>
												</FormGroup>
											</MDBCol>
											<MDBCol className="col-12 col-md-4">
												<FormGroup>
													<label>
														Cantón <font color="red">*</font>
													</label>
													<Select
														className="selectLetter"
														name="canton"
														components={animatedComponents}
														options={this.state.cantonList}
														classNamePrefix="select"
														placeholder="Seleccione un Cantón"
														onChange={this.valueCanton}
														value={auxCnt}
														theme={(theme) => ({
															...theme,
															borderRadius: 4,
															font: 'Open Sans',
															height: 9,

															colors: {
																...theme.colors,
																primary25: '#FFA93F',
																primary: '#808080'
															}
														})}
													/>
												</FormGroup>
											</MDBCol>
											<MDBCol className="col-12 col-md-4">
												<FormGroup>
													<label>
														Distrito <font color="red">*</font>
													</label>
													<Select
														className="selectLetter"
														name="district"
														components={animatedComponents}
														options={this.state.districtList}
														classNamePrefix="select"
														placeholder="Seleccione un distrito"
														onChange={this.valueDistrict}
														value={auxDist}
														theme={(theme) => ({
															...theme,
															borderRadius: 4,
															font: 'Open Sans',
															height: 9,

															colors: {
																...theme.colors,
																primary25: '#FFA93F',
																primary: '#808080'
															}
														})}
													/>
												</FormGroup>
											</MDBCol>
										</MDBRow>
									) : null}
									<MDBRow>
										<MDBCol className="col-12 col-md-6">
											<FormGroup>
												<label>
													Dirección exacta <font color="red">*</font>
												</label>
												<textarea
													className="userTextFont"
													rows={3}
													value={this.state.exactDirection}
													name="exactDirection"
													onChange={this.handleInput}
													onKeyPress={this.onKeyEvent}
												/>
											</FormGroup>
										</MDBCol>
										<MDBCol className="col-12 col-md-6">
											<FormGroup>
												<label>Observaciones</label>
												<textarea
													className="userTextFont"
													rows={3}
													className="form-control "
													value={this.state.observation}
													name="observation"
													onChange={this.handleInput}
													onKeyPress={this.onKeyEvent}
												/>
											</FormGroup>
										</MDBCol>
									</MDBRow>
								</MDBCardBody>
								<MDBCardFooter color="white">
									<Link to={'/Providers'}>
										<MDBBtn color="purpleButton" className="modalButton purpleButton">
											<span className="modalButtonText">Cancelar</span>
										</MDBBtn>
									</Link>
									<MDBBtn
										color="greenButton"
										className="modalButton greenButton"
										type="submit"
										onClick={this.validateData}
									>
										<span className="modalButtonText">Guardar</span>
									</MDBBtn>
								</MDBCardFooter>
							</MDBCard>
						</MDBCol>
					</MDBRow>

					{/*-------------------------AQUI TERMINA LO DE LA TABLA----------------------------------------  */}
				</MDBContainer>
			</Fragment>
		);
	}
}

export default EditProvider;
