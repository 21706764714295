/**
 * @fileoverview Employee, this component allow to get and show a list of employees in the data base.
 * 
 * @version 1.0
 * 
 * @author Anthony Vargas Méndez <anthony.vargas@ucrso.info>
 * History
 * v1.0 - Initial Release
 * ----
 * The first version of Employee was writen by Anthony Vargas Méndez
 */
import React, { Component, Fragment } from 'react';
import { MDBRow, MDBCol, MDBIcon, MDBBtn, MDBTooltip } from 'mdbreact';
import { MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBDataTable, MDBCard, MDBContainer } from 'mdbreact';
import { FormGroup, Container } from 'reactstrap';
import axios from 'axios';
import { baseUrl } from './baseUrl';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class Employee extends Component {
	state = {
		/**
		 * validations:
		 * @type {object}
		 * Property that validate the inputs in this component
		 * 
		 * deleteModal:
		 * @type {boolean}
		 * Property that indicates the value of deleteModal to show the modal
		 * 
		 * firstname:
		 * @type {string}
		 * Propety that indicates the name of the colaborator
		 * 
		 * lastname:
		 * @type {string}
		 * Propety that indicates the last name of the colaborator
		 * 
		 * userID:
		 * @type {Integer}
		 * Propety that indicates the user ID
		 * 
		 * userTypeID:
		 * @type {Integer}
		 * Propety that indicates the user type ID
		 * 
		 * userType:
		 * @type {array}
		 * Propety that indicates and contain all user type in the database
		 * 
		 * title:
		 * @type {string}
		 * Property that indicates the title of the modal
		 *
		 * message:
		 * @type {string}
		 * Property that indicates the message of the modal
		 * 
		 * employees
		 * @type {object}
		 * Propety that indicates a object that contain a list of colaborators
		 */
		deleteModal: false,
		firstname: '',
		lastname: '',
		userID: 0,
		userType: [],
		userTypeID: 0,
		description: '',
		show: false,
		title: '',
		message: '',
		employees: {
			columns: [
				{
					label: 'Nombre',
					field: 'firstname',
					width: 150
				},
				{
					label: 'Apellido',
					field: 'lastname',
					width: 150
				},
				{
					label: 'Acciones',
					field: 'actions',
					width: 150
				}
			],
			rows: []
		}
	};
	deleteModal = this.deleteModal.bind(this);
	delete = this.delete.bind(this);

	/**
	 * Method that show or not the modals with the userID
	 * @param {string} text
	 * @param {Integer} id
	 */
	toggle(text, id) {
		let modalAction = text + 'Modal';
		if (id != null) {
			this.setState({
				[modalAction]: !this.state[modalAction],
				userID: id
			});
		} else {
			if (this.state[modalAction] === true) {
				this.setState({
					[modalAction]: !this.state[modalAction],
					userID: 0
				});
			} else {
				this.setState({
					[modalAction]: !this.state[modalAction]
				});
			}
		}
	}

	/**
	 * Method that specify the different alerts in the component
	 * @param {event} evt
	 * @param {value} value
	 * @param {string} msj
	 */
	notify = (evt, value, msj) => {
		switch (value) {
			case 'SUCCESS':
				toast.success(msj);
				break;
			case 'ERROR':
				toast.error(msj);
				break;
			case 'WARN':
				toast.warn(msj);
				break;
			case 'INFO':
				toast.info(msj);
				break;
			default:
				toast.info(msj);
		}
	};

	/**
	 * Method that delete a specific colaborator of the data base, and call the api that delete it, and update a new colaborator list
	 * @param {event} event
	 */
	delete(event) {
		axios
			.get(baseUrl + 'employee/verifyColaboratorForDelete', {
				params: { userID: this.state.userID }
			})
			.then((results) => {
				var newEmployeeRows = [];
				var verification = results.data;
				var valid = verification['fun_verifyColaboratorForDelete(' + this.state.userID + ')'].data[0];
				if (valid === 1) {
					axios
						.post(baseUrl + 'employee/deleteEmployee', {
							userID: this.state.userID
						})
						.then(
							(newEmployeeRows = this.state.employees.rows.filter(
								(row) => row.userID !== this.state.userID
							)),
							this.setState({
								userID: 0,
								deleteModal: false,
								employees: {
									columns: this.state.employees.columns,
									rows: newEmployeeRows
								}
							}),
							this.notify(event, 'SUCCESS', 'Se ha eliminado correctamente')
						);
				} else {
					this.setState({
						userID: 0,
						deleteModal: false
					});
					this.notify(event, 'WARN', 'No se ha podido eliminar');
				}
			})
			.catch((err) => console.error('Ha ocurrido un error inesperado'));
		event.preventDefault();
	}

	/**
	 * Method that get all employees from de database and set in the employees and assign the buttons of edit an delete
	 */
	getEmployees() {
		axios
			.get(baseUrl + 'employee/getEmployeeColaborators')
			.then((response) => {
				var dbEmployees = response.data;
				this.setState({
					...this.state,
					employees: {
						...this.state.employees,
						rows: dbEmployees.map((dbEmployee) => {
							return {
								...dbEmployee,
								actions: (
									<Fragment>
										<MDBTooltip>
											<Link to={`/Employees/${dbEmployee.userID}`}>
												<MDBBtn color="white" className="tableControlButton">
													<MDBIcon icon="pen" size="1x" />
												</MDBBtn>
											</Link>
											<div>Editar</div>
										</MDBTooltip>
										<MDBTooltip>
											<MDBBtn
												color="white"
												className="tableControlButton"
												onClick={() => this.toggle('delete', dbEmployee.userID)}
											>
												<MDBIcon icon="trash" size="1x" className="red-text" id="delete" />
											</MDBBtn>
											<div>Eliminar</div>
										</MDBTooltip>
									</Fragment>
								)
							};
						})
					}
				});
			})
			.catch((err) => console.error('Hola: ' + err));
	}

	/**
	 * Method that charge and indicates the session storage
	 */
	componentDidMount() {
		if (sessionStorage.getItem('userType') == 2) {
			this.getEmployees();
		} else if (sessionStorage.getItem('userType') == 3) {
			this.props.history.push('/admin');
		} else {
			this.props.history.push('/');
		}
	}

	/**
	 * Method that view a delete modal for delete the employee
	 */
	deleteModal() {
		return (
			<MDBContainer>
				<MDBModal isOpen={this.state.deleteModal} toggle={() => this.toggle('delete')}>
					<MDBModalHeader className="modalHeader modalTitlesFont" toggle={() => this.toggle('delete')}>
						Eliminar Colaborador
					</MDBModalHeader>
					<MDBModalBody>
						<FormGroup className="text-center">
							<label className="modalTextFont">
								¿Desea eliminar este colaborador?<br />Esta acción es irreversible
							</label>
						</FormGroup>
					</MDBModalBody>
					<MDBModalFooter>
						<MDBBtn
							color="purpleButton"
							className="modalButton purpleButton"
							onClick={() => this.toggle('delete')}
						>
							<span className="modalButtonText">Cancelar</span>
						</MDBBtn>
						<MDBBtn color="redButton" className="modalButton redButton" onClick={this.delete}>
							<span className="modalButtonText">Eliminar</span>
						</MDBBtn>
					</MDBModalFooter>
				</MDBModal>
			</MDBContainer>
		);
	}

	/**
	 * Method that render the view with the all colaborators and their actions
	 */
	render() {
		//----------------------ESTO PARA LA TABLA--------------------------------------------------------/
		return (
			<div>
				{/*-------------------------AQUI ESTA LA TABLA----------------------------------------  */}
				<Container>
					<this.deleteModal />
					<ToastContainer
						position="top-right"
						autoClose={5000}
						hideProgressBar
						newestOnTop
						closeOnClick
						rtl={false}
						pauseOnVisibilityChange
						draggable
						pauseOnHover
					/>
					<MDBRow>
						<MDBCol className="col-12 col-lg-9 offset-lg-3 mt-3">
							<h3 className="adminTitlesFont">Administración de Colaboradores</h3>
						</MDBCol>
						<MDBCol className="col-12 col-lg-9 offset-lg-3">
							<Link to={'/Employees/Add'}>
								<MDBBtn className="addButtom" color="orange">
									<MDBIcon icon="plus" color="plusIcon" className="plusIcon" size="lg" />
								</MDBBtn>
							</Link>
							<MDBCard style={{ padding: '20px 20px 20px 20px' }}>
								<MDBDataTable
									data={this.state.employees}
									hover
									striped
									bordered
									entriesLabel={'Mostrar'}
									searchLabel={'Buscar'}
									paginationLabel={[ 'Anterior', 'Siguiente' ]}
									infoLabel={[ 'Mostrando', 'de', 'de un total de', 'registros' ]}
									entries={5}
									entriesOptions={[ 5, 10, 15 ]}
									small
									responsive
									noBottomColumns={true}
									noRecordsFoundLabel="No se han encontrado datos"
									theadTextWhite={true}
									pagesAmount={4}
								/>
							</MDBCard>
						</MDBCol>
					</MDBRow>

					{/*-------------------------AQUI TERMINA LO DE LA TABLA----------------------------------------  */}
				</Container>
			</div>
		);
	}
}

export default Employee;
