/**
 * @fileoverview CourseUp_In this component consists of showing the user how to add course data as well as edit the data.
 *
 * @version 1.0
 * 
 * @author María Ester Molina Richmond <maria.molina@ucrso.info>
 * @author Antony Giovanni Vargas Méndez  <antony.vargas@ucrso.info>
 * @author Estrella López López <estrella.lopez@ucrso.info>
 * 
 * History - Initial Release
 * ----
 * The first version of CourseUp_In was written by Antony Giovanni Vargas Méndez, Estrella López L and Ester Molina R
 */

import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardTitle, MDBCardFooter, MDBBtn, MDBModalBody, MDBIcon, MDBModalFooter, MDBModal, MDBModalHeader } from 'mdbreact';
import { FormGroup } from 'reactstrap';
import imagen from '../images/imagen.jpeg';
import Select from 'react-select';
import { baseUrl } from './baseUrl';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import validations from './Validations';

class CourseUp_In extends Component {
	constructor(props) {
		super(props);
		/**
		 * validations: 
		 * @type {Object}
		 * Property that has methods for validate with regular expressions
		 * 
		 * initial: 
		 * @type {String}
		 * Property that will save the initials of the course.
		 * 
		 * name:
		 * @type {String}
		 * Property that will save the name of the course.
		 * 
		 * descripcion: 
		 * @type {String}
		 * Property that will save the course description.
		 * 
		 * free: 
		 * @type {Integer}
		 * Property that will save the id if free or paid is selected.
		 *
		 * hours:
		 * @type {Integer}
		 * Property that will save the hours that are dedicated to each course.
		 * 
		 * enterprise:
		 * @type {Integer}
		 * Property that will keep the identification of the company.
		 * 
		 * courseType:
		 * @type {Integer}
		 * Property that will store the identification of the type of course.
		 * 
		 * category:
		 * @type {Integer}
		 * Property that will store the category of the course.
		 * 
		 * detail:
		 * @type {Integer}
		 * Property that will store the id of the selected course.
		 * 
		 * nameCat:
		 * @type {String}
		 * Property that will store the name of the course category.
		 * 
		 * nameTy:
		 * @type {String}
		 * Property that will store the name of the course type.
		 * 
		 * title:
		 * @type {String}
		 * Property that will keep the title of the modal.
		 * 
		 * image:
		 * @type {String}
		 * Property that stores the course image.
		 * 
		 * imageID:
		 * @type {Integer}
		 * Property that stores the id of the image.
		 * 
		 * imageLink:
		 * @type {String}
		 * Property that stores the link of the image brought from the database.
		 * 
		 * courseTypes:
		 * @type {Array}
		 * Property to store the list of course types.
		 * 
		 * categories:
		 * @type {Array}
		 * Property to store the list of categories.
		 * 
		 * course:
		 * @type {Array}
		 * Property to store the list of course.
		 * 
		
		 * 	materialList:
		 *  @type {Array}
		 *  Property that contains the course material
		 *  
		 * 	modal:
		 * 	@type {boolean}
		 * 	Property that contains if the course material modal is open or not
		 * 
		 * 	modalTitle:
		 * 	@type {String}
		 * 	Property that contains the title for the course material modal
		 * 
		 * 	counter:
		 * 	@type {Integer}
		 * 	Property that contains the quantity of course material
		 * 
		 * 	materialName1:
		 * 	@type {String}
		 * 	Property that contains the name of the first course material
		 * 
		 * 	materialLink1:
		 * 	@type {String}
		 * 	Property that contains the link of the first course material
		 * 
		 * materialName2:
		 * 	@type {String}
		 * 	Property that contains the name of the second course material
		 * 
		 * 	materialLink2:
		 * 	@type {String}
		 * 	Property that contains the link of the second course material
		 * 
		 * materialName3:
		 * 	@type {String}
		 * 	Property that contains the name of the third course material
		 * 
		 * 	materialLink3:
		 * 	@type {String}
		 * 	Property that contains the link of the third course material
		 * 
		 * 	materialID1:
		 * 	@type {Integer}
		 * 	Property that contains the identification of the first course material
		 * 
		 * materialID2:
		 * 	@type {Integer}
		 * 	Property that contains the identification of the second course material
		 * 
		 * materialID3:
		 * 	@type {Integer}
		 * 	Property that contains the identification of the third course material
		 * 
		 *  hidden2:
		 * 	@type {boolean}
		 *  Property that shows or not the second space for course material
		 * 
		 * hidden3:
		 * 	@type {boolean}
		 *  Property that shows or not the third space for course material
		 * 
		 * hidden1:
		 * 	@type {boolean}
		 *  Property that shows or not the first space for course material
		 */


		this.state = {
			validations: new validations(),
			initial: '',
			name: '',
			description: '',
			free: 0,
			hours: 0,
			enterprise: 1,
			courseType: 0,
			category: 0,
			detail: 0,
			nameCat: '',
			nameTy: '',
			title: '',
			image: null,
			imageID: 0,
			imageLink: "",
			imageURL: "",
			courseTypes: [],
			categories: [],
			course: [],

			/** Material del curso */
			materialList: [],
			modal: false,
			modalTitle: "",
			counter: 1,
			materialName1: "",
			materialLink1: "",
			materialName2: "",
			materialLink2: "",
			materialName3: "",
			materialLink3: "",
			materialID1: 0,
			materialID2: 0,
			materialID3: 0,
			hidden2: true,
			hidden3: true,
			hidden1: false
		};

		this.addCourse = this.addCourse.bind(this);
		this.valueInitial = this.valueInitial.bind(this);
		this.valueName = this.valueName.bind(this);
		this.valueDescription = this.valueDescription.bind(this);
		this.valueFree = this.valueFree.bind(this);
		this.valueHours = this.valueHours.bind(this);
		this.valueEnterprise = this.valueEnterprise.bind(this);
		this.valueCourseType = this.valueCourseType.bind(this);
		this.valueCategory = this.valueCategory.bind(this);
		this.loadTypes = this.loadTypes.bind(this);
		this.loadCategories = this.loadCategories.bind(this);
		this.getCategory = this.getCategory.bind(this);
		this.getType = this.getType.bind(this);
		this.back = this.back.bind(this);
		this.updateCourse = this.updateCourse.bind(this);
		this.getNum = this.getNum.bind(this);
		this.notify = this.notify.bind(this);
		this.validateData = this.validateData.bind(this);
		this.empty = this.empty.bind(this);
		this.handleImageChange = this.handleImageChange.bind(this);
		this.getData = this.getData.bind(this);
		this.addMaterialModal = this.addMaterialModal.bind(this);
		this.addNewMaterial = this.addNewMaterial.bind(this);
		this.removeMaterial = this.removeMaterial.bind(this);
		this.inputMaterial = this.inputMaterial.bind(this);
		this.validateMaterialData = this.validateMaterialData.bind(this);
		this.submitMaterial = this.submitMaterial.bind(this);
	}

	/**
	 * Method that loads the elements to be initially displayed on the website.
	 */
	componentDidMount() {
		if (sessionStorage.getItem('userType') == 2) {
			this.state.detail = this.props.match.params.ID;
			this.setState({ detail: this.props.match.params.ID });
			const newCourseID = this.props.match.params.ID;

			if (this.state.imageLink.length === 0) {
				document.getElementById("courseImg").style.display = "none";
			}

			axios.get(baseUrl + 'course/getAllCategories').then((response) => {
				this.setState({ categories: response.data });
			});

			this.loadTypes();


			if (this.state.detail == 0) {
				this.setState({ title: 'Añadir curso' });

				document.getElementById('nameCategory').style.display = 'none';
				document.getElementById('nameCourseType').style.display = 'none';
				document.getElementById('nameHours').style.display = 'none';
				document.getElementById("courseImg").style.display = "none";

				this.setState({ hours: '' });
			} else {
				this.setState({ title: 'Editar curso' });
				document.getElementById('selectCategory').style.display = 'none';
				document.getElementById('selectCourseType').style.display = 'none';
				document.getElementById('hoursNum').style.display = 'none';
				document.getElementById('size').style.display = 'none';

				axios.get(baseUrl + 'course/selectCourseID/' + this.state.detail).then((response) => {
					this.setState({
						initial: response.data[0].initials,
						name: response.data[0].name,
						description: response.data[0].description,
						free: response.data[0].gratuitousYN,
						hours: response.data[0].hoursQuantity,
						enterprise: response.data[0].enterpriseID,
						nameTy: response.data[0].courseType,
						nameCat: response.data[0].categoryName,
						courseType: response.data[0].courseTypeID,
						category: response.data[0].categoryID
					});


				});
				this.getData(newCourseID);
				axios.get(baseUrl + 'course/getMaterial', {
					params: {
						courseID: this.state.detail
					}
				}).then(response => {
					var list = response.data[0];
					console.log(list);
					var i = 0;
					var name = "";
					var id = "";
					var link = "";
					list.map((item) => {
						name = "materialName" + (i + 1);
						id = "materialID" + (i + 1);
						link = "materialLink" + (i + 1);
						this.setState({
							[name]: item.name,
							[id]: item.courseMaterialID,
							[link]: item.materialLink
						});
						i = i + 1;
					});
					this.setState({
						counter: i
					});
				})
			}
		} else if (sessionStorage.getItem('userType') == 3) {
			this.props.history.push('/admin');
		} else {
			this.props.history.push('/');
		}
	}

	/**
	 * Method that receives an id and brings the link of the image from the database that corresponds to the id of the course entered, 
	 * in addition to loading it.
	 * @param {Integer} newCourseID 
	 */
	getData(newCourseID) {
		axios
			.get(baseUrl + 'course/getCourseMultimedia', {
				params: { newCourseID: newCourseID }
			})
			.then((response) => {
				var multimedia = response.data;
				multimedia.map((data) => {
					var name = data.link;
					if (window.innerWidth < 500) {
						name = name.substring(8, 10) + name.substring(name.length - 4, name.length);
					} else {
						name = name.substring(8, 12) + name.substring(name.length - 4, name.length);
					}
					document.getElementById("courseImg").src = baseUrl + data.link;
					document.getElementById("courseImg").style.display = "block";
					this.setState({
						imageLink: name,
						imageID: data.multimediaID,
						imageURL: '/root/servidor/public/' + data.link
					});


				});
			});
	}

	/**
	 * Method that validates the data entered by the user, the way it should be written.
	 * @param {event} event 
	 */
	validateData(event) {
		
		if (this.empty(event)) {
			if (!this.state.validations.validateTextWithNumberField(this.state.initial)) {
				this.notify(event, 'ERROR', 'Las siglas no deben contener caracteres especiales');
			} else if (!this.state.validations.validateTextWithNumberField(this.state.name)) {
				this.notify(event, 'ERROR', 'El nombre no debe contener carateres especiales');
			} else if (!this.state.validations.validateTextWithNumberField(this.state.description)) {
				this.notify(event, 'ERROR', 'La descripción no debe contener carecteres especiales');
			} else if (this.state.free != 0 && this.state.free != 1) {
				this.notify(event, 'ERROR', 'Debe seleccionar sí o no para la característica gratuito');
			} else if (!this.state.validations.validateNumericField(this.state.hours)) {
				this.notify(event, 'ERROR', 'La cantidad de horas debe ser un número');
			} else {

				if (this.state.detail !== '0') {
					this.updateCourse(event);

				} else {

					this.addCourse(event);
				}
			}
		}
	}

	/**
	 * Method that validates that the required fields are not empty.
	 * @param {event} event 
	 */
	empty(event) {
		if (this.state.initial.length === 0) {
			this.notify(event, 'ERROR', 'Las siglas no deben estar vacías');
			return false;
		} else if (this.state.name.length === 0) {
			this.notify(event, 'ERROR', 'El nombre no debe estar vacío');
			return false;
		} else if (this.state.description.length === 0) {
			this.notify(event, 'ERROR', 'La descripción no debe estar vacía');
			return false;
		} else if (this.state.hours === 0 || this.state.hours === '') {
			this.notify(event, 'ERROR', 'La cantidad de horas no debe estar vacía o ser 0');
			return false;
		} else if (this.state.courseType === 0) {
			this.notify(event, 'ERROR', 'Debe seleccionar un tipo de curso');
			return false;
		} else if (this.state.category === 0) {
			this.notify(event, 'ERROR', 'Debe seleccionar una categoría');
			return false;
		} else if ((this.state.image === null || this.state.image === undefined) && this.state.imageLink.length === 0) {
			this.notify(event, 'ERROR', 'Debe seleccionar la imagen del curso');
			return false;
		} else {
			return true;
		}
	}

	/**
	 * Method that returns to the previous page
	 */
	back() {
		this.props.history.push(`/CourseList`);
	}


	/**
 	* Method that loads and reads the image in the src structure.
 	* @param {input} input 
 	*/
	readFile1 = (input) => {
		if (input.files && input.files[0]) {
			var reader = new FileReader();
			reader.onload = function (e) {
				var filePreview = document.getElementById("courseImg");
				filePreview.src = e.target.result;
				filePreview.style.display = "block";
			}
			reader.readAsDataURL(input.files[0]);
		}
	}


	/**
	 * Method that goes through the list of categories and compares the name that is in the list with the selected 
	 * one, if they are the same it is assigned the value
	 */
	getCategory() {
		this.state.categories.map((eachS, keyS) => {
			if (eachS.categoryID == this.state.nameCat) {
				this.state.nameCat = eachS.categoryName;
				this.setState({ nameCat: eachS.categoryName });
			}
		});
	}

	/**
	 * Method that goes through the list of courseType and compares the name that is in the list with the selected 
	 * one, if they are the same it is assigned the value
	 */
	getType() {
		this.state.courseTypes.map((eachS, keyS) => {
			if (eachS.typeID == this.state.nameTy) {
				this.state.nameTy = eachS.typeName;
				this.setState({ nameTy: eachS.typeName });
			}
		});
	}
	/**
	 * Method that takes a call to the database and loads the list of course types and saves it to a variable.
	 */
	loadTypes() {
		axios.get(baseUrl + 'course/getAllTypeCourses').then((response) => {
			this.state.courseTypes = response.data;
			this.setState({ courseTypes: response.data });
		});
	}

	/**
	 * Method where the course is added to the database.
	 */
	addCourse(event) {

		const formData = new FormData();
		formData.append('initial', this.state.initial);
		formData.append('name', this.state.name);
		formData.append('description', this.state.description);
		formData.append('free', this.state.free);
		formData.append('hours', this.state.hours);
		formData.append('enterprise', this.state.enterprise);
		formData.append('courseType', this.state.courseType);
		formData.append('category', this.state.category);
		formData.append('image', this.state.image);

		axios.post(`${baseUrl}course/addCourse`, formData).then(response => {
			var courseID = response.data.courseID;
			this.submitMaterial(courseID);
			
			this.props.history.push('/CourseList');


		})
			.catch((err) => console.error(err));

	}

	/**
	 * Method that updates or carries the modifications made to the database.
	 * @param {event} event 
	 */
	updateCourse(event) {
		fetch(baseUrl + 'course/updateCourse', {
			method: 'put',
			body: JSON.stringify(this.state),
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			}
		})
		if (this.state.image !== null && this.state.image !== undefined) {
			const formData = new FormData();
			formData.append('image', this.state.image);
			formData.append('multimediaImageID', this.state.imageID);
			formData.append('url',this.state.imageURL);
			axios.post(`${baseUrl}course/updateCourseImage`, formData);
		}

		this.submitMaterial(this.state.detail);
		this.props.history.push('/CourseList');

	}

	/**
	 * Method that hides a component when adding and shows another.
	 */
	loadCategories() {
		document.getElementById('nameCategory').style.display = 'none';
		document.getElementById('selectCategory').style.display = 'block';
	}

	/**
	 * Method that hides a component when adding and shows another.
	 */
	getNum() {
		document.getElementById('nameHours').style.display = 'none';
		document.getElementById('hoursNum').style.display = 'block';
	}

	/**
	 * Method that hides a component when adding and shows another.
	 */
	loadCourseType() {
		document.getElementById('nameCourseType').style.display = 'none';
		document.getElementById('selectCourseType').style.display = 'block';
	}

	/**
	 * Method that assigns the value entered to the variable.
	 * @param {event} event 
	 */
	valueInitial(event) {
		this.state.initial = event.target.value;
		this.setState({ initial: event.target.value });
	}

		/**
	 * Method that assigns the value entered to the variable.
	 * @param {event} event 
	 */
	valueName(event) {
		this.state.name = event.target.value;
		this.setState({ name: event.target.value });
	}

	/**
	 * Method that assigns the value entered to the variable.
	 * @param {event} event 
	 */
	valueDescription(event) {
		this.state.description = event.target.value;
		this.setState({ description: event.target.value });
	}

	/**
	 * Method that assigns the value entered to the variable.
	 * @param {event} event 
	 */
	valueFree(event) {
		this.state.free = parseInt(event.target.value);
		this.setState({ free: parseInt(event.target.value) });
	}

	/**
	 * Method that assigns the value entered to the variable.
	 * @param {event} event 
	 */
	valueHours(event) {
		
		this.state.hours = parseInt(event.target.value);
		this.setState({ hours: parseInt(event.target.value) });
	}

	/**
	 * Method that assigns the value entered to the variable.
	 * @param {event} event 
	 */
	valueEnterprise(event) {
		this.state.enterprise = 1;
		this.setState({ enterprise: 1 });
	}

	/**
	 * Method that assigns the value entered to the variable.
	 * @param {event} event 
	 */
	valueCourseType(event) {
		this.state.courseType = parseInt(event.value);
		this.setState({ courseType: parseInt(event.value) });
	}

	/**
	 * Method that assigns the value entered to the variable.
	 * @param {event} event 
	 */
	valueCategory(event) {

		this.state.category = parseInt(event.value);
		this.setState({ category: parseInt(event.value) });
	}


	/**
	 * Method that show the course material modal
	 */
	toggle = () => {
		if (this.state.detail === 0) {
			this.setState({
				modal: !this.state.modal,
				modalTitle: "Agregar material"
			});
		} else {
			this.setState({
				modal: !this.state.modal,
				modalTitle: "Editar material"
			});
		}

		if (this.state.counter === 2) {
			this.setState({
				hidden2: false
			});
		}
		if (this.state.counter === 3) {
			this.setState({
				hidden2: false,
				hidden3: false
			});
		}
	}

	/**
	 * Method that validate the course material data
	 * @param {event} event 
	 */
	validateMaterialData(event) {
		var list = [];
		var obj = {};
		if (this.state.materialID1 !== 0 && this.state.materialName1.length === 0 && this.state.materialLink1.length === 0) {
			obj = { id: this.state.materialID1, name: this.state.materialName1, link: this.state.materialLink1 };
			list.push(obj);
		}
		if (this.state.materialID2 !== 0 && this.state.materialName2.length === 0 && this.state.materialLink2.length === 0) {
			obj = { id: this.state.materialID2, name: this.state.materialName2, link: this.state.materialLink2 };
			list.push(obj);
		}
		if (this.state.materialID3 !== 0 && this.state.materialName3.length === 0 && this.state.materialLink3.length === 0) {
			obj = { id: this.state.materialID3, name: this.state.materialName3, link: this.state.materialLink3 };
			list.push(obj);
		}
		if (this.state.materialName1.length !== 0 && this.state.materialLink1.length !== 0) {
			if (!this.state.validations.validateTextWithNumberField(this.state.materialName1)) {
				this.notify(event, 'ERROR', 'El nombre de los materiales no debe contener caracteres especiales');
			} else if (!this.state.validations.validateURLField(this.state.materialLink1)) {
				this.notify(event, 'ERROR', 'Debe agregar un link válido a los materiales');
			} else {
				obj = { id: this.state.materialID1, name: this.state.materialName1, link: this.state.materialLink1 };
				list.push(obj);
			}
		}
		if (this.state.materialName2.length !== 0 && this.state.materialLink2.length !== 0) {
			if (!this.state.validations.validateTextWithNumberField(this.state.materialName2)) {
				this.notify(event, 'ERROR', 'El nombre de los materiales no debe contener caracteres especiales');
			} else if (!this.state.validations.validateURLField(this.state.materialLink2)) {
				this.notify(event, 'ERROR', 'Debe agregar un link válido a los materiales');
			} else {
				obj = { id: this.state.materialID2, name: this.state.materialName2, link: this.state.materialLink2 };
				list.push(obj);
			}
		}
		if (this.state.materialName3.length !== 0 && this.state.materialLink3.length !== 0) {
			if (!this.state.validations.validateTextWithNumberField(this.state.materialName3)) {
				this.notify(event, 'ERROR', 'El nombre de los materiales no debe contener caracteres especiales');
			} else if (!this.state.validations.validateURLField(this.state.materialLink3)) {
				this.notify(event, 'ERROR', 'Debe agregar un link válido a los materiales');
			} else {
				obj = { id: this.state.materialID3, name: this.state.materialName3, link: this.state.materialLink3 };
				list.push(obj);
			}
		}

		if (list.length !== 0) {
			if (this.state.counter === 2) {
				this.setState({
					materialList: list,
					modal: !this.state.modal,
					hidden2: !this.state.hidden2
				});
			} else if (this.state.counter === 3) {
				this.setState({
					materialList: list,
					modal: !this.state.modal,
					hidden2: !this.state.hidden2,
					hidden3: !this.state.hidden3
				});
			} else {
				this.setState({
					materialList: list,
					modal: !this.state.modal
				});
			}
		} else {
			this.setState({
				materialList: list,
				modal: !this.state.modal,
				counter: 1
			});
		}
	}

	/**
	 * Method that submit the course material data
	 * @param {event} courseID 
	 */
	submitMaterial(courseID) {
		if (this.state.materialList.length !== 0) {
			for (var i = 0; i < this.state.materialList.length; i++) {
				if (this.state.materialList[i].id !== 0) {
					if (this.state.materialList[i].name.length === 0 && this.state.materialList[i].link.length === 0) {
						fetch(baseUrl + 'course/deleteMaterial', {
							method: "post",
							body: JSON.stringify({
								materialID: this.state.materialList[i].id
							}),
							headers: {
								Accept: "application/json",
								"Content-Type": "application/json"
							}
						});
					} else {
						fetch(baseUrl + 'course/updateMaterial', {
							method: "post",
							body: JSON.stringify({
								name: this.state.materialList[i].name,
								link: this.state.materialList[i].link,
								materialID: this.state.materialList[i].id
							}),
							headers: {
								Accept: "application/json",
								"Content-Type": "application/json"
							}
						});
					}
				} else {
					fetch(baseUrl + 'course/addMaterial', {
						method: "post",
						body: JSON.stringify({
							name: this.state.materialList[i].name,
							link: this.state.materialList[i].link,
							courseID: courseID
						}),
						headers: {
							Accept: "application/json",
							"Content-Type": "application/json"
						}
					});
				}
			}
		}
	}

	/**
	 * Method that loads the image link to the tag and calls another method to load the image to the src.
	 * @param {event} event 
	 */
	handleImageChange(event) {

		event.preventDefault();

		if (event.target.files && event.target.files.length === 1) {

			const file = event.target.files[0];
			this.setState({
				image: file
			});
			var name = file.name;
			if (window.innerWidth < 500) {
				if (name.length >= 10) {
					name = name.substring(0, 8) + name.substring(name.length - 4, name.length);
				}
			} else {
				if (name.length >= 20) {
					name = name.substring(0, 10) + name.substring(name.length - 4, name.length);
				}
			}
			document.getElementById('labelImage').textContent = name;
			document.getElementById('size').style.display = 'none';

		}
		this.readFile1(event.target);
	}

	/**
	 * Method that removes the course material from the list
	 * @param {event} event 
	 */
	removeMaterial(event) {
		if (this.state.counter > 0) {
			if (this.state.counter === 3) {
				this.setState({
					materialLink3: "",
					materialName3: "",
					hidden3: true
				});
			} else if (this.state.counter === 2) {
				this.setState({
					materialLink2: "",
					materialName2: "",
					hidden2: true
				});
			} else if (this.state.counter === 1) {
				document.getElementById("formgroup1").style.display = "none";
				this.setState({
					materialLink1: "",
					materialName1: "",
					hidden1: true
				});
			}
			this.setState({
				counter: this.state.counter - 1
			});
		} else {
			this.notify(event, 'WARN', 'No hay más elementos a eliminar');
		}
	}


	/**
	 * Method that add course material to the list
	 * @param {event} event 
	 */
	addNewMaterial(event) {
		if (this.state.counter < 3) {
			if (this.state.counter === 0) {
				document.getElementById("formgroup1").style.display = "block";
				this.setState({
					hidden1: false
				});
			} else if (this.state.counter === 1) {
				this.setState({
					hidden2: false
				});
			} else if (this.state.counter === 2) {
				this.setState({
					hidden3: false
				});
			}
			this.setState({
				counter: this.state.counter + 1
			});

		} else {
			this.notify(event, 'WARN', 'Solo se pueden agregar 3 elementos');
		}
	}


	/**
	 * 
	 * Method that, depending on the value of the variable, displays the type of message it is, if it is correctly entered, there was an 
	 * error, an informational message, or a warning message.
	 * @param {String} evt
	 * @param {String} value 
	 * @param {String} msj 
	 */
	notify = (evt, value, msj) => {
		switch (value) {
			case 'SUCCESS':
				toast.success(msj);
				break;
			case 'ERROR':
				toast.error(msj);
				break;
			case 'WARN':
				toast.warn(msj);
				break;
			case 'INFO':
				toast.info(msj);
				break;
			default:
				toast.info(msj);
		}
	};

	/**
	 * Method that change the state about the course material
	 * @param {event} event 
	 */
	inputMaterial(event) {
		this.setState({
			[event.target.name]: event.target.value
		});
	}

	/**
	 * Methos that perform the validate material data action when an ENTER is clicked
	 * @param {event} e 
	 */
	onKeyEventMaterial = (e) => {
		if (e.key == "Enter") {
			this.validateMaterialData(e);
		}
	}
	
	/**
	 * Method that perform the validate data action when an ENTER is clicked
	 * @param {event} e 
	 */
	onKeyEvent = (e) => {
		if (e.key == "Enter") {
			this.validateData(e);
		}
	}

	/**
	 * Method that render the course material modal
	 */
	addMaterialModal() {
		return (
			<MDBContainer>
				<MDBModal isOpen={this.state.modal} toggle={() => this.toggle()}>
					<MDBModalHeader className="modalHeader modalTitlesFont" toggle={() => this.toggle()}>{this.state.modalTitle}</MDBModalHeader>
					<MDBModalBody>
						<div id="newMaterial">
							<FormGroup id="formgroup1">
								<label className="modalTextFont" id="lbName1">Nombre del material</label>
								<input className="form-control modalTextFont" type="text" id="nameInput1" name="materialName1" value={this.state.materialName1} onChange={this.inputMaterial} onKeyPress={this.onKeyEventMaterial}></input>
								<label className="modalTextFont" id="lbLink1">Link del material</label>
								<input className="form-control modalTextFont" type="text" id="linkInput1" name="materialLink1" value={this.state.materialLink1} onChange={this.inputMaterial} onKeyPress={this.onKeyEventMaterial}></input>
							</FormGroup>
							<FormGroup id="formgroup2" hidden={this.state.hidden2}>
								<label className="modalTextFont" id="lbName2">Nombre del material</label>
								<input className="form-control modalTextFont" type="text" id="nameInput2" name="materialName2" value={this.state.materialName2} onChange={this.inputMaterial} onKeyPress={this.onKeyEventMaterial}></input>
								<label className="modalTextFont" id="lbLink2">Link del material</label>
								<input className="form-control modalTextFont" type="text" id="linkInput2" name="materialLink2" value={this.state.materialLink2} onChange={this.inputMaterial} onKeyPress={this.onKeyEventMaterial}></input>
							</FormGroup>
							<FormGroup id="formgroup3" hidden={this.state.hidden3}>
								<label className="modalTextFont" id="lbName3">Nombre del material</label>
								<input className="form-control modalTextFont" type="text" id="nameInput3" name="materialName3" value={this.state.materialName3} onChange={this.inputMaterial} onKeyPress={this.onKeyEventMaterial}></input>
								<label className="modalTextFont" id="lbLink3">Link del material</label>
								<input className="form-control modalTextFont" type="text" id="linkInput3" name="materialLink3" value={this.state.materialLink3} onChange={this.inputMaterial} onKeyPress={this.onKeyEventMaterial}></input>
							</FormGroup>
						</div>
						<FormGroup style={{ textAlign: 'right' }}>
							<MDBBtn id="plusIcon" color="orangeButton" className="materialBtn orangeButton" onClick={this.addNewMaterial}>
								<MDBIcon icon="plus" color="plusIcon" className="plusIcon" size="lg" />
							</MDBBtn>
							<MDBBtn id="trashIcon" color="orangeButton" className="materialBtn orangeButton" onClick={this.removeMaterial}>
								<MDBIcon id="icon" icon="trash" color="plusIcon" className="plusIcon " size="lg" />
							</MDBBtn>
						</FormGroup>
					</MDBModalBody>
					<MDBModalFooter>
						<MDBBtn color="greenButton" className="modalButton greenButton" onClick={this.validateMaterialData}><span className="modalButtonText">Guardar</span></MDBBtn>
					</MDBModalFooter>
				</MDBModal>
			</MDBContainer>
		)
	}

	/**
	 * Method that shows the elements found or added to the web page.
	 */
	render() {
		return (
			<MDBContainer>
				<this.addMaterialModal />
				<ToastContainer
					position="top-right"
					autoClose={5000}
					hideProgressBar
					newestOnTop
					closeOnClick
					rtl={false}
					pauseOnVisibilityChange
					draggable
					pauseOnHover
				/>
				<MDBRow>
					<MDBCol className="col-12 col-lg-9 offset-lg-3 mt-3">
						<h3 className="adminTitlesFont">{this.state.title}</h3>
					</MDBCol>
					<MDBCol className="col-12 col-lg-9 offset-lg-3 mb-3">
						<MDBCard style={{ padding: '20px 20px 20px 20px' }}>
							<MDBCardTitle className="cardHeader">Información del curso</MDBCardTitle>

							<MDBRow>
								<MDBCol className="col-12 col-md-4">
									<FormGroup>
										<label>Sigla <font color="red">*</font></label>
										<input
											className="form-control"
											value={this.state.initial}
											type="text"
											name="name"
											onChange={this.valueInitial}
											onKeyPress={this.onKeyEvent}
											required
										/>
									</FormGroup>

									<FormGroup>
										<label>Cantidad de horas <font color="red">*</font></label>
										<input
											className="form-control"
											id="nameHours"
											value={this.state.hours}
											type="text"
											name="name"
											onClick={this.getNum}
											onKeyPress={this.onKeyEvent}
										/>
										<input
											className="form-control"
											id="hoursNum"
											type="number"
											name="name"
											required
											onChange={this.valueHours}
											onKeyPress={this.onKeyEvent}
										/>
									</FormGroup>
								</MDBCol>
								<MDBCol className="col-12 col-md-4">
									<FormGroup>
										{this.getCategory()}
										<label>Categoría <font color="red">*</font></label>
										<input
											className="form-control"
											id="nameCategory"
											value={this.state.nameCat}
											type="text"
											name="name"
											onClick={this.loadCategories}
											onKeyPress={this.onKeyEvent}
										/>
										<Select
											className="selectLetter"
											id="selectCategory"
											required
											placeholder="Categoría"
											onChange={this.valueCategory}
											options={this.state.categories.map(function (json) {
												return { label: json.categoryName, value: json.categoryID };
											})}
											theme={theme => ({
												...theme,
												borderRadius: 4,
												font: 'Open Sans',
												height: 9,
												
											
												colors: {
													...theme.colors,
													primary25: '#FFA93F',
													primary: '#808080',
												},
											})}
										/>
									</FormGroup>

									<FormGroup>
										{this.getType()}
										<label >Tipo curso <font color="red">*</font></label>
										<div class="dropdown">
											<input
												className="form-control"
												id="nameCourseType"
												value={this.state.nameTy}
												type="text"
												name="name"
												onClick={this.loadCourseType}
											/>
											<Select
												className="selectLetter"
												id="selectCourseType"
												required
												placeholder="Tipo de curso"
												onChange={this.valueCourseType}
												options={this.state.courseTypes.map(function (json) {
													return { label: json.typeName, value: json.typeID };
												})}
												theme={theme => ({
													...theme,
													borderRadius: 4,
													font: 'Open Sans',
													height: 9,
													
												
													colors: {
														...theme.colors,
														primary25: '#FFA93F',
														primary: '#808080',
													},
												})}
											/>
										</div>
									</FormGroup>
								</MDBCol>

								<MDBCol className="col-12 col-md-4">
									<FormGroup>
										<label>Nombre <font color="red">*</font></label>
										<input
											className="form-control "
											type="text"
											name="name"
											value={this.state.name}
											size="15"
											required
											onChange={this.valueName}
											onKeyPress={this.onKeyEvent}
										/>
									</FormGroup>

									<FormGroup>
										<label>Gratuito <font color="red">*</font></label>
									</FormGroup>


									{this.state.free == 1 && (
										<div>
											<div class="form-check form-check-inline">
												<input
													class="form-check-input"
													type="radio"
													name="inlineRadioOptions"
													id="inlineRadio1"
													value="1"
													onChange={this.valueFree}
													checked
												/>
												<label class="form-check-label" for="inlineRadio1">
													Sí
													</label>
											</div>
											<div class="form-check form-check-inline">
												<input
													class="form-check-input"
													type="radio"
													name="inlineRadioOptions"
													id="inlineRadio2"
													value="0"
													onChange={this.valueFree}
												/>
												<label class="form-check-label" for="inlineRadio2">
													No
													</label>
											</div>
										</div>


									)}
									{this.state.free == 0 && (
										<div>
											<div class="form-check form-check-inline">
												<input
													class="form-check-input"
													type="radio"
													name="inlineRadioOptions"
													id="inlineRadio1"
													value="1"
													onChange={this.valueFree}
												/>
												<label class="form-check-label" for="inlineRadio1">
													Sí
													</label>
											</div>
											<div class="form-check form-check-inline">
												<input
													class="form-check-input"
													type="radio"
													name="inlineRadioOptions"
													id="inlineRadio2"
													value="0"
													onChange={this.valueFree}
													checked
												/>
												<label class="form-check-label" for="inlineRadio2">
													No
													</label>
											</div>
										</div>
									)
									}

								</MDBCol>
							</MDBRow>
							<MDBRow>
								<MDBCol className="col-12 col-md-8 order-1 order-md-2">
									<div class="form-group">
										<label for="exampleFormControlTextarea1">Descripción <font color="red">*</font></label>
										<textarea
		
											id="exampleFormControlTextarea1"
											value={this.state.description}
											rows="5"
											required
											onChange={this.valueDescription}
											onKeyPress={this.onKeyEvent}
										/>
										<label className="materialLink" onClick={() => this.toggle()}>Agregar material al curso</label>
									</div>
								</MDBCol>
								<MDBCol className="col-12 col-md-4 order-md-1 order-2">
									<FormGroup>
										<img src={imagen} id="courseImg" alt="logo" width="100%" height="30%" />
										<label>
											Seleccione una imagen <font color="red">*</font>
										</label>
										<div className="custom-file">
											<input
												type="file"
												className="custom-file-input"
												lang="es"
												id="courseImage"
												name="image"
												accept=".jpg,.svg,.jpeg,.png"
												onChange={this.handleImageChange}
												onKeyPress={this.onKeyEvent}
												required
											/>
											<div className="imageSizeCourse" id="size">
											Tamaño recomendado es 1920 x 1280
											</div>
											<label
												className="custom-file-label"
												id="labelImage"
												htmlFor="customFile"
											>
												{this.state.imageLink == "" &&
													<p>Elegir archivo </p>}
												{this.state.imageLink != null &&
													this.state.imageLink}
											</label>
										</div>
									</FormGroup>
								</MDBCol>
							</MDBRow>


							<MDBCardFooter color="white">
								<MDBBtn color="purpleButton" className="purpleButton generalButton" onClick={() => this.back()}>
									<span className="modalButtonText">Cancelar</span>
								</MDBBtn>
								<MDBBtn color="greenButton" className="greenButton generalButton" onClick={() => this.validateData()}>
									<span className="modalButtonText">	Guardar</span>
								</MDBBtn>
							</MDBCardFooter>


						</MDBCard>
					</MDBCol>
				</MDBRow>
			</MDBContainer>
		);
	}
}

export default CourseUp_In;
